import React, { useState, useEffect} from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import LoginVerify from "./pages/LoginVerify";
import SelectProvider from "./pages/SelectProvider";
import Dashboard from "./pages/Dashboard";
import PatientSearch from "./pages/PatientSearch";
import PatientHistory from "./pages/PatientHistory";
import RecentPreview from "./pages/RecentPreview";
import CCQ from "./pages/CCQ";
import CCQAction from "./pages/CCQAction";
import PatientContact from "./pages/PatientContact";
import ValidateProvider from "./pages/ValidateProvider";
import Program from "./pages/Program";
import CarePlan from "./pages/CarePlan";
import Hospitalizations from "./pages/Hospitalizations";
import RviewMedication from "./pages/RviewMedication";
import CareplanGoals from "./pages/CareplanGoals";
import CareplanNote from "./pages/CareplanNote";
import CareplanCondition from "./pages/CareplanCondition";
import ProgramLanding from "./pages/ProgramLanding";
import ClientDashboard from "./pages/ClientDashboard";
import ManageProviderList from "./pages/ManageProviderList";
import ManageUserList from "./pages/ManageUserList";
import AddProvider from "./pages/AddProvider";
import InsightBilling from "./pages/InsightBilling";
import JourneySelector from "./pages/JourneySelector";
import PatientReview from "./pages/PatientReview";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyOtp from "./pages/VerifyOtp";
import PatientInformation from "./pages/PatientInformation";
import PatientProvider from "./pages/PatientProvider";
import PatientAssignment from "./pages/PatientAssignment";
import RecentFinalized from "./pages/RecentFinalized";
import AddUser from "./pages/AddUser";
import ProgramTemplate from "./pages/ProgramTemplate";
import ManagePatient from "./pages/ManagePatient";
import CCQDetails from "./pages/CCQDetails";
import DashboardRedirect  from "./pages/DashboardRedirect";
import ProtectedRouteAdmin from "./Util/ProtectedRoute";
import PatientPharmacy from "./pages/PatientPharmacy";
import IdleTimer from "./Util/IdleTimer";
import LogoutModal from "./pages/LogoutModal";
import PatientFilterSearch from "./pages/PatientFilterSearch";
import ManageBilling from "./pages/ManageBilling";
import BillingList from './pages/BillingList';

function App() {

  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("Token") &&
      localStorage.getItem("Refresh_Token")
    ) {
      const timer = new IdleTimer({
        timeout: 900, //expire after x seconds
        onTimeout: () => {
          setIsTimeout(true);
          console.log("onTimeout");
        },
        onExpired: () => {
          //do something if expired on load
          setIsTimeout(true);
          console.log("onExpired");
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [isTimeout]);

  const onCloseModal = () => {
    setIsTimeout(false);
  };

  return isTimeout ? (
    <LogoutModal onClose={onCloseModal}></LogoutModal>
  ) : (
    <>
    <Router>
      <div className="App">
        <Switch>
          
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard-redirect" component={DashboardRedirect} />
          <Route exact path="/login-verify" component={LoginVerify} />
          <ProtectedRouteAdmin exact path="/provider-list" component={SelectProvider} />
          <ProtectedRouteAdmin exact path="/dashboard" component={Dashboard} />
         
          
          <ProtectedRouteAdmin  exact path="/patient-search" component={PatientSearch} />
          <ProtectedRouteAdmin  exact path="/patient-list" component={PatientFilterSearch} />
          <ProtectedRouteAdmin  exact path="/patient-assignment" component={PatientAssignment} />
          <ProtectedRouteAdmin  exact path="/recent-finalized" component={RecentFinalized} />
          <ProtectedRouteAdmin  exact path="/patient-history" component={PatientHistory} />
          <ProtectedRouteAdmin  exact path="/recent-preview" component={RecentPreview} />
          <ProtectedRouteAdmin  exact path="/ccq" component={CCQ} />
          <ProtectedRouteAdmin  exact path="/ccq-action" component={CCQAction} />
          <ProtectedRouteAdmin  exact path="/patient-contact" component={PatientContact} />
          <ProtectedRouteAdmin  exact path="/validate-provider" component={ValidateProvider} />
          <ProtectedRouteAdmin  exact path="/program" component={Program} />
          <ProtectedRouteAdmin  exact path="/resources" component={Program} />
          <ProtectedRouteAdmin  exact path="/care-plan-symtoms" component={CarePlan} />
          <ProtectedRouteAdmin  exact path="/hospitalizations" component={Hospitalizations} />
          <ProtectedRouteAdmin  exact path="/review-medication" component={RviewMedication} />
          <ProtectedRouteAdmin  exact path="/careplan-goals" component={CareplanGoals} />
          <ProtectedRouteAdmin  exact path="/careplan-note" component={CareplanNote} />
          <ProtectedRouteAdmin 
            exact
            path="/careplan-condition"
            component={CareplanCondition}
          />
          <ProtectedRouteAdmin  exact path="/program-landing" component={ProgramLanding} />
          <ProtectedRouteAdmin exact path="/client-dashboard" component={ClientDashboard} />
          <ProtectedRouteAdmin  exact path="/manage-provider" component={ManageProviderList} />
          <ProtectedRouteAdmin  exact path="/manage-user" component={ManageUserList} />
          <ProtectedRouteAdmin  exact path="/add-provider" component={AddProvider} />
          <ProtectedRouteAdmin  exact path="/reports" component={InsightBilling} />
          <ProtectedRouteAdmin  exact path="/journey-selector" component={JourneySelector} />
          <ProtectedRouteAdmin  exact path="/patient-review" component={PatientReview} />
          <ProtectedRouteAdmin  exact path="/forgot-password" component={ForgotPassword} />
          <ProtectedRouteAdmin  exact path="/verify-otp" component={VerifyOtp} />
          <ProtectedRouteAdmin  exact path="/patient-provider" component={PatientProvider} />
          <ProtectedRouteAdmin  exact path="/patient-pharmacy" component={PatientPharmacy} />
          <ProtectedRouteAdmin  exact path="/manage-provider" component={ManageProviderList} />
          <ProtectedRouteAdmin  exact path="/add-user" component={AddUser} />
          <ProtectedRouteAdmin  exact path="/program-template" component={ProgramTemplate} />
          <ProtectedRouteAdmin  exact path="/manage-patient" component={ManagePatient} />
          <ProtectedRouteAdmin  exact path="/ccq-details" component={CCQDetails} />
          {/* <Route exact path="/manage-provider" component={ManageProvider} /> */}
          <ProtectedRouteAdmin  exact path="/manage-billing" component={ManageBilling} />
          <ProtectedRouteAdmin  exact path="/billing-list" component={BillingList} />
          <ProtectedRouteAdmin 
            exact
            path="/patient-information"
            component={PatientInformation}
          />
          
        </Switch>
      </div>
    </Router>
    </>
  );
}

export default App;
