import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiArrowBack,BiClipboard, BiSearch,BiCheckSquare} from "react-icons/bi"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTime } from 'react-timer-hook'
import Select from "react-dropdown-select"
import Setting from '../components/Setting'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import TextField from '@material-ui/core/TextField'
import { FaMedkit } from "react-icons/fa"
import { FiPlusCircle } from "react-icons/fi";
import Patientinfo from '../components/Patientinfo'
import LeftNav from '../components/LeftNav'
import Timer from '../components/Timer'


function ValidateProvider({ options }) {
    const classes = useStyles();
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});
    const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState('panel6');
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };
  // Accordion3 //
  const [expanded4, setExpanded4] = React.useState('panel9');
  const handleChange4 = (panel) => (event, isExpanded4) => {
    setExpanded4(isExpanded4 ? panel : false);
  };
  // tab //
  const [value, setValue] = React.useState('1');

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <div>
            <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Patientinfo />
                 {/* left Accordion*/}
                 <LeftNav />

                 <Box className={classes.bottomnav}>
                  <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Timer />
                <Box className={classes.btncol} style={{ marginTop:'30px'}}></Box>
                   
                   {/* tab with accordion */}
                   <Box className={classes.Tabcol}>
                   <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Providers</Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={9}>
        <Box className={classes.symptomsdetails}>
           
           <h3 className={classes.topheading}>Daniel Caldwell</h3>
           <ul>
               <li><span>Physician Type</span> General & Interventional Cardiologist</li>
               <li><span>Phone</span> 301-535-0907</li>
               <li><span>Fax</span> 301-535-0099</li>
               <li><span>Email</span> alisha@dentoncardiology.net</li>
               <li><span>Appointment Date of Last Visit</span> 02/03/2019</li>
               <li><span>Appointment Date of Next Visit</span> --</li>
           </ul>
           
        </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
        <Box className={classes.Filter}>
           <Button className={classes.Addbtn}><FiPlusCircle className={classes.Addicon} /> Add Providers</Button>
        </Box>
        </Grid>
        </Grid>
        
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Other Prescribing CCM Providers</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar
            diam eros in elit. Pellentesque convallis laoreet laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Other Providers</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
            vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Pharmacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
            vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
                  </Box> 
               </Box>
            </Box>
        </div>
    )
}

export default ValidateProvider
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    Leftcol:{
        width: '22%',
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px'
    },
    Rightcol:{
        width:'78%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #5f80a9 solid',
            height:'auto',
            color:'#bfc4cb',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
    },
    ccqhistory:{
        '& ul':{
            listStyle:'none',
            margin:'0px',
            padding:'0 0 0 25px',
            position:'relative',
            '& li':{
                marginBottom:'15px',
                color:'#0f3d6e',
                '&:first-child::before':{
                    background:'#0f3d6e',
                    content:'""',
                },
                '&::before':{
                width:'10px',
                height:'10px',
                borderRadius:'50%',
                background:'#0f3d6e',
                content:'""',
                position:'absolute',
                left:'0px',
                display:'block',
                marginTop:'8px'
                }
            },
            '&::before':{
                position:'absolute',
                width:'1px',
                background:'#AEAEAE',
                content:'""',
                height:'88%',
                left:'5px',
                display:'block',
                top:'10px'
            }
        }
    },
    ccqtext:{
        fontSize:'12px',
        fontWeight:'normal',
        color:'#bfc4cb'
    },
    removetext:{
       fontSize:'13.5px',
       color:'#bfc4cb',
       margin:'0 0 5px', 
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        flexDirection:'column',
        alignItems:'flex-end',
        marginTop:'0'
   },  
   chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 30px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    '&:hover':{
        background:'#333'
    }
},
symptomsdetails:{
  '& .MuiInput-underline:before':{
    display:'none'
  },
  '& .MuiInput-underline:after':{
    borderBottom:'1px #ccc solid'
  },
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        '& li':{
            lineHeight:'40px',
            display:'flex',
            fontSize:'15px',
            '& span':{
                color:'#bfc4cb',
                width:'250px'
            }
        }
    }
},
topheading:{
  fontSize:'16px',
  fontWeight:'normal',
  color:'#000'
},
Filter:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'flex-end'
},
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Filtertext:{
    display:'flex',
    alignItems:'center',
    margin:'0 0 7px',
    '& span':{
        color:'#bfc4cb',
        marginRight:'5px'
    }
},
contacttab:{
  background:'#fff',
  boxShadow:'none',
  color:'#000',
  textTransform:'capitalize',
  '& .MuiTabs-indicator':{
    display:'none !important'
  },
  '& .MuiTabs-flexContainer':{
    borderRadius:'10px',
    background:'#F3F3F3',
    width:'360px',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTab-textColorInherit.Mui-selected':{
      background:'#0f3d6e',
            color:'#ffffff'
    },
    '& .MuiTab-textColorInherit':{
      textTransform:'capitalize',
      fontSize:'16px',
      padding:'0 22px'
    }
  },
  
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},
Accordionnav:{
  '& .MuiAccordion-root:before':{
    display:'none'
  },
  '& ul':{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
      fontSize:'15px',
      color:'#0f3d6e',
      lineHeight:'40px',
      '& a':{
        color:'#0f3d6e',
        cursor:'pointer',
        '&:hover':{
          color:'#0f3d6e',
          textDecoration:'none'
        }
      }
    }
  },
  '& .MuiTypography-root':{
    display:'flex',
    alignItems:'center'
  },
  '& .MuiAccordion-root.Mui-expanded':{
    margin:'0px'
  },
  '& .MuiAccordion-rounded':{
    borderBottom:'1px #E3E5E5 solid',
    padding:'10px 0',
    background: 'none',
    '@media only screen and (max-width: 1200px)':{
      borderBottom: "0px rgba(0,0,0,0.1) solid",
            },
  }
},
icon:{
  color:'#0f3d6e',
  fontSize:'20px',
  marginRight:'10px'
},
Addbtn:{
    color:'#0f3d6e',
    textTransform:'capitalize',
    fontSize:'16px',
    borderRadius:'10px'
},
Addicon:{
    marginRight:'10px',
    fontSize:'20px'
}  
   }));