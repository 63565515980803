import { Box, Button, Link, ListItem, Typography } from '@material-ui/core'
import React, { Component,useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import linechart from '../images/Line-chart.jpg'
import barchart from '../images/Bar-chart.jpg'
import Grid from '@material-ui/core/Grid'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Header from '../components/Header'
import Setting from '../components/Setting'
import ManageUser from '../components/ManageUser'
import ManageProvider from '../components/ManageProvider'
import ReactDOM from 'react-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { Bar } from 'react-chartjs-2'
const genData = () => ({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        borderWidth: 2,
        fill: false,
        data: [12, 19, 3, 5, 2, 3,8],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        type: 'bar',
        label: 'Dataset 2',
        data: [2, 3, 20, 5, 1, 4,9],
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'white',
        options: {
          scales: {
              xAxes: [{ barPercentage: 0.1 }]
          }
      }
  
      },
      {
        type: 'bar',
        label: 'Dataset 3',
        data: [3, 10, 13, 15, 22, 30,40],
        backgroundColor: 'rgb(75, 192, 192)',
        options: {
          scales: {
              xAxes: [{ barPercentage: 0.1 }]
          }
      }
  
      },
    ],
  });
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
function ClientDashboard() {
    const classes = useStyles();
    const [data, setData] = useState(genData());

  useEffect(() => {
    const interval = setInterval(() => setData(genData()), 5000);

    return () => clearInterval(interval);
  }, []);
    return (
        <div>
            
        <Header />
                <Box className={classes.pagecontent}>
                <Box className={classes.leftcol}>
                    <Box className={classes.leftnav}>
                       <ul>
                           <li>
                               <Typography variant='p'>Total Active Patients</Typography>
                               <Button style={{background:'#0f3d6e'}}>1200</Button>
                           </li>
                           <li>
                           <Typography variant='p'>Total Active CCM</Typography>
                               <Button>800</Button>
                           </li>
                           <li>
                           <Typography variant='p'>Total Active PCM</Typography>
                               <Button>100</Button>
                           </li>
                           <li>
                           <Typography variant='p'>Total Active RPM</Typography>
                               <Button>400</Button>
                           </li>
                       </ul>
                       <ManageUser />
                       <ManageProvider />
                       <Box className={classes.bottomnav}>
                       <Setting />
                       </Box>
                       </Box>
                    </Box>
                    <Box className={classes.graphcol}>
                        <Box className={classes.barchart}>
                        <Box className={classes.charttop}>
                            <h4>Patients w/ 10mins or more with no finalize</h4>
                            <ul>
                                <li><Link to="">D</Link></li>
                                <li><Link to="">W</Link></li>
                                <li><Link to="" className={classes.active}>M</Link></li>
                                <li><Link to="">Y</Link></li>
                            </ul>
                        </Box>
                        <Carousel>
                <div>
                <Bar data={data} options={options} />
                    
                </div>
                <div>
                <img src={barchart} alt="bar chart" />
                    
                </div>
                <div>
                <img src={barchart} alt="bar chart" />
                   
                </div>
            </Carousel>
                            
                            <Box className={classes.btncol}><Button className={classes.chartbtn}>Go To Patient List</Button></Box>
                            </Box>
                        <Box className={classes.linechart}>
                        <Box className={classes.linecharttop}>
                            <h4>Patient Engagement Shortcut</h4>
                            <ul>
                                <li><Link to="">2019</Link></li>
                                <li><Link to="">2020</Link></li>
                                <li><Link to="" className={classes.activeyear}>2021</Link></li>
                                
                            </ul>
                        </Box>
                            <img src={linechart} alt="line chart" />
                            <Box className={classes.btncol}><Button className={classes.chartbtn}>Go To Patient Assignment</Button></Box>
                            </Box>
                    </Box>
                    <Box className={classes.rightcol}>
                        <Box className={classes.patienttable}>
                            <h4>Most Recent Finalized Patients</h4>
                            <Box className={classes.throw}>
                               <Box className={classes.name}>Patient</Box>
                               <Box className={classes.type}>Type</Box>
                               <Box className={classes.date}>Date</Box>
                               <Box className={classes.provider}>Provider</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>John Doe</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Billy Thompson</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Lucy Ethel</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Stacey Kidd</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.btncol} style={{ marginTop:'15px'}}><Button className={classes.chartbtn} style={{width:'200px', padding:'10px'}}>Go To Finalize Preview</Button></Box>
                        </Box>
                        <Box className={classes.patienttable}>
                            <h4>CCQ Preview</h4>
                            <Box className={classes.throw}>
                               <Box className={classes.name}>Patient</Box>
                               <Box className={classes.type}>Type</Box>
                               <Box className={classes.date}>Date</Box>
                               <Box className={classes.provider}>Provider</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>John Doe</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Billy Thompson</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Lucy Ethel</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>Stacey Kidd</Box>
                               <Box className={classes.type}>CCM</Box>
                               <Box className={classes.date}>11-17-20</Box>
                               <Box className={classes.provider}>Candace</Box>
                            </Box>
                            <Box className={classes.btncol} style={{ marginTop:'15px'}}><Button className={classes.chartbtn} style={{width:'200px'}}>Go To CCQ</Button></Box>
                        </Box>
                        <Box className={classes.bottomtext}>
                        <Typography variant='p'><span>Date:</span> 04-02-2021 / <span>Time:</span> 08:45pm</Typography>
                        </Box>
                    </Box>
               
            </Box>
        </div>
    )
}

export default ClientDashboard
const useStyles = makeStyles(() => ({
  pagecontent:{
      width:'100%',
      overflow:'hidden',
      display:'flex'
  },
  bottomtext:{
      marginTop:'70%',
      '& p':{
          fontSize:'14px',
          color:'#aeaeae',
          '& span':{color:'#0f3d6e',}
      }
  },
  leftcol:{
      width:'12%',
      padding:'30px 4%',
      position:'relative',
      '& ul':{
          listStyle:'none',
          margin:'0px 0px 60px',
          padding:'0px',
          '& li':{
              color:'#1C295D',
              fontSize:'12px',
              fontWeight:'500'
          },
          '& li button':{
              background:'#0f3d6e',
              width:'150px',
              height:'55px',
              textAlign:'center',
              color:'#fff',
              borderRadius:'10px',
              fontSize:'18px',
              '&:hover':{
                  background:'#0f3d6e'
              }
          }
      }
  },
 
  graphcol:{
    width:'44%',
    padding:'30px 3%',
    borderLeft:'1px #F6F6F6 solid',
    '& .carousel.carousel-slider .control-arrow':{
        bottom:'initial',
        top:'45%',
        width:40,
        height:40,
        borderRadius:50,
        backgroundColor:'rgba(0,0,0,0.5)'
    },
    '& h4':{
        fontSize:'18px',
        color:'#3D4857',
        fontWeight:'500'
    }
  },
  rightcol:{
    width: '22%',
background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
    padding:'20px 2%',
    background:'#F6F7FA'
  },
  barchart:{
      marginBottom:'30px',
      '& img':{
          width:'100%'
      }
  },
  linechart:{
    '& img':{
        width:'100%'
    }
},
charttop:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        display:'flex',
        '& li':{
            color:'#0f3d6e',
            width:'30px',
            height:'30px',
            textAlign:'center',
            '& a':{
                color:'#0f3d6e',
                cursor:'pointer',
                '&:hover':{
                    color:'#000',
                    textDecoration:'none'
                }
            }
        }
    }
},
active:{
    background:'#eefaf1',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'3px',
    color:'#000 !important'
},
btncol:{
     display:'flex',
     justifyContent:'flex-end'
},
chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 30px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    '&:hover':{
        background:'#333'
    }
},
linecharttop:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        display:'flex',
        '& li':{
            color:'#0f3d6e',
            height:'30px',
            textAlign:'left',
            marginLeft:'15px',
            '& a':{
                color:'#0f3d6e',
                cursor:'pointer',
                padding:'0 5px',
                '&:hover':{
                    color:'#0f3d6e',
                    textDecoration:'none',
                    borderBottom:'2px #0f3d6e solid'
                }
            }
        }
    }
},
activeyear:{
    borderBottom:'2px #0f3d6e solid'
},
throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'2px #E3E5E5 solid',
    fontSize:'14px',
    color:'#bfc4cb',
    paddingBottom:'5px'
},
tdrow:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px #E3E5E5 solid',
    fontSize:'14px',
    padding:'7px 0'
},
name:{
    width:'40%',
    textAlign:'left',
    color: '#ffffff'
},
type:{
    width:'15%'
},
date:{
    width:'20%'
},
patienttable:{
marginBottom:'50px',
'& h4':{
    fontSize:'16px',
    textAlign:'left',
    color:'#0f3d6e',
    marginTop:'0px'
}
},
bottomnav:{
    position:'absolute',
    bottom:'0px',
    left:'0px'
},
leftnav:{
position:'absolute',
top:'15px',
bottom:'15px',
left:'40px'
}
  }));