import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import LeftNav from "../components/LeftNav";
import Patientinfo from "../components/Patientinfo";
import Timer from "../components/Timer";
import { FaPlusCircle } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { BiXCircle } from "react-icons/bi";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendericon from "../images/calendar-icon.svg";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";

import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import PatientInsuranceModal from "./PatientInsuranceModal";

import { BiChevronUp, BiChevronDown, BiErrorCircle } from "react-icons/bi";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrimaryProviderInfo() {
  const classes = useStyles();
  let pat_details = JSON.parse(localStorage.getItem("patient"));

  const [primaryProviderData, setPrimaryProviderData] = useState([]);
  const [otherProviderData, setOtherProviderData] = useState([]);
  const [expanded, setExpanded] = useState("false");

  const getProviderData = () => {
    let data = {
      pd_id: pat_details.pd_id,
      client_patient_program_id: pat_details.cpatp_id,
      table_config_rows_per_page: "15",
      table_config_page_no: "1",
    };
    apiAxios
      .post("/provider/list-patient-provider-program", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          console.log("provider data primary - ", response.data.data);
          if (response.data.data) {
            response.data.data.map((val) => {
              if (val.is_primary === "1") {
                setPrimaryProviderData(val);
              } else {
                setOtherProviderData(val);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProviderData();
  }, []);

  //   const handleModalOpen = (isEdit, data) => {
  //     setIsOpen(true);
  //     setIsEdit(isEdit);
  //     setInsuranceEditInfo(data);
  //   };

  //   const handleModalClose = () => {
  //     setIsOpen(false);
  //     getProviderData();
  //   };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  
  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }

  //   function handleClickDelete() {
  //     let data = {
  //       pd_id: pat_details.pd_id,
  //       id: otherProviderData[0].id,
  //     };

  //     apiAxios
  //       .post("/patient/delete-patient-new-insurance", data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("Token"),
  //         },
  //       })
  //       .then((response) => {
  //         console.log("response --> ", response);
  //         if (response.data.status == 200) {
  //           toast.success(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //           handleModalClose();
  //           getProviderData();
  //         } else {
  //           toast.error(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         toast.error(error.message, {
  //           position: "bottom-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       });
  //   }

  //   const deleteModal = () => {
  //     confirmAlert({
  //       customUI: ({ onClose }) => {
  //         return (
  //           <div className="custom-ui">
  //             <h1>Are you sure?</h1>
  //             <p>You want to delete this record?</p>
  //             <button
  //               className={classes.loginbtn}
  //               style={{
  //                 border: "2px solid #0f3d6e",
  //                 backgroundColor: "#fff",
  //                 color: "#0f3d6e",
  //                 cursor: "pointer",
  //                 width: "auto",
  //               }}
  //               onClick={onClose}
  //             >
  //               No
  //             </button>
  //             <button
  //               className={classes.loginbtn}
  //               style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
  //               onClick={() => {
  //                 handleClickDelete();
  //                 onClose();
  //               }}
  //             >
  //               Yes, Delete
  //             </button>
  //           </div>
  //         );
  //       },
  //     });
  //   };

  return (
    <div>
      <Box className={classes.Pagecontent}>
        <Box className={classes.Careplan}>
          <Box className={classes.plantop}>
            <h3 className={classes.topheading}>Primary Provider Information</h3>
          </Box>
          <Box className={classes.throw}>
            <Box className={classes.Row1}>Program Name</Box>
            <Box className={classes.Row2}>Provider Name</Box>
            <Box className={classes.Row3}>Program Abbrev</Box>
            <Box className={classes.Row4}>Mobile</Box>
            <Box className={classes.Row5}>Email</Box>
          </Box>
          {primaryProviderData ? (
            <>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                style={{ boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<FaPlusCircle />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ background: "#fff", borderRadius: "0" }}
                >
                  <Box className={classes.tdrow}>
                    <Box className={classes.Row1}>
                      {primaryProviderData.program_name
                        ? primaryProviderData.program_name
                        : "--"}
                    </Box>
                    <Box className={classes.Row2}>
                      {primaryProviderData.provider_name
                        ? primaryProviderData.provider_name
                        : "--"}
                    </Box>
                    <Box className={classes.Row3}>
                      {primaryProviderData.program_abbrev
                        ? primaryProviderData.program_abbrev
                        : "--"}
                    </Box>
                    <Box className={classes.Row4}>
                      {primaryProviderData.mobile
                        ? formatPhoneNumber(primaryProviderData.mobile)
                        : "--"}
                    </Box>
                    <Box className={classes.Row5}>
                      {primaryProviderData.email
                        ? primaryProviderData.email
                        : "--"}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                      <h3 className={classes.topheading}>Primary Provider Information</h3>
                      {primaryProviderData ? (
                        <>
                          <ul className={classes.Plandetails}>
                            <li>
                              <span>Program Name</span>{" "}
                              {primaryProviderData.program_name
                                ? primaryProviderData.program_name
                                : "--"}
                            </li>
                            <li>
                              <span>Provider Name</span>{" "}
                              {primaryProviderData.provider_name
                                ? primaryProviderData.provider_name
                                : "--"}
                            </li>
                            <li>
                              <span>Program Abbrev</span>{" "}
                              {primaryProviderData.program_abbrev
                                ? primaryProviderData.program_abbrev
                                : "--"}
                            </li>
                            <li>
                              <span>Mobile</span>{" "}
                              {primaryProviderData.mobile
                                ? formatPhoneNumber(primaryProviderData.mobile)
                                : "--"}
                            </li>
                            <li>
                              <span>Email</span>{" "}
                              {primaryProviderData.email
                                ? primaryProviderData.email
                                : "--"}
                            </li>
                          </ul>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <Box style={{ padding: "5%", textAlign: "center" }}>
              No data found!
            </Box>
          )}
        </Box>
      </Box>
      {/* <PatientInsuranceModal
        isOpen={isOpen}
        onClose={handleModalClose}
        isEdit={isEdit}
        otherProviderData={insuranceEditInfo[0]}
      ></PatientInsuranceModal> */}
    </div>
  );
}

export default PrimaryProviderInfo;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "22%",
    background: "linear-gradient(45deg, #114ebd, #0f3d6e)",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
  },
  Dateinput: {
    borderRadius: "10px",
    height: "42px",
    width: "98%",
    fontFamily: "Poppins",
    fontSize: "12px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    paddingLeft: 15,
    "&:focus": {
      border: "1px solid #D5D5D5 !important",
    },
  },
  Dateinput2: {
    borderRadius: "10px",
    height: "42px",
    width: "98%",
    fontFamily: "Poppins",
    fontSize: "12px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    paddingLeft: 15,
    "&:focus": {
      border: "1px solid #D5D5D5 !important",
    },
  },
  calendaricon: {
    position: "absolute",
    right: "5px",
    zIndex: "1",
    top: "12px",
    minWidth: "15px",
    height: "20px",
    width: "15px",
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
  },
  subheading: {
    fontSize: "16px",
    color: "#000",
    marginBottom: "0px",
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #5f80a9 solid",
      height: "40px",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: "40px",
    },
  },
  modaltop: {
    margin: "-20px 0 0",
  },
  Careplan: {
    width: "100%",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid",
      height: "40px",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
  modaltext: {
    marginTop: "5px",
    color: "#000",
    fontSize: "14px",
    marginBottom: "50px",
  },
  topheading: {
    fontSize: "18px",
    fontWeight: "normal",
    color: "#000",
    fontWeight: "500",
  },
  Filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  Filtertext: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 7px",
    "& span": {
      color: "#bfc4cb",
      marginRight: "5px",
    },
  },
  contacttab: {
    background: "#fff",
    boxShadow: "none",
    color: "#000",
    textTransform: "capitalize",
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
    "& .MuiTabs-flexContainer": {
      borderRadius: "10px",
      background: "#F3F3F3",
      width: "320px",
      textTransform: "capitalize",
      overflow: "hidden",
      "& .MuiTab-textColorInherit.Mui-selected": {
        background: "#0f3d6e",
        color: "#ffffff",
      },
      "& .MuiTab-textColorInherit": {
        textTransform: "capitalize",
        fontSize: "16px",
        padding: "0 22px",
      },
    },
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },

  icon: {
    color: "#0f3d6e",
    fontSize: "20px",
    marginRight: "10px",
  },
  throw: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px #E3E5E5 solid",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#ffffff",
    padding: 6,
    background: "linear-gradient(to left, #114ebd, #0f3d6e)",
    width: "98%",
    alignItems: "center",
  },
  tdrow: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "0px #E3E5E5 solid",
    fontSize: "12px",
    padding: "10px 0",
    width: "100%",
    alignItems: "center",
    lineHeight: "15px",
    color: "#000",
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },
  Row1: {
    width: "19.5%",
    paddingRight:'0.5%'
  },
  Row2: {
    width: "19.5%",
    wordWrap : "break-word",
    paddingRight:'0.5%'
  },
  Row3: {
    width: "14.5%",
    wordWrap : "break-word",
    textAlign: "center",
    paddingRight:'0.5%'
  },
  Row4: {
    width: "14.5%",
    paddingRight:'0.5%'
  },
  Row5: {
    width: "29.5%",
    paddingRight:'0.5%'
  },
  Row6: {
    width: "14.5%",
    paddingRight:'0.5%'
  },
  Row7: {
    width: "9.5%",
    display: "flex",
    justifyContent: "center",
    textAlign: "right",
    paddingRight:'0.5%'
  },
  Row8: {
    width: "14.5%",
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
    paddingRight:'0.5%'
  },
  Row9: {
    width: "9.5%",
    textAlign: "right",
    paddingRight:'0.5%'
  },
  Plandetails: {
    listStyle: "none",
    margin: "10px 0 0",
    minHeight: "300px",
    padding: "0px",
    "& li": {
      fontSize: "12px",
      marginBottom: "25px",
      display: "flex",
      position: "relative",
      "& span": {
        flex: "0 0 200px",
        color: "#114ebd",
      },
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "776px",
      maxWidth: "776px",
    },
    "& .MuiFormControlLabel-label": {
      flex: "0 0 220px !important",
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      fontFamily: "poppins",
    },
    "& .MuiBox-root": {
      width: "78%",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  Optionsbtn: {
    width: "150px",
    border: "1px #AEAEAE solid",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    marginTop: "10px",
    float: "right",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  textarea: {
    width: "100%",
    borderRadius: "10px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    padding: "10px 15px",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Editplan: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
    "& li": {
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      marginBottom: "15px",
      position: "relative",
      "& .MuiTypography-root": {
        flex: "0 0 150px",
        color: "#121212",
        margin: "0px",
      },
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInputBase-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  loginbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "120px",
    height: "40px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#0f3d6e",
  },
  plantop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "10px",
  },
  recordbtn: {
    background: "#fff",
    fontSize: "16px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  addicon: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginRight: "10px",
  },
  Tabcol: {
    "& .MuiTabPanel-root": {
      padding: "0px",
    },
  },
  radiobtn: {
    "& span": {
      fontSize: "13px",
      color: "#000",
    },
  },
  formgroup: {
    width: "100%",
    "& label": {
      color: "#000",
      marginBottom: "3px",
      display: "flex",
    },
  },
}));
