import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiDownArrowAlt } from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTime } from "react-timer-hook";
import Select from "react-dropdown-select";
import Setting from "../components/Setting";
import Patientinfo from "../components/Patientinfo";
import PatientInfoNav from "../components/PatientInfoNav";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { BiChevronUp, BiChevronDown, BiErrorCircle } from "react-icons/bi";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useLocation } from "react-router-dom";
import SymptomsCCQ from "./SymptomsCCQ";
import MedicationCCQ from "./MedicationCCQ";
import HospitalizationCCQ from "./HospitalizationCCQ";
import GoalsCCQ from "./GoalsCCQ";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";

import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateTimer from "../components/UpdateTimer";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BiClipboard, BiInfoCircle, BiXCircle } from "react-icons/bi";
import Slide from "@material-ui/core/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CCQDetails(props) {
  const classes = useStyles();
  const { seconds, minutes, hours, ampm } = useTime({ format: "12-hour" });
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [patientDetails, setPatientDetails] = React.useState("");

  const [caregiverList, setCaregiverList] = React.useState([]);
  const [caregiverListOption, setCaregiverListOption] = React.useState([]);
  const [providerProgramList, setProviderProgramList] = React.useState([]);
  const [engageDetails, setEngageDetails] = React.useState([]);

  localStorage.removeItem("patient");

 

  useEffect(() => {
    if (props.history.location.state) {
      setPatientDetails(props.history.location.state.patientInfo);

      getCaregivers();
      getProviderProgram();

      localStorage.removeItem("engagement_timer");
      localStorage.removeItem("start_timer");
    }
  }, [props.history.location.state.patientInfo]);

  const location = useLocation();
  const { PD_ID } = location.state;
  // console.log(PD_ID)
  // const {firstName} = location.state;
  // const {lastName}  = location.state;
  // const {dob} = location.state;
  // const {programName} = location.state;
  // const {emr} = location.state
  // const {hrpm} = location.state
  // const {providerFname} = location.state
  // const {providerLname} = location.state
  // const {patientInfo} = location.state
  // console.log(patientInfo)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const [selectedCaregiver, setSelectedCaregiver] = React.useState("");

  const [caregiverError, setCaregiverError] = React.useState(false);

  const [selected, setSelected] = React.useState("patient");

  const handleChangeSelected = (event) => {
    //console.log("Selected = ", selected);
    setSelected(event.target.value);
  };

  function continuePatientReview() {
    console.log("-------------");
    console.log(selectedCaregiver);

    let isValid = true;
    let speaking_with_id, user_speaking_id, speaking_with_types;

    let pat_details = JSON.parse(localStorage.getItem("patient"));
    let jwtData = JSON.parse(localStorage.getItem("JWT"));
    // pat_details.program_abbrev = engageDetails.program_abbrev;
    // pat_details.program_name = engageDetails.program_name;
    // pat_details.cpatp_id = engageDetails.patient_program_id;

    if (selected == "patient") {
      speaking_with_id = pat_details.pd_id;
      speaking_with_types = "0";
    } else {
      if (selectedCaregiver) {
        speaking_with_id = selectedCaregiver
          ? selectedCaregiver[0].value
          : selectedCaregiver.value;
        speaking_with_types = "1";
        setCaregiverError(false);
      } else {
        isValid = false;
        setCaregiverError(true);
      }
    }

    if (isValid) {
      let data;

      if (engageFlag == 1) {
        data = {
          speaking_with_id: speaking_with_id,
          pd_id: pat_details.pd_id,
          patient_program_id: engageDetails.patient_program_id,
          user_speaking_id: localStorage.getItem("User ID")
            ? localStorage.getItem("User ID")
            : jwtData.user_uuid,
          engagement_type: engageFlag, //1 emgagement, 0 view
          speaking_with_types: speaking_with_types,
        };
      } else {
        data = {
          speaking_with_id: "",
          pd_id: pat_details.pd_id,
          patient_program_id: engageDetails.patient_program_id,
          user_speaking_id: localStorage.getItem("User ID")
            ? localStorage.getItem("User ID")
            : jwtData.user_uuid,
          engagement_type: engageFlag, //1 emgagement, 0 view
          speaking_with_types: "",
        };
      }

      let url = "/patient/create-user-speaking-with-patient";

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            localStorage.setItem(
              "engagement_timer",
              JSON.stringify(engageDetails)
            );
            localStorage.setItem("start_timer", new Date());
            localStorage.setItem("engagement_ID", response.data.id);
            // localStorage.setItem("engagement_parent_ID", response.data.parent_id);
            //localStorage.setItem("patient", JSON.stringify(pat_details));
            let parentID = response.data.parent_id;
            localStorage.setItem(
              "engagement_parent_ID",
              JSON.stringify(parentID)
            );

            props.history.push({
              pathname: "/ccq-action",
              state: {
                patientInfo: props.history.location.state.patientInfo,
              },
            });
          } else if (response.data.statusCode == 502) {
            //setMessage("No Data Found");
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            //setMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";
        });
    }
  }

  const getProviderProgram = () => {
    // setProviderProgramList([]);
    // setMessage2("Loading...");

    //let patient_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      table_config_page_no: "1",
      table_config_rows_per_page: "100",
      patient_id: props.history.location.state.patientInfo.pd_id,
    };

    apiAxios
      .post("/finalize/timer-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setProviderProgramList(response.data.data);
        } else if (response.data.statusCode == 502) {
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        //localStorage.removeItem("Refresh_Token");
        //localStorage.removeItem("Token");
        //window.location.href = "/";
      });
  };

  useEffect(() => {
    if (providerProgramList.length > 0) {
      // alert(providerProgramList[0].agent_id)
      console.log(providerProgramList);
      console.log(props.history.location.state.patientInfo.cpatp_id);

      for (var i = 0; i < providerProgramList.length; i++) {
        if (
          providerProgramList[i].patient_program_id ===
          props.history.location.state.patientInfo.cpatp_id
        ) {
          console.log(providerProgramList[i]);

          setEngageDetails(providerProgramList[i]);
        }
      }
    }
  }, [providerProgramList]);

  // caregiver info

  useEffect(() => {
    if (caregiverList) {
      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < caregiverList.length; i++) {
        let value = caregiverList[i].id;
        let label =
          caregiverList[i].patient_caregiver_contacts_first_name +
          " " +
          caregiverList[i].patient_caregiver_contacts_last_name;
        let ob = {
          label: label,
          value: value,
        };
        console.log("ob types = ", ob);
        ob_arr.push(ob);
      }
      setCaregiverListOption(ob_arr);
      //  setSelectedCaregiver(ob_arr[0]);
      console.log("Caregivers - ", caregiverListOption);
    }
  }, [caregiverList]);

  function getCaregivers() {
    let data = {
      pd_id: props.history.location.state.patientInfo.pd_id,
      table_config_rows_per_page: "20",
      table_config_page_no: "1",
    };

    apiAxios
      .post("/patient/list-patient-caregiver-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          var temp = response.data.data;
          setCaregiverList(temp);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        localStorage.removeItem("Refresh_Token");
        localStorage.removeItem("Token");
        window.location.href = "/";
      });
  }

  // enagegement modal code

  const [engageFlag, setEngageFlag] = React.useState(2);

  const handleClickOpen = (flag) => {
    setEngageFlag(flag);
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    // setEngageDetails(each);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = () => {
    if (engageFlag == 0) {
      continuePatientReview();
    } else {
      setOpen3(true);
    }
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  //   ******   //
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  //  get symptoms

  // useEffect(() => {

  //   getCCQHist();

  // }, []);

  // const getCCQHist = useCallback(
  //   async ({
  //     table_config_rows_per_page = "15",
  //     table_config_page_no = "1",
  //   } = {}) => {
  //     //setCurrentPage(parseInt(table_config_page_no));

  //     setCCQHist([]);
  //     //setMessage("Loading...");
  //     let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

  //     let data = {
  //       table_config_page_no: table_config_page_no,
  //       table_config_rows_per_page: table_config_rows_per_page,

  //       pd_id: location.state?.ppID,
  //       patient_program_id: engage_details.patient_program_id
  //     };

  //     apiAxios
  //       .post("/client/list-ccq-history", data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("Token"),
  //         },
  //       })
  //       .then((response) => {
  //         console.log("response --> ", response);
  //         if (response.data.statusCode == 200) {
  //           setCCQHist(response.data.data);
  //           //setMessage("");
  //           //setTotalPages(response.data.total_pages);

  //           //setCcqCount(response.data.count_ccq);
  //           //setCcqPatCount(response.data.count_patient);
  //         } else if (response.data.statusCode == 502) {
  //           //setMessage("No Data Found");
  //         } else {
  //           toast.error(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });

  //           //setMessage(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         toast.error(error.message, {
  //           position: "bottom-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });

  //         // localStorage.removeItem("Refresh_Token");
  //         // localStorage.removeItem("Token");
  //         // window.location.href = "/";
  //       });
  //   }
  // );

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  return (
    <div>
      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            {/* <Patientinfo firstName={firstName} lastName = {lastName} dob={dob} emr= {emr} programName= {programName} hrpm= {hrpm} providerFname={providerFname} providerLname={providerLname} /> */}

            <PatientInfoNav
              patDetails={patientDetails}
              firstName={patientDetails.patient_first_name}
              lastName={patientDetails.patient_last_name}
              dob={patientDetails.patient_dob}
              emr={patientDetails.emr_id}
              programName={patientDetails.program_name}
              hrpm={patientDetails.hrpm_id}
              providerFname={patientDetails.provider_first_name}
              providerLname={patientDetails.provider_last_name}
              onClick={handleClickOpen2}
            />

            <Box className={classes.ccqhistory}>
              {/* { ccqHist ?
                <ul>
                  { ccqHist.map( (each) => (
                  <li>{ (each.ccq_category == "hospitalizations") ? "Hospitalization" : (each.ccq_category == "symptoms") ? "Symptoms" : (each.ccq_category == "goals") ? "Goals" : "Medications" } <FaArrowAltCircleDown style={{ color: '#1fdf1f', margin: '0 4px'}} />{each.ccq_add_date} <FaArrowAltCircleUp style={{ color: 'red', margin: '0 4px'}} />{each.ccq_remove_date ? each.ccq_remove_date : "--"}</li>
                  ))}
                </ul>
                :
                <ul>
                  <li>No Data Found</li>
                </ul>
                } */}
              <br />
              {/* <Link>
                <Button
                  style={{
                    color: "#fff",
                    backgroundColor: "#88b3eb",
                    padding: "5px 8px",
                    borderRadius: "10px",
                    textDecoration: "none",
                  }}
                  onClick={() => handleClickOpen2()}
                >
                  Start Engagement to remove from CCQ
                </Button>
              </Link> */}
            </Box>
            <Box className={classes.bottomnav}></Box>
          </Box>
        </Box>
        {/* right col */}

        <Box className={classes.Rightcol}>
          <a href="/ccq">
            <Button>
              <BiArrowBack
                className={classes.backarrow}
                style={{ color: "#b4bcc9" }}
              />{" "}
              Back to previous
            </Button>
          </a>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ boxShadow: "none", marginTop: "15px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Symptoms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <SymptomsCCQ PD_ID={PD_ID} />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>
                Hospitalization & Surgeries
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <HospitalizationCCQ PD_ID={PD_ID} />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Medications</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <MedicationCCQ PD_ID={PD_ID} />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Goals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <GoalsCCQ PD_ID={PD_ID} />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      
      <Dialog
        className={classes.modal}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose2} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                What would you like to do?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  className={classes.loginbtn}
                  onClick={() => handleClickOpen(0)}
                >
                  View Record
                </Button>

                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={() => handleClickOpen(1)}
                >
                  Engage with patient
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                Do you want to continue
                <br /> with the status:{" "}
                <span style={{ color: "#88b3eb" }}>
                  {engageDetails.program_abbrev}
                </span>
                ?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  onClick={handleClose}
                  className={classes.loginbtn}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={handleClickOpen3}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose3}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Button onClick={handleClose3} className={classes.closebtn}>
            <BiXCircle className={classes.closeicon} size="30" />
          </Button>
          <Typography
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 30,
              marginTop: 10,
            }}
          >
            Who are you speaking with ?
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                style={{ display: "flex", alignItems: "center" }}
                value={selected}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <FormControlLabel
                    value="patient"
                    control={
                      <Radio
                        color="primary"
                        selected={selected}
                        onChange={handleChangeSelected}
                      />
                    }
                    label="Patient"
                    style={{ width: 230, display: "flex", fontSize: 14 }}
                  />
                  <Typography
                    style={{
                      width: "70%",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    {patientDetails.patient_first_name +
                      " " +
                      patientDetails.patient_last_name}
                  </Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    value="caregiver"
                    control={
                      <Radio
                        selected={selected}
                        onChange={handleChangeSelected}
                        color="primary"
                      />
                    }
                    label="Select Caregiver"
                    style={{ fontSize: 14 }}
                  />
                  {console.log(caregiverListOption)}
                  <Select
                    options={caregiverListOption}
                    onChange={setSelectedCaregiver}
                    placeholder="Select caregiver"
                    className={classes.datepicker}
                    style={{ width: "230px" }}
                  />
                </Box>
              </RadioGroup>

              {caregiverError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: "right",
                  }}
                >
                  Please select a caregiver if available or go with patient
                </p>
              ) : (
                <></>
              )}

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 30,
                }}
              ></Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  className={classes.loginbtn}
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={continuePatientReview}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CCQDetails;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "24%",
    background: "linear-gradient(45deg, #114ebd, #0f3d6e)",
    padding: "20px 3%",
    position: "relative",
    height: "1050px",
    "@media only screen and (max-width: 1200px)": {
      width: "24%",
      padding: "20px 1%",
    },
    "@media only screen and (max-width: 820px)": {
      position: "absolute",
      background: "none",
      width: "98%",
      padding: "20px 1%",
      minHeight: 35,
    },
    "@media only screen and (max-width: 768px)": {
      position: "absolute",
      background: "none",
      width: "98%",
      padding: "20px 1%",
      minHeight: 35,
    },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "30px",
    width: "80%",
    "@media only screen and (max-width: 1200px)": {
      left: "20px",
      right: "20px",
      width: "96%",
    },
  },
  Rightcol: {
    width: "73%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    "@media only screen and (max-width: 1200px)": {
      width: "72%",
      padding: "0 1%",
    },
    "@media only screen and (max-width: 820px)": {
      width: "96%",
      padding: "0 2%",
      marginTop: 60,
    },
    "@media only screen and (max-width: 768px)": {
      width: "96%",
      padding: "0 2%",
      marginTop: 60,
    },
    "& .MuiAccordionSummary-root": {
      height: "45px",
      color: "#104696",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& button": {
      padding: "0px",
      background: "none",
      color: "#bfc4cb",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
    "& a": {
      textDecoration: "none",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 45,
    },
  },
  accSummar: {
    background: "rgb(243, 243, 243)",
    color: "rgb(15, 61, 110)",
    borderRadius: "10px",
    padding: "4px 10px",
    border: "none",
    margin: "5px 0",
  },
  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#bfc4cb",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  backarrow: {
    color: "#b4bcc9",
    fontSize: "20px",
    marginRight: "8px",
  },
  Downarrow: {
    fontSize: "15px",
  },
  patientinfo: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px #E3E5E5 solid",
    paddingBottom: "10px",
    marginBottom: "13px",
  },
  info: {
    color: "#bfc4cb",
    fontSize: "12px",
    "& span": {
      color: "#0f3d6e",
      fontSize: "16px",
      letterSpacing: "0.496657px",
    },
  },
  Optionsbtn: {
    width: "150px",
    border: "1px #AEAEAE solid",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    float: "right",
  },
  ccqhistory: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0 0 0 25px",
      position: "relative",
      "& li": {
        marginBottom: "15px",
        fontSize: "11px",
        color: "#bfc4cb",
        "&:first-child::before": {
          background: "#bfc4cb",
          content: '""',
        },
        "&::before": {
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          background: "#bfc4cb",
          content: '""',
          position: "absolute",
          left: "1.5px",
          display: "block",
          marginTop: "8px",
        },
      },
      "&::before": {
        position: "absolute",
        width: "1px",
        background: "#AEAEAE",
        content: '""',
        height: "88%",
        left: "5px",
        display: "block",
        top: "10px",
      },
    },
  },
  ccqtext: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#bfc4cb",
  },
  nametext: {
    fontSize: "12px",
    color: "#bfc4cb",
    margin: "40px 0 0",
  },
  name: {
    fontSize: "20px",
    color: "#ffffff",
    margin: "0 0 30px",
  },
  Timercol: {
    width: "96%",
    padding: "10px 2%",
    background: "linear-gradient(to left, #114ebd, #0f3d6e)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  Timer: {
    display: "flex",
    alignItems: "center",
  },
  Toptext: {
    fontSize: "12px",
    color: "#bfc4cb",
    margin: "0px",
  },
  Bottomtext: {
    fontSize: "16px",
    color: "#ffffff",
    margin: "0px",
    fontWeight: "normal",
  },
  Timertext: {
    fontSize: "14px",
    color: "#bfc4cb",
    margin: "0px",
    fontWeight: "normal",
  },
  Playbtn: {
    width: "38px",
    height: "38px",
    background: "#fff",
    borderRadius: "50%",
    display: "flex",
    minWidth: "38px",
  },
  pause: {
    width: "12px",
    height: "12px",
    background: "#000",
  },
  Playpusebtn: {
    marginRight: "10px",
  },
  Finalizebtn: {
    width: "142px",
    background: "#88b3eb",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0f3d6e",
    },
  },
  select: {
    width: "148px !important",
    height: "40px !important",
    border: "1px solid #AEAEAE !important",
    borderRadius: "10px !important",
    background: "#fff !important",
    "& .css-16cm63-DropDown": {
      width: "100% !important",
      top: "30px !important",
    },
  },
  Timerinfo: {
    "& span": {
      fontSize: "14px",
      color: "#fff",
    },
  },
  removetext: {
    fontSize: "13.5px",
    color: "#bfc4cb",
    margin: "0 0 5px",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "0",
  },
  chartbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    padding: "10px 30px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
  symptomsdetails: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
    width: "100%",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid",
      height: "auto",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
      "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
        {
          width: 1000,
        },
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
  Filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  Filtertext: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 7px",
    "& span": {
      color: "#bfc4cb",
      marginRight: "5px",
    },
  },
  modal: {
    "& a": {
      textDecoration: "none",
    },
    "& .react-datepicker-ignore-onclickoutside:focus": {
      border: "1px #0f3d6e solid !important",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .react-datepicker__input-container": {
      borderRadius: 10,
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "500px",
      maxWidth: "500px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: 0,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: "0 0 0 15px",
      },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "30px",
    color: "#0f3d6e",
  },
  loginform: {
    textAlign: "center",
  },
  loginbtn: {
    background: "#fff",
    padding: "0 20px",
    height: "40px",
    borderRadius: "10px",
    border: "1px #88b3eb solid",
    color: "#88b3eb",
    textTransform: "capitalize",
    marginRight: 15,
    "&:hover": {
      background: "#88b3eb",
      color: "#fff",
    },
  },
  chartbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    padding: "0 20px",
    height: "40px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
}));
