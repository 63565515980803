import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { apiAxios } from "../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-dropdown-select";
import { Box, Grid, Link } from "@material-ui/core";
import {
  BiChevronUp,
  BiChevronDown,
  BiArrowBack,
  BiPlusCircle,
  BiXCircle,
  BiCheckCircle,
} from "react-icons/bi";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LogoutModal(onClose) {
  const classes = useStyles();

  const continueClick = () => {
    if (
      localStorage.getItem("Token") &&
      localStorage.getItem("Refresh_Token")
    ) {
      let data = {
        token_id: localStorage.getItem("Token"),
        refresh_token: localStorage.getItem("Refresh_Token"),
      };

      apiAxios
        .post("/user/regenerate-idtoken", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            localStorage.setItem("Token", response.data.token_id
            );
            localStorage.setItem("Refresh_Token", response.data.refresh_token);
            onClose.onClose();
          } else {
            onClose.onClose();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      window.location.reload(true);
    }
  };

  const logoutClick = () => {
    localStorage.removeItem("Refresh_Token");
    localStorage.removeItem("Token");
    window.location.href = "/";
  };

  return (
    <>
      {/* <Modal show={true} keyboard={false}>
        <Modal.Header style={{ backgroundColor: "#093276", color: "#fff" }}>
          <Modal.Title>Session Timed out</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={continueClick}>
            Continue
          </Button>
          <Button variant="primary" onClick={logoutClick}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Dialog
        className={classes.modal}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            <Typography variant="h3" className={classes.TopHeading}>
              Session Timed Out
            </Typography>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <Grid container spacing={5} style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Button variant="secondary" onClick={continueClick}>
                  Continue
                </Button>
                <Button variant="primary" onClick={logoutClick}>
                  Logout
                </Button>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default LogoutModal;

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  marginTopTen: {
    marginTop: "10px !important",
    fontSize: "15px !important",
  },
  SubmitAdd: {
    background: "#1612C6",
    borderRadius: 50,
    height: 30,
    minWidth: 30,
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "600",
    width: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0A70E8",
    },
  },
  ModalTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 25,
  },
  checkCol: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 40,
  },
  checkList: {
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    "& label": {
      flex: "0 0 160px !important",
      color: "#141621",
      marginTop: "0px !important",
    },
  },
  ActionBtn: {
    borderRadius: 10,
    textTransform: "capitalize",
    background: "#0A70E8",
    color: "#fff",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  EditBtn: {
    borderRadius: 10,
    textTransform: "capitalize",
    background: "#E8740A",
    color: "#fff",
    marginRight: 10,
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  TopHeading: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "600",
  },
  addprovider: {
    fontSize: 16,
    textTransform: "capitalize",
    color: "#7087A7",
    "&:hover": {
      background: "none",
      color: "#000",
    },
  },
  btncol: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 20,
  },
  icon: {
    color: "#7087A7",
    fontSize: "24px",
    marginRight: "15px",
  },
  backarrow: {
    color: "#8088A8",
    fontSize: "20px",
    marginRight: "8px",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  Formcol2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "5px",
    "& label": {
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "350px",
      maxWidth: "350px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
}));
