import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-dropdown-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import {
  checkPhoneNumbers,
  checkEmail,
  checkName,
  checkPhone,
  checkUSPhone
} from "../../Util/validations";
import { stateList } from "../../Util/DropDownData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ResidenceInfoModal(props) {
  const classes = useStyles();

  const [residentName, setResidentName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");

  const [residentError, setResidentError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  useEffect(() => {
    console.log("Resident info Props - ", props);
    if (props.isOpen && props.isEdit) {
      setResidentName(props.residenceInfo.patient_assisted_residence_name);
      setCity(props.residenceInfo.patient_assisted_residence_city);
      setPhone(
        formatPhoneNumber(props.residenceInfo.patient_assisted_contacts_primary_phone_number)
      );
      setState(props.residenceInfo.patient_assisted_residence_state);
    } else {
      setResidentName("");
      setCity("");
      setPhone("");
      setState("");
    }
    setResidentError(false);
    setCityError(false);
    setStateError(false);
    setPhoneError(false);
  }, [props.isOpen === true]);

  const handleSubmit = () => {
    let isValid = true;

    if (!checkName(residentName.trim())) {
      isValid = false;
      setResidentError(true);
    } else {
      setResidentError(false);
    }
    if (!checkName(city.trim())) {
      isValid = false;
      setCityError(true);
    } else {
      setCityError(false);
    }
    if (!checkUSPhone(phone)) {
      isValid = false;
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    if (state == "") {
      isValid = false;
      setStateError(true);
    } else {
      setStateError(false);
    }

    if (isValid) {
      setShowLoader(true);
      if (props.isEdit) {
        let dataEdit = {
          pd_id: pat_details.pd_id,
          assisted_contact_id: props.residenceInfo.id,
          emr_id: pat_details.emr_id,
          patient_assisted_contacts_primary_phone_number: phone.replace(/[^0-9]/g,""),
          patient_assisted_residence_name: residentName,
          patient_assisted_residence_city: city,
          patient_assisted_residence_state: state,
        };

        console.log("dataEdit", dataEdit);

        apiAxios
          .post(
            "/patient/update-patient-assisted-residence-information",
            dataEdit,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Token"),
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        let dataAdd = {
          pd_id: pat_details.pd_id,
          emr_id: pat_details.emr_id,
          patient_assisted_contacts_primary_phone_number: phone.replace(/[^0-9]/g,""),
          patient_assisted_residence_name: residentName,
          patient_assisted_residence_city: city,
          patient_assisted_residence_state: state,
        };

        console.log("dataAdd", dataAdd);

        apiAxios
          .post(
            "/patient/create-patient-assisted-residence-information",
            dataAdd,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Token"),
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };


  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };
  


  

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }

  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        className={classes.modal}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
        </Box>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.onClose();
          }}
        >
          {props.isEdit ? "Edit" : "Add"} Assisted Residence Details
        </DialogTitle>
        <DialogContent className={classes.ccmmodal}>
          <form>
            <Box className={classes.Formcol}>
              <label>Name of Resident*</label>
              <TextField
                className={classes.input}
                placeholder="Name of Resident"
                type="text"
                value={residentName}
                onChange={(e) => {
                  setResidentError(false);
                  setResidentName(e.target.value);
                }}
              />
            </Box>
            {residentError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid residence name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>City*</label>
              <TextField
                className={classes.input}
                placeholder="Enter City"
                type="text"
                value={city}
                onChange={(e) => {
                  setCityError(false);
                  setCity(e.target.value);
                }}
              />
            </Box>
            {cityError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid city.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>State*</label>
              <FormControl className={classes.formControl}>
                <Select
                  options={stateList()}
                  value={state}
                  onChange={(e) => {
                    setState(e[0].label);
                  }}
                  placeholder="Select State"
                  className={classes.select}
                 
                  backspaceDelete={false}
                />
              </FormControl>
            </Box>
            {stateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please select a valid state.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Phone*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Phone Number"
                type="tel"
                value={phone}
                onChange={(e) => {
                 handleInput(e)
                }}
              />
            </Box>
            {phoneError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid phone number.
              </p>
            ) : (
              <></>
            )}
          </form>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResidenceInfoModal;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "78%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
