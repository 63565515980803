import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-dropdown-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import {
  checkPhoneNumbers,
  checkEmail,
  checkName,
  checkPhone,
  alphaNumeric,
  checkNumbersOnly,
} from "../Util/validations";
import { InsuranceWillPay } from "../Util/DropDownData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientInsuranceModal(props) {
  const classes = useStyles();

  const [primaryCo, setPrimaryCo] = useState("");
  const [primaryGrpNo, setPrimaryGrpNo] = useState("");
  const [primaryPayAmt, setPrimaryPayAmt] = useState("");
  const [secondaryCo, setSecondaryCo] = useState("");
  const [secondaryGrpNo, setSecondaryGrpNo] = useState("");
  const [secondaryPayAmt, setSecondaryPayAmt] = useState("");
  const [insuranceCode, setInsuranceCode] = useState(false);
  const [billingTier, setBillingTier] = useState([]);
  const [selectedTier, setSelectedTier] = useState("");
  const [primaryEffectiveDate, setPrimaryEffectiveDate] = useState(new Date());
  const [primaryEndDate, setPrimaryEndDate] = useState(new Date());
  const [secondaryEffectiveDate, setSecondaryEffectiveDate] = useState(new Date());
    
  const [secondaryEndDate, setSecondaryEndDate] = useState(new Date());

  const [primaryCoError, setPrimaryCoError] = useState(false);
  const [primaryGrpNoError, setPrimaryGrpNoError] = useState(false);
  const [primaryPayAmtError, setPrimaryPayAmtError] = useState(false);
  const [secondaryCoError, setSecondaryCoError] = useState(false);
  const [secondaryGrpNoError, setSecondaryGrpNoError] = useState(false);
  const [secondaryPayAmtError, setSecondaryPayAmtError] = useState(false);
  const [insuranceCodeError, setInsuranceCodeError] = useState(false);
  const [billingTierError, setBillingTierError] = useState(false);
  const [selectedTierError, setSelectedTierError] = useState(false);
  const [primaryEffectiveDateError, setPrimaryEffectiveDateError] =
    useState(false);
  const [primaryEndDateError, setPrimaryEndDateError] = useState(false);
  const [secondaryEffectiveDateError, setSecondaryEffectiveDateError] =
    useState(false);
  const [secondaryEndDateError, setSecondaryEndDateError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  useEffect(() => {

    console.log("Patient Insurance Props - ", props);
    setBillingTierError(false);
    setPrimaryCoError(false);
    setSecondaryCoError(false);
    setPrimaryGrpNoError(false);
    setSelectedTierError(false);
    setInsuranceCodeError(false);
    setPrimaryPayAmtError(false);
    setSecondaryGrpNoError(false);

    if (props.isOpen && props.isEdit) {
      setPrimaryCo(props.insuranceData.primary_insurance_co);
      setPrimaryGrpNo(props.insuranceData.primary_group_num);
      setPrimaryPayAmt(props.insuranceData.primary_co_pay_amount);
      setSecondaryCo(props.insuranceData.secondary_insurance_co);
      setSecondaryGrpNo(props.insuranceData.secondary_insurance_group_num);
      setSecondaryPayAmt(props.insuranceData.secondary_insurance_co_pay_amt);
      setSelectedTier(props.insuranceData.patient_billing_tier);

    
     


    
      if (props.insuranceData.insurance_will_pay_addon_code_flag == "True") {
        setInsuranceCode(true);
      }else {
        setInsuranceCode(false)
      }



      setPrimaryEffectiveDate(
        props.insuranceData.primary_effective_date.length > 0
          ? new Date(props.insuranceData.primary_effective_date)
          : new Date()
      );
      setPrimaryEndDate(
        props.insuranceData.primary_end_date.length > 0
          ? new Date(props.insuranceData.primary_end_date)
          : new Date()
      );
      setSecondaryEffectiveDate(
        props.insuranceData.secondary_effective_date.length > 0
          ? new Date(props.insuranceData.secondary_effective_date)
          : new Date()
      );
      setSecondaryEndDate(
        props.insuranceData.secondary_end_date.length > 0
          ? new Date(props.insuranceData.secondary_end_date)
          : new Date()
      );

    }else {


  setPrimaryCo("");
  setPrimaryGrpNo("");
  setPrimaryPayAmt("");
  setSecondaryCo("");
  setSecondaryGrpNo("");
  setSecondaryPayAmt("");
  setInsuranceCode(false);
  setBillingTier([]);
  setSelectedTier("");
  setPrimaryEffectiveDate(new Date());
  setPrimaryEndDate(new Date());
  setSecondaryEffectiveDate(new Date());
  setSecondaryEndDate(new Date());






    }







  }, [props.isOpen]);

  useEffect(() => {
    if (insuranceCode && billingTier.length == 0) {
      getBillingTier();
    }
  });

  

  const handleTierChange = (value) => {
    setInsuranceCode(value);
  };

  const getBillingTier = () => {
    let data = {
      table_config_rows_per_page: "15",
      table_config_page_no: "1",
      search_value: "MCS",
      is_dropdown: true,
    };

    apiAxios
      .post("/patient/list-patient-new-billing-tier", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        if (billingTier.length == 0) {
          response.data.data.map((e) => {
            billingTier.push({
              label: e.primary_description,
              value: e.billing_tier_id,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    let isValid = true;

    console.log(secondaryEffectiveDate)
    console.log(secondaryEndDate)
    console.log(primaryEffectiveDate)
    console.log(primaryEndDate)
   

    if (!checkName(primaryCo.trim())) {
      isValid = false;
      setPrimaryCoError(true);
    } else {
      setPrimaryCoError(false);
    }
    if (!alphaNumeric(primaryGrpNo.trim())) {
      isValid = false;
      setPrimaryGrpNoError(true);
    } else {
      setPrimaryGrpNoError(false);
    }
    if (!checkNumbersOnly(primaryPayAmt)) {
      isValid = false;
      setPrimaryPayAmtError(true);
    } else {
      setPrimaryPayAmtError(false);
    }
    if (!checkNumbersOnly(secondaryPayAmt) && secondaryPayAmt.length > 0) {
      isValid = false;
      setSecondaryPayAmtError(true);
    } else {
      setSecondaryPayAmtError(false);
    }
    if (insuranceCode === true) {
      if(selectedTier){
        setBillingTierError(false);
      
      }else {
        isValid = false;
      setBillingTierError(true);
     
      }
      
    } else {
      setBillingTierError(false); 
      
    }

    if(primaryEffectiveDate){
      setPrimaryEffectiveDateError(false)
    }else {
      setPrimaryEffectiveDateError(true)
      isValid = false
    }

  

      if(primaryEndDate && primaryEffectiveDate <= primaryEndDate){
        setPrimaryEndDateError(false)
        
      }else {
        isValid = false;
        setPrimaryEndDateError(true)
      }
      

     
      if(secondaryEndDate){

        if(!secondaryEffectiveDate){
          setSecondaryEffectiveDateError(true)
          isValid = false
        }else {
          setSecondaryEffectiveDateError(false)
        }


         if(secondaryEndDate >= secondaryEffectiveDate){
          setSecondaryEndDateError(false)
       }
       else {
         isValid = false;
         setSecondaryEndDateError(true)
          
       }


      }else {
        setSecondaryEndDateError(false)
        setSecondaryEffectiveDateError(false)
      }

     

     
    



    if (isValid) {
      setShowLoader(true);
      if (props.isEdit) {
        let dataEdit = {
          primary_insurance_co: primaryCo,
          primary_group_num: primaryGrpNo,
          primary_co_pay_amount: primaryPayAmt,
          secondary_insurance_co: secondaryCo,
          secondary_insurance_group_num: secondaryGrpNo,
          secondary_insurance_co_pay_amt: secondaryPayAmt,
          insurance_will_pay_addon_code_flag: insuranceCode,
          patient_billing_tier: insuranceCode ? selectedTier : "",
          id: props.insuranceData.id,
          pd_id: pat_details.pd_id,
          primary_member_num: "",
          primary_effective_date:primaryEffectiveDate?
            moment(primaryEffectiveDate).format("YYYY-MM-DD") : "",
          primary_end_date:primaryEndDate ? moment(primaryEndDate).format("YYYY-MM-DD") : "",
          secondary_insurance_effective_date: "",
          secondary_effective_date:secondaryEffectiveDate?  moment(secondaryEffectiveDate).format(
            "YYYY-MM-DD"
          ): "",
          secondary_end_date:secondaryEndDate?  moment(secondaryEndDate).format("YYYY-MM-DD") : "",
          secondary_insurance_update_date: "",
          primary_member_plan_number: "",
          secondary_member_plan_number: "",
        };

        console.log("dataEdit", dataEdit);

        apiAxios
          .post("/patient/update-patient-new-insurance", dataEdit, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        let dataAdd = {
          primary_insurance_co: primaryCo,
          primary_group_num: primaryGrpNo,
          primary_co_pay_amount: primaryPayAmt,
          secondary_insurance_co: secondaryCo,
          secondary_insurance_group_num: secondaryGrpNo,
          secondary_insurance_co_pay_amt: secondaryPayAmt,
          insurance_will_pay_addon_code_flag: insuranceCode,
          patient_billing_tier: insuranceCode ? selectedTier : "",
          pd_id: pat_details.pd_id,
          primary_member_num: "",
          primary_effective_date: primaryEffectiveDate?
            moment(primaryEffectiveDate).format("YYYY-MM-DD") : "",
          primary_end_date:primaryEndDate? moment(primaryEndDate).format("YYYY-MM-DD"): "",
          secondary_insurance_effective_date: "",
          secondary_effective_date:secondaryEffectiveDate? moment(secondaryEffectiveDate).format(
            "YYYY-MM-DD"
          ) : "",
          secondary_end_date:secondaryEndDate? moment(secondaryEndDate).format("YYYY-MM-DD") : "",
          secondary_insurance_update_date: "",
          primary_member_plan_number: "", 
          secondary_member_plan_number: "",
        };

        console.log("dataAdd", dataAdd);

        apiAxios
          .post("/patient/create-patient-new-insurance", dataAdd, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        className={classes.modal}
        disableEnforceFocus={true}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
        </Box>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.onClose();
          }}
        >
          {props.isEdit ? "Edit" : "Add"} Patient Insurance Details
        </DialogTitle>
        <DialogContent className={classes.ccmmodal}>
          <form>
            <Box className={classes.Formcol}>
              <label>Primary Insurance Company*</label>
              <TextField
                className={classes.input}
                placeholder="Primary Insurance Company"
                type="text"
                value={primaryCo}
                onChange={(e) => {
                  setPrimaryCoError(false);
                  setPrimaryCo(e.target.value);
                }}
              />
            </Box>
            {primaryCoError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid company name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Primary Group Number*</label>
              <TextField
                className={classes.input}
                placeholder="Primary Group Number"
                type="text"
                value={primaryGrpNo}
                onChange={(e) => {
                  setPrimaryGrpNoError(false);
                  setPrimaryGrpNo(e.target.value);
                }}
              />
            </Box>
            {primaryGrpNoError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid group number.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Primary Pay Amount*</label>
              <TextField
                className={classes.input}
                placeholder="Primary Pay Amount"
                type="text"
                value={primaryPayAmt}
                onChange={(e) => {
                  setPrimaryPayAmtError(false);
                  setPrimaryPayAmt(e.target.value);
                }}
              />
            </Box>
            {primaryPayAmtError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid amount.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Insurance Company</label>
              <TextField
                className={classes.input}
                placeholder="Secondary Insurance Company"
                type="text"
                value={secondaryCo}
                onChange={(e) => {
                  setSecondaryCoError(false);
                  setSecondaryCo(e.target.value);
                }}
              />
            </Box>
            {secondaryCoError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid secondary company name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Group Number</label>
              <TextField
                className={classes.input}
                placeholder="Secondary Group Number"
                type="text"
                value={secondaryGrpNo}
                onChange={(e) => {
                  setSecondaryGrpNoError(false);
                  setSecondaryGrpNo(e.target.value);
                }}
              />
            </Box>
            {secondaryGrpNoError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid secondary group number.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Pay Amount</label>
              <TextField
                className={classes.input}
                placeholder="Secondary Pay Amount"
                type="text"
                value={secondaryPayAmt}
                onChange={(e) => {
                  setSecondaryPayAmtError(false);
                  setSecondaryPayAmt(e.target.value);
                }}
              />
            </Box>
            {secondaryPayAmtError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid secondary amount.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Insurance will pay add on code*</label>
              <Select
                options={InsuranceWillPay()}
                // onChange={(values) =>
                //   values[0]
                //     ? handleTierChange(values[0].value)
                //     : ""
                // }
                placeholder="Select Insurance Code"
                onChange={(values)=>{
                  setInsuranceCode(values[0].value)
                }}
                className={classes.datepicker}
                style={{ width: "100%" }}
                values={InsuranceWillPay().filter(
                  (option) => option.value === insuranceCode
                )}
               
              />
            </Box>
            {insuranceCode ? (
              <>
                <Box className={classes.Formcol}>
                  <label>Billing Tier*</label>
                  <Select
                    options={billingTier}
                    onChange={(values) => setSelectedTier(values[0].value)}
                    className={classes.datepicker}
                    style={{ width: "100%" }}
                    values={billingTier.filter(
                      (option) => option.value == selectedTier
                    )}
                  />
                </Box>
                {billingTierError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                    }}
                  >
                    Please select a billing tier.
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Primary Effective Date*</label>
              <DatePicker
               className={classes.input}
                // value={
                //   new Date(moment(primaryEffectiveDate).format("YYYY-MM-DD"))
                // }
                value={primaryEffectiveDate}
                // onChange={(e) =>
                //   setPrimaryEffectiveDate(
                //     e
                //       ? e
                //       : new Date(
                //           moment(primaryEffectiveDate).format("YYYY-MM-DD")
                //         )
                //   )
                // }
                //maxDate={expireDate}
                onChange={(e)=>setPrimaryEffectiveDate(e)}
              />
            </Box>
            {primaryEffectiveDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid effective date.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Primary End Date*</label>
              <DatePicker
               className={classes.input}
                // value={new Date(moment(primaryEndDate).format("YYYY-MM-DD"))}

                value={primaryEndDate}
               
                // onChange={(e) =>
                //   setPrimaryEndDate(
                //     e
                //       ? e
                //       : new Date(moment(primaryEndDate).format("YYYY-MM-DD"))
                //   )
                // }
                onChange={(e)=>setPrimaryEndDate(e)}

                //maxDate={expireDate}
              
              />
            </Box>
            {primaryEndDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid end date greater than start date
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Effective Date</label>
              <DatePicker
                className={classes.input}
                // value={
                //   new Date(moment(secondaryEffectiveDate).format("YYYY-MM-DD"))
                // }

                value={secondaryEffectiveDate}
                
                // onChange={(e) =>
                //   setSecondaryEffectiveDate(
                //     e
                //       ? e
                //       : new Date(
                //           moment(secondaryEffectiveDate).format("YYYY-MM-DD")
                //         )
                //   )
                // }
                //maxDate={expireDate}

                onChange={(e)=>setSecondaryEffectiveDate(e)}
               

              />
            </Box>
            {secondaryEffectiveDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid effective date.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary End Date</label>
              <DatePicker
                className={classes.input}
                // value={
                //   new Date(moment(secondaryEndDate).format("YYYY-MM-DD"))
                // }
              value={secondaryEndDate}
                // onChange={(e) =>
                //   setSecondaryEndDate(
                //     e
                //       ? e
                //       : new Date(moment(secondaryEndDate).format("YYYY-MM-DD"))
                //   )
                // }
                onChange={(e)=>setSecondaryEndDate(e)}

             
              />
            </Box>
            {secondaryEndDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid Secondary End Date greater than Secondary Effective Date
              </p>
            ) : (
              <></>
            )}
          </form>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PatientInsuranceModal;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  datepicker: {
    borderRadius: "10px !important",
    height: "45px",
    width: "96%",
    fontFamily: "Poppins",
    fontSize: "14px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5 !important",
    paddingLeft:'15px !important',
    "&:focus": {
      border: "1px #0f3d6e solid !important",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "70%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      marginRight:10,
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "70%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },

    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
  },
 
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
