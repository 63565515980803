import React from "react";
import { Route, } from "react-router";
import { Link } from 'react-router-dom';

export const ProtectedRouteAdmin = (props) => {

  console.log("the props are here",props)
  if (localStorage.getItem("Token")) return <Route {...props} />;

 
  
  return (
    <>

       
      <div>
        
        <p>You are not authorized to view this page.</p> <p>Please <Link to="/">Login!</Link></p>
      </div>
    </>
  );
};

export default ProtectedRouteAdmin;
