import { Box, Grid, Link, Typography } from '@material-ui/core'
import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import profile from '../images/doctor1.jpg'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import {BiSearch, BiBell} from "react-icons/bi"
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'

function Header(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
      });
    const [ userDetails, setUserDetails ]  = React.useState(""); 

    const client_details = JSON.parse(localStorage.getItem("JWT"));
    const domainName = client_details.domain_name;
    const s3Url = client_details.s3_url;
    const imgLink = domainName + s3Url;
    
      useEffect(() => {
        
        setUserDetails( JSON.parse(localStorage.getItem("JWT")) );
    
      }, []);    

      

      const logout = () => {

          localStorage.removeItem("Token");
          localStorage.removeItem("Refresh_Token");
        //   localStorage.removeItem("JWT");
        //   localStorage.removeItem("User ID");
        //   localStorage.removeItem("patient");
        //   localStorage.removeItem("engagement_id");
          
          window.location.href = "/";
      

      }
    
      const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
      };

     const reloadPage = ()=>{
        window.location.reload();
     }




    return (
        <div>
            <Box className={classes.headertop}>
            <Grid container spacing={3}>
               <Grid item xs={12} sm={6}><Box className={classes.logo}><img src={logo} alt="logo" onClick={reloadPage} /> <h3 style={{fontWeight: '400', color: '#88b3eb', backgroundColor: '#0f3d6e0d', padding: '2% 5%', borderRadius: '20px', marginLeft: '5%'}}>{userDetails.client_name}</h3></Box></Grid>
               <Grid item xs={12} sm={3} style={{ display:'flex', alignItems:'center'}}>
                      {/* <form style={{ width:'100%'}}>
                      <Box className={classes.filterbox}>
                       <Box className={classes.searchcol}>
                           <BiSearch className={ classes.searchicon } />
                           <TextField className={classes.input}
                        placeholder="Search Patient"
          type="text"
        />
                           </Box> 
                           <Box className={classes.filtercol}>
                           <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
        <Select style={{ width:'100%'}}
          native
          value={state.Filter}
          onChange={handleChange}
          label="Filter"
          inputProps={{
            name: 'filter',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Ten</option>
          <option value={20}>Twenty</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
                            </Box>  
                           </Box>
                      </form> */}
               </Grid>
               <Grid item xs={12} sm={3} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                   {/* <Box className={classes.notification}><span className={classes.bellicon}></span><BiBell /></Box> */}
                   <Box className={classes.profilecol}>
                       <Box className={classes.profile}>
                      { client_details.logo_flag?
                        <img src={imgLink + `?${performance.now()}`}  alt="profile images" />
                        :
                        <img src={profile}  alt="profile images" />
                      }
                     
                      
                       </Box>
                       <Box className={classes.profileinfo}>
                           <h5 style={{fontSize:'14px'}}>{userDetails.first_name} {userDetails.last_name}</h5>
                           <Typography style={{margin:'0px', color:'rgb(136 179 235)',textAlign:'left',fontSize:'12px', fontWeight:'500'}}>{userDetails.email}</Typography>
                           <Typography style={{margin:'0px', color:'#0f3d6e',textAlign:'left',fontSize:'10px', fontWeight:'300'}}>Last Login: {userDetails.last_login}</Typography>
                       </Box>
                       <Box style={{marginLeft:15,}}>
                           <Button className={classes.logoutBtn} onClick={logout}><svg viewBox="0 0 841.9 595.3" width="50" height="50">
        <g fill="#88b3eb">
        <path id="XMLID_7_" d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z"/>
	<path id="XMLID_8_" d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z"/>
        </g>
      </svg></Button>
                           {/* <span style={{backgroundColor: '#0f3d6e', color: '#fff', padding: '4px 10px', borderRadius: '5px', marginTop: '8px'}} >
                       Logout</span> */}
                       </Box>
                   </Box>
               </Grid>
            </Grid>
            </Box>
        </div>
    )
}

export default Header
const useStyles = makeStyles(() => ({
    headertop:{
        width:'94%',
        padding:'10px 3% 10px',
        borderBottom:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
            width:'97.5%',
            padding:'10px 1% 10px',
                  },
    },
    logo:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        '& img':{
            height:80,
        }
    },
    logoutBtn:{
        width:40,height:40,borderRadius:50, minWidth:40, padding:0,
        '&:hover':{
            background:'#0f3d6e'
        },
        '& .MuiButton-label':{
            paddingLeft: '50%',
            marginTop:14,
        }
    },
    searchicon:{
        position:'absolute',
        left:'15px',
        zIndex:'999',
        top:'15px',
        fontSize:'25px'
    },
    input:{ 
      
        border:'none',
        borderRadius:'10px',
        height:'55px',
        width:'90%',
        border:'1px #ccc solid',
        padding:'0 15px 0 45px',
    },
    searchcol:{
        width:'58%',
        position:'relative'
    },
    filtercol:{
        width:'35%',
        display:'flex',
        '& .MuiInputBase-formControl':{
            borderRadius:'10px !important'
        },
        '&>div':{
            width:'100%'
        }
    },
    filterbox:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        '& .MuiInput-underline:before':{
            display:'none'
        },
        '& .MuiInput-underline:after':{
            display:'none'
        },
        '& .MuiInput-formControl':{ 
            height:'55px',
            
        },
        '& .MuiInputBase-input':{
            height:'auto',
            borderRadius:'10px',
        }
    },
    profile:{
        width:'45px',
        height:'45px',
        borderRadius:'50px',
        
        marginRight:'10px',
        overflow:'hidden',
        '& img':{
            width:'100%',
            objectFit:"stretch",
            height:'100%'
        }
    },

    // img:{
    //     // width: "100%",
    //     // textAlign: "center",
    //     // color: "green",
       
    //     // lineHeight: "100px",
    //     // borderRadius: "50%",
    //      // fontSize: "25px",
    //     left: "50%",
    //     position: "absolute",
    //     top: "50%",
    //     transform: "translate(-50%, -50%)",
       
    //     // background: "#512DA8",
      
    //     // color:"#fff",
    //     // textAlign: "center",
      
    //     // margin: "20px 0"
    //   },
    profileinfo:{
        '& a':{
            display:'flex',
            justifyContent:'flex-end',
            fontSize:'10px',
            color:'#0f3d6e'
        },
        '& h5':{
            textAlign:'left',
            margin:'0px',
            fontSize:'16px',
            color:'#0f3d6e',
        },
        '& p':{
            margin:'0px',
            color:'#AEAEAE',
            textAlign:'left',
            fontSize:'12px',
            fontWeight:'500'
        }
    },
    profilecol:{
        display:'flex',
        alignItems:'center',
        '& a':{
            textDecoration:'none',
            cursor:'pointer',
        },
        '& a:hover':{
          color:'#0f3d6e'
        }
    },
    notification:{
        marginRight:'20px',
        fontSize:'28px',
        position:'relative',
    },
   bellicon:{
    width:'5px',
    height:'5px',
    background:'#FF6E91',
    borderRadius:'50px',
    position:'absolute',
    right:'5px',
    top:'5px',
   }
}));