import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { makeStyles } from '@material-ui/core/styles'
  import Header from '../components/Header'
  import { Box,Grid, Link } from '@material-ui/core'
  import Button from '@material-ui/core/Button'
  import Accordion from '@material-ui/core/Accordion'
  import AccordionDetails from '@material-ui/core/AccordionDetails'
  import AccordionSummary from '@material-ui/core/AccordionSummary'
  import Typography from '@material-ui/core/Typography'
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
  import { useTime } from 'react-timer-hook'
  import TextField from '@material-ui/core/TextField'
  import Dialog from '@material-ui/core/Dialog'
  import DialogActions from '@material-ui/core/DialogActions'
  import Select from "react-dropdown-select"
  import DialogContent from '@material-ui/core/DialogContent'
  import DialogContentText from '@material-ui/core/DialogContentText'
  import DialogTitle from '@material-ui/core/DialogTitle'
  import Setting from '../components/Setting'
  import LeftNav from '../components/LeftNav'
  import Patientinfo from '../components/Patientinfo'
  import Timer from '../components/Timer'
  import TextareaAutosize from '@material-ui/core/TextareaAutosize'
  import FormControlLabel from '@material-ui/core/FormControlLabel'
  import FormControl from '@material-ui/core/FormControl'
  import FormLabel from '@material-ui/core/FormLabel'
  import Checkbox from '@material-ui/core/Checkbox'
  import FormGroup from '@material-ui/core/FormGroup'
  import { FaPlusCircle } from "react-icons/fa"
  import { BiXCircle,BiDownArrowAlt } from "react-icons/bi"
  import DatePicker from "react-datepicker"
  import Slide from '@material-ui/core/Slide'
  import { Pagination } from '@material-ui/lab';
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from "react-loader-spinner";
  
  import { apiAxios } from "../Util/ApiAxios"
  import moment from "moment";
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  
  function Program({ options }) {
      const classes = useStyles();
      const {
          seconds,
          minutes,
          hours,
          ampm,
        } = useTime({ format: '12-hour'});
      const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    const [open, setOpen] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
  
    
  
    const handleModal = (data) => {
      if(data == ""){
        setConditionDescription("");
        setFinalizeCat("");
        setIcd10codes("");
        setIcd10("");
        setDiagnosisDate("");
        setEditData("");
      }
      else{
        setEditData(data);
        setConditionDescription(data.condition_description);
        setFinalizeCat(data.finalize_category);
        setDiagnosisDate(new Date(data.diagnosis_date));
        if(icdList){
          for(var i=0;i< icdList.length; i++){
            if(data.icd10_codes == icdList[i].label){
              setIcd10(icdList[i]);
              
            }
          }
        }
      }
      console.log("icd - ", icd10);
      setOpen(true);
    }
  
    const handleClose = () => {
      setEditData("");
      setOpen(false);
    }
  
    // tab //
    const [value, setValue] = React.useState('1');
    const [editData, setEditData] = React.useState("");
    const [conditionDescription, setConditionDescription] = React.useState("");
    const [finalizeCat, setFinalizeCat] = React.useState("");
    const [icd10, setIcd10] = React.useState("");
    const [icd10codes, setIcd10codes] = React.useState("");
    const [diagnosisDate, setDiagnosisDate] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
    const [descriptionError, setDescriptionError] = React.useState(false);
    const [icdError, setIcdError] = React.useState(false);
    const [diagDateError, setDiagDateError] = React.useState(false);
  
    const [programList, setProgramList] = React.useState("");
    const [message, setMessage] = React.useState("");
  
    const [icdList, setIcdList] = React.useState("");
  
    useEffect(() => {
  
      getPrograms();
  
    }, []);  
  
    useEffect(() => {
      if(icd10codes){ 
         var ob_arr =[];
         //console.log("user types = ", userTypes);
         for(var i=0;i<icd10codes.length;i++){
         let value = icd10codes[i].id;
         let label = icd10codes[i].icd10_code_id;
         let ob = {
             "label":label, "value": value
         }
         //console.log("ob types = ", ob);
         ob_arr.push(ob);
         }
         setIcdList(ob_arr);
     }
     
   }, [icd10codes]);  
  
  
  
    const getPrograms = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      //setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
          
      setProgramList([]);
      setMessage("Loading...");
  
      let data ={
          table_config_page_no: table_config_page_no,
          pd_id: pd.pd_id,
          client_patient_program_id: pd.cpatp_id, 
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/program/list-program-summary", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.statusCode == 200) {
                setProgramList(response.data.data); 
                setMessage("");
                //setTotalPages(response.data.total_pages);
                
            }
            else if(response.data.statusCode == 502){
                setMessage("No Data Found");
            }
            else {
                // toast.error(response.data.message, {
                //     position: "bottom-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     });
                
                    setMessage(response.data.message);    
            }
          })
          .catch((error) => {
            console.log(error);
    
            // toast.error(error.message, {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );
  
    
  
    const handleChange2 = (event, newValue) => {
      setValue(newValue);
    };
      return (
          <div>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Header />
              <Box className={classes.Pagecontent}>
                 <Box className={classes.Leftcol}>
                 <Box className={classes.leftnav}>
                   
                  <Patientinfo />
                  
                   {/* left Accordion*/}
                   <Box className={classes.TabPatientinfo}>
                  <LeftNav />
                  </Box>
                   <Box className={classes.bottomnav}>
                   <Setting />
                 </Box>
                 </Box>
                 
                 </Box>
                 {/* right col */}
                 <Box className={classes.Rightcol}>
                 <Timer />
                 
                    <Box className={classes.Careplan}>
                          <Box className={classes.plantop}>
                              
                              <h3 className={classes.topheading}>Summary</h3>
                              
                          </Box>
                          <Box className={classes.throw}> 
                                    <Box className={classes.Row1}>Program</Box>
                                    <Box className={classes.Row2}>Care Team Coach</Box>
                                    {/* <Box className={classes.Row1}>Approx Date of Next Visit</Box> */}
                                    <Box className={classes.Row3}>Patient Call Frequency</Box>
                                    <Box className={classes.Row4}>Finalized Date</Box>
                                    {/* <Box className={classes.Row6}>Patient Call Date</Box> */}
                                    
                          </Box> 
                          {(programList.length > 0) ? 
                          <>
                          {programList.map((each) => (
                          <Box className={classes.tdrow}> 
                                    <Box className={classes.Row1}>{each.program_abbrev}</Box>
                                    <Box className={classes.Row2}>{each.care_team_coach}</Box>
                                    {/* <Box className={classes.Row1}>{each.patient_approximate_date_of_next_provider_visit? each.patient_approximate_date_of_next_provider_visit : "--"}</Box> */}
                                    <Box className={classes.Row3}>{each.patient_program_call_frequency?each.patient_program_call_frequency==10?'Monthly':each.patient_program_call_frequency: "--"}</Box>
                                    <Box className={classes.Row4}>{each.finalized_datetime ? moment.utc(each.finalized_datetime).local().format("YYYY-MM-DD HH:mm a") : "--"}</Box>
                                    {/* <Box className={classes.Row6}>{each.patient_program_call_date ? moment(each.patient_program_call_date).format("YYYY-MM-DD") : "--"}</Box> */}
                          </Box>
                          ))}
                          </>
                          :
                          <Box style={{padding: '5%', textAlign: 'center', width:'90%'}}> 
                            {message}
                          </Box>
                          }                  
                    </Box>
                    <br />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getPrograms({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 
                      
                 </Box>
                 
                 
              </Box>
              
  
  
                  <Dialog  className={classes.modal}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
              <Box className={classes.btncol}>
              <Button onClick={handleClose} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
              </Box>
            <DialogContentText id="alert-dialog-slide-description">
                <form>
              <ul className={classes.Editplan}>
                  <li><span>Condition Description<span style={{color:'red', fontSize: '15px'}}>*</span></span><TextField className={classes.input} onChange={(e) => setConditionDescription(e.target.value)}
                          value={conditionDescription ? conditionDescription : ""}
            type="text"
          /></li>
          {descriptionError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Condition description is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                  <li><span>Finalize Category</span> <TextareaAutosize onChange={(e) => setFinalizeCat(e.target.value)} className={classes.textarea} aria-label="minimum height" minRows={4} value={finalizeCat ? finalizeCat : ""} /></li>
                          
                  <li><span>ICD10 Codes<span style={{color:'red', fontSize: '15px'}}>*</span></span><FormControl style={{width:'100%'}}> <Select
                            options={icdList}
                            value={icd10.label ? icd10.label : ""}
                            onChange={setIcd10}
                            placeholder={icd10? icd10.label : "Select ICD10 Codes"}
                            className={classes.datepicker}
                          /></FormControl></li>
                  {icdError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    ICD10 code is mandatory.
                  </p>
                ) : (
                  <></>
                )}        
                                  
                  <li><span>Diagnosis Date<span style={{color:'red', fontSize: '15px'}}>*</span></span> 
                  <DatePicker className={classes.datepicker} placeholder="Start Date" selected={diagnosisDate} onChange={(date) => setDiagnosisDate(date)} />
                          </li>
                  {diagDateError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Diagnosis date is mandatory.
                  </p>
                ) : (
                  <></>
                )}        
              </ul>
              
              </form>
            </DialogContentText>
          </DialogContent>
          {editData?
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn}>
                          Update
                      </Button>
                  )
          }
          </DialogActions>
          :
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn}>
                          Submit
                      </Button>
                  )
          }
          </DialogActions>
          }
                  </Dialog>
              
          </div>
      )
  }
  
  export default Program
  const useStyles = makeStyles(() => ({
      Pagecontent:{
          width:'100%',
          display:'flex',
          textAlign:'left'
      },
      TabPatientinfo:{
        '@media only screen and (max-width: 820px)':{
          display:'none'
                },
                '@media only screen and (max-width: 768px)':{
                  display:'none'
                        },
      },
      Leftcol:{
          width: '22%',
          background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
          padding:'20px 3%',
          position:'relative',
          minHeight:'1050px',
          '@media only screen and (max-width: 1200px)':{
            width: '24%',
            padding:'20px 1%',
                  },
                  '@media only screen and (max-width: 820px)':{
                    position:'absolute',
                    background:'none',
                    width: '98%',
                    padding:'20px 1%',
                    minHeight:35,
                          },
                  '@media only screen and (max-width: 768px)':{
                    position:'absolute',
                    background:'none',
                    width: '98%',
                    padding:'20px 1%',
                    minHeight:35,
                          },
      },
      topheading:{
        fontSize:'18px',
        fontWeight:'normal',
        color:'#000',
        fontWeight:'500'
      },
      bottomnav:{
          position:'absolute',
          bottom:'0px',
          left:'0px'
      },
      leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1200px)':{
        left:'20px',
        right:'20px',
              },
      },
      Rightcol:{
          width:'78%',
          padding:'20px 3%',
          borderLeft:'1px #F6F6F6 solid',
          '@media only screen and (max-width: 1200px)':{
            width:'72%',
            padding:'0 1%',
                  },
                  '@media only screen and (max-width: 820px)':{
                    width:'96%',
                    padding:'0 2%',
                    marginTop:60,
                          },
                  '@media only screen and (max-width: 768px)':{
                    width:'96%',
                    padding:'0 2%',
                    marginTop:60,
                          },
          '& .MuiAccordionDetails-root':{ 
              paddingLeft:'0px',
              paddingRight:'0px'
          },
          '& .MuiTabPanel-root':{
            padding:'20px 0'
          },
          '& .MuiAccordionSummary-root':{
              borderBottom:'2px #5f80a9 solid',
              height:'auto',
              color:'#bfc4cb',
              fontFamily:'Poppins',
          },
          '& .MuiAccordion-root:before':{
              display:'none'
          },
          '& .MuiTab-root':{
            minHeight:'40px'
          }
      },
      
      btncol:{
          display:'flex',
          justifyContent:'flex-end',
          flexDirection:'column',
          alignItems:'flex-end',
          marginTop:'0'
     },  
  
     datepicker:{
      borderRadius:'10px !important',
        height:'45px',
        width:'96%',
        fontFamily:'Poppins',
        fontSize:'14px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5 !important',
        paddingLeft:15,
        '&:focus':{
          border:'1px #0f3d6e solid !important',
          zIndex: '999'
        }
    },
  
     modal:{
      '& .MuiPaper-rounded':{
          borderRadius:'10px !important',
          padding:'20px',
          width:'776px',
          maxWidth:'776px'
      }
  },
  ccmmodal:{
      borderRadius:'10px',
  },
  modalbtn:{
      display:'flex',
      justifyContent:'flex-end',
      marginRight:'30px'
  },
  Optionsbtn:{
      width:'150px',
      border:'1px #AEAEAE solid',
      borderRadius:'10px',
      display:'flex',
      justifyContent:'space-between',
      textTransform:'capitalize',
      float:'right'
  },
  input:{ 
        
      border:'none',
      borderRadius:'10px',
      height:'42px',
      width:'100%',
      fontFamily:'Poppins',
      fontSize:'12px'
  },
  textarea:{
      width:'100%',
      borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'10px 15px',
          fontFamily:'Poppins',
          fontSize:'12px'
  },
  Editplan:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        display:'flex',
        fontSize:'14px',
        alignItems:'center',
        marginBottom:'15px',
        '& span':{
            flex:'0 0 120px',
            color:'#121212',
            maxHeight: '50px'
        }
    },
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'42px',
        
    },
    '& .MuiInputBase-input':{
        height:'42px',
        borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'0 15px',
        fontFamily:'Poppins',
        fontSize:'12px',
        color:'#000'
    },
    '& .MuiInput-input:focus':{
      border:'1px #0f3d6e solid',
      boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
  }
  },
  loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
  btncol:{
    display:'flex',
    justifyContent:'flex-end',
  },
  closebtn:{
    width:'40px',
    position:'absolute',
    right:'10px',
    height:'40px',
    background:'#fff',
    top:'10px',
    minWidth:'40px',
    '&:hover':{
        background:'#fff'
    }
  },
  closeicon:{
    fontSize:'25px',
    color:'#0f3d6e'
  },
  contacttab:{
    background:'#fff',
    boxShadow:'none',
    color:'#000',
    textTransform:'capitalize',
    '& .MuiTabs-indicator':{
      display:'none !important'
    },
    '& .MuiTabs-flexContainer':{
      borderRadius:'10px',
      background:'#F3F3F3',
      width:'360px',
      textTransform:'capitalize',
      overflow:'hidden',
      '& .MuiTab-textColorInherit.Mui-selected':{
        background:'#0f3d6e',
              color:'#ffffff' 
      },
      '& .MuiTab-textColorInherit':{
        textTransform:'capitalize',
        fontSize:'16px',
        padding:'0 22px'
      }
    },
    
  },
  editbtn: {
    background: '#88b3eb',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer',
  },
  deletebtn: {
    background: '#0f3d6e',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer'
  },
  
  Careplan:{
    width:'100%',
    '& .MuiAccordionSummary-root':{
        borderBottom:'1px #e3e5e5 solid',
        height:'40px',
        color:'#bfc4cb',
        padding:'0px',
        fontFamily:'Poppins',
    },
    '& .MuiAccordion-root.Mui-expanded':{
        margin:'0px'
    },
    '& .MuiAccordionSummary-content':{
        margin:'0',
        order:'2',
        width:'100%'
    },
    '& .MuiIconButton-root':{
        padding:'5px',
        fontSize:'16px',
        marginRight:'5px',
        color:'#88b3eb',
        position:'absolute',
        left:'-25px'
    },
    '& .MuiAccordion-root:before':{
        display:'none'
    }
  },
  plantop:{
    display:'flex',
    justifyContent:'space-between',
    marginBottom:'30px',
    marginTop:'20px',
    '@media only screen and (max-width: 820px)':{
      marginBottom:'0px',
      marginTop:'10px',
            },
            '@media only screen and (max-width: 768px)':{
              marginBottom:'0px',
              marginTop:'10px',
                    },
  },
  recordbtn:{
    background:'#fff',
    fontSize:'16px',
    color:'#0f3d6e',
    textTransform:'capitalize',
    '&:hover':{
        background:'#fff'
    }
  },
  icon:{
    color:'#0f3d6e',
    fontSize:'20px',
    marginRight:'10px'
  },
  throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'2px #E3E5E5 solid',
    alignItems:'center',
    fontSize:'13px',
    lineHeight:'16px',
    color:'#fff',
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
    padding: 6,
    width:'98%',
    alignItems:'center'
  },
  tdrow:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'1px #E3E5E5 solid',
      fontSize:'14px',
      padding:'20px 0',
      width:'98%',
      padding: 6,
      alignItems:'center',
      lineHeight:'15px',
      color:'#000'
  },
  providerbtn:{
      display:'flex',
      cursor:'pointer',
      alignItems:'center',
      '& span':{
          display:'flex',
          flexDirection:'column',
          width:'20px',
          marginRight:'10px',
          '& button':{
              background:'none',
              border:'none',
              height:'10px',
              cursor:'pointer'
          }
      }
  },
  Row1:{
    width:'19.5%',
    paddingRight:'0.5%',
    textAlign: "left"
  },
  Row2:{
    width:'29.5%',
    paddingRight:'0.5%',
    textAlign: "left",
    wordWrap: "break-word"
  },
  Row3:{
    width:'24.5%',
    paddingRight:'0.5%',
    textAlign: "left",
    wordWrap: "break-word"
  },
  Row4:{
    width:'24.5%',
    paddingRight:'0.5%',
    textAlign: "right"
  },
  Row5:{
    width:'24.5%',
    paddingRight:'0.5%',
    textAlign:'right'
  },
  Row6:{
    width:'9.5%',
    paddingRight:'0.5%'
  },
  Row7:{
    width:'14.5%',
    paddingRight:'0.5%',
    display:'flex',
    justifyContent:'center',
    textAlign:'right'
  },
  
  
     }));