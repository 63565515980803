import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import { BiArrowBack } from "react-icons/bi";
import DatePicker from "react-date-picker";
import Select from "react-dropdown-select";
import Slide from "@material-ui/core/Slide";
import { BiPlus } from "react-icons/bi";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { BiCamera } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/core/SwitchUnstyled";
import TopHeading from "../components/Header";
import LeftNav from "../components/LeftNav";
import TextField from '@material-ui/core/TextField'
import { useLocation } from "react-router-dom";
import { apiAxios } from "../Util/ApiAxios";
import { stateList } from "../Util/DropDownData";
import {
  checkName,
  checkEmail,
  checkNumbers,
  checkPhone,
  checkPhoneNumbers,
  checkNumbersOnly,
  checkDob,
  checkOnlyZero,
  checkPassword,
  checkZip,
  checkUserName,
  checkUSPhone
} from "../Util/validations";
import moment from "moment";
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Checkbox from '@mui/material/Checkbox'
import { clientAdmin, careteam , noAdminAccess } from '../Util/Permission'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Input = styled("input")({
  display: "none",
});
const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #e1dddd;
    border-radius: 40px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 15px;
    height: 15px;
    top: 3px;
    left: 3px;
    border-radius: 40px;
    background-color: #10c20c;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: #e1dddd;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 27px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #e1dddd;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
function AddUser({ options }) {
  const classes = useStyles();
  const [value, setValue] = React.useState("female");
  const [toggleFlag, setToggleFlag] = React.useState(false);

  const [userTypes, setUserTypes] = React.useState([]);
  const [userType, setUserType] = React.useState();
  const [userTypesOption, setUserTypesOption] = React.useState([]);

  const [clientUserTypes, setClientUserTypes] = React.useState([]);
  const [clientUserType, setClientUserType] = React.useState();
  const [clientUserTypesOption, setClientUserTypesOption] = React.useState([]);

  const [checked, setChecked] = useState("noAdmin")
  
  const [permissionChecked, setPermissionChecked] = useState(noAdminAccess())

  const [newUserType, setNewUserType] = React.useState();
  const [ifLoading, setIfLoading] = useState(false);
  const [loading, setLoading] = useState(false)

  const location = useLocation();
  const client_id = location.state?.client_id;
  const client_name = location.state?.client_name;
 

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [value2, onChange2] = useState(new Date());

  const [npiNumber,setNpiNUmber] = useState("");
  const [npiGroupNumber,setNpiGroupNumber] = useState("")
  const [employerNumber,setEmployerNumber] = useState("")
  const [providerPracticeName,setProviderPracticeName] = useState("")
  const [countryCode,setCountryCode] = useState("")
  const [providerType,setProviderType] = useState("")
  const [mailingAddress,setMailingAddress] = useState("")
  const [mailingCity,setMailingCity] = useState("")
  const [mailingState,setMailingState] = useState("")
  const [mailingZip,setMailingZip] = useState("")
  const [locationAddress,setLocationAddress] = useState("")
  const [locationCity,setLocationCity] = useState("")
  const [locationState,setLocationState] = useState("")
  const [locationZip,setLocationZip] = useState("")

  const [npiNumberError,setNpiNUmberError] = useState(false);
  const [npiGroupNumberError,setNpiGroupNumberError] = useState(false)
  const [employerNumberError,setEmployerNumberError] = useState(false)
  const [providerPracticeNameError,setProviderPracticeNameError] = useState(false)
  const [countryCodeError,setCountryCodeError] = useState(false)
  const [providerTypeError,setProviderTypeError] = useState(false)
  const [mailingAddressError,setMailingAddressError] = useState(false)
  const [mailingCityError,setMailingCityError] = useState(false)
  const [mailingStateError,setMailingStateError] = useState(false)
  const [mailingZipError,setMailingZipError] = useState(false)
  const [locationAddressError,setLocationAddressError] = useState(false)
  const [locationCityError,setLocationCityError] = useState(false)
  const [locationStateError,setLoctionStateError] = useState(false)
  const [locationZipError,setLocationZipError] = useState(false)





  const [clientUserTypeError, setClientUserTypeError] = useState(false);
  const [userTypeError, setUserTypeError] = useState(false);
  const [firstnameError, setFirstNameError] = useState(false);
  const [lastnameError, setLastNameError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dobError, setDobError] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const toggleUserType = () => {
    console.log("hello world");
    if (toggleFlag) {
      setToggleFlag(false);
    } else {
      setToggleFlag(true);
    }
  };

  useEffect(() => {
    getUserType();
    getClientUserType();
  }, []);

  function addNewUserType() {
    let isValid = true;

    if (userType) {
      setUserTypeError(false);
      console.log("user type = ", userType);
    } else {
      setUserTypeError(true);
      isValid = false;
    }

    if (isValid) {
      setLoading(true)

      let data = {
        utm_id: userType ? userType[0].value : "",
        name: newUserType ? newUserType : "",
        client_id: client_id,
        permission: permissionChecked
      };

      apiAxios
        .post("/client/createusertype", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            getClientUserType();
            setToggleFlag(false);
            setChecked("noAdmin")
            setPermissionChecked(noAdminAccess())
            setNewUserType("")
            setLoading(false)
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          //localStorage.removeItem("Refresh_Token");
          //localStorage.removeItem("Token");
          //window.location.href = "/";
        });
    }
  }

  useEffect(() => {
    if (clientUserTypes) {
      var ob_arr = [];
      for (var i = 0; i < clientUserTypes.length; i++) {
        let value = clientUserTypes[i].id;
        let label = clientUserTypes[i].short_name;
        let perm = clientUserTypes[i].client_user_permission;
        let utmID = clientUserTypes[i].user_type_master_id;
        let ob = {
          label: label,
          value: value,
          permission: perm,
          utm : utmID
        };
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
      }
      setClientUserTypesOption(ob_arr);
      //console.log("Ob arr = ", clientUserTypesOption);
    }
  }, [clientUserTypes]);

  useEffect(() => {
    if (userTypes) {
      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < userTypes.length; i++) {
        let value = userTypes[i].id;
        let label = userTypes[i].name;
        let ob = {
          label: label,
          value: value,
        };
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
      }
      setUserTypesOption(ob_arr);
    }
  }, [userTypes]);

  function getUserType() {
    let data = {
      table_config_rows_per_page: "100",
      table_config_page_no: "1",
      search : "",
    };

    apiAxios
      .post("/client/user-type-master-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setUserTypes(response.data.data);
        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        //localStorage.removeItem("Refresh_Token");
        //localStorage.removeItem("Token");
        //window.location.href = "/";
      });
  }

  function backButton() {
    window.location.href = "/manage-provider";
  }

  function getClientUserType() {
    let data = {
      client_id: client_id,
      table_config_rows_per_page: "100",
      table_config_page_no: "1",
    };

    apiAxios
      .post("/client/client-user-type-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("client user type response --> ", response);

        if (response.data.statusCode == 200) {
          setClientUserTypes(response.data.data);

          //console.log("user types = ", userTypes);
        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })
      .catch((error) => {
        console.log(error);

        // toast.error("Something Went Wrong!", {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  }

  function submitForm() {
    let isValid = true;

    // addtional provider fields

    if(clientUserType?.utm != "13448f62-746e-438e-bf71-a153d67cbbda"){
      
      setNpiNUmber("")
      setNpiGroupNumber("")
      setEmployerNumber("")
      setProviderPracticeName("")
      setCountryCode("")
      setProviderType("")
      setMailingAddress("")
      setMailingCity("")
    
      setMailingZip("")
      setLocationAddress("")
      setLocationCity("")
   
      setLocationZip("")
      setLocationState("");
      setMailingState("");
      }


      
      if(providerPracticeName){
        if(!checkName(providerPracticeName.trim())){
          setProviderPracticeNameError(true)
          isValid = false
        }else {
          setProviderPracticeNameError(false)
        }
      }else {
        setProviderPracticeNameError(false)
      }



      if(mailingAddress){
        if(mailingAddress.trim()){
          setMailingAddressError(false)
        }else {
          setMailingAddressError(true)
          isValid = false
        }
      }else {
        setMailingAddressError(false)
      }


      if(locationAddress){
        if(locationAddress.trim()){
          setLocationAddressError(false)
        }else {
          setLocationAddressError(true)
          isValid = false
        }
      }else {
        setLocationAddressError(false)
      }


      if(mailingCity){
        if(mailingCity.trim()){
          setMailingCityError(false)
        }else {
          setMailingCityError(true)
          isValid = false
        }
      }else {
        setMailingCityError(false)
      }


      if(locationCity){
        if(locationCity.trim()){
          setLocationCityError(false)
        }else {
          setLocationCityError(true)
          isValid = false
        }
      }else {
        setLocationCityError(false)
      }

      if(mailingZip){
        if(!checkZip(mailingZip)){
          setMailingZipError(true)
          isValid = false
        }else {
          
          setMailingZipError(false)
        }
      }else {
        setMailingZipError(false)
      }
      

      if(locationZip){
        if(!checkZip(locationZip)){
          setLocationZipError(true)
          isValid = false
        }else {
          
          setLocationZipError(false)
        }
      }else {
        setLocationZipError(false)
      }


      if(providerType){
        if(providerType.trim()){
          setProviderTypeError(false)
        }else {
          setProviderTypeError(true)
          isValid = false
        }
      }else {
        setProviderTypeError(false)
      }
      

// mandatory fields



    console.log("submit form");
    console.log(clientUserType)

    if (!checkName(firstname.trim())) {
      setFirstNameError(true);
      isValid = false;
      console.log("First Error if= ", firstnameError);
    } else {
      setFirstNameError(false);
    }
    console.log("First Error = ", firstnameError);
    if (!checkUserName(username.trim())) {
      setUserNameError(true);
      isValid = false;
    } else {
      setUserNameError(false);
    }
    if (!checkName(lastname.trim())) {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }
    if (!checkUSPhone(phone)) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }
    if (!checkEmail(email)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (clientUserType) {
      setClientUserTypeError(false);
      console.log("user type = ", clientUserType);
    } else {
      setClientUserTypeError(true);
      isValid = false;
    }
   
    if(value2){
      if (parseInt(checkDob(value2)) < 18 || parseInt(checkDob(value2)) >200 || isNaN(parseInt(checkDob(value2)))) {
        setDobError(true);
        isValid = false;
      } else {
        setDobError(false);
      }
    }else {
      setDobError(false);
    }
  


    // if (!checkPassword(password)) {
    //   setPasswordError(true);
    //   isValid = false;
    // } else {
    //   setPasswordError(false);
    // }
    
    
    

    if (isValid) {
      setIfLoading(true);
      let data = {
        additional_info_view_table: "",
        cutid: clientUserType ? clientUserType.value : "",
        permission: clientUserType ? clientUserType.permission : "",
        email: email,
        first_name: firstname,
        last_name: lastname,
        dob: value2 === null ? "" : moment(value2).format("YYYY-MM-DD"),
        username: username,
        // password: password,
        user_login_panel_type: "1",
        mobile: phone.replace(/[^0-9]/g,''),
        action: "create_user_master",
        utm_id : clientUserType ? clientUserType.utm : "",
         
        provider_npi_number:npiNumber? npiNumber : "",
        provider_npi_group_number: npiGroupNumber ? npiGroupNumber : "",
        employer_number: employerNumber ? employerNumber : "",
        provider_practice_name: providerPracticeName ? providerPracticeName : "",
        provider_country_code: countryCode ? countryCode : "",
        provider_type: providerType ? providerType : "",
        provider_mailing_address: mailingAddress? mailingAddress : "",
        provider_mailing_city: mailingCity ? mailingCity : "",
        provider_mailing_state: mailingState? mailingState[0].label : mailingState.label,
        provider_mailing_zip: mailingZip ? mailingZip : "",
        provider_location_address: locationAddress ? locationAddress : "",
        provider_location_city: locationCity ? locationCity : "",
        provider_location_state: locationState ? locationState[0].label : locationState.label,
        provider_location_zip: locationZip ? locationZip : ""

        



      };

      console.log("Final Data = ", data);

      apiAxios
        .post("/client/create-user-master", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);

          if (response.data.statusCode == 200) {
            setClientUserTypes(response.data.data);

            //console.log("user types = ", userTypes);
            toast.success(response.data.messsage, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            
            setIfLoading(false);
            const timer = setTimeout(() => {
              window.location.href = "/manage-provider"; 
            }, 2000);
           
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }

         



        })
        .catch((error) => {
          console.log(error);
          setIfLoading(false);
          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  const handleCheck = (e)=>{   
    
   
    
   
    if(e.target.id ==="noAdmin" ){
      setPermissionChecked(noAdminAccess())
    
    }else if(e.target.id ==="careteamLevel"){
      setPermissionChecked(careteam())
     
    }else if(e.target.id ==="clientLevel"){
      setPermissionChecked( clientAdmin())
    
    }

    setChecked(e.target.id)
  }



  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };


  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;


  }




  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };
  return (
    <div>
      <Header />
      <Box className={classes.Pagecontent}>
        
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Link to="/dashboard">
                <Button className={classes.backBtn}>
                  <BiArrowBack
                    className={classes.backarrow}
                    onClick={backButton}
                  />
                </Button>
              </Link>
              <Box className={classes.loginform}>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.UserType}>
                        <label>User Type</label>
                        <Box style={{ width: "40%" }}>
                          <Select
                            options={clientUserTypesOption}
                            value={clientUserType ? clientUserType : ""}
                            onChange={(values)=>{
                              setClientUserType(values[0])}
                            }
                            placeholder="select user type"
                            className={classes.select}
                            style={{ width: "100%" }}
                          />
                        
                        </Box>
                        <Button
                          className={classes.AddBtn}
                          onClick={toggleUserType}
                        >
                          <BiPlus size="25" />
                        </Button>
                      </Box>
                      {clientUserTypeError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Select user type.
                        </p>
                      ) : (
                        <></>
                      )}
                      {/* <Typography variant="h5" className={classes.FormHeading}>Client Admin Information</Typography> */}
                      

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          First name required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Last Name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Last name required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          User Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="User Name"
                          value={username}
                          onChange={(e) => setUserName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {userNameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          User name required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>Date of Birth</label>
                        <DatePicker
                          onChange={onChange2}
                          value={value2}
                          className={classes.input}
                          maxDate={moment().toDate()}
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter correct dob for valid age range(18-200 years)
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Phone<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => {
                            
                            handleInput(e)
                          }}
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter valid phone.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Email<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Email Address"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter valid email.
                        </p>
                      ) : (
                        <></>
                      )}


                      
{clientUserType?.utm === "13448f62-746e-438e-bf71-a153d67cbbda"?
                        <>
                        <Box className={classes.Formcol}>
                        <label>
                          Provider NPI number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider NPI number"
                          type="number"
                          value={npiNumber}
                          onChange={(e) => {
                          
                              setNpiNUmber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>

                       <Box className={classes.Formcol}>
                        <label>
                          Provider NPI group number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider NPI Group Number"
                          type="number"
                          value={npiGroupNumber}
                          onChange={(e) => {
                         
                              setNpiGroupNumber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                        Employer number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Employer number"
                          type="number"
                          value={employerNumber}
                          onChange={(e) => {
                            
                              setEmployerNumber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>

                      <Box className={classes.Formcol}>
                        <label>
                        Provider practice name
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider practice name"
                          value={providerPracticeName}
                          onChange={(e) => setProviderPracticeName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {providerPracticeNameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid name
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider Country code
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider Country code"
                          type="number"
                          value={countryCode}
                          onChange={(e) => {
                          
                              setCountryCode(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                        Provider type
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider type"
                          value={providerType}
                          onChange={(e) => setProviderType(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {providerTypeError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid provider type
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider mailing address
                        </label>
                        <TextareaAutosize className={classes.textarea} aria-label="minimum height" 
                      maxLength={500} minRows={4} 
                      value={mailingAddress}
                      onChange={(e) => setMailingAddress(e.target.value)}
                       placeholder="Provider mailing address" />
                      </Box>
                      {mailingAddressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid address
                        </p>
                      ) : (
                        <></>
                      )}
                        
                      <Box className={classes.Formcol}>
                        <label>
                        Provider mailing city
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider mailing city"
                          value={mailingCity}
                          onChange={(e) => setMailingCity(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {mailingCityError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid city
                        </p>
                      ) : (
                        <></>
                      )}
                      
                      <Box className={classes.Formcol}>
                                            <label>
                                            Provider mailing state
                                            </label>
                                            <Select
                                                options={stateList()}
                                                value={mailingState}
                                                onChange={setMailingState}
                                                placeholder="Provider mailing state"
                                                className={classes.select}
                                            />
                                        </Box>
                          
                                        <Box className={classes.Formcol}>
                                            <label>
                                            Provider mailing zip
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Provider mailing zip"
                                                type="tel"
                                                value={mailingZip}
                                                onChange={(e) =>{
                                                    setMailingZip(e.target.value);
                                                    if (checkZip(e.target.value)) {
                                                     setMailingZipError(false);
                                                   } else {
                                                     setMailingZipError(true);
                                                     
                                                   }
                                                   }}
                                            />
                                        </Box>
                                        {mailingZipError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid zip(4 to 6 Digits)
                        </p>
                      ) : (
                        <></>
                      )}
                                        
                                
                                        <Box className={classes.Formcol}>
                        <label>
                        Provider location address
                        </label>
                        <TextareaAutosize className={classes.textarea} aria-label="minimum height" 
                      maxLength={500} minRows={4} 
                      value={locationAddress}
                      onChange={(e) => setLocationAddress(e.target.value)}
                       placeholder="Provider location address" />
                      </Box>
                      {locationAddressError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid address
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider Location city
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider Location city"
                          value={locationCity}
                          onChange={(e) => setLocationCity(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {locationCityError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid city
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                                            <label>
                                            Provider location state
                                            </label>
                                            <Select
                                                options={stateList()}
                                                value={locationState}
                                                onChange={setLocationState}
                                                placeholder="Provider location state"
                                                className={classes.select}
                                            />
                                        </Box>
                                        <Box className={classes.Formcol}>
                                            <label>
                                            Provider location zip
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Provider location zip"
                                                type="tel"
                                                value={locationZip}
                                                onChange={(e) =>{
                                                    setLocationZip(e.target.value);
                                                    if (checkZip(e.target.value)) {
                                                     setLocationZipError(false);
                                                   } else {
                                                     setLocationZipError(true);
                                                     
                                                   }
                                                   }}
                                            />
                                        </Box>
                                        {locationZipError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid zip(4 to 6 Digits)
                        </p>
                      ) : (
                        <></>
                      )}
                      </>
                      : 
                      <></>





                         }


                      {/* <Box className={classes.Formcol}>
                        <label>
                          Password<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Box>
                      {passwordError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter valid password. (Password should have: 8
                          characters and minimum 1 upper character, 1 lower
                          character, 1 number and 1 special character)
                        </p>
                      ) : (
                        <></>
                      )} */}
                  
                      <Box className={classes.Btncol}>
                       
                      {!ifLoading ?
                      <>
                          {/* <Button
                            size="large"
                            className={classes.backButton}
                            onClick={backButton}
                            // style={{position : "fixed"}}
                          >
                            {" "}
                            Back{" "}
                          </Button> */}
                        
                       
                        
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={submitForm}
                        >
                          {" "}
                          Submit{" "}
                        </Button></>
                        :
                        <Loader
                        type="ThreeDots"
                        color="#000000"
                        height={50}
                        width={50}
                        timeout={0} //30 secs
                      />}
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            {toggleFlag ? (
              <Grid item xs={4}>
                <Box className={classes.Addform}>
                  <Box className={classes.loginform}>
                    <Box className={classes.RightFormcol}>
                      <label>
                        New user type<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <Select
                        options={userTypesOption}
                        value={userType}
                        onChange={setUserType}
                        placeholder="Select user type"
                        className={classes.select}
                        style={{ width: "100%" }}
                      />
                    </Box>
                    {userTypeError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Select user type.
                      </p>
                    ) : (
                      <></>
                    )}

             <Box style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                       
                       {/* <label>Permission
                         
                         </label> */}
                      
                     <FormControl>
                                     <RadioGroup
                                       row
                                       aria-labelledby="demo-row-radio-buttons-group-label"                                   
                                     >
                                       <label>Client Admin Level</label>
                                      <Checkbox
                                      name="client"
                                      id="clientLevel"
                                     //  value={clientAdminLevel}
                                      inputProps={{ 'aria-label': 'Checkbox A'}}
                                      onChange={handleCheck}
                                       checked={checked === "clientLevel"}
                                      />

                                    <label>Care Team Level</label>
                                      <Checkbox
                                      name="client"
                                      id="careteamLevel"
                                     // value={careteamLevel}
                                      inputProps={{ 'aria-label': 'Checkbox B'}}
                                      onChange={handleCheck}
                                      checked={checked === "careteamLevel"}
                                      />

                                     <label>No Admin Access Level</label>
                                      <Checkbox
                                       name="client"
                                       id="noAdmin"
                                     //  value={noAdmin}
                                      inputProps={{ 'aria-label': 'Checkbox C'}}
                                      onChange={handleCheck}
                                      checked={checked === "noAdmin"}
                                    
                                      />
                                     </RadioGroup>
                                   </FormControl>
                                   </Box>
                    <Box className={classes.RightFormcol}>
                      <label>Name</label>
                      <TextField
                        className={classes.input}
                        placeholder="Enter Name"
                        type="text"
                        value={newUserType}
                        onChange={(e) => setNewUserType(e.target.value)}
                      />
                    </Box>
                    <Box
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                     {!loading?
                       <Button
                       className={classes.SubmitAdd}
                       onClick={addNewUserType}
                     >
                       Add
                     </Button>
                     :
                     <Loader
                     type="ThreeDots"
                     color="#000000"
                     height={50}
                     width={50}
                     timeout={0} //30 secs
                   />
                     }

                    
                     
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={4}></Grid>
            )}
            {/* <Grid item xs={4}>
                    <Box className={classes.ProfileCol}>
                        <Box className={classes.ProfileImg}>
                        <img src={profile} alt="profile images" />
                        </Box>
                        <Box className={classes.UploadBtn}>
                        <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <BiCamera color="#121212" />
        </IconButton>
      </label>
      <h3 style={{fontSize:14,color:'#121212',fontFamily:'Poppins',fontWeight:'400'}}>Upload</h3>
                        </Box>
                        <Box className={classes.StatusCol}>
                            <Typography variant="h4" style={{fontSize:18,color:'#121212',fontFamily:'Poppins',marginBottom:20,}}>Client Status</Typography>
                            <Box style={{display:'flex',width:'90%',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                                <Typography variant="h5" style={{fontSize:14,color:'#121212',fontFamily:'Poppins'}}>Status : Active</Typography>
                                <SwitchUnstyled component={Root} {...label} defaultChecked />
                            </Box>
                        </Box>
                    </Box>
                  </Grid> */}
          </Grid>
        </Box>
      </Box>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default AddUser;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  UserType: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 30,
    "& label": {
      marginRight: 20,
    },
  },
  SubmitAdd: {
    background: "#1612C6",
    borderRadius: 10,
    height: 50,
    color: "#fff",
    width: 100,
    fontSize: 16,
    textTransform: "capitalize",
    "&:hover": {
      background: "#0A70E8",
    },
  },
  Addform: {
    width: "85%",
    paddingLeft: "15%",
    marginTop: 50,
  },
  AddBtn: {
    width: 50,
    height: 50,
    minWidth: 50,
    borderRadius: 10,
    background: "#0A70E8",
    color: "#fff",
    marginLeft: 20,
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  StatusCol: {
    width: "80%",
    background: "#F9F9F9",
    borderRadius: "15px 10px 10px",
    border: "1px #D5D5D5 solid",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  FormHeading: {
    fontFamily: "Poppins",
    fontSize: 17,
    color: "rgba(0,0,0,0.5)",
    marginBottom: 20,
    fontWeight: "600",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 10,
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 30,
    height: 20,
    marginTop: 20,
    marginBottom: 20,
    "&:hover": {
      background: "none",
    },
  },
  UploadBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  ProfileCol: {
    padding: "90px 25px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  ProfileImg: {
    width: 150,
    height: 150,
    borderRadius: 20,
    display: "fle",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  Btncol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  providerlist: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 50,
    marginTop: 20,
  },
  Righttext: {
    fontSize: 16,
    color: "#696969",
  },
  Btnlink: {
    fontSize: "16px",
    color: "#7087A7",
    backgroundColor: "transparent",
    padding: "0 10px",
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      backgroundColor: "#fff",
    },
  },
  Leftbutton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  Accessbtn: {
    fontSize: "14px",
    color: "#141621",
    textTransform: "capitalize",
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  btncheck: {
    color: "#5FD827",
    marginLeft: "10px",
  },
  btncancel: {
    color: "#C13229",
    marginLeft: "10px",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topheading: {
    marginBottom: "0px",
    fontWeight: "600",
    color: "#141621",
    fontFamily: "Poppins",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 10,
  },
  toprow: {
    width: "100%",
    borderBottom: "2px #E3E5E5 solid",
    display: "flex",
    color: "#919699",
    paddingBottom: "10px",
  },
  pageTop: {
    textAlign: "left",
    marginBottom: "40px",
    display: "flex",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  profile: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  backarrow: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "8px",
  },
  Leftcol: {
    width: "15%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
  },
  Rightcol: {
    width: "70%",
    marginLeft: "15%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    '@media only screen and (max-width: 820px)':{
      width:'96%',
      padding:'0 2%',
      marginTop:30,
      marginLeft:0,
            },
    '@media only screen and (max-width: 768px)':{
      width:'96%',
      padding:'0 2%',
      marginTop:30,
      marginLeft:0,
            }, 
    "& .MuiAccordionSummary-root": {
      borderBottom: "2px #E3E5E5 solid",
      height: "40px",
      color: "#919699",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },

  Downarrow: {
    fontSize: "20px",
    color: "#7087A7",
    marginLeft: "5px",
  },

  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#7087A7",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#7087A7",
      color: "#fff",
    },
  },

  icon: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "10px",
  },
  providerrow: {
    width: "100%",
    borderBottom: "1px #E3E5E5 solid",
    padding: "15px 0",
    display: "flex",
    "& p": {
      textAlign: "left",
    },
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },

  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  checkicon: {
    fontSize: "25px",
    color: "#47C932",
  },
  inputfile: {
    display: "none",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  Toptext: {
    fontSize: "18px",
    color: "#141621",
    fontWeight: "600",
    marginTop: "-15px",
    marginBottom: "30px",
  },
  Textheading: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "500",
  },

  cancelbtn: {
    background: "#DADADA",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      background: "#000",
    },
  },
  nextbtn: {
    background: "#7087A7",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    marginLeft: "15px",
    "&:hover": {
      background: "#000",
    },
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  RightFormcol: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    flexDirection: "column",
    "&>div": {
      width: "100%",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
    "& label": {
      textAlign: "left",
      marginBottom: 5,
    },
  },
  textarea:{
    width:'100%',
    borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'10px 15px',
        fontFamily:'Poppins',
        fontSize:'14px'
}, 
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },

    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "180px",
    height: "45px",
    borderRadius: "10px",
    textTransform: "capitalize",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  backButton: {
    background: "#8E8E8E",
    padding: "0 40px",
    width: "180px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    textTransform: "capitalize",
    marginRight: 15,
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "700px",
      maxWidth: "700px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  
  
}));
