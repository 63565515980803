import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-dropdown-select"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-date-picker";
import Loader from "react-loader-spinner";
import {
  checkPhoneNumbers,
  checkEmail,
  checkPhone,
  checkZip,
  checkAddress,
  checkUSPhone,
  checkName,
  checkDob

} from "../../Util/validations";
import { stateList } from "../../Util/DropDownData";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientBasicInfoModal(props) {
  const classes = useStyles();
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [emrID, setEmrID] = useState("");
  const [dob,setDOB] = useState(new Date());
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneHome, setPhoneHome] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [emailPrimary, setEmailPrimary] = useState("");
  const [emailSecondary, setEmailSecondary] = useState("");

  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [phoneHomeError, setPhoneHomeError] = useState(false);
  const [phoneMobileError, setPhoneMobileError] = useState(false);
  const [emailPrimaryError, setEmailPrimaryError] = useState(false);
  const [emailSecondaryError, setEmailSecondaryError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [emrError, setEmrError] = React.useState(false);
  const [dobError, setDOBError] = React.useState(false);


  let pat_details = JSON.parse(localStorage.getItem("patient"));

 

  useEffect(() => {
    console.log("Patient Contact Props - ", props);
    if (props.isOpen && props.isEdit) {
      setStreet(props.patientInfo.patient_mailing_address);
      setCity(props.patientInfo.patient_city);
      setState(props.patientInfo.patient_state);
      console.log("state prop")
      console.log(props.patientInfo.patient_state)

      
      let stateCatList = stateList();
  
      for(var i=0; i<stateCatList.length; i++){
  
        let str = props.patientInfo.patient_state
     
  
      const words = str.split(" ");
  
     
  
  
       for (let j = 0; j < words.length; j++) {
        words[j] = words[j][0].toUpperCase() + words[j].substr(1);
        }
    
           let newWords = words.join(' ');
            console.log(newWords)
  
  
          if(stateCatList[i].label ==  newWords){
            console.log(stateCatList[i])
          
            setState(stateCatList[i]);
            console.log(state)
          }
    }
  
      setZip(props.patientInfo.patient_zip);
      setPhoneMobile(props.patientInfo.patient_primary_phone);
      setEmailPrimary(props.patientInfo.patient_email);
      setPhoneHome(props.patientInfo.patient_second_contact_mobile_phone);
      setEmailSecondary(props.patientInfo.patient_second_contact_email);
      setFirstName(props.patientInfo.patient_first_name)
      setLastName(props.patientInfo.patient_last_name)
      
      setDOB(props.patientInfo.patient_dob)
      setEmrID(props.patientInfo.emr_id)
    } else {
      setPhoneHome("");
      setPhoneMobile("");
      setEmailPrimary("");
      setEmailSecondary("");
    }
    setStateError(false);
    setCityError(false);
    setStreetError(false);
    setZipError(false);
    setPhoneHomeError(false);
    setPhoneMobileError(false);
    setEmailPrimaryError(false);
    setEmailSecondaryError(false);
    setFirstNameError(false)
    setLastNameError(false)
    setEmrError(false)
    setDOBError(false)
  }, [props.isOpen === true]);

  const handleSubmit = () => {

   
 

    let isValid = true;


    
    if (!checkName(firstName.trim())) {
        setFirstNameError(true);
        isValid = false;
      
      } else {
        setFirstNameError(false);
      }
     
  
      if (!checkName(lastName.trim())) {
        setLastNameError(true);
        isValid = false;
      } else {
        setLastNameError(false);
      }
      if (emrID.trim().length != "") {
        setEmrError(false);
      } else {
        setEmrError(true);
        isValid = false;
      }
    
      if (parseInt(checkDob(dob)) < 0 || parseInt(checkDob(dob)) >200 || isNaN(parseInt(checkDob(dob)))) {
        setDOBError(true);
        isValid = false;
      } else {
        setDOBError(false);
      }
   
    




    if (isValid) {
      setShowLoader(true);
      if (props.isEdit) {

        let jwt = JSON.parse(localStorage.getItem("JWT"));

        let dataEdit = {
      

          client_id: jwt.client_id,
          patient_id: pat_details.pd_id,
          emr_id: emrID,
          patient_first_name: firstName,
          patient_last_name: lastName,
          patient_mailing_address: street,
          patient_city: city,
          patient_state: state.label ? state.label : state[0].label,
          patient_zip: zip,
          patient_email: emailPrimary,
          patient_dob: moment(dob).format("YYYY-MM-DD"),
          patient_primary_phone: phoneMobile,

        };

        console.log("dataEdit", dataEdit);

        apiAxios
          .post("/patient/update-patient-demographics", dataEdit, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          });
      }
    }
  };


 


  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        className={classes.modal}
        open={props.isOpen}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
        </Box>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.onClose();
          }}
        >
          Edit Patient Basic Details
        </DialogTitle>

        <DialogContent className={classes.ccmmodal}>
          <form>
           
            <Box className={classes.Formcol}>
              <label>Patient EMR ID*</label>
              <TextField
                className={classes.input}
                placeholder="EMR ID"
                type="text"
                value={emrID}
                onChange={(e) => {
                  
                 setEmrID(e.target.value)
                }}
              />
            </Box>
            {emrError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please enter EMR ID
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>First Name*</label>
              <TextField
                className={classes.input}
                
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                   
                }}
              />
            </Box>
            {firstNameError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please enter a valid First Name.
              </p>
            ) : (
              <></>
            )}
            
            <Box className={classes.Formcol}>
              <label>Last Name*</label>
              <TextField
                className={classes.input}
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
            </Box>
            {lastNameError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please enter a valid last name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Date of Birth*</label>
              <DatePicker
                          onChange={(date)=>setDOB(date)}
                          value={dob? new Date(dob):  null}
                          
                          className={classes.input}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                          
                        />
            </Box>
            {dobError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid date of birth
              </p>
            ) : (
              <></>
            )}
           
          
          </form>
        </DialogContent>
        <br />
        <br />
        <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PatientBasicInfoModal;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    '& .react-dropdown-select-input':{
        // display: 'none'
     
    
    }
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex:'0 0 205px',
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  Selectcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'20px',
    '&>div':{
        width:'100%'
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 205px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400'
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "78%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
    '& .react-date-picker__wrapper':{
        borderRadius:10,
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 10px',
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
