import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { makeStyles } from '@material-ui/core/styles'
  import Header from '../components/Header'
  import { Box,Grid} from '@material-ui/core'
  import Button from '@material-ui/core/Button'
  import Setting from '../components/Setting'
  import LeftNav from '../components/LeftNav'
  import Patientinfo from '../components/Patientinfo'
  import Timer from '../components/Timer'
  import { FaPlusCircle } from "react-icons/fa"
  import Accordion from '@material-ui/core/Accordion'
  import AccordionDetails from '@material-ui/core/AccordionDetails'
  import AccordionSummary from '@material-ui/core/AccordionSummary'
  import FormControl from '@material-ui/core/FormControl'
  import Typography from '@material-ui/core/Typography'
  import Select from "react-dropdown-select"
  import NativeSelect from '@material-ui/core/NativeSelect'
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
  import ClickAwayListener from '@material-ui/core/ClickAwayListener'
  import Grow from '@material-ui/core/Grow'
  import Paper from '@material-ui/core/Paper'
  import Popper from '@material-ui/core/Popper'
  import Fade from '@material-ui/core/Fade'
  import MenuItem from '@material-ui/core/MenuItem'
  import MenuList from '@material-ui/core/MenuList'
  import { Link } from 'react-router-dom';
  import {
    BiArrowBack,
    BiClipboard,
    BiInfoCircle,
    BiXCircle,
  } from "react-icons/bi";
  import Dialog from '@material-ui/core/Dialog'
  import DialogActions from '@material-ui/core/DialogActions'
  import DialogContent from '@material-ui/core/DialogContent'
  import DialogContentText from '@material-ui/core/DialogContentText'
  import DialogTitle from '@material-ui/core/DialogTitle'
  import Slide from '@material-ui/core/Slide'
  import TextField from '@material-ui/core/TextField'
  import TextareaAutosize from '@material-ui/core/TextareaAutosize'
  import DatePicker from "react-datepicker"
  import "react-datepicker/dist/react-datepicker.css"
  import calendericon from '../images/calendar-icon.svg'
  import FormGroup from '@material-ui/core/FormGroup'
  import FormControlLabel from '@material-ui/core/FormControlLabel'
  import Checkbox from '@material-ui/core/Checkbox'
  import { apiAxios } from "../Util/ApiAxios"
  import moment from "moment";
  import { Pagination } from '@material-ui/lab';
  import DateRangePicker from '@wojtekmaj/react-daterange-picker';
  import Radio from "@material-ui/core/Radio";
  import RadioGroup from "@material-ui/core/RadioGroup";
  
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  
  import {hospitalizationIncidentType} from "../Util/OtherList";
  import PatientStatus from '../components/PatientStatus';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from "react-loader-spinner";
import {BiChevronUp, BiChevronDown, BiErrorCircle, BiError} from "react-icons/bi"
import { Details } from '@material-ui/icons'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function CCQ(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

  

    const [ccqList, setCCQList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
    const [message, setMessage] = React.useState("");

    const [ccqCount, setCcqCount] = React.useState("");
    const [ccqPatCount, setCcqPatCount] = React.useState("");

    const [patientDetails, setPatientDetails] = React.useState("");

    const [caregiverList, setCaregiverList] = React.useState([]);
    const [caregiverListOption, setCaregiverListOption] = React.useState([]);    

    const [selectedCaregiver, setSelectedCaregiver] = React.useState("");

    const [ccqHist, setCCQHist] = useState("");

    const [caregiverError, setCaregiverError] = React.useState(false);

    const [providerProgramList, setProviderProgramList] = React.useState([]);
    const [engageDetails, setEngageDetails] = React.useState([]);


// new

// useEffect(() => {
//   if (props.history.location.state) {
//     setPatientDetails(props.history.location.state.patientInfo);
//     getHealthcareCounts();
//     getProviderProgram();
//     getRecentEngagement();
//     getCaregivers();

//     localStorage.removeItem("engagement_timer");
//     localStorage.removeItem("start_timer");
    
//   }
// }, [props.history.location.state]);




  useEffect(() => {
    getCCQ();
    localStorage.removeItem("engagement_timer");
  }, []); 

  useEffect(() => {
    
    if(caregiverList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<caregiverList.length;i++){
       let value = caregiverList[i].id;
       let label = caregiverList[i].patient_caregiver_contacts_first_name + " " + caregiverList[i].patient_caregiver_contacts_last_name;
       let ob = {
           "label":label, "value": value
       }
       console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setCaregiverListOption(ob_arr);
       setSelectedCaregiver(ob_arr[0]);
       console.log("Caregivers - ", caregiverListOption);
   }
   
 }, [caregiverList]);

  useEffect(() => {
    if(patientDetails){
        getCaregivers();
        getProviderProgram();
    }
  }, [patientDetails]);     

  
  const getCCQ = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

    setCurrentPage(parseInt(table_config_page_no));

    
    setCCQList([]);
    setMessage("Loading...");

    let data ={
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
      }

    apiAxios
        .post("/client/ccq-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setCCQList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
              setCcqCount(response.data.count_ccq);
              setCcqPatCount(response.data.count_patient);

          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
            
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const getProviderProgram = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      

      setProviderProgramList([]);
      

      //let patient_details = JSON.parse(localStorage.getItem("patient"));

      let data = {
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
        patient_id: patientDetails.pd_id,
      };

      apiAxios
        .post("/finalize/timer-list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setProviderProgramList(response.data.data);
          } else if (response.data.statusCode == 502) {
           
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          //localStorage.removeItem("Refresh_Token");
          //localStorage.removeItem("Token");
          //window.location.href = "/";
        });
    }
  );

  function getCaregivers() {
    let data = {
      pd_id: patientDetails.pd_id,
      table_config_rows_per_page: "20",
      table_config_page_no: "1"
    };

    apiAxios
      .post("/patient/list-patient-caregiver-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          var temp = response.data.data;
          setCaregiverList(temp);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        localStorage.removeItem("Refresh_Token");
        localStorage.removeItem("Token");
        window.location.href = "/";
      });
  }

  function continuePatientReview() {

    let isValid = true;
    let speaking_with_id, user_speaking_id, speaking_with_types;

    //let pat_details = JSON.parse(localStorage.getItem("patient"));

    if(selected == "patient") {
      
      speaking_with_id = patientDetails.pd_id;
      speaking_with_types = "0";

    }else{

      speaking_with_id = selectedCaregiver[0].value ? selectedCaregiver[0].value : selectedCaregiver.value;
      speaking_with_types = "1";

      if(selectedCaregiver){
        setCaregiverError(false);
      }
      else {
        isValid = false;
        setCaregiverError(true);
      }

    }
    

    if(isValid){

      let data;

      if(engageFlag == 1){


            
        // let storeMe = true;
            
        // localStorage.setItem("updateTimer", JSON.stringify(storeMe));
            
            

        data = {
          speaking_with_id: speaking_with_id,
          pd_id: patientDetails.pd_id,
          patient_program_id: patientDetails.cpatp_id,
          user_speaking_id: localStorage.getItem("User ID"),
          engagement_type: engageFlag,  //1 emgagement, 0 view
          speaking_with_types: speaking_with_types
        }
      }
      else{
        data = {
          speaking_with_id: "",
          pd_id: patientDetails.pd_id,
          patient_program_id: patientDetails.cpatp_id,
          user_speaking_id: localStorage.getItem("User ID"),
          engagement_type: engageFlag,  //1 emgagement, 0 view
          speaking_with_types: ""
        }
      }

      let url = "/patient/create-user-speaking-with-patient";

      apiAxios
        .post(url, data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {

            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              localStorage.setItem("engagement_timer", JSON.stringify(engageDetails));  
              localStorage.setItem("start_timer", new Date());
              localStorage.setItem("engagement_ID", response.data.id);  
              localStorage.setItem("engagement_parent_ID", response.data.parent_id);  

              localStorage.setItem("patient", JSON.stringify(patientDetails));

            props.history.push({
              pathname: "/ccq-action",
              state: {
                patientInfo: patientDetails,
              },
            });
              
          }
          else if(response.data.statusCode == 502){
              //setMessage("No Data Found");
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              
                  //setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });



    }

  }

  const [engageFlag, setEngageFlag] = React.useState(2);
  

  const handleClickOpen = (flag) => {
    setEngageFlag(flag);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = (data) => {
  
    setPatientDetails(data);

    setOpen2(true);
    
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = () => {

    for(var i=0;i< providerProgramList.length;i++){
      if(providerProgramList[i].patient_program_id == patientDetails.cpatp_id){
        setEngageDetails(providerProgramList[i]);
        console.log(providerProgramList[i]);
      }
    }

    console.log("Engage details - ", engageDetails);

    if(engageFlag == 0){
      continuePatientReview();
    }
    else{
      setOpen3(true);
    }
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const [selected, setSelected] = React.useState("patient");

  const handleChangeSelected = (event) => {

    //console.log("Selected = ", selected);
    setSelected(event.target.value);
   
  };

  const moveToCCQ = (each)=>{
    props.history.push({
      pathname : "ccq-details",
      state: { PD_ID : each.pd_id,
         firstName: each.patient_first_name, 
         lastName: each.patient_last_name, 
         programName: each.program_abbrev, 
         emr: each.emr_id, 
         hrpm : each.hrpm_id,
         dob: each.patient_dob,
         providerFname: each.provider_first_name,
          providerLname: each.provider_last_name,
          patientInfo : each
      },


    });
  }


  const [value, onChange] = useState([new Date(), new Date()]);
    return (
        <div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
             <Header />
             <Box className={classes.pagecontent}>
                 {/* <Box className={classes.pageTop}>
                     
                     <a href="/dashboard"><Button><BiArrowBack className={classes.backarrow} /> Back to dashboard</Button></a>
                    
                 </Box> */}
                  <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                    <a href="/dashboard"> <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button> </a>
                 </Box>
                 <PatientStatus />
                 {/* left Accordion*/}
                 
                 </Box>
               </Box>

               <Box className={classes.Rightcol}>
                 <Box className={classes.Searchresult}>
                     <Box className={classes.topcol}>
                     <h4>Clinical Change Queue (CCQ)</h4>
                     <Box className={classes.rightcol}>
                         <Box className={classes.textcol}><BiError className={classes.erroricon} />= Severe</Box>
                         <Box className={classes.billing}> 
                         {/* <label>Current Billing Month</label>
                         <DateRangePicker className={classes.input}
                            onChange={onChange}
                            value={value} /> */}
                        </Box>
                         <Box className={classes.totalpatient}>
                             <label>Total Patient</label>
                             <TextField className={classes.input} value={ccqPatCount} variant="outlined" />
                        </Box>
                         <Box className={classes.ccq}>
                             <label>CCQ</label>
                             <TextField value={ccqCount} className={classes.input} variant="outlined" />
                             </Box>
                     </Box>
                     </Box>
                     <Box className={classes.CcqTable}>
                     <Box className={classes.throw}>
                         <Box className={classes.program}>Program</Box> 
                         <Box className={classes.provider}>Provider</Box> 
                         <Box className={classes.name}>Patient Name</Box>
                         <Box className={classes.date}>Date Added</Box>
                         {/* <Box className={classes.time}> Total Time</Box> */}
                         <Box className={classes.symptoms}>Symptoms</Box>
                         <Box className={classes.medication}>Medication</Box>
                         <Box className={classes.hospital}>Hospital</Box>
                         <Box className={classes.goal}>Goals</Box>
                         <Box className={classes.action}>Action</Box>
                         
                     </Box>
                     { (ccqList.length > 0) ?
                     <>
                     {ccqList.map((each) => (
                     <Box className={classes.tdrow}>
                         {/* <Box className={classes.provider}><Link to="/ccq-action"><b>{each.program_abbrev}</b></Link></Box>   */}
                         <Box className={classes.program}>{each.program_abbrev}</Box>  
                         <Box className={classes.provider}>{each.provider_first_name} {each.provider_last_name}</Box>
                         <Box className={classes.name2}>{each.patient_first_name} {each.patient_last_name}</Box>
                         <Box className={classes.date}>{each.ccq_enrollment_date}</Box>
                         {/* <Box className={classes.time}>{each.finalized_time}</Box> */}
                         <Box className={classes.symptomsCol}>{ each.ccq_symp_counter > 0 ? <><BiError className={classes.erroricon} /> <span className={classes.ccqCount}>{each.ccq_symp_counter}</span></> : "" }</Box>
                         <Box className={classes.medicationCol}>{each.ccq_med_counter > 0 ? <><BiError className={classes.erroricon} /> <span className={classes.ccqCount}>{each.ccq_med_counter}</span></> : "" }</Box>
                         <Box className={classes.hospitalCol}>{ each.ccq_hosp_counter > 0 ? <><BiError className={classes.erroricon} /> <span className={classes.ccqCount}>{each.ccq_hosp_counter}</span></> : "" }</Box>
                         <Box className={classes.goalCol}>{ each.ccq_goal_counter > 0 ? <><BiError className={classes.erroricon} /> <span className={classes.ccqCount}>{each.ccq_goal_counter}</span></> : "" }</Box>
                         {/* <Box className={classes.action}> <Button className={classes.Addbtn} onClick={() => handleClickOpen2(each)}><b>View</b></Button></Box> */}
                         {/* <Box className={classes.actionCol}><Link to={{pathname: "/ccq-details", state: { PD_ID : each.pd_id, firstName: each.patient_first_name, lastName: each.patient_last_name, programName: each.program_abbrev, emr: each.emr_id, hrpm : each.hrpm_id,dob: each.patient_dob,providerFname: each.provider_first_name, providerLname: each.provider_last_name }, }}><Button className={classes.chartbtn}>view</Button></Link></Box> */}
                         <Box className={classes.actionCol}><Button className={classes.chartbtn} onClick={()=>moveToCCQ(each)}>view</Button></Box>

                     </Box>
                     ))}
                     </>
                     :
                     <Box style={{textAlign:'center'}}>{message}</Box>
                    }
                    </Box>
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getCCQ({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                        style={{float: 'right', marginTop: '10px'}}
                      />
                     
                </Box>
                 </Box>
                {/* modal */}
      <Dialog
        className={classes.modal}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose2} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                What would you like to do?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link to="/patient-review">
                  <Button size="large" className={classes.loginbtn} onClick={() => handleClickOpen(0)}>
                    View Record
                  </Button>
                </Link>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={() => handleClickOpen(1)}
                >
                  Engage with patient
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog> 
      
      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                Do you want to continue
                <br /> with the status: <span style={{color: '#88b3eb'}}>{patientDetails.program_abbrev}</span>?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button size="large" className={classes.loginbtn}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={handleClickOpen3}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose3}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Button onClick={handleClose3} className={classes.closebtn} style={{float: 'right'}}>
            <BiXCircle className={classes.closeicon} size="30" />
          </Button>
          <Typography
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 30,
              marginTop: 10,
            }}
          >
            Who are you speaking with ?
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <FormControlLabel
                    value="patient"
                    control={<Radio color="primary" selected={selected} onChange={handleChangeSelected} defaultChecked />}
                    label="Patient"
                    style={{ width: 230, display: "flex", fontSize: 14 }}
                  />
                  <Typography style={{ width: "70%", textAlign: "left" }}>
                    {patientDetails.patient_first_name} {patientDetails.patient_last_name}
                  </Typography>
                </Box>
                <Box style={{ display:'flex', alignItems: "center" }}>
                  <FormControlLabel
                    value="caregiver"
                    control={<Radio selected={selected} onChange={handleChangeSelected} color="primary" />}
                    label="Select Caregiver"
                    style={{ fontSize: 14 }}
                  />
                  {console.log(caregiverListOption)}
                  <Select
                    options={caregiverListOption}
                    onChange={setSelectedCaregiver}
                    placeholder="Select caregiver"
                    className={classes.datepicker}
                    style={{width:'230px'}}
                  />
                  {caregiverError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Please select a caregiver.
                    </p>
                  ) : (
                    <></>
                  )}
                </Box>
              </RadioGroup>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 30,
                }}
              ></Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  className={classes.loginbtn}
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={continuePatientReview}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      
      
      <Setting />
             </Box>




        </div>
    )
}

export default CCQ
const useStyles = makeStyles(() => ({
    pagecontent:{
        width:'100%',
        // padding:'20px 3%',
        display:'flex',
        // flexDirection:'column'
    },
    CcqTable:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
        overflowX:'auto'
              }
    },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#fff',
        paddingBottom:'5px',
        background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
        padding: 6,
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1000,
                }
    },
    ccqCount:{
        background: '#114bae',
        borderRadius: '50%',
        color: '#fff',
        fontSize: '12px',
        marginLeft: '-8px',
        zIndex: '-9',
        width:20,
        height:20,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontFamily:'poppins'
    },
    loginform: {
        textAlign: "center",
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 10px',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1000,
                },
        '& a':{
            color:'#0f3d6e',
            cursor:'pointer'
        }
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    Addbtn: {
        background: "#fff",
        border: "1px #0f3d6e solid",
        height: "30px",
        fontSize: 14,
        textTransform: "capitalize",
        color: "#0f3d6e",
        textTransform: "capitalize",
        borderRadius: "10px",
        fontWeight: "600",
        "&:hover": {
          background: "#0f3d6e",
          color: "#fff",
        },
      },
    provider:{
        width:'10%',
        textAlign:'left',
        wordWrap : "break-word"
    },
    program:{
      width:'10%',
      textAlign:'left',
      wordWrap : "break-word"
  },
    name:{
        width:'12%',
        // display:'flex',
        // justifyContent:'flex-start',
        color: '#ffffff'
    },
    name2:{
        width:'12%',
        // display:'flex',
        // justifyContent:'flex-start',
        wordWrap : "break-word"
    },
    date:{
        width:'10%'
    },
    time:{
        width:'10%'
    },
    symptoms:{
        width:'10%',
        
    },
    medication:{
        width:'10%',
        
    },
    hospital:{
        width:'10%',
        
    },
    goal:{
        width:'10%',
        
    },
    
    symptomsCol:{
      width:'10%',
      textAlign : "center",
      fontFamily:'poppins',
      fontSize:14,
      display:'flex'
  },
  medicationCol:{
      width:'10%',
      textAlign : "center",
      fontFamily:'poppins',
      fontSize:14,
      display:'flex'
  },
  hospitalCol:{
      width:'10%',
      textAlign : "center",
      fontFamily:'poppins',
      fontSize:14,
      display:'flex'
  },
  goalCol:{
      width:'10%',
      textAlign : "center",
      fontFamily:'poppins',
      fontSize:14,
      display:'flex'
  },

    action:{
        width:'10%',
        // display:'flex',
        // justifyContent:'flex-end'
        fontFamily:'poppins',
        fontSize:14,
    },
    actionCol:{
      width:'10%',
      // display:'flex',
      // justifyContent:'flex-end'
      fontFamily:'poppins',
      fontSize:14,
  },
   
    planbtn:{
        fontSize:'14px',
        color:'#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px'
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        },
        '& a':{
          textDecoration:'none'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        }
    },
    leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1200px)':{
        left:'20px',
        right:'20px',
              },
      },
      Leftcol: {
        width: "15%",
        padding: "20px 3%",
        position: "relative",
        minHeight: "800px",
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                },     
                '@media only screen and (max-width: 768px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                }           
      },
      Rightcol:{
        width:'73%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
          width:'72%',
          padding:'0 1%',
                },
                '@media only screen and (max-width:820px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:30,
                        }, 
                '@media only screen and (max-width: 768px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:30,
                        },       
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minWidth:'33%',
        },
        '& .MuiTab-root:nth-child(6)':{
          minWidth:'30%',
        },
        '& .MuiTab-root:nth-child(7)':{
          minWidth:'30%',
        },
        '& a':{
          textDecoration:'none'
        }
        // '& .MuiBox-root':{
        //     paddingLeft:'0px',
        //     paddingRight:'0px'
        // }
    },
    btncol: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "flex-end",
        marginTop: "0",
      },
   chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'5px 10px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    fontFamily:'poppins',
    '&:hover':{
        background:'#333'
    }
},
viewbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    color:'#fff',
    textTransform:'capitalize',
    width:'220px',
    fontSize:'16px',
    cursor:'pointer',
    marginRight:'15px',
    '&:hover':{
        background:'#0f3d6e'
    }
},
Engagebtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    width:'220px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'16px',
    cursor:'pointer',
    '&:hover':{
        background:'#0f3d6e'
    }
},
modal: {
    "& a": {
      textDecoration: "none",
    },
    "& .react-datepicker-ignore-onclickoutside:focus": {
      border: "1px #0f3d6e solid !important",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .react-datepicker__input-container": {
      borderRadius: 10,
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "500px",
      maxWidth: "500px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: 0,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: "0 0 0 15px",
      },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
infoicon:{
    fontSize:'55px',
    color:'#0f3d6e'
},
topcol:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
},
rightcol:{
    display:'flex',
    alignItems:'center',
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'40px',
        
    },
    '& .MuiInputBase-input':{
        height:'40px',
        borderRadius:'10px !important',
        background:'#fff',
        padding:'0 15px',
        textAlign:'center'
    },
    '& .MuiOutlinedInput-root':{
        borderRadius:'7px !important',
    }
},
input:{ 
    border:'none',
    borderRadius:'10px !important',
    height:'40px',
    width:'100%',
    marginBottom:'30px',
    textAlign:'center'
},
billing:{
    textAlign:'left',
    marginRight:'15px',
    display:'flex',
    flexDirection:'column',
    '& .react-daterange-picker__wrapper':{
        borderRadius:'7px',
        border:'1px #cecaca solid',
        fontSize:'14px',
        paddingLeft:'10px',
        paddingRight:'10px'
    },
    '& label':{
        fontSize:'12px',
        textAlign:'left',
        color:'#1C295D',
        marginBottom:'4px'
    }
},
totalpatient:{
    width:'100px',
    marginRight:'15px',
    textAlign:'left',
    '& label':{
        fontSize:'12px',
        textAlign:'left',
        color:'#1C295D'
    }
},
ccq:{
    width:'100px',
    textAlign:'left',
    '& label':{
        fontSize:'12px',
        textAlign:'left',
        color:'#1C295D'
    }
},
erroricon:{
    fontSize:'25px',
    color:'#c10d91'
},
textcol:{
    display:'flex',
    alignItems:'center',
    marginRight:'15px',
    color:'#bfc4cb'
}
}));