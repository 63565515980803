import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FaMedkit } from "react-icons/fa";
import { Box, Grid,Button } from "@material-ui/core";
import {
  BiArrowBack,
  BiClipboard,
  BiSearch,
  BiCheckSquare,
} from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'

function LeftNav() {
  const classes = useStyles();
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState("panel1");
  const [patDetails, setPatDetails] = useState("");
  const [selectTab, setSelectTab] = React.useState(1);
  const [selectOption, setSelectOption] = React.useState(1);
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
    localStorage.setItem("panel", panel);
  };

  const handleSelect = (item) => {
    setSelectTab(item);
    localStorage.setItem("tab", item);
  };

  let engagement = JSON.parse(localStorage.getItem("engagement_timer"));

  useEffect(() => {
    let pat_details = JSON.parse(localStorage.getItem("patient"));
    if (pat_details) {
      setPatDetails(pat_details);
    }

    if (localStorage.getItem("panel")) {
      setExpanded2(localStorage.getItem("panel"));
      setSelectTab(localStorage.getItem("tab"));
      //console.log("Local storage - ", localStorage.getItem("tab"));
    }
  }, []);
// ***** //


  return (
    <div> 
      <Box className={classes.Accordionnav}>
      {engagement.initial_visit_required === true ?
        <Accordion
          expanded={expanded2 === "panel1"}
          onChange={handleChange3("panel1")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <BiClipboard className={classes.icon} />{" "}
              {patDetails.program_abbrev}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {selectTab === 1 ? (
                <li>
                  <Link to="/patient-review" style={{ color: "#fff" }}>
                    Review Of Systems
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/patient-review" onClick={() => handleSelect(1)}>
                    Review Of Systems
                  </Link>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        : 
        <></>
              }
        <Accordion
          expanded={expanded2 === "panel2"}
          onChange={handleChange3("panel2")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              <BiCheckSquare className={classes.icon} /> VALIDATE
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {selectTab == 2 ? (
                <li>
                  <Link to="/patient-contact" style={{ color: "#fff" }}>
                    Contact Information
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/patient-contact" onClick={() => handleSelect(2)}>
                    Contact Information
                  </Link>
                </li>
              )}
              {selectTab == 3 ? (
                <li>
                  <Link to="/patient-provider" style={{ color: "#fff" }}>
                    Providers
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/patient-provider" onClick={() => handleSelect(3)}>
                    Providers
                  </Link>
                </li>
              )}
              {selectTab == 4 ? (
                <li>
                  <Link to="/patient-pharmacy" style={{ color: "#fff" }}>
                    Pharmacy
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/patient-pharmacy" onClick={() => handleSelect(4)}>
                    Pharmacy
                  </Link>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded2 === "panel7"}
          onChange={handleChange3("panel7")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>
              <BiSearch className={classes.icon} /> PROGRAM
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {selectTab == 4 ? (
                <li>
                  <Link to="/program" style={{ color: "#fff" }}>
                    Summary
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/program" onClick={() => handleSelect(4)}>
                    Summary
                  </Link>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded2 === "panel8"}
          onChange={handleChange3("panel8")}
          style={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              <FaMedkit className={classes.icon} /> CARE PLAN
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {selectTab == 5 ? (
                <li>
                  <Link to="/careplan-condition" style={{ color: "#fff" }}>
                    Conditions
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    to="/careplan-condition"
                    onClick={() => handleSelect(5)}
                  >
                    Conditions
                  </Link>
                </li>
              )}
              {selectTab == 6 ? (
                <li>
                  <Link to="/care-plan-symtoms" style={{ color: "#fff" }}>
                    Symptoms
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/care-plan-symtoms" onClick={() => handleSelect(6)}>
                    Symptoms
                  </Link>
                </li>
              )}
              {selectTab == 7 ? (
                <li>
                  <Link to="/careplan-goals" style={{ color: "#fff" }}>
                    Goals
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/careplan-goals" onClick={() => handleSelect(7)}>
                    Goals
                  </Link>
                </li>
              )}
              {selectTab == 8 ? (
                <li>
                  <Link to="/ccq-action" style={{ color: "#fff" }}>
                    Clinical Change Queue
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/ccq-action" onClick={() => handleSelect(8)}>
                    Clinical Change Queue
                  </Link>
                </li>
              )}
              {selectTab == 9 ? (
                <li>
                  <Link to="/hospitalizations" style={{ color: "#fff" }}>
                    Hospitalizations & Surgeries
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/hospitalizations" onClick={() => handleSelect(9)}>
                    Hospitalizations & Surgeries
                  </Link>
                </li>
              )}
              {selectTab == 10 ? (
                <li>
                  <Link to="/review-medication" style={{ color: "#fff" }}>
                    Medications & Vaccinations
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    to="/review-medication"
                    onClick={() => handleSelect(10)}
                  >
                    Medications & Vaccinations
                  </Link>
                </li>
              )}
              {selectTab == 11 ? (
                <li>
                  <Link to="/resources" style={{ color: "#fff" }}>
                    Resources
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/resources" onClick={() => handleSelect(11)}>
                    Resources
                  </Link>
                </li>
              )}
              {selectTab == 12 ? (
                <li>
                  <Link to="/careplan-note" style={{ color: "#fff" }}>
                    Notes
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/careplan-note" onClick={() => handleSelect(12)}>
                    Notes
                  </Link>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
}

export default LeftNav;
const useStyles = makeStyles(() => ({
  Accordionnav: {
    marginTop: "30px",
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        fontSize: "15px",
        color: "#88b3eb",
        lineHeight: "40px",
        textDecoration: "none",
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          fontSize: "14px",
                },
        "& a": {
          color: "#88b3eb",
          cursor: "pointer",
          textDecoration: "none",
          "&:hover": {
            color: "#ffffff",
            textDecoration: "none",
          },
        },
      },
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      color: "#88b3eb",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordion-rounded": {
      borderBottom: "1px #5f80a9 solid",
      padding: "10px 0",
      background: "none",
    },
    "& .MuiIconButton-root": {
      color: "#88b3eb",
    },
  },
  icon: {
    color: "#88b3eb",
    fontSize: "20px",
    marginRight: "10px",
  },
 
}));
