import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp,BiChevronDown, BiCloudDownload,BiArrowBack, BiCloudUpload, BiXCircle} from "react-icons/bi"
import ManageUser from '../components/ManageUser'
import ManageProvider from '../components/ManageProvider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import PatientStatus from '../components/PatientStatus'
import Billing from '../components/Billing'
import CCQ from '../components/CCQ'
import Finalize from '../components/Finalize'
import LoginLogout from '../components/LoginLogout'
import PatientEngagement from '../components/PatientEngagement'
import PopulationPieChart from '../components/PopulationPieChart'
import PopulationAssessment from '../components/PopulationAssessment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
function InsightBilling({ options }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//  modal  //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                    <a href="/dashboard"> <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button> </a>
                 </Box>
                 <PatientStatus />
                 {/* left Accordion*/}
                 
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Box className={classes.Tabcol}>
                <h3 style={{fontWeight:'500', marginBottom:'30px'}}>Reports</h3>
               <AppBar position="static" className={classes.contacttab}>
               <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          aria-label="auto tabs"
          width="100%"
        >
          
          <Tab label="CCQ" {...a11yProps(0)} />
          <Tab label="Finalize" {...a11yProps(1)} />
          <Tab label="Patient Engagement" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      
      <TabPanel value={value} index={0}>
      <CCQ />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Finalize />
      </TabPanel>
      <TabPanel value={value} index={2}>
     <PatientEngagement />
      </TabPanel>
      </Box>
               </Box>
                {/* modal */}
                <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <Button onClick={handleClose} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          <Box className={classes.loginform}>
              <h3 className={classes.Toptext}>Add New Program</h3>
              <form>
              <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                          <label>Program Name</label>
                          <TextField className={classes.input} placeholder="Enter Program Name"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Description</label>
                          <TextField className={classes.input} placeholder="Enter Description" type="text" />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <h4 style={{fontSize:'16px', color:'#AEAEAE', fontWeight:'500'}}>Primary</h4>
                      <Box className={classes.Formcol}>
                          <label>Code</label>
                          <TextField className={classes.input} placeholder="Enter Code" type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Timer Maximum</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="30:00" className={classes.select} style={{width:'200px'}} />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Max Use</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="1" className={classes.select} style={{width:'200px'}} />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <h4 style={{fontSize:'16px', color:'#AEAEAE', fontWeight:'500'}}>Add-On</h4>
                      <Box className={classes.Formcol}>
                          <label>Code</label>
                          <TextField className={classes.input} placeholder="Enter Code" type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Timer Maximum</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="30:00" className={classes.select} style={{width:'200px'}} />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Max Use</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="1" className={classes.select} style={{width:'200px'}} />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Max Billing Frequency</label>
                          <TextField className={classes.input} placeholder="Calendar Month"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Min User Level</label>
                          <TextField className={classes.input} placeholder="5"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Chronic Condition Minimum</label>
                          <TextField className={classes.input} placeholder="0"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Initial Visit Required</label>
                          <TextField className={classes.input} placeholder="NO"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Careplan Template</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="CCM" className={classes.select} style={{width:'456px'}} />
                      </Box>
                  </Grid>
              </Grid>
            </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
        <FormGroup aria-label="position" row>
        </FormGroup>
        <Button size="large" className={classes.loginbtn}>
        Save
        </Button>
        </DialogActions>
      </Dialog>
            </Box>
        </div>
    )
}

export default InsightBilling
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    Templatecontent:{
        '& ul':{
            listStyle:'none',
            margin:'0px',
            padding:'0px',
            '& li':{
                marginBottom:'15px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                '& span':{
                    width:'250px'
                }
            }
        }
    },
   
    
    InsightBilling:{
        fontSize:'16px',
        color:'#0f3d6e',
        textTransform:'capitalize'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'100px'
    },
    topheading:{
        marginBottom:'50px',
        fontWeight:'600',
        color:'#0f3d6e'
    },
    Tabcol:{
      position:'relative',
        '& .MuiTabPanel-root':{
            padding:'0px'
        }
    },
    contacttab:{
      background:'#F3F3F3',
      borderRadius:'10px',
      boxShadow:'none',
      color:'#000',
      textTransform:'capitalize',
      overflow:'hidden',
      width:'60%',
      '@media only screen and (max-width: 1200px)':{
        width:'80%',
        },
        '@media only screen and (max-width: 820px)':{
          width:'84%',
          },
        '@media only screen and (max-width: 768px)':{
          width:'84%',
          },
      '& .MuiTabScrollButton-root:last-child':{
          position:'absolute',
          right:'-15px',
          marginTop:'11px'
      },
      '& .MuiTabScrollButton-root:first-child':{
        position:'absolute',
        left:'-15px',
        zIndex:'99',
        marginTop:'12px'
    },
      '& .MuiTabs-indicator':{
        display:'none !important'
      },
      '& .MuiTabScrollButton-root':{
          width:'25px',
          height:'25px',
          borderRadius:'50%',
          background:'#88b3eb',
          color:'#fff',
      },
      '& .MuiTab-root':{
        textTransform:'capitalize',
        fontFamily:'Poppins'
      },
      '& .MuiTabs-flexContainer':{
        borderRadius:'10px',
        background:'#F3F3F3',
        textTransform:'capitalize',
        '& .MuiTab-textColorPrimary.Mui-selected':{
          background:'#D9E3F0',
          color:'#000',
          borderRadius:'10px'
        },
        '& .MuiTab-textColorInherit':{
          textTransform:'capitalize',
          fontSize:'16px',
          padding:'0 22px'
        }
      },
      
    },
    toprow:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#bfc4cb',
        paddingBottom:'10px',
    },
    pageTop:{
        textAlign:'left',
        marginBottom:'40px',
        display:'flex',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        },
        '& a':{
textDecoration:'none'
        }
    },
    profile:{
        width:'80px',
        height:'80px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol: {
      width: "15%",
      padding: "20px 3%",
      position: "relative",
      minHeight: "800px",
      background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
      '@media only screen and (max-width: 1200px)':{
        width: "17%",
        padding: "20px 1%",
        },
        '@media only screen and (max-width: 820px)':{
          width: "98%",
          padding: "20px 1%",
          position:'absolute',
          background:'none'
          },
        '@media only screen and (max-width: 768px)':{
          width: "98%",
          padding: "20px 1%",
          position:'absolute',
          background:'none'
          },
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
      },
    },
    Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
          width:'77%',
          },
          '@media only screen and (max-width: 820px)':{
            width:'96%',
            marginTop:20,
            },
          '@media only screen and (max-width: 768px)':{
            width:'96%',
            marginTop:20,
            },
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minWidth:'33.33%',
        },
        '& .MuiTab-root:nth-child(6)':{
          minWidth:'30%',
        },
        '& .MuiTab-root:nth-child(7)':{
          minWidth:'30%',
        },
        '& .MuiBox-root':{
            paddingLeft:'0px',
            paddingRight:'0px'
        }
    },
    
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Uploadbtn:{
    width:'230px',
    height:'50px',
    background:'#F9F9F9',
    color:'#AEAEAE',
    fontSize:'14px',
    display:'flex',
    justifyContent:'space-between',
    borderRadius:'10px',
    boxShadow:'none',
    textTransform:'capitalize'
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},

icon:{
  color:'#0f3d6e',
  fontSize:'30px',
  marginRight:'10px'
},
downloadicon:{
    position:'absolute',
    right:'20px',
    zIndex:'99',
    color:'#0f3d6e',
  fontSize:'30px',
  top:'10px',
  cursor:'pointer'
},

modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'776px',
        maxWidth:'776px'
    }
},
ccmmodal:{
    borderRadius:'10px',
},
modalbtn:{
    display:'flex',
    justifyContent:'space-between',
    marginRight:'30px',
    marginLeft:'15px',
    alignItems:'center'
},

}));