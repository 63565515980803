import React, { useEffect,  useCallback, } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import calendericon from "../images/calendar-icon.svg";
import {
  BiArrowBack,
  BiClipboard,
  BiInfoCircle,
  BiXCircle,
} from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPhoneCall } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Header from "../components/Header";
import LeftNav from "../components/LeftNav";
import Patientinfo from "../components/Patientinfo";
import Setting from "../components/Setting";
import Timer from "../components/Timer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";

import "react-datepicker/dist/react-datepicker.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#0f3d6e",
      },
    },
  });


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditPatientProgramModal(props) {
  const classes = useStyles();
  const [programNameId, setProgramNameId] = React.useState("");
  const [agentId, setAgentId] = React.useState("");
  const [agentData, setAgentData] = React.useState([]);
  const selectedAgent = React.useMemo(()=> agentData?.find?.(ad => ad.id === agentId) ?? {}, [agentData, agentId])
  const [consentDate, setConsentDate] = React.useState("");
  const [startDate, setStartDate] = React.useState((props.patientDetails?.patient_program_start_date?.length) > 0 ? new Date(props.patientDetails.patient_program_start_date): "");
  const [endDate, setEndDate] = React.useState((props.patientDetails?.patient_program_end_date?.length) > 0 ? new Date(props.patientDetails.patient_program_end_date) : "" );
  const [optoutDate, setOptoutDate] = React.useState((props.patientDetails?.patient_program_opt_out_date?.length) > 0 ? new Date(props.patientDetails.patient_program_opt_out_date) : "" );
  const [callFreq, setCallFreq] = React.useState([]);
  const [agentSearch, setAgentSearch] = React.useState("");
  const [endDateError, setEndDateError] = React.useState(false)

  const [providerIDError, setProviderIDError] = React.useState(false);
  const [programNameError, setProgramNameError] = React.useState(false);
  const [agentNameError, setAgentNameError] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [providerList, setProviderList] = React.useState([])
  const [providerListOptions, setProviderListOption] = React.useState([])
  const [providerID, setProviderID] = React.useState([])
  const [patientStatus, setPatientStatus] = React.useState('active');
  const [toggleButtonVal, setToggleButtonVal] = React.useState(props.patientDetails.status);
  const [loader, setLoader] = React.useState(true);
  const [callSelect, setCallSelect] = React.useState('');

  useEffect(() => {
    setToggleButtonVal(props.patientDetails.status);
      setLoader(false);
  }, [props.patientDetails.status]);

  console.log(toggleButtonVal);
  const handleChangeValue = (event, newAlignment) => {
    setToggleButtonVal(newAlignment);
  };
  useEffect(() => {
    console.log("list details new", props);
console.log("HI THERE", props.patientDetails);
   setStartDate((props.patientDetails?.patient_program_start_date?.length) > 0 ? new Date(props.patientDetails.patient_program_start_date): "");
   setEndDate((props.patientDetails?.patient_program_end_date?.length) > 0 ? new Date(props.patientDetails.patient_program_end_date) : "" );
   setOptoutDate((props.patientDetails?.patient_program_opt_out_date?.length) > 0 ? new Date(props.patientDetails.patient_program_opt_out_date) : "" );
   setAgentId((props.patientDetails?.patient_programe_care_team_assoc?.length) > 0 ? props.patientDetails.patient_programe_care_team_assoc : "" );
  
  }, [props]);

  useEffect(() => {
  
    let data = {
      table_config_page_no: "1",
      cutid: "",
      search_value: agentSearch,
      table_config_rows_per_page: "15",
      user_master_type_id: "",
      is_provider: false,
    };

    apiAxios
      .post("/client/clientuserlist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("agent res", response.data);
        if (response.data.statusCode == 200) {
          setAgentData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    
  }, [agentSearch]);

  // provider list

  // const getProviderList = useCallback(
  //   async (id,{
  //     table_config_rows_per_page = '500',
  //     table_config_page_no = '1',
  //   } = {}) => {
  // // const getProviderList = (id) => {

  //   setProviderID("");
  //   setProviderListOption([])

  //   let data ={
  //     program_id: id,
  //     table_config_rows_per_page: table_config_rows_per_page,
  //     table_config_page_no: table_config_page_no,
  //   }
  

    
  //     apiAxios
  //       .post("/provider/provider-for-given-program-list", data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("Token"),
  //         },
  //       })
  //       .then((response) => {
  //         console.log("res", response.data);
  //         setShowLoader(false);
          
  //         if (response.data.statusCode == 200) {
  //           setProviderList(response.data.data); 
  //           toast.success(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });

       
            
             
           

  //         } else {
  //           // toast.error(response.data.message, {
  //           //   position: "bottom-center",
  //           //   autoClose: 5000,
  //           //   hideProgressBar: false,
  //           //   closeOnClick: true,
  //           //   pauseOnHover: true,
  //           //   draggable: true,
  //           //   progress: undefined,
  //           // });
  //         }
  //       })
  //       .catch((error) => {
  //         setShowLoader(false);
  //         console.log(error);
  //         // toast.error(error.message, {
  //         //   position: "bottom-center",
  //         //   autoClose: 5000,
  //         //   hideProgressBar: false,
  //         //   closeOnClick: true,
  //         //   pauseOnHover: true,
  //         //   draggable: true,
  //         //   progress: undefined,
  //         // });
  //       });
  //     }
  // );

  useEffect(() => {
    if(providerList){ 
      var ob_arr =[];
      //console.log("user types = ", userTypes);
      for(var i=0;i<providerList.length;i++){
      let value = providerList[i].id;
      let label = providerList[i].name;
     
      let ob = {
          "label":label, "value": value, 
      }
      //console.log("ob types = ", ob);
      ob_arr.push(ob);
      }

      console.log(ob_arr)
      setProviderListOption(ob_arr);
      // setSelectedProgram(ob_arr[0]);
   }
   
 }, [providerList]);  

 

  const handleSubmit = (event) => {

    // console.log(startDate)
    

    // console.log("agent ID" +agentId)
    // console.log("programID" +programNameId)
    let isvalid = true;
    // if (programNameId == "") {
    //   setProgramNameError(true);
    //   isvalid = false;
    // } else {
    //   setProgramNameError(false);
    // }

    // if (providerID == "") {
    //   setProviderIDError(true);
    //   isvalid = false;
    // } else {
    //   setProviderIDError(false);
    // }

    if (agentId == "") {
      setAgentNameError(true);
      isvalid = false;
    } else {
      setAgentNameError(false);
    }

    if(endDate){

      if(startDate < endDate){
        setEndDateError(false)
        
      }else {
        isvalid = false;
        setEndDateError(true)
      }
      
    }else {
      setEndDateError(false)
      
    }
console.log(isvalid);
    if (isvalid) {
      setShowLoader(true);
      if(toggleButtonVal == "1"){
        let dataActive = {
          patient_program_id: props.patientDetails.cpatp_id,
          patient_programe_care_team_assoc: agentId ? agentId : "",
          status: toggleButtonVal,
        }
        console.log(dataActive);
        apiAxios
        .post("/client/update-client-patient-program", dataActive, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("res", response.data);
          setShowLoader(false);
          
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

       
            props.onClose();
            
        

           window.location.reload();
           

          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error);
          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      }else if(toggleButtonVal == "2"){
        let dataOPt = {
          patient_program_id: props.patientDetails.cpatp_id,
          patient_programe_care_team_assoc: agentId ? agentId : "",
          status: toggleButtonVal,
          patient_program_opt_out_date:
            optoutDate === "" ? "" : moment(optoutDate).format("YYYY-MM-DD"),
          patient_program_start_date: startDate === null ? "" : moment(startDate).format("YYYY-MM-DD"),
          patient_program_end_date:
            endDate === "" ? "" : moment(endDate).format("YYYY-MM-DD"),
          patient_program_call_frequency: "10",
          
          // provider_program_id: providerID ? providerID:  "",
          // pd_id: props.patientDetails.id ? props.patientDetails.id : props.patientDetails.pd_id,
          // contract_end: "",
          // patient_program_consent_date:
          //   consentDate === "" ? "" : moment(consentDate).format("YYYY-MM-DD"),
          // patient_programe_care_team_assoc: agentId ? agentId : "",
          // patient_program_call_date: "",
        };
        console.log(dataOPt);
        apiAxios
        .post("/client/update-client-patient-program", dataOPt, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("res", response.data);
          setShowLoader(false);
          
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

       
            props.onClose();
            
        

           window.location.reload();
           

          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error);
          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }
      
      
    }
  };

  return (
    <>
    {loader ? 
    (<>Loading...</>) 
    : 
    (
      <div style={{ width: "100%" }}>
      <Dialog
        className={classes.modal}
        style={{ textAlign: "left" }}
        open={props.isOpen}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Typography
            style={{
              fontSize: 18,
              fontFamily: "poppins",
              fontWeight: "600",
              marginBottom: 30,
            }}
          >
            Patient Associate Information
          </Typography>
          <Button onClick={() => props.onClose()} className={classes.closebtn}>
            <BiXCircle className={classes.closeicon} size="30" />
          </Button>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <Box className={classes.Formcol}>
                <label>Agent Name<span style={{ color: "#ff0000" }}>*</span>

                </label>
                <Autocomplete
                  id="combo-box-demo"
                  style={{ width: "100%" }}
                  placeholder="Select Agent"
                  renderInput={(params) => <TextField {...params} placeholder="Select Agent" disabled={true} />}
                  options={agentData}
                  value={selectedAgent}
                  inputValue={[selectedAgent.first_name, selectedAgent.last_name].join(' ')}
                  getOptionLabel={(option) => option.first_name + " " + option.last_name}
                  getOptionSelected={(option,value) =>option.id === value.id}
                  onChange={(e,v) => {
                    setAgentId(v? v.id : "");
                    if (e.target.value === undefined || e.target.value === null) {
                      setAgentId("");
                    }
                  }}
                />
              </Box>
              {agentNameError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                  }}
                >
                  Please select a agent name.
                </p>
              ) : (
                <></>
              )}
              {/* <Box className={classes.Formcol}>
                <label>Consent Date</label>
                <Box className={classes.Enddate}>
               
                  <DatePicker
                    className={classes.input}
                    clearIcon={null}
                    placeholder="Consent Date"
                    value={consentDate}
                    onChange={(e) => setConsentDate(e)}
                   
                  />
                </Box>
              </Box> */}

              <Box className={classes.Statuscol}>
                <label>Patient Status</label>
                
                <ToggleButtonGroup
                // className={classes.statusControl}
                color="primary"
                value={toggleButtonVal}
                exclusive
                size="small"
                onChange={handleChangeValue}
                aria-label="Platform"
                >
                <ToggleButton value="1">Active</ToggleButton>
                <ToggleButton value="2">Opt-Out</ToggleButton>
                </ToggleButtonGroup>
                
                
              </Box>
              
              {toggleButtonVal === "1" ? 
              (
              <>
              </>
              ) : 
              (
              <>
              <Box className={classes.Formcol}>
                <label>Start Date</label>
                <Box className={classes.Enddate}>
      
                  <DatePicker
                    className={classes.input}
                    clearIcon={null}
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e)}
                  />
                </Box>
              </Box>
              <Box className={classes.Formcol}>
                <label>End Date</label>
                <Box className={classes.Enddate}>
                
                  <DatePicker
                    className={classes.input}
                    clearIcon={null}
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e)}
                    minDate={startDate}
                  />
                   {endDateError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                  }}
                >
                  Please enter end date greater than start date
                </p>
              ) : (
                <></>
              )}
                </Box>
              </Box>
              <Box className={classes.Formcol}>
                <label>OPT Out Date</label>
                <Box className={classes.Enddate}>
                
                  <DatePicker
                    className={classes.input}
                    clearIcon={null}
                    placeholder="OPT Out Date"
                    value={optoutDate}
                    onChange={(e) => setOptoutDate(e)}
                    minDate={startDate}
                  />
                </Box>
              </Box>
              <Box className={classes.Formcol}>
                <label>Call Frequency</label>
                <FormControl className={classes.formControl}>
                  <NativeSelect 
                  className={classes.selectEmpty} 
                  name="age"
                  value={callSelect}
                  onChange={(event, val) => setCallSelect(val)}
                  >
                    {/* <option value="" disabled>
                      Call Frequency
                    </option> */}
                    <option value={10}>Monthly</option>
                    {/* <option value={20}>Weekly</option>
                    <option value={30}>Bi-Weekly</option> */}
                  </NativeSelect>
                </FormControl>
              </Box>
              </>
              )}  






              



              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  className={classes.loginbtn}
                  onClick={() => props.onClose()}
                >
                  Close
                </Button>
                {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (
                  <Button
                    size="large"
                    className={classes.chartbtn}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
    )
    }
    </>
    
  );
}

export default EditPatientProgramModal;

const useStyles = makeStyles(() => ({
  Throw: {
    display: "flex",
    borderBottom: "1px rgba(0,0,0,0.2) solid",
    paddingBottom: 10,
    alignItems: "center",
  },
  calendaricon: {
    position: "absolute",
    right: "15px",
    zIndex: "1",
    top: "12px",
    minWidth: "15px",
    height: "20px",
    width: "15px",
  },
  Enddate: {
    position: "relative",
    marginRight: "0",
    marginLeft: "0",
    width: "100%",
  },
  Ccqbtn: {
    background: "#fff",
    borderRadius: 5,
    color: "#0f3d6e",
    fontFamily: "poppins",
    fontSize: 16,
    padding: 0,
    border: "1px #0f3d6e solid",
    width: 75,
    marginLeft: 20,
    fontWeight: "600",
  },
  CcqText: {
    fontFamily: "poppins",
    fontSize: 15,
    color: "#0f3d6e",
  },
  Downloadbtn: {
    fontFamily: "poppins",
    fontSize: 12,
    textTransform: "capitalize",
    color: "#0f3d6e",
    padding: 0,
    "&:hover": {
      background: "none",
      color: "#000",
    },
  },
  CcqRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    borderBottom: "1px #E3E5E5 solid",
    paddingBottom: 15,
  },
  loginbtn: {
    background: "#fff",
    padding: "0 20px",
    height: "40px",
    borderRadius: "10px",
    border: "1px #E42626 solid",
    color: "#E42626",
    textTransform: "capitalize",
    marginRight: 15,
    "&:hover": {
      background: "#E42626",
      color: "#fff",
    },
  },
  loginform: {
    textAlign: "center",
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
  },
  chartbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    padding: "0 20px",
    height: "40px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
  CcqList: {
    borderRadius: 10,
    textAlign: "left",
    padding: "20px 0 10px",
    marginTop: 0,
  },
  Thtext: {
    fontFamily: "poppins",
    fontSize: 14,
    color: "rgba(0,0,0,0.6)",
  },
  Thcol: {
    width: "15%",
  },
  Thcol2: {
    width: "15%",
  },
  Thcol3: {
    width: "20%",
    textAlign: "left",
  },
  Thcol4: {
    width: "15%",
  },
  Thcol5: {
    width: "15%",
  },
  Thcol6: {
    width: "20%",
    textAlign: "right",
  },
  Thcol7: {
    width: "10%",
  },
  Thcol8: {
    width: "25%",
  },
  Thcol9: {
    width: "30%",
  },
  Thcol10: {
    width: "20%",
  },
  Thcol11: {
    width: "15%",
    textAlign: "right",
  },
  Tdcol7: {
    width: "10%",
    display: "flex",
    alignItems: "center",
  },
  Tdcol8: {
    width: "25%",
  },
  Tdcol9: {
    width: "30%",
  },
  Tdcol10: {
    width: "20%",
  },
  Tdcol11: {
    width: "15%",
    textAlign: "right",
  },
  Tdtext: {
    fontFamily: "poppins",
    fontSize: 14,
    color: "rgba(0,0,0,0.9)",
  },
  Tdcol: {
    width: "15%",
  },
  Tdcol2: {
    width: "15%",
  },
  Tdcol3: {
    width: "20%",
    textAlign: "left",
  },
  Tdcol4: {
    width: "15%",
  },
  Tdcol5: {
    width: "15%",
  },
  Tdcol6: {
    width: "20%",
    textAlign: "right",
  },
  Tdrow: {
    display: "flex",
    borderBottom: "1px rgba(0,0,0,0.2) solid",
    padding: "10px 0",
    alignItems: "center",
  },
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #0f3d6e solid",
    height: "30px",
    fontSize: 14,
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
    textAlign: "left"
  },

  
  
  // Dateinput: {
  //   borderRadius: "10px",
  //   height: "42px",
  //   width: "94%",
  //   fontFamily: "Poppins",
  //   fontSize: "12px",
  //   background: "#F9F9F9",
  //   border: "1px solid #D5D5D5",
  //   paddingLeft: 15,
  //   "&:focus": {
  //     border: "1px solid #D5D5D5 !important",
  //   },
  // },

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "180px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  Statuscol:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
    borderRadius: 20,
    textTransform: "capitalize",
    "& p": {
      fontSize: "8px",
      margin: "0px",
    },
    "& label": {
      width: "130px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    
  },
  modal: {
    "& a": {
      textDecoration: "none",
    },
    "& .react-datepicker-ignore-onclickoutside:focus": {
      border: "1px #0f3d6e solid !important",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .react-datepicker__input-container": {
      borderRadius: 10,
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "500px",
      maxWidth: "500px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: 0,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: "0 0 0 15px",
      },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
//   statusControl: {
//     minWidth: 120,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-start",
//     borderRadius: "100px",
//     textTransform: "lowercase",
//   },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },

  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "22%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "800px",
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    "& .MuiAccordion-rounded": {
      borderBottom: "1px rgba(0,0,0,0.1) solid",
      background: 'none',
      '@media only screen and (max-width: 1200px)':{
        borderBottom: "0px rgba(0,0,0,0.1) solid",
              },
    },
    "& .MuiAccordion-root.Mui-expanded": {
      marginBottom: 0,
    },
  },

  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "0",
  },
  Viewbtn: {
    background: "#0f3d6e",
    fontFamily: "poppins",
    fontSize: 14,
    textTransform: "capitalize",
    color: "#fff",
    padding: "6px 15px",
    borderRadius: 10,
    "&:hover": {
      background: "rgba(0,0,0,0.7)",
    },
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "30px",
    color: "#0f3d6e",
  },
}));
