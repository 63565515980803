import { Box } from "@material-ui/core";
import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../images/DHCT_Logo.png";
import loginbg from "../images/login-bg.png";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { apiAxios } from "../Util/ApiAxios";
import { useState } from "react";
import { checkOtp, checkPassword, numbers } from "../Util/validations";
import Select from "react-dropdown-select"
// import { useHistory } from 'react-router-dom'
// import { RemoveRedEye } from '@material-ui/icons';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';


import jwt_decode from "jwt-decode";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";




    







function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [isSuccessUser, setSuccess] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [forgotPassStep1, setForgotPassStep1] = useState(false);
  const [ifLoading, setIfLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorPassChange, setErrorPassChange] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false)

  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false)










  function onUserSubmit(e) {

    e.preventDefault();

    // setIsValid(true);
    // console.log(isValid);
    let isValid = true;
    // localStorage.setItem("email", email);
    // localStorage.setItem("password", password);
    let data = {
      username: email,
      password: password,
      user_login_panel_type: "1",
    };

    localStorage.removeItem("Refresh_Token");
    localStorage.removeItem("Token");

    if(email && password){
      // setIsValid(true);
      isValid = true;
    }
    else if(password === "" && email === ""){
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false;
    }
    else if(email === "" && password === ""){
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false;
    }
    else{
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false;
    }

    if(isValid){

    setErrorMsg("");   
    setIfLoading(true);

    apiAxios
      .post("/auth/sign-in", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem(
            "First Name",
            response.data.user_details.first_name
          );
          localStorage.setItem(
            "Last Name",
            response.data.user_details.last_name
          );
          localStorage.setItem("User ID", response.data.user_details.user_id);
          
          console.log(response.data.accounts);
          if(response.data.accounts){
            let arr = [];
            for(var i=0;i < response.data.accounts.length;i++){
                let value = response.data.accounts[i].cuid;
                let label = response.data.accounts[i].client_name + " : " + response.data.accounts[i].designation;
                let ob = {
                    "label":label, "value": value
                }
                arr.push(ob);
            }
            setRoleList(arr);
            }

          setSuccess(true);
          setIfLoading(false);
          

        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIfLoading(false);
      });

    }  
  }

  function onOtpChange(e) {
    if (checkOtp(e.target.value)) {
      setOtp(e.target.value);
    }
  }

  const [roleError, setRoleError] = React.useState(false);

  function onVerify(e) {

    e.preventDefault();

    let isValid = true;

    if(role){
      setRoleError(false);
    }
    else{
      setRoleError(true);
      isValid = false;
    }

   

    // if(otp == ""){
     
    //   isValid = false;
    //   setOtpError("OTP can not be blank")
    // }else {
    //   setOtpError("")
    // }


    if(isValid){

    console.log("Role = ", role);
    
    let data = {
      user_id: role[0].value ? role[0].value : role.value,
      otp: otp,
      login_type: "care_team",
    };

    setIfLoading(true);

    apiAxios
      .post("/auth/verify-otp", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode == 200) {
          localStorage.setItem("User ID", role[0].value);  
          localStorage.setItem("Token", response.data.id_token);
          localStorage.setItem("Refresh_Token", response.data.refresh_token);

          var decoded = jwt_decode(localStorage.getItem("Token"));
          localStorage.setItem("JWT", JSON.stringify(decoded) );

          props.history.push("/dashboard");
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIfLoading(false);
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setIfLoading(false);
      });

    }
  }

  function onForgotPass() {
    setForgotPass(true);
    setForgotPassStep1(true);
    setEmail("");
    setPassword("");
    setErrorMsg("")
  }

  function onForgotPassGetOtp(e) {

    e.preventDefault();

    let isValid = true;

    if(email === ""){
      setErrorMsg("Username cannot be blank");
     
      isValid = false
    }

    let data = {
      username: email,
      login_type: "1",
    };

    if(isValid){
    setIfLoading(true);

    apiAxios
      .post("/auth/forgot-pass-send-otp", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode == 200) {
          setUserId(response.data.user_id);
          setForgotPassStep1(false);
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setIfLoading(false);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIfLoading(false);
      });
    }
  }

  function onPassUpdate(e) {


    e.preventDefault();

    // setIsValid(true);

    let isValid = true;

    let data = {
      user_id: userId,
      otp: otp,
      password: password,
      confirm_password: confirmPassword,
    };

    if(otp === ""){
      // setIsValid(false);
      
      setOtpError("OTP cannot be blank");
      isValid = false;
    }else {
      setOtpError("")
    }

    if(!checkPassword(password)){
      // setIsValid(false);

      setErrorPassChange("Enter a valid password. At least 8 characters— One special character, 1 Uppercase, 1 lowercase & 1 Number.");
      console.log("test");
      isValid = false;
    }

    if (password != confirmPassword) {
      // setIsValid(false);
      setErrorPassChange("Confirm password didn't match.");
      isValid = false;
    }

    console.log(isValid);
    console.log(errorPassChange);

    if (isValid) {
      setErrorPassChange("");
      setIfLoading(true);
      
      apiAxios
        .post("/auth/update-forgot-password", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            setForgotPass(false);
            setOtp("");
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            window.location.href = "/";  
            setIfLoading(false);
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    }
  }

  const enterForgot = (e)=>{
  
    if(e.keyCode == 13){
      onForgotPass();
  }
  }


  // handle key press login

  const handleKeyPress = (e)=>{

    if(e.keyCode === 13){
      onUserSubmit();
    }

  }

  const onKeyPress = (e)=>{

    if(e.keyCode === 13){
      onVerify();
    }

  }
// forgot password get otp
  const keyPressHandle = (e)=>{

    if(e.keyCode === 13){
      onForgotPassGetOtp();
    }

  }

  const handleKey= (e)=>{

    if(e.keyCode ===13){
      onPassUpdate()
    }

  }

  const togglePasswordVisibility = (flag)=>{

    if(flag === 1){
      setPasswordShown(!passwordShown)
    }else if(flag === 2){
      setPasswordChangeVisible(!passwordChangeVisible)
    }
    
  }



  const classes = useStyles();
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Login}>
        <Box className={classes.LoginLeft}>
          <Box className={classes.logo}>
            <img src={logo} alt="logo" style={{ width: "200px" }} />
          </Box>
          <h2 className={classes.toptext}>HealthyRPM</h2>
          <h6 style={{backgroundColor: "rgb(136 179 235 / 35%)", padding: "6px 10px", borderRadius: "40px", width: "100px", fontWeight: '400'}}>Care Team</h6>
          <Box className={classes.loginform}>
            <form>
              {!forgotPass ? (
                !isSuccessUser ? (
                  <>
                    <TextField
                      className={classes.input}
                      placeholder="Username"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyPress={handleKeyPress}
                      value={email}
                    />
                    <Box style={{position:"relative"}}>
                    <TextField
                      style={{ marginBottom: "15px"}}
                      className={classes.input}
                      placeholder="Password"
                      // type="password"
                      type={passwordShown? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                      value={password}
                    />
                    {password?
                    (<RemoveRedEyeIcon
                className={classes.redEye}
                onClick={()=>togglePasswordVisibility(1)}
              />) : <></>}
              </Box>
                    <FormGroup aria-label="position" row style={{justifyContent:'flex-end'}}>
                      {/* <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" />}
                        label="Remember Me"
                        labelPlacement="end"
                      /> */}

                      <a
                        style={{ marginTop: "8px", marginLeft: "60%", color: "#7da2d5", cursor: "pointer"  }}
                        onClick={(e) => {
                          onForgotPass();
                        }}
                        tabindex="0"
                        onKeyDown={enterForgot}
                      >
                        Forgot Password
                      </a>
                    </FormGroup>
                    {errorMsg ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorMsg}
                      </p>
                    ) : (
                      <></>
                    )}
                    { !ifLoading ?
                    <Button
                      size="large"
                      className={classes.loginbtn}
                      type="submit"
                      onClick={onUserSubmit}
                    >
                      LOG IN
                    </Button>
                    :
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                    }
                  </>
                ) : (
                  <>


                    
                    <Select options={roleList} value={role} onChange={setRole} placeholder="select user type" className={classes.select} style={{width:'100%'}} />
                        
                    <TextField
                      className={classes.input}
                      placeholder="Enter OTP"
                      type="tel"
                      value={otp}
                      onChange={(e) => onOtpChange(e)}
                      onKeyPress={onKeyPress}
                    />
                    <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                   <Button className={classes.backbtn} 
                    onClick={()=>{
                      // window.location.href = "/"

                      setSuccess(false)
                      setRoleError(false)
                      
                    }
                     
                    }
                    >Back</Button>
                    
                    <Box style={{position:"relative"}}>

                    { !ifLoading ?


                      

                   

                      
                    <Button
                      size="large"
                      className={classes.verifybtn}
                      type="submit"
                      onClick={onVerify}
                    >
                      VERIFY
                    </Button>     

                    
                    
                  

                    
                    :
                  
                      <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //0 secs
                   
                    />

                   
                  
                    }
                    </Box>
                    </Box>
                    {roleError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Please select a user type
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                )
              ) : forgotPassStep1 ? (
                <>
                  <p style={{ marginTop: "0px" }}>Forgot Password</p>
                  <TextField
                    className={classes.input}
                    placeholder="Username"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={keyPressHandle}
                  />

                     {errorMsg ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorMsg}
                      </p>
                    ) : (
                      <></>
                    )}
                
                    <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Button className={classes.backbtn} 
                    onClick={()=>{
                      window.location.href = "/"
                    }
                     
                    }
                    >Back</Button>
                  

                  <Box styel={{position:"relative"}}>
                  { !ifLoading ?
                  <Button
                    size="large"
                    className={classes.otpbtn}
                    onClick={onForgotPassGetOtp}
                    type="submit"
                  >
                    SEND OTP
                  </Button>
                  
                  :

                  
                       <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />

                 
                 
                  }
                  </Box>
                  </Box>
                </>
              ) : (
                <>
                  <TextField
                    className={classes.input}
                    placeholder="Enter OTP"
                    type="tel"
                    value={otp}
                    onChange={(e) => onOtpChange(e)}
                    onKeyPress={handleKey}
                  />

                  {otpError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {otpError}
                      </p>
                    ) : (
                      <></>
                    )}


                  <p style={{ marginTop: "20px" }}>Change Password</p>
                  <Box style={{position:"relative"}}>
                  <TextField
                    style={{ marginBottom: "15px" }}
                    className={classes.input}
                    placeholder="Password"
                    type={passwordShown? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKey}
                  />
                  {password?
                     (<RemoveRedEyeIcon
                className={classes.redEyeIcon}
                onClick={()=>togglePasswordVisibility(1)}
              />) : <></>
                     }
                  </Box>
                  <Box style={{position:"relative"}}>
                  <TextField
                    style={{ marginBottom: "15px" }}
                    className={classes.input}
                    placeholder="Confirm Password"
                    type={passwordChangeVisible? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={handleKey}
                  />
                 {confirmPassword?
                    ( <RemoveRedEyeIcon
                className={classes.eyeIcon}
                onClick={()=>togglePasswordVisibility(2)}
              />) : <></> }
                </Box>
                  {/* <Button className={classes.backbtn} 
                    onClick={()=>{
                      window.location.href = "/"
                    }
                     
                    }
                    >Back</Button> */}
                  {errorPassChange ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorPassChange}
                      </p>
                    ) : (
                      <></>
                    )}

                     {/* {otpError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {otpError}
                      </p>
                    ) : (
                      <></>
                    )} */}

                  <Button className={classes.backbtn} 
                    onClick={()=>{
                      // window.location.href = "/"
                      setForgotPassStep1(true);
                      setOtpError("")
                      setErrorPassChange("")
                    }
                     
                    }
                    >Back</Button>

                  { !ifLoading ?
                  <Button
                    size="large"
                    className={classes.verifybtn}
                  
                    onClick={onPassUpdate}
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                   :
                  //  <Loader
                  //     type="ThreeDots"
                  //     color="#000000"
                  //     height={50}
                  //     width={50}
                  //     timeout={0} //30 secs
                  //   />
                  <box style={{textAlign:"right"}}>
                  <Loader
                   type="ThreeDots"
                   color="#000000"
                   height={50}
                   width={50}
                    timeout={0} //30 secs
                   />

                   </box>
                   }
                </>

                
              )}

               
            </form>
            <p>www.healthyrpm.com</p>
          </Box>
        </Box>
        <Box className={classes.LoginRight}>
          <Box className={classes.rightcontent}>
            <h3 className={classes.rightheading}>About HealthyRPM</h3>
            <p className={classes.righttext}>
              HealthyRPM is DHCT's web-based CCM patient engagement application.
              HealthyRPM is designed for Medicare reimbursement programs such as
              Chronic Care Management (CCM), Principal Care Management (PCM),
              and Behavioral Health.
            </p>
          </Box>
          <img src={loginbg} alt="login-bg" />
        </Box>
      </Box>
    </div>
  );
}

export default Login;
const useStyles = makeStyles(() => ({
  Login: {
    display: "flex",
    width: "100%",
    // position:'fixed',
    left:0,
    right:0,
    '@media only screen and (max-width: 820px)':{
      flexDirection:'column'
                  },
    '@media only screen and (max-width: 768px)':{
flexDirection:'column'
            },
    "& a": {
      textDecoration: "none",
    },
  },
  LoginLeft: {
    width: "40%",
    paddingTop: "50px",
    paddingLeft: "5%",
    paddingRight: "5%",
    textAlign: "left",
    '@media only screen and (max-width: 1200px)':{
      width: '34%',
      paddingLeft: "3%",
    paddingRight: "3%",
            },
            '@media only screen and (max-width: 820px)':{
              width: '94%',
              paddingLeft: "3%",
            paddingRight: "3%",
                    },
                    '@media only screen and (max-width: 768px)':{
                      width: '94%',
                      paddingLeft: "3%",
                    paddingRight: "3%",
                            },
    "& p": {
      fontSize: "16px",
      color: "#AEAEAE",
      marginTop: "70%",
      '@media only screen and (max-width: 1200px)':{
        marginTop: "85%",
              },
              '@media only screen and (max-width: 820px)':{
                marginTop: "5%",
                      },
                      '@media only screen and (max-width: 768px)':{
                        marginTop: "5%",
                              },
    },
    '& h2':{
      fontSize:'34px',
      fontWeight:'bold',
      marginBottom:'50px'
  },
  '& .react-dropdown-select-content':{
'& span':{
fontSize:13
}
  }
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    backgroundColor:'#F1F1F1',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    marginBottom: '20px'
   },
//    selectMax:{
//     width:'100%',
//     border:'none !important',
//     borderRadius:'10px !important',
//     background:'#F1F1F1',
//     height:'50px',
    
//     fontSize:'14px !important',
//     paddingLeft:'15px !important',
//     paddingRight:'15px !important',
//     paddingTop:'15px !important',
//     marginBottom: '20px'
// },
  toptext: {
    fontSize: "34px",
    fontWeight: "bold",
    marginBottom: "50px",
  },
  LoginRight: {
    width: "50%",
    position: "relative",
    '@media only screen and (max-width: 1200px)':{
      width: '60%',
            },
            '@media only screen and (max-width: 820px)':{
              width: '100%',
                    },
                    '@media only screen and (max-width: 768px)':{
                      width: '100%',
                            },
    "& img": {
      width: "100%",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    marginBottom: "30px",
  },
  loginform: {
    width: "70%",
    '@media only screen and (max-width: 1200px)':{
      width: '100%',
            },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#F1F1F1",
      padding: "0 15px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  loginbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    // marginLeft: "93px",
    "&:hover": {
      background: "#333",
    },
  },
  verifybtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "99px",
    "&:hover": {
      background: "#333",
    },
  },
  backbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  otpbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "87px",
    "&:hover": {
      background: "#333",
    },
  },
  rightcontent: {
    width: "80%",
    padding: "0 10%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    left: "0px",
    top: "0px",
    bottom: "0px",
  },
  rightheading: {
    color: "#fff",
    margin: "0px",
  },
  righttext: {
    textAlign: "left",
    color: "#fff",
  },
  redEye: {
    position: "absolute",
    // top: "20px",
   bottom: "30px",
    // left: "345px",
    right: "20px",
    cursor: "pointer",
    opacity: 0.8
  },
  redEyeIcon : {
    position: "absolute",
    // top: "20px",
   bottom: "29px",
   right: "13px",
    cursor: "pointer",
    opacity: 0.8
    
  },
  eyeIcon : {
    position: "absolute",
    // top: "20px",
   bottom: "29px",
    right: "13px",
    cursor: "pointer",
    opacity: 0.8

  },
 
}));
