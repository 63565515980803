import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box, Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import {BiArrowBack} from "react-icons/bi"
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import { Pagination } from '@material-ui/lab';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Setting from '../components/Setting'
import Typography from '@material-ui/core/Typography'
import { BiXCircle,BiInfoCircle } from "react-icons/bi"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios"
import AddPatientProgramModal from "./AddPatientProgramModal";
import { BiPlus, BiMinus } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "react-loader-spinner";
import {
  
  BiPlusCircle,

  BiCheckCircle,
} from "react-icons/bi";
import DatePicker from "react-date-picker";
import { stateList } from "../Util/DropDownData";
import {
  checkName,
  checkEmail,
  checkNumbers,
  checkPhone,
  checkPhoneNumbers,
  checkNumbersOnly,
  checkDob,
  checkZip,
  checkUSPhone
} from "../Util/validations";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function PatientAssignment(props, { options }) {
    const classes = useStyles();
    
  const [selectedValue, setSelectedValue] = React.useState('patient');
  const [providerList, setProviderList] = useState([]);
  const [providerListOption, setProviderListOption] = useState([]); 
  const [selectedProvider, setSelectedProvider] = useState("");

  const [programList, setProgramList] = useState([]);
  const [programListOption, setProgramListOption] = useState([]); 
  const [selectedProgram, setSelectedProgram] = useState("");

  const [patientList, setPatientList] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [checked, setChecked] = React.useState(false);

  const [message, setMessage] = useState("");

  const [flag, setFlag] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [currentReportPage, setCurrentReportPage] = useState(1);
  const [totalReportPages, setTotalReportPages] = useState(1);
  const currentPageRef = useRef(1);
  const [loader, setLoader] = useState(false)

  const location = useLocation();
  const client_id = location.state?.client_id;

  // modal state
  const [value2, onChange2] = useState(new Date());
  const [emrid, setEmrID] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [patientID, setPatientID]= React.useState("");
  const [patientReport, setPatientReport] = React.useState("")
  const [tablemessage, setTableMessage] = useState("");
  

  //Error States
  const [emrError, setEmrError] = useState(false);
  const [firstnameError, setFirstNameError] = useState(false);
  const [lastnameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipError, setZipError] = useState(false);


  useEffect(() => {
    // getProviderList();
    // getProgramList();
     getPatientList();
  

    if(localStorage.getItem("tab")){
      localStorage.removeItem("tab");
      localStorage.removeItem("panel");
    }

  }, []);  

//   useEffect(() => {
//     if(providerList){ 
//        var ob_arr =[];
//        //console.log("user types = ", userTypes);
//        for(var i=0;i<providerList.length;i++){
//        let value = providerList[i].id;
//        let label = providerList[i].first_name + " " + providerList[i].last_name;
//        let ob = {
//            "label":label, "value": value
//        }
//        //console.log("ob types = ", ob);
//        ob_arr.push(ob);
//        }
//        setProviderListOption(ob_arr);
//        setSelectedProvider(ob_arr[0]);
//    }
   
//  }, [providerList]);  

 const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const [open4, setOpen4] = React.useState(false);
  const [patientInfo, setPatientInfo] = React.useState("");

  const handleClickOpen4 = (data) => {
     getProgramList()
    setPatientInfo(data);
    setOpen4(true);
  };


  const handleClose4 = () => {
    setOpen4(false);
  }; 


  //  modal  //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // update patient modal
  const [openUpdate, setOpenUpdate] = React.useState(false);


 

  const handleUpdateOpen = (data)=>{
   
    let stateCatList = stateList();
  
    for(var i=0; i<stateCatList.length; i++){

      let str = data.patient_state
    // let stateName = str.charAt(0).toUpperCase() + str.slice(1);

    const words = str.split(" ");

    // words.map((word) => { 
    //     return word[0].toUpperCase() + word.substring(1); 
    //  }).join(" ");


     for (let j = 0; j < words.length; j++) {
      words[j] = words[j][0].toUpperCase() + words[j].substr(1);
      }
  
         let newWords = words.join(' ');
          console.log(newWords)


        if(stateCatList[i].label ==  newWords){
          console.log(stateCatList[i])
          // let st = stateCatList[i]
          // console.log(st)
          setState(stateCatList[i]);
          console.log(state)
        }
  }

    

   
    setEmrID(data.emr_id);
    setFirstName(data.patient_first_name);
    setLastName(data.patient_last_name);
    onChange2(data.patient_dob);
   
    setEmail(data.patient_email);
    setPhone(formatPhoneNumber(data.patient_primary_phone));
    setAddress(data.patient_mailing_address);
  
    setCity(data.patient_city);
    setZip(data.patient_zip);
    setPatientID(data.id);
    // console.log("patientID")
    // console.log(patientID)
    // console.log(data.patient_state)
   
 
  
    
  setOpenUpdate(true);
    
}

  const handleUpdateClose = ()=>{
    setOpenUpdate(false);
    setEmrID("")
    setFirstName("")
    setLastName("")
    onChange2(new Date())
    setEmail("")
    setPhone("")
    setAddress("")
    setState("")
    setCity("")
    setZip("")


  }




 useEffect(() => {
    if(programList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<programList.length;i++){
       let value = programList[i].id;
       let label = programList[i].program_name;
       let id = programList[i].template_id
       let ob = {
           "label":label, "value": value, template_id: id
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProgramListOption(ob_arr);
       setSelectedProgram(ob_arr[0]);
   }
   
 }, [programList]);  

  function openPatProgram(data) {

    console.log(data);

    props.history.push({
        pathname: "/patient-information",
        state: {
          patientInfo: data,
          providerList: providerListOption,
          programList: programListOption
        },
      });
  } 


  // function getProviderList() {


  //   let data ={
  //     table_config_rows_per_page: '25',
  //     table_config_page_no: '1',
  //     search_value: "",
  //     cutid: "",
  //     user_master_type_id: "",
  //     client_id: localStorage.c,
  //     is_provider: 1
  //   }

  //   apiAxios
  //       .post("/client/clientuserlist", data, {
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": localStorage.getItem("Token")
  //         },
  //       })
  //       .then((response) => {
  //         console.log("response --> ",response);
  //         if(response.data.statusCode == 200) {
  //             setProviderList(response.data.data); 
              
  //         }
  //         else {
  //             toast.error(response.data.message, {
  //                 position: "bottom-center",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  
  //         toast.error(error.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             });

  //             localStorage.removeItem("Refresh_Token");
  //             localStorage.removeItem("Token");
  //             window.location.href = "/";        

  
  //       });
  // }

  function getProgramList() {


    let data ={
        table_config_rows_per_page: "100",
        table_config_page_no: "1",
        program_id: "",
        provider_id: "",
        client_id: "",
        search_value: "",
        status: "1",
        is_unique : "1"
      }

    apiAxios
        .post("/client/client-provider-program-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setProgramList(response.data.data); 
              
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }

  function submitSearch() {
      getPatientList();
      setFlag(true);
  }

  function resetFilter() {
    window.location.href = "/patient-assignment";
}

  const getPatientList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

    setCurrentPage(parseInt(table_config_page_no));
    
        
    setPatientList([]);
    setMessage("Loading...");

    let jwt = JSON.parse(localStorage.getItem("JWT"));

    let data ={
        table_config_page_no: table_config_page_no,
        client_id: jwt.client_id,
        provider_id: "",
        search_value: searchVal? searchVal : "",
        program_id: "",
        table_config_rows_per_page: table_config_rows_per_page,
      }

    apiAxios
        .post("/patient/list-patient-demographics", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setPatientList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
         

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  function onSearchSubmit() {
    getPatientList();
  }

  

 

  function submitForm(flag) {

    console.log(state)

    

    let isValid = true;

    console.log("submit form");

    if (!checkName(firstname.trim())) {
      setFirstNameError(true);
      isValid = false;
      console.log("First Error if= ", firstnameError);
    } else {
      setFirstNameError(false);
    }
    console.log("First Error = ", firstnameError);

    if (!checkName(lastname.trim())) {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }
    if (emrid.trim().length >0 && emrid.trim().length <=255) {
      setEmrError(false);
    } else {
      setEmrError(true);
      isValid = false;
    }
    if (!checkEmail(email) && email.length > 0) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (!checkUSPhone(phone)) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }
    console.log("Age no sub = ", parseInt(checkDob(value2)));
    if (parseInt(checkDob(value2)) < 40 || parseInt(checkDob(value2)) >200 || isNaN(parseInt(checkDob(value2)))) {
      setDobError(true);
      isValid = false;
    } else {
      setDobError(false);
    }
    if (address.trim().length > 0) {
      setAddressError(false);
    } else {
      setAddressError(true);
      isValid = false;
    }
    // if (state.length > 0) {
    //   setStateError(false);
    // } else {
    //   setStateError(true);
    //   isValid = false;
    // }

    if (state) {
      setStateError(false);
    } else {
      setStateError(true);
      isValid = false;
    }

    if (city.trim().length > 0 && city.trim().length <=255) {
      setCityError(false);
    } else {
      setCityError(true);
      isValid = false;
    }
    if (checkZip(zip)) {
      setZipError(false);
    } else {
      setZipError(true);
      isValid = false;
    }

    if (isValid) {
      setLoader(true)
      if(flag ===1){
      let data = {
        client_id: client_id,
        emr_id: emrid,
        patient_first_name: firstname,
        patient_last_name: lastname,
        patient_mailing_address: address,
        patient_city: city,
        patient_state:  state[0]? state[0].label : "",
        patient_zip: zip,
        patient_email: email,
        patient_dob: moment(value2).format("YYYY-MM-DD"),
        patient_primary_phone: phone.replace(/[^0-9]/g,""),
      };

      apiAxios
        .post("/patient/create-patient", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpen(false);
            setLoader(false)
            const timer = setTimeout(() => {
              window.location.href = "/patient-assignment";   
            }, 2000);
            
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoader(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false)
          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });


      }else{

        let data = {
          client_id: client_id,
          patient_id: patientID,
          emr_id: emrid,
          patient_first_name: firstname,
          patient_last_name: lastname,
          patient_mailing_address: address,
          patient_city: city,
          patient_state: state.label ? state.label : state[0].label,
          patient_zip: zip,
          patient_email: email,
          patient_dob: moment(value2).format("YYYY-MM-DD"),
          patient_primary_phone: phone.replace(/[^0-9]/g,""),
        };
  
        apiAxios
          .post("/patient/update-patient-demographics", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log("response --> ", response);
            if (response.data.statusCode == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setOpen(false);
              setLoader(false)
              window.location.href = "/patient-assignment";     
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoader(false)
            }
          })
          .catch((error) => {
            console.log(error);
            setLoader(false)
            toast.error("Something Went Wrong!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });




      }
    }
  }


  function backButton() {
      window.location.href = "/dashboard";
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };


// patient report download modal


const [openView, setOpenView] = React.useState(false);

const handleClickOpenView = (data) =>{
  console.log(data.id)
    
    setOpenView(true);
    setPatientID(data.id)

    if(patientID){
      getPatientReport()
    }else {

    }
   
   
    
}


const handleCloseView = () => {
setOpenView(false);
};
// patient report download

const getPatientReport = useCallback(
async ({
    
    table_config_rows_per_page = "15",
    table_config_page_no = "1",
    
   
} = {}) => {
    setCurrentPage(parseInt(table_config_page_no));

    let data = {
      
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
        pd_id: patientID
    };

    setTableMessage("Loading...");

    apiAxios
        .post("/report/patient-history-report", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Token"),
            },
        })
        .then((response) => {
            console.log("patient list data --> ", response);
            if (response.data.statusCode == 200) {
                setPatientReport(response.data.data);
                setTotalReportPages(response.data.total_pages);

                if (response.data.data.length == 0) {
                    setTableMessage("No Data Found");
                }
            } else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
}
);




const handleInput = (e) => {
   
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  
  setPhone(formattedPhoneNumber);
};


function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;


}



    return (
        <div>
             <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
 
             <Header />
             <Box className={classes.pagecontent}>
                 <Box className={classes.pageTop}>
                     <Button onClick={backButton}><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box>
                 <Box className={classes.Searchresult}>
                     <Box className={classes.Topfilter}>
                 {/* <Box style={{marginRight:15,}}>
                 <label>Provider Filter</label>
                      <Select options={providerListOption} value={selectedProvider} onChange={setSelectedProvider} placeholder="Select Provider" className={classes.select} style={{width:'200px'}} />
                 </Box> */}
                 {/* <Box style={{marginRight:15,}}>
                   <label>Program Filter</label>
                      <Select options={programListOption} value={selectedProgram} onChange={setSelectedProgram} placeholder="Select Program" className={classes.select} style={{width:'200px'}} /></Box> */}
                 <Box className={classes.TopfilterLeft} style={{display:'flex',flexDirection:'row',}}>
                   <Box style={{width:'60%'}}>
                  <label>Patient Assignment</label>
                  <TextField className={classes.input} onChange={(e) => setSearchVal(e.target.value)} placeholder="Patient Search" type="text" />
                  </Box>
                  <Button className={classes.Searchbtn} onClick={submitSearch}>Search</Button>
                  
                  <p style={{color: 'blue', cursor: 'pointer', margin:'40px 0 0 35px'}} onClick={resetFilter}>Reset</p>  
                 </Box>
                 <Box className={classes.TopfilterRight} style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                 <Button className={classes.Searchbtn}  onClick={handleClickOpen}>
                            {/* <BiPlusCircle className={classes.icon} /> */}
                             Add New Patient
                        </Button>
                 </Box>
                </Box>
                     <Box>
                         {(searchVal && flag) ?
                            <h4>Search Results for ‘{searchVal}’</h4>
                            :
                            <></>
                         }
                    </Box> 
                    <Box className={classes.PatientTable}> 
                     <Box className={classes.throw}> 
                         <Box className={classes.firstname}>First Name</Box>
                         <Box className={classes.lastname}>Last Name</Box>
                         <Box className={classes.dob}>DOB</Box>
                         <Box className={classes.city}>City</Box>
                         <Box className={classes.email}>Email</Box>
                         <Box className={classes.phone}>Phone</Box>
                         
                         <Box className={classes.status}>Status</Box>
                         <Box className={classes.hrpm}>HRPM ID</Box>
                         <Box className={classes.hrpm}>EMR ID</Box>
                         <Box className={classes.program}>Program</Box>
                         <Box className={classes.patient}>Action</Box>
                         {/* <Box className={classes.action}>Action</Box> */}
                         
                     </Box>
                     {(patientList.length > 0) ?
                    <>
                    {patientList.map((each) => (
                     <Box className={classes.tdrow}> 
                         <Box className={classes.firstname}>{each.patient_first_name}</Box>
                         <Box className={classes.lastname}>{each.patient_last_name}</Box>
                         <Box className={classes.dob}>{each.patient_dob}</Box>
                         <Box className={classes.city}>{each.patient_city}</Box>
                         <Box className={classes.email}>{each.patient_email}</Box>
                         <Box className={classes.phone}>{formatPhoneNumber(each.patient_primary_phone)}</Box>
                        
                         <Box className={classes.status}>{(each.status == 1) ? "Active" : "Inactive"}</Box>
                         <Box className={classes.hrpm}>{each.hrpm_id}</Box>
                         <Box className={classes.hrpm}>{each.emr_id}</Box>
                         { (each.program_id)?
                         <Box className={classes.program} onClick={()=>openPatProgram(each)}><b style={{color: '#88b3eb'}}>{each.program_abbrev}</b></Box>
                         :
                         <Box className={classes.program}><Button className={classes.planbtn} style={{backgroundColor: '#c10d91'}} onClick={() => handleClickOpen4(each)}><b>Add Program</b></Button></Box>
                         }
                         {/* <Box className={classes.plan}><Button className={classes.planbtn}><b>Download PDF</b></Button></Box> */}
                         <Box className={classes.patient}><Button className={classes.planbtn} onClick={()=>handleUpdateOpen(each)}><b>Update</b></Button></Box>
                         {/* <Box className={classes.action}><Button className={classes.planbtn} onClick= {()=>{handleClickOpenView(each)}}><b>Download</b></Button></Box> */}

                          
                         
                        
                         
                     </Box>
                    ))}
                    </>
                     :
                     <Box style={{ marginTop:'30px',textAlign: 'center'}}>
                        {message}
                     </Box>
                     }
                        </Box> 
                    </Box>
                    <br />
                
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getPatientList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 
                
             </Box>
              {/* Add new patient modal */}
        <Dialog
          className={classes.modal}
          open={open}
          disableEnforceFocus={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <h3 className={classes.Toptext}>Add New Patient</h3>
              <Button onClick={handleClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className={classes.loginform}>
                
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                        <label>
                          Patient EMR ID
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Patient EMR ID"
                          type="text"
                          value={emrid}
                          onChange={(e)=>setEmrID(e.target.value)}
                          // onChange={(e) =>{
                          //    setEmrID(e.target.value);
                          //    if (emrid.length != "") {
                          //     setEmrError(false);
                          //   } else {
                          //     setEmrError(true);
                             
                          //   }
                             
                          //   }}
                        />
                      </Box>
                      {emrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Emr ID is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          
                          onChange={(e)=>setFirstName(e.target.value)}
                          // onChange={(e) =>{
                          //    setFirstName(e.target.value);

                          //    if (!checkName(firstname.trim())) {
                          //     setFirstNameError(true);
                              
                          //   } else {
                          //     setFirstNameError(false);
                          //   }
                           
                          // }}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid First Name
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e)=> setLastName(e.target.value)}
                          // onChange={(e) =>{
                          //    setLastName(e.target.value);
                            
                          //    if (!checkName(lastname.trim())) {
                          //     setLastNameError(true);
                              
                          //   } else {
                          //     setLastNameError(false);
                          //   }
                           
                            
                            
                          // }
                          //   }
                          
                          type="text"


                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid Last name 
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Date of Birth
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                         
                          onChange={onChange2}
                          value={value2}
                         
                          className={classes.input}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                         
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Age should be between (40-200)yrs.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e)=>handleInput(e)}

                        
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Address<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                         
                          onChange={(e)=>setAddress(e.target.value)}
                           
                        />
                      </Box>
                      {addressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Address is required.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          State<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <FormControl style={{width:'100%'}}>
                        <Select
                          options={stateList()}
                          value={state}
                          // displayEmpty={true}
                          onChange={setState}
                          placeholder={state ? state.label : "Select State"}
                          className={classes.select}
                        />
                        </FormControl>
                      </Box>
                      {stateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please select a state.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          City<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City"
                          type="text"
                          value={city}
                          onChange={(e) =>setCity(e.target.value)}
                          
                        />
                      </Box>
                      {cityError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          City is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Zipcode<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="tel"
                          value={zip}
                          onChange={(e) =>setZip(e.target.value)}
                          
                        />
                      </Box>
                      {zipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid zipcode
                        </p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            {loader ? (
              <Loader
                type="ThreeDots"
                color="#000000"
                height={50}
                width={50}
                timeout={0} //30 secs
              />
            ) : (
              <Button
              size="large"
              className={classes.loginbtn}
              onClick={() => submitForm(1)}
            >
              Save
            </Button>
            )}
           
          </DialogActions>
        </Dialog>

              {/* Update patient modal */}

        <Dialog
          className={classes.modal}
          open={openUpdate}
          disableEnforceFocus={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleUpdateClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleUpdateClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className={classes.loginform}>
                <h3 className={classes.Toptext}>Update Patient Details</h3>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                        <label>
                          Patient EMR ID
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Patient EMR ID"
                          type="text"
                          value={emrid}
                          onChange={(e) =>setEmrID(e.target.value)}
                          // onChange={(e) =>{
                          //   setEmrID(e.target.value);
                          //   if (emrid.length != "") {
                          //    setEmrError(false);
                          //  } else {
                          //    setEmrError(true);
                            
                          //  }
                            
                          //  }}
                        />
                      </Box>
                      {emrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Emr ID is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          onChange={(e) =>setFirstName(e.target.value)}
                        //   onChange={(e) =>{
                        //     setFirstName(e.target.value);

                        //     if (!checkName(firstname.trim())) {
                        //      setFirstNameError(true);
                             
                        //    } else {
                        //      setFirstNameError(false);
                        //    }
                          
                        //  }}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid First name 
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e) =>setLastName(e.target.value)}
                        //   onChange={(e) =>{
                        //     setLastName(e.target.value);
                           
                        //     if (!checkName(lastname.trim())) {
                        //      setLastNameError(true);
                             
                        //    } else {
                        //      setLastNameError(false);
                        //    }
                          
                           
                           
                        //  }
                        //    }
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid Last name 
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Date of Birth
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          onChange={(date)=>onChange2(date)}
                          value={value2? new Date(value2):  null}
                          
                          className={classes.input}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                          
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                         Age should be between (40-200)yrs.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) =>handleInput(e)}
                          
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Address<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                          onChange={(e) =>setAddress(e.target.value)}
                          // onChange={(e) => {
                          //   setAddress(e.target.value);
                          //   if (address.length > 0) {
                          //     setAddressError(false);
                          //   } else {
                          //     setAddressError(true);
                             
                          //   }
                          // }}
                        />
                      </Box>
                      {addressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Address is required.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          State<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={stateList()}
                          value = {state? state : ""}
                          onChange={setState}
                          placeholder={state ? state.label : "Select State"}
                          className={classes.select}
                        />
                      </Box>
                      {stateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please select a state.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          City<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City"
                          type="text"
                          value={city}
                          onChange={(e) =>setCity(e.target.value)}
                        //   onChange={(e) =>{

                          
                        //     setCity(e.target.value)
                        //     if (city.length > 0) {
                        //      setCityError(false);
                        //    } else {
                        //      setCityError(true);
                           
                        //    }
                        //  }}
                        />
                      </Box>
                      {cityError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          City is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Zipcode<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="tel"
                          value={zip}
                          onChange={(e) =>setZip(e.target.value)}
                          // onChange={(e) =>{
                          //   setZip(e.target.value);
                          //   if (checkZip(e.target.value)) {
                          //    setZipError(false);
                          //  } else {
                          //    setZipError(true);
                             
                          //  }
                          //  }}
                        />
                      </Box>
                      {zipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Enter a valid zipcode
                        </p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            {loader ? (
              <Loader
                type="ThreeDots"
                color="#000000"
                height={50}
                width={50}
                timeout={0} //30 secs
              />
            ) : (
              <Button
              size="large"
              className={classes.loginbtn}
              onClick={()=>submitForm(2)}
            >
              Save
            </Button>
            )}
            
          </DialogActions>
        </Dialog>
            
            
                {/* Excel List */}
        <Dialog
        className={classes.downloadModal}
        open={openView}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseView}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            {/* <Typography variant="h5" className={classes.TopHeading}>
              Excel List 
            </Typography> */}
            <Button onClick={handleCloseView} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          
          <DialogContentText id="alert-dialog-slide-description">
           
             
          <h2 style={{textAlign:"center"}}>Patient Report List</h2> <br /> 
        <Grid container spacing={3}>
      
        <Grid item xs={12}>
        
          <Box className={classes.Throw1}>
            <Box className={classes.Thcol5}>Input File</Box>
            <Box className={classes.Thcol6}>Output File</Box>
            <Box className={classes.Thcol8}>Created At</Box>
            <Box className={classes.Thcol11}>Action</Box>
           
          </Box>
            
          <br /> 

          <Pagination
            count={totalReportPages}
            color="primary"
            page={currentReportPage}
            onChange={(event, pageNumber) =>
              getPatientReport({
                table_config_page_no: `${pageNumber}`,
              })
            }
          />

         
        </Grid>
      </Grid>

                
           
          
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
        
        </DialogActions>
      </Dialog>




             <AddPatientProgramModal
              isOpen={open4}
              onClose={handleClose4}
              providerList={providerListOption}
              programList={programListOption}
              patientDetails={patientInfo}
            ></AddPatientProgramModal>
        </div>
    )
}

export default PatientAssignment
const useStyles = makeStyles(() => ({
  Topcol:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    marginBottom:'0',
    '& a':{
        textDecoration:'none'
    }
},
TopfilterLeft:{
  width:'50%',
  '@media only screen and (max-width: 820px)':{
    width:'70%'
          },
          '@media only screen and (max-width: 768px)':{
            width:'70%'
                  },
},
TopfilterRight:{
  width:'50%',
  '@media only screen and (max-width: 820px)':{
    width:'30%'
          },
  '@media only screen and (max-width: 768px)':{
    width:'30%'
          },
},
PatientTable:{
  width:'100%',
  '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
    overflowX:'auto'
          },
},
Toptext:{
  margin:0,
  fontFamily:'poppins',
  fontSize:18,
  color:'#000',
  fontWeight:'500'
},
    pagecontent:{
        width:'94%',
        background: 'linear-gradient(to bottom, #0f3d6e05, #ffffff00)',
        padding:'20px 3%',
        display:'flex',
        flexDirection:'column',
        '& a':{
          textDecoration:'none'
        }
    },
    chartbtn:{
      background:'#0f3d6e',
      borderRadius:'10px',
      padding:'0 20px',
      height:'40px',
      color:'#fff',
      textTransform:'capitalize',
      fontSize:'14px',
      cursor:'pointer',
      '&:hover':{
          background:'#333'
      }
  },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#fff',
        paddingBottom:'5px',
        background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
        padding: 6,
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1366,
                },
  
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 0',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1366,
                },
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    provider:{
        width:'15%',
        textAlign:'left'
    },
    firstname:{
        width:'6%',
        textAlign:'left',
        whiteSpace: "normal",
        wordWrap:"break-word",
        overflow: "auto"
        
      
    },
    lastname:{
        width:'6%',
        textAlign:'left',
        wordWrap:"break-word",
        overflow: "auto"
    },
    dob:{
        width:'7%',
        textAlign:'left',
      
    },
    city:{
      width:'5%',
      textAlign:'left',
    
  },
    // emrID:{
    //     width:'5%',
    //     textAlign:'left'
    // },
    hrpmID:{
        width:'15%',
        textAlign:'left',
        wordBreak: 'break-all'
    },
    phone:{
        width:'10%',
        textAlign:'left',
        wordWrap:"break-word",
        overflow: "auto"
    },
    email:{
      width:'10%',
      textAlign:'left',
      wordWrap:"break-word",
      overflow: "auto"
  },
    hrpm:{
      width:'5%',
      textAlign:'left',
      wordWrap:"break-word",
      overflow: "auto"
  },
    status:{
      width:'5%',
        textAlign:'left'
    },
    program:{
        width:'8%',
        textAlign:'center',
        cursor: 'pointer'
    },
    action:{
      width:'8%',
      textAlign:'center',
      cursor: 'pointer'
  },
    patient:{
      width:'8%',
      textAlign:'center',
      cursor: 'pointer'
  },
    date:{
        width:'10%',
        textAlign:'left'
    },
    agentID:{
        width:'9%',
        textAlign:'left'
    },
    finaldate:{
        width:'8%',
        textAlign:'left'
    },
    plan:{
        width:'10%',
    },
    planbtn:{
        fontSize:'12px',
        color:'#fff',
        background: '#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px',
        '&:hover':{
          background:'#333'
        }
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        }
    },
    btncol:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:40,
        alignItems:'center'
   },

Topfilter:{
    width:'100%',
    display:'flex',
    marginTop:30,
    marginBottom:50,
    flexDirection:'row',
    '& label':{ color:'#666',marginBottom:5, display:'flex'},
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInput-input:focus':{
        border:'1px #0074D9 solid',
        boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 15px'
    },
    '& .MuiTextField-root':{
        width:'100%'
    }
},
Searchbtn:{
    background:'#0f3d6e',
    borderRadius:10,
    display:'flex',
    color:'#fff',
    height:50,
    paddingLeft:30,
    paddingRight:30,
    marginLeft:15,
    textTransform:'capitalize',
    marginTop:28,
    '&:hover':{
      background:'rgba(0,0,0,0.8)'
    }
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    fontFamily:'poppins',
    textTransform:'capitalize',
    fontSize:14,
    "&:hover": {
      background: "#000",
    },
  },

  Formcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'20px',
    '&>div':{
        width:'100%'
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 205px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400'
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    '& .react-dropdown-select-input':{
        // display: 'none'
     
    
    }
  },
  modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'550px',
        maxWidth:'550px'
    }
  },
  downloadModal: {
    "& .MuiPaper-rounded": {
        borderRadius: "10px !important",
        padding: "20px",
        width: "950px",
        maxWidth: "950px",
    },
},
  ccmmodal:{
    borderRadius:'10px',
      '& .MuiInput-underline:before':{
          display:'none'
      },
      '& .MuiInput-underline:after':{
          display:'none'
      },
      '& .MuiInput-formControl':{ 
          height:'50px',
          
      },
      '& .MuiInput-input:focus':{
          border:'1px #0074D9 solid',
          boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
      },
      '& .MuiInputBase-input':{
          height:'50px',
          borderRadius:'10px',
          border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
          padding:'0 15px'
      },
      '& .react-date-picker__wrapper':{
        borderRadius:10,
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 10px',
    },
  },
  modalbtn:{
    display:'flex',
    justifyContent:'space-between',
    marginRight:'30px',
    marginLeft:'15px',
    alignItems:'center'
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  icon:{
    color:'#7087A7',
    fontSize:'20px',
    marginRight:'10px',
    marginRight:'10px'

  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    
  },
  Throw: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    paddingBottom: 7,
    fontSize: 14,
    "& label": {
        color: "#919699",
    },
},
Throw1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 5,
  },
Thcol: {
    width: "8%",
},
Thcol2: {
    width: "8%",
},
Thcol3: {
    width: "8%",
},
Thcol4: {
    width: "8%",
},

Thcol5: {
    width: "24%",
    fontSize: 16,
    color: "#696969",
  },
  Thcol6: {
    width: "27%",
    fontSize: 16,
    color: "#696969",
  },

  Thcol8: {
    width: "25%",
    fontSize: 16,
    color: "#696969",
  },
  Thcol9: {
    width: "14%",
    fontSize: 16,
    color: "#696969",
  },
  Thcol10: {
    width: "20%",
    fontSize: 16,
    color: "#696969",
  },
  Thcol11: {
    width: "20%",
    fontSize: 16,
    color: "#696969",
    display: "flex",
    justifyContent: "flex-end",
  },

Thcol7: {
    width: "10%",
},

Thcol12: {
    width: "5%",
},
Thcol13: {
    width: "10%",
},
Tdrow: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    padding: "10px 0",
    fontSize: 14,
},
Tdrow1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    padding: "10px 0",
  },
  Tdcol5: {
    width: "23.5%",
    paddingRight:'0.5%',
    wordWrap:"break-word",
    overflow: "auto"

  },
  Tdcol6: {
    width: "26.5%",
    paddingRight:'0.5%',
    wordWrap:"break-word",
    overflow: "auto"
  },
  Tdcol7: {
    width: "13.5%",
    paddingRight:'0.5%'
  },
  Tdcol8: {
    width: "27.5%",
    paddingRight:'0.5%'
  },
  Tdcol9: {
    width: "13.5%",
    paddingRight:'0.5%'
  },
  Tdcol10: {
    width: "19.5%",
    paddingRight:'0.5%'
  },
  Tdcol11: {
    width: "19.5%",
    paddingRight:'0.5%',
    display: "flex",
    justifyContent: "flex-end",
  },
 
}));