import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box, Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import {BiArrowBack} from "react-icons/bi"
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import { Pagination } from '@material-ui/lab';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Setting from '../components/Setting'
import Typography from '@material-ui/core/Typography'
import { BiXCircle,BiInfoCircle } from "react-icons/bi"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios"
import AddPatientProgramModal from "./AddPatientProgramModal";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientStatus from '../components/PatientStatus'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function RecentFinalized(props, { options }) {
    const classes = useStyles();
    
  const [selectedValue, setSelectedValue] = React.useState('patient');
  const [providerList, setProviderList] = useState([]);
  const [providerListOption, setProviderListOption] = useState([]); 
  const [selectedProvider, setSelectedProvider] = useState("");

  const [programList, setProgramList] = useState([]);
  const [programListOption, setProgramListOption] = useState([]); 
  const [selectedProgram, setSelectedProgram] = useState("");

  const [patientList, setPatientList] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [checked, setChecked] = React.useState(false);

  const [message, setMessage] = useState("");

  const [flag, setFlag] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);

  const location = useLocation();
    

   
      const  {userMaster}  = location.state ? location.state : "";
      console.log(userMaster)
     
     
    
  

 
    
   
   


  useEffect(() => {
    
    getPatientList();

    if(localStorage.getItem("tab")){
      localStorage.removeItem("tab");
      localStorage.removeItem("panel");
    }

  }, []);  

  useEffect(() => {
    if(providerList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<providerList.length;i++){
       let value = providerList[i].id;
       let label = providerList[i].first_name + " " + providerList[i].last_name;
       let ob = {
           "label":label, "value": value
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProviderListOption(ob_arr);
       setSelectedProvider(ob_arr[0]);
   }
   
 }, [providerList]);  

 const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const [open4, setOpen4] = React.useState(false);
  const [patientInfo, setPatientInfo] = React.useState("");

  const handleClickOpen4 = (data) => {

    setPatientInfo(data);
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  }; 

 useEffect(() => {
    if(programList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<programList.length;i++){
       let value = programList[i].id;
       let label = programList[i].program_abbrev;
       let ob = {
           "label":label, "value": value
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProgramListOption(ob_arr);
       setSelectedProgram(ob_arr[0]);
   }
   
 }, [programList]);  

  function openPatProgram(data) {

    console.log(data);

    props.history.push({
        pathname: "/patient-information",
        state: {
          patientInfo: data,
          providerList: providerListOption,
          programList: programListOption
        },
      });
  } 


  const getPatientList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

    setCurrentPage(parseInt(table_config_page_no));
        
    setPatientList([]);
    setMessage("Loading...");

    let jwt = JSON.parse(localStorage.getItem("JWT"));

    let data ={
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
        user_master_id: userMaster? userMaster : "",
      }

    apiAxios
        .post("/finalize/list-recent-finalized-patient", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setPatientList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
             
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
         

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });
  }
  );

  function backButton() {
      window.location.href = "/dashboard";
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
    return (
        <div>
             <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
 
             <Header />
             <Box className={classes.pagecontent}>
             <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                    <a href="/dashboard"> <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button> </a>
                 </Box>
                 <PatientStatus />
                 {/* left Accordion*/}
                 
                 </Box>
               </Box>
               <Box className={classes.Rightcol}>
                 {/* <Box className={classes.pageTop}>
                     <Button onClick={backButton}><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box> */}
                 <Box className={classes.Searchresult}>
                     
                    <Box>
                        <h3 style={{color :'#141621', textAlign : "left"}}>Recent Finalized</h3>      
                    </Box>   
                     <Box className={classes.throw}>
                         <Box className={classes.provider}>Provider</Box>  
                         <Box className={classes.firstname}>Patient Name</Box>
                         <Box className={classes.lastname}>Program Abbrev</Box>
                         <Box className={classes.dob}>Finalized Date</Box>
                         <Box className={classes.hrpmID}>Finalized Time</Box>
                     </Box>
                     {(patientList.length > 0) ?
                    <>
                    {patientList.map((each) => (
                     <Box className={classes.tdrow}>
                         <Box className={classes.provider}>{each.provider_name}</Box>  
                         <Box className={classes.firstname}>{each.patient_name}</Box>
                         <Box className={classes.lastname}>{each.program_abbrev}</Box>
                         <Box className={classes.dob}>{moment.utc(each.finalized_datetime).local().format("YYYY-MM-DD")}</Box>
                         <Box className={classes.hrpmID}>{moment.utc(each.finalized_datetime).local().format("HH:mm a")}</Box>
                     </Box>
                    ))}
                    </>
                     :
                     <Box style={{ marginTop:'30px',textAlign: 'center'}}>
                        {message}
                     </Box>
                     }
                        
                 </Box>
                
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getPatientList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 
                
             </Box>
             </Box>

             <AddPatientProgramModal
              isOpen={open4}
              onClose={handleClose4}
              providerList={providerListOption}
              programList={programListOption}
              patientDetails={patientInfo}
            ></AddPatientProgramModal>
        </div>
    )
}

export default RecentFinalized
const useStyles = makeStyles(() => ({
    pagecontent:{
        width:'100%',
        background: 'linear-gradient(to bottom, #0f3d6e05, #ffffff00)',
        // padding:'20px 3%',
        display:'flex',
        // flexDirection:'column',
        '& a':{
          textDecoration:'none'
        }
    },
    chartbtn:{
      background:'#0f3d6e',
      borderRadius:'10px',
      padding:'0 20px',
      height:'40px',
      color:'#fff',
      textTransform:'capitalize',
      fontSize:'14px',
      cursor:'pointer',
      '&:hover':{
          background:'#333'
      }
  },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#fff',
        paddingBottom:'5px',
        background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
        padding : '6px 10px',
        alignItems : "center"
       
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 10px',
        alignItems : "center"
       
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    provider:{
        width:'20%',
        textAlign:'left',
       
    },
    firstname:{
        width:'20%',
        textAlign:'left'
    },
    lastname:{
        width:'20%',
        textAlign:'left'
    },
    dob:{
        width:'20%',
        textAlign:'left'
    },
    emrID:{
        width:'5%',
        textAlign:'left'
    },
    hrpmID:{
        width:'20%',
        textAlign:'left',
        wordBreak: 'break-all'
    },
    phone:{
        width:'20%',
        textAlign:'left'
    },
    program:{
        width:'20%',
        textAlign:'center',
        cursor: 'pointer'
    },
    date:{
        width:'20%',
        textAlign:'left'
    },
    agentID:{
        width:'20%',
        textAlign:'left'
    },
    finaldate:{
        width:'20%',
        textAlign:'left'
    },
    plan:{
        width:'20%',
    },
    planbtn:{
        fontSize:'14px',
        color:'#fff',
        background: '#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px'
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        }
    },
    btncol:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:40,
   },

Topfilter:{
    width:'100%',
    display:'flex',
    marginTop:30,
    marginBottom:50,
    flexDirection:'row',
    '& label':{ color:'#666',marginBottom:5, display:'flex'},
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInput-input:focus':{
        border:'1px #0074D9 solid',
        boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 15px'
    },
    '& .MuiTextField-root':{
        width:'100%'
    }
},
Searchbtn:{
    background:'#0f3d6e',
    borderRadius:10,
    display:'flex',
    color:'#fff',
    height:50,
    paddingLeft:30,
    paddingRight:30,
    marginLeft:10,
    textTransform:'capitalize',
    marginTop:28,
    '&:hover':{
      background:'rgba(0,0,0,0.8)'
    }
  },
  Formcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'20px',
    '&>div':{
        width:'100%'
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 205px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400'
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    '& .react-dropdown-select-input':{
      display:'none'
    }
  },
  leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
    },
    Leftcol: {
      width: "15%",
      padding: "20px 3%",
      position: "relative",
      minHeight: "800px",
      background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
      '@media only screen and (max-width: 1200px)':{
        width: '24%',
        padding:'20px 1%',
              },
              '@media only screen and (max-width: 820px)':{
                width: '98%',
                padding:'20px 1%',
                position:'absolute',
                background:'none',
                minHeight:25,
              }, 
              '@media only screen and (max-width: 768px)':{
                width: '98%',
                padding:'20px 1%',
                position:'absolute',
                background:'none',
                minHeight:25,
              } 
    },
    Rightcol:{
      width:'75%',
      padding:'20px 2%',
      borderLeft:'1px #F6F6F6 solid',
      '@media only screen and (max-width: 820px)':{
        width:'96%',
        padding:'0 2%',
        marginTop:30,
              },
      '@media only screen and (max-width: 768px)':{
        width:'96%',
        padding:'0 2%',
        marginTop:30,
              }, 
      '& .MuiAccordionSummary-root':{
          borderBottom:'1px #5f80a9 solid',
          height:'40px',
          color:'#bfc4cb',
          padding:'0px',
          fontFamily:'Poppins',
      },
      '& .MuiAccordion-root:before':{
          display:'none'
      },
      '& .MuiTab-root':{
        minWidth:'33%',
      },
      '& .MuiTab-root:nth-child(6)':{
        minWidth:'30%',
      },
      '& .MuiTab-root:nth-child(7)':{
        minWidth:'30%',
      },
      // '& .MuiBox-root':{
      //     paddingLeft:'0px',
      //     paddingRight:'0px'
      // }
  },
  modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'550px',
        maxWidth:'550px'
    }
  },
  ccmmodal:{
    borderRadius:'10px',
      '& .MuiInput-underline:before':{
          display:'none'
      },
      '& .MuiInput-underline:after':{
          display:'none'
      },
      '& .MuiInput-formControl':{ 
          height:'50px',
          
      },
      '& .MuiInput-input:focus':{
          border:'1px #0074D9 solid',
          boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
      },
      '& .MuiInputBase-input':{
          height:'50px',
          borderRadius:'10px',
          border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
          padding:'0 15px'
      },
      '& .react-date-picker__wrapper':{
        borderRadius:10,
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 10px',
    },
  },
  modalbtn:{
    display:'flex',
    justifyContent:'space-between',
    marginRight:'30px',
    marginLeft:'15px',
    alignItems:'center'
  },
  
}));