import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box, Button, Link } from '@material-ui/core'
import {BiArrowBack} from "react-icons/bi"
import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Setting from '../components/Setting'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function PatientHistory() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    return (
        <div>
             <Header />
             <Box className={classes.pagecontent}>
                 <Box className={classes.pageTop}>
                     <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box>
                 <Box className={classes.Searchresult}>
                     <h4>Patients w/ 10mins or more engagement history to finalize</h4>
                     <Box className={classes.throw}>
                     <Box className={classes.provider}>
                     <Box className={classes.providerbtn} role="button"><span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> Provider</Box>
                         </Box>  
                         <Box className={classes.firstname}>First Name</Box>
                         <Box className={classes.lastname}>Last Name</Box>
                         <Box className={classes.dob}>DOB</Box>
                         <Box className={classes.emrID}>EMR ID</Box>
                         <Box className={classes.hrpmID}>HRPM ID</Box>
                         <Box className={classes.program}><Box className={classes.providerbtn} role="button"><span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> Program</Box></Box>
                         <Box className={classes.time}><Box className={classes.providerbtn} role="button"><span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> Time Elapsed</Box></Box>
                         <Box className={classes.teammember}><Box className={classes.providerbtn} role="button"><span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> Care Team Member</Box></Box>
                         
                     </Box>
                     <Box className={classes.tdrow}>
                     <Box className={classes.provider}>Caldwell</Box>  
                     <Box className={classes.firstname}>Mike</Box>
                         <Box className={classes.lastname}>Burroughs</Box>
                         <Box className={classes.dob}>5/27/1942</Box>
                         <Box className={classes.emrID}>9999</Box>
                         <Box className={classes.hrpmID}>37</Box>
                         <Box className={classes.program}><Link to="" onClick={handleClickOpen}><b>CCM</b></Link></Box>
                         <Box className={classes.time}>18:22 mins</Box>
                         <Box className={classes.teammember}>Kelsey Ackerman</Box>
                     </Box>
                     <Box className={classes.tdrow}>
                     <Box className={classes.provider}>Caldwell</Box>  
                     <Box className={classes.firstname}>Mike</Box>
                         <Box className={classes.lastname}>Burroughs</Box>
                         <Box className={classes.dob}>5/27/1942</Box>
                         <Box className={classes.emrID}>9999</Box>
                         <Box className={classes.hrpmID}>37</Box>
                         <Box className={classes.program}><Link to="" onClick={handleClickOpen}><b>CCM</b></Link></Box>
                         <Box className={classes.time}>18:22 mins</Box>
                         <Box className={classes.teammember}>Tina Walker</Box>
                     </Box>
                     <Box className={classes.tdrow}>
                     <Box className={classes.provider}>Caldwell</Box>  
                     <Box className={classes.firstname}>Mike</Box>
                         <Box className={classes.lastname}>Burroughs</Box>
                         <Box className={classes.dob}>5/27/1942</Box>
                         <Box className={classes.emrID}>9999</Box>
                         <Box className={classes.hrpmID}>37</Box>
                         <Box className={classes.program}><Link to="" onClick={handleClickOpen}><b>CCM</b></Link></Box>
                         <Box className={classes.time}>18:22 mins</Box>
                         <Box className={classes.teammember}>Kelsey Ackerman</Box>
                     </Box>
                     <Box className={classes.tdrow}>
                     <Box className={classes.provider}>Caldwell</Box>  
                     <Box className={classes.firstname}>Mike</Box>
                         <Box className={classes.lastname}>Burroughs</Box>
                         <Box className={classes.dob}>5/27/1942</Box>
                         <Box className={classes.emrID}>9999</Box>
                         <Box className={classes.hrpmID}>37</Box>
                         <Box className={classes.program}><Link to="" onClick={handleClickOpen}><b>CCM</b></Link></Box>
                         <Box className={classes.time}>18:22 mins</Box>
                         <Box className={classes.teammember}>Candace Gount</Box>
                     </Box>
                     <Box className={classes.btncol} style={{ marginTop:'30px'}}><Button className={classes.chartbtn} style={{width:'200px'}}>Load More</Button></Box>
                </Box>
                  {/* modal */}
                  <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <DialogContentText id="alert-dialog-slide-description">
            <BiErrorCircle className={classes.infoicon} />
            <h3>What would you like to do ?</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <Button onClick={handleClose} className={classes.viewbtn}>
          View Record
          </Button>
          <Button onClick={handleClose} className={classes.Engagebtn}>
          Engage With Patient
          </Button>
        </DialogActions>
      </Dialog>
      <Setting />
             </Box>
        </div>
    )
}

export default PatientHistory
const useStyles = makeStyles(() => ({
    pagecontent:{
        width:'94%',
        padding:'20px 3%',
        display:'flex',
        flexDirection:'column'
    },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#bfc4cb',
        paddingBottom:'5px'
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 0'
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    provider:{
        width:'15%',
        textAlign:'left'
    },
    firstname:{
        width:'10%'
    },
    lastname:{
        width:'10%'
    },
    dob:{
        width:'8%'
    },
    emrID:{
        width:'8%'
    },
    hrpmID:{
        width:'9%'
    },
    program:{
        width:'10%'
    },
    time:{
        width:'10%'
    },
    teammember:{
        width:'20%',
        display:'flex',
        justifyContent:'flex-end'
    },
   
    planbtn:{
        fontSize:'13px',
        color:'#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px',
        '&:hover':{
            background:'#333'
        }
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        }
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'40px'
   },
   chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 30px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    '&:hover':{
        background:'#333'
    }
},
viewbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    color:'#fff',
    textTransform:'capitalize',
    width:'220px',
    fontSize:'16px',
    cursor:'pointer',
    marginRight:'15px',
    '&:hover':{
        background:'#0f3d6e'
    }
},
Engagebtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    width:'220px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'16px',
    cursor:'pointer',
    '&:hover':{
        background:'#0f3d6e'
    }
},
modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'15px !important',
        padding:'40px'
    }
},
ccmmodal:{
    width:'500px',
    borderRadius:'30px',
    textAlign:'center',
    '& h3':{
        fontSize:'28px',
        color:'#0f3d6e',
        marginBottom:'0px',
        marginTop:'15px'
    }
},
modalbtn:{
    display:'flex',
    justifyContent:'center'
},
infoicon:{
    fontSize:'55px',
    color:'#0f3d6e'
}
}));