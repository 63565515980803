import { Box, Button, ListItem } from '@material-ui/core'
import React,{
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import linechart from '../images/Line-chart.jpg'
import barchart from '../images/Bar-chart.jpg'
import Grid from '@material-ui/core/Grid'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Header from '../components/Header'
import Setting from '../components/Setting'
import PatientStatus from '../components/PatientStatus'
import { Line} from "react-chartjs-2"
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import Select from "react-dropdown-select"
import ManageUser from '../components/ManageUser'
import ManageProvider from '../components/ManageProvider'
import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";



import loader  from  '../images/loader.gif';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
function Dashboard({ options }) {
    const classes = useStyles();
    //const [data, setData] = useState(genData());
    const [providerList, setProviderList] = useState([]);
    const [providerListOption, setProviderListOption] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState("");

    const [ccqList, setCCQList] = useState([]);
    const [ccqLoader, setCCQLoader] = useState([]);
    const [userMasterID, setUserMasterID] = useState("");

    const [finalizeList, setFinalizeList] = React.useState([]);
    const [finalizeLoader, setFinalizeLoader] = React.useState("");

    const [unfinalizedData, setUnfinalizedData] = React.useState("");
    const [monthNames, setMonthNames] = React.useState([]);
    const [unfinalizedCount, setUnfinalizedCount] = React.useState([]);

    const client_details = JSON.parse(localStorage.getItem("JWT"));

  useEffect(() => {
    getProviderList();
    getCCQList();
    getFinalizeList();
    getUnfinalizedData();

  }, []);  

  useEffect(() => {
    if(unfinalizedCount){
      genData();
      console.log(monthNames+ " | "+ unfinalizedCount );
    }
  }, [unfinalizedCount]);

  const genData = () => ({
    labels: monthNames,
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        borderWidth: 2,
        fill: false,
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#88b3eb',
      },
      {
        type: 'bar',
        label: 'Dataset 2',
        data: unfinalizedCount,
        backgroundColor: '#88b3eb',
        borderColor: 'white',
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                  backgroundColor: 'rgb(255,255,255,0)'
              }
            }],
            yAxes: [{
              gridLines: {
                  backgroundColor: 'rgb(255,255,255,0)'
              }
            }],
          }
      }
  
      },
      {
        type: 'bar',
        label: 'Dataset 3',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: '#0f3d6e',
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                  backgroundColor: 'rgb(255,255,255,0)'
              }
            }],
            yAxes: [{
              gridLines: {
                  backgroundColor: 'rgb(255,255,255,0)'
              }
            }],
          }
      }
  
      },
    ],
  });
  
  const data2 = {
      labels: ['1', '2', '3', '4', '5', '6'],
      datasets: [
        {
          label: '# No of patient',
          data: [0, 0, 0, 0, 0, 0],
          fill: false,
          backgroundColor: '#88b3eb',
          borderColor: '#88b3eb',
        },
      ],
    };
    
    const optionsCust = {
      
        scales: {
          xAxes: [{
            gridLines: {
                display:false
            }
          }],
          yAxes: [{
            gridLines: {
                display:false
            },
            ticks: {
              beginAtZero: true,
            },
          }],
        },
    };

  useEffect(() => {
    if(providerList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<providerList.length;i++){
       let value = providerList[i].user_master_id;
       let label = providerList[i].first_name + " " + providerList[i].last_name;
       let ob = {
           "label":label, "value": value
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProviderListOption(ob_arr);
       setSelectedProvider("");
   }
   
 }, [providerList]);  

  function changeProvider() {
    if(selectedProvider){
      getCCQList();
      getFinalizeList();
      getUnfinalizedData();
    }
  }

  function getProviderList() {


    let data ={
      table_config_rows_per_page: '25',
      table_config_page_no: '1',
      search_value: "",
      cutid: "",
      user_master_type_id: "",
      // client_id: client_details? client_details.client_id : "",
      is_provider: true
    }

    apiAxios
        .post("/client/clientuserlist", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setProviderList(response.data.data); 
              
              
          }
          else {
             

                  console.log("PL =",providerList);
          }
        })
        .catch((error) => {
          console.log(error);
  
        

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // // window.location.href = "/";        

  
        });
  }

  function getCCQList() {

    setCCQLoader("Loading...");

    console.log(selectedProvider);

    let data ={
      table_config_rows_per_page: '5',
      table_config_page_no: '1',
      user_master_id: selectedProvider? selectedProvider[0].value : '',
      // client_id: client_details? client_details.client_id : "",
    }

    apiAxios
        .post("client/ccq-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response ccq --> ",response);
          if(response.data.statusCode == 200) {
              setCCQList(response.data.data); 
              let res = response.data.data

              if(res.length ===1){
              console.log(res[0].user_master_id)
              let userMaster = res[0].user_master_id
              setUserMasterID(userMaster)
              }else {
                setUserMasterID("")
              }

             
              
                
              
              // setUserMasterID(response.data.data.user_master_id)
              setCCQLoader("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
                  setCCQList([]); 
                  setCCQLoader("No Data Found");   
                  console.log("ccq")
                  console.log(ccqList.user_master_id) 
          }
        })
        .catch((error) => {
          console.log(error);
  
       

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }

  function getFinalizeList() {

    setFinalizeLoader("Loading...");

    let data ={
      table_config_rows_per_page: '5',
      table_config_page_no: '1',
      user_master_id: selectedProvider? selectedProvider[0].value : '',
      // client_id: client_details? client_details.client_id : "",
    }

    apiAxios
        .post("/finalize/list-recent-finalized-patient", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setFinalizeList(response.data.data); 
              setFinalizeLoader("");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
                  setFinalizeLoader("No Data Found");    
          }
        })
        .catch((error) => {
          console.log(error);
  
        

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }

  function getUnfinalizedData() {

    //setFinalizeLoader("Loading...");

    let data ={
      table_config_rows_per_page: '8',
      table_config_page_no: '1',
      user_master_id: selectedProvider? selectedProvider[0].value : '',
      // client_id: client_details? client_details.client_id : "",
    }

    apiAxios
        .post("/finalize/list-dashboard-finalization", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setUnfinalizedData(response.data.data); 
              let temp = response.data.data;
              if(temp.length > 0){
                let co =[], month= [];
                for(var i=0;i< temp.length;i++){
                  co[i] = Math.floor(temp[i].count_unfinalized);
                  month[i] = temp[i].month_name;
                }

                setUnfinalizedCount(co);
                setMonthNames(month);
                
              }

          }
          else {
             
          
          }
        })
        .catch((error) => {
          console.log(error);
  
         

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }



    return (
        <div>
            
            { (localStorage.getItem("JWT")) ?    
        <Header />
        :
        <></>
        }
                <Box className={classes.pagecontent}>
                <Box className={classes.leftcol}>
                    <Box className={classes.leftnav}>
                      { (localStorage.getItem("JWT")) ?
                      <PatientStatus />
                      :
                      <></>
                      }
                        {/* <ManageProvider />
                        <ManageUser /> */}
                       <Box className={classes.bottomnav}>
                       <Setting />
                       </Box>
                       </Box>
                    </Box>
                    <Box className={classes.DashboardContent}>
                    <Box className={classes.graphcol}>
<form>
<Box className={classes.Formcol}>
    <label>Providers</label>
                         {(providerList.length > 0)?
                         <>
                          <Select options={providerListOption} value={selectedProvider} onChange={setSelectedProvider} placeholder={selectedProvider? selectedProvider.label : "Select Provider"} className={classes.select} />
                          {selectedProvider ?
                          <Button className={classes.chartbtn} style={{padding:'6px 20px', margin:'0 10px', fontSize:'14px',height:45,}} onClick={changeProvider}>Apply Filter</Button>
                          :
                          <></>
                          }
                         </> 
                          :
                          <div className={classes.noProvider}>User don't have any provider</div>  
                         }
                        </Box>
</form>
                        <Box className={classes.barchart}>
                        <Box className={classes.charttop}>
                            <h4>Patients w/ 10mins or more with no finalize</h4>
                            {/* <ul>
                                <li><Link to="">D</Link></li>
                                <li><Link to="">W</Link></li>
                                <li><Link to="" className={classes.active}>M</Link></li>
                                <li><Link to="">Y</Link></li>
                            </ul> */}
                        </Box>
                        { unfinalizedCount ?
                        <Bar data={genData()} options={optionsCust} style={{ cursor : "pointer"}} />
                        :
                        <>No Data Found </>
                        }
                            <Box className={classes.btncol}><Link to="/patient-list"><Button className={classes.chartbtn}>Go To Patient List</Button></Link></Box>
                            </Box>

                        {/* <Box className={classes.linechart}>
                        <Box className={classes.linecharttop}>
                            <h4>Patient Engagement Shortcut</h4>
                            
                        </Box>
                        <Line data={data2} />
                            <Box className={classes.btncol}><Link to="/patient-assignment"><Button className={classes.chartbtn}>Go To Patient Assignment</Button></Link></Box>
                            </Box> */}
                    </Box>
                    <Box className={classes.rightcol}>
                        <Box className={classes.patienttable}>
                            <h4>Most Recent Finalized Patients</h4>
                            <Box className={classes.throw}>
                               <Box className={classes.name}>Patient</Box>
                               <Box className={classes.type}>Type</Box>
                               <Box className={classes.date}>Date</Box>
                               <Box className={classes.provider}>Provider</Box>
                            </Box>

                            { (finalizeList.length > 0) ?
                            <>
                            { finalizeList.map((each) => (
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>{each.patient_name}</Box>
                               <Box className={classes.type} style={{width:'25%'}}>{each.program_abbrev}</Box>
                               <Box className={classes.date}>{moment.utc(each.finalized_datetime).local().format("YY-MM-DD HH:mm a")}</Box>
                               <Box className={classes.provider} style={{textAlign: 'right'}}>{each.provider_name}</Box>
                            </Box>
                            ))}
                            </>
                            :
                            <Box style={{textAlign: 'center'}}>
                                { (finalizeLoader == "Loading...") ? <img src={loader} alt="logo" style={{width:'40px'}} />  : "No Data Found" }
                            </Box>
                            }

                            <Box className={classes.btncol} style={{ marginTop:'15px'}}><Link to={{pathname: "/recent-finalized", state: { userMaster : userMasterID}, }}><Button className={classes.chartbtn} style={{width:'200px', padding:'10px'}}>Go To Finalize Preview</Button></Link></Box>
                        </Box>
                        <Box className={classes.patienttable}>
                            <h4>CCQ Preview</h4>
                            <Box className={classes.throw}>
                               <Box className={classes.name}>Patient</Box>
                               <Box className={classes.type}>CCQ</Box>
                               <Box className={classes.date}>Date</Box>
                               <Box className={classes.provider}>Provider</Box>
                            </Box>
                            { (ccqList.length > 0) ?
                            <>
                            {ccqList.map((each) => (
                            <Box className={classes.tdrow}>
                               <Box className={classes.name}>{each.patient_first_name} {each.patient_last_name}</Box>
                               <Box className={classes.type}>{parseInt(each.ccq_goal_counter)+ parseInt(each.ccq_hosp_counter) + parseInt(each.ccq_med_counter) + parseInt(each.ccq_symp_counter)}</Box>
                               <Box className={classes.date}>{each.ccq_enrollment_date? moment.utc(each.ccq_enrollment_date).local().format("YYYY-MM-DD") : "--"}</Box>
                               <Box className={classes.provider}>{each.provider_first_name}</Box>
                            </Box>
                            ))}
                            </>
                            :
                            <Box style={{textAlign: 'center'}}>
                                { (ccqLoader == "Loading...") ? <img src={loader} alt="logo" style={{width:'40px'}} />  : "No Data Found" }
                            </Box>
                            }

                            

                            <Box className={classes.btncol} style={{ marginTop:'15px'}}><Link to="/ccq"><Button className={classes.chartbtn} style={{width:'200px'}}>Go To CCQ</Button></Link></Box>
                        </Box>
                    </Box>
                    </Box>
            </Box>
        </div>
    )
}

export default Dashboard
const useStyles = makeStyles(() => ({
  pagecontent:{
      width:'100%',
      overflow:'hidden',
      display:'flex',
      '& a':{
        textDecoration:'none'
      }
  },
  DashboardContent:{
    display:'flex',
    width:'80%',
    justifyContent:'space-between',
    '@media only screen and (max-width: 820px)':{
      width:'100%',
      flexDirection:'column',
            },
    '@media only screen and (max-width: 768px)':{
      width:'100%',
      flexDirection:'column',
            },
  },
  noProvider:{
    color: '#c9c9c9',
    border: '2px solid #f1f1f1',
    borderRadius: '10px',
    padding: '4px 6px'
  },
  leftcol:{
      width:'12%',
      padding:'30px 4%',
      background: 'linear-gradient(-45deg, #114ebd, #0f3d6e)',
      position:'relative',
      '@media only screen and (max-width: 820px)':{
        position:'absolute',
        background:'none',
        width:'96%',
        padding:'30px 2%',
              },
              '@media only screen and (max-width: 768px)':{
                position:'absolute',
                background:'none',
                width:'96%',
                padding:'30px 2%',
                      },
      '& ul':{
          listStyle:'none',
          margin:'0px',
          padding:'0px',
          '& li':{
              color:'#1C295D',
              fontSize:'12px',
              fontWeight:'500'
          },
          '& li button':{
              background:'#f1f1f1',
              width:'150px',
              height:'55px',
              margin: '8px 0',
              textAlign:'center',
              color:'#0f3d6e',
              borderRadius:'10px',
              fontSize:'18px',
              '&:hover':{
                  background:'#0f3d6e'
              }
          }
      }
  },
 
  graphcol:{
    width:'57%',
    padding:'30px 3%',
    borderLeft:'1px #F6F6F6 solid',
    '@media only screen and (max-width: 1200px)':{
      width:'50%',
            },
            '@media only screen and (max-width: 820px)':{
              width:'94%',
                    },
    '@media only screen and (max-width: 768px)':{
      width:'94%',
            },
    '& h4':{
        fontSize:'18px',
        color:'#3D4857',
        fontWeight:'500'
    }
  },
  rightcol:{
    width: '33%',
    //background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
    padding:'20px 2%',
    background:'linear-gradient(to left, #dddddd45, #fff);',
    
    '@media only screen and (max-width: 1200px)':{
      width: '42%',
      padding:'20px 2% 20px 0',
            },
            '@media only screen and (max-width: 820px)':{
              width: '94%',
              padding:'20px 3% 20px 3%',
                    },
  },
  barchart:{
      marginBottom:'30px',
      '& img':{
          width:'100%'
      }
  },
  linechart:{
    '& img':{
        width:'100%'
    }
},
charttop:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        display:'flex',
        '& li':{
            color:'#0f3d6e',
            width:'30px',
            height:'30px',
            textAlign:'center',
            '& a':{
                color:'#0f3d6e',
                cursor:'pointer',
                '&:hover':{
                    color:'#000',
                    textDecoration:'none'
                }
            }
        }
    }
},
active:{
    background:'#eefaf1',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'3px',
    color:'#000 !important'
},
btncol:{
     display:'flex',
     justifyContent:'flex-end'
},
chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 30px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    marginTop:10,
    '&:hover':{
        background:'#333'
    }
},
linecharttop:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        display:'flex',
        '& li':{
            color:'#0f3d6e',
            height:'30px',
            textAlign:'left',
            marginLeft:'15px',
            '& a':{
                color:'#0f3d6e',
                cursor:'pointer',
                padding:'0 5px',
                '&:hover':{
                    color:'#0f3d6e',
                    textDecoration:'none',
                    borderBottom:'2px #0f3d6e solid'
                }
            }
        }
    }
},
activeyear:{
    borderBottom:'2px #0f3d6e solid'
},
throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px #E3E5E5 solid',
    fontSize:'14px',
    color:'#fff',
    padding:'2px 8px',
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',

},
tdrow:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px #E3E5E5 solid',
    fontSize:'14px',
    padding:'2px 4px',
    color: '#000'
},
name:{
    width:'30%',
    textAlign:'left',
    wordWrap:"break-word",
    overflow: "auto"
},
type:{
    width:'10%'
},
date:{
    width:'30%'
},
provider:{
  width:'30%',
  wordWrap:"break-word",
    overflow: "auto"
},
patienttable:{
marginBottom:'50px',
'& h4':{
    fontSize:'16px',
    textAlign:'left',
    color:'#0f3d6e',
    marginTop:'0px'
}
},
bottomnav:{
    position:'absolute',
    bottom:'0px',
    left:'0px'
},
leftnav:{
position:'absolute',
top:'15px',
bottom:'15px',
left:'40px',
'@media only screen and (max-width: 820px)':{
  left:'25px',
              },
'@media only screen and (max-width: 768px)':{
  left:'25px',
              },
},
select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    background:'#F1F1F1',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    textAlign:'left',
    minWidth:350,
    '@media only screen and (max-width: 1200px)':{
      minWidth:300,
                  },
                  '@media only screen and (max-width: 820px)':{
                    minWidth:350,
                                },
                  '@media only screen and (max-width: 768px)':{
                    minWidth:350,
                                },
    // '& .react-dropdown-select-input':{
    //   display:'none',
    // }
},
Formcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'30px',
    '@media only screen and (max-width: 1200px)':{
flexWrap:'wrap',
            },
            '@media only screen and (max-width: 820px)':{
              flexWrap:'nowrap',
                          },
            '@media only screen and (max-width: 768px)':{
              flexWrap:'nowrap',
                          },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 110px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400',
        textAlign:'left',
        '@media only screen and (max-width: 1200px)':{
          flex:'0 0 100%',
                      },
                      '@media only screen and (max-width: 820px)':{
                        marginTop:20,
                                    },
                      '@media only screen and (max-width: 768px)':{
                        flex:'0 0 110px',
                        marginTop:20,
                                    },
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
},
  }));