import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import calendericon from "../images/calendar-icon.svg";
import {
  BiArrowBack,
  BiClipboard,
  BiInfoCircle,
  BiXCircle,
} from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPhoneCall } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-dropdown-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Pagination } from "@material-ui/lab";
import Slide from "@material-ui/core/Slide";
import Header from "../components/Header";
import LeftNav from "../components/LeftNav";
import Patientinfo from "../components/Patientinfo";
import PatientLeftInfo from "../components/PatientLeftInfo";
import Setting from "../components/Setting";
import Timer from "../components/Timer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import AddPatientProgramModal from "./AddPatientProgramModal";
import "react-datepicker/dist/react-datepicker.css";
import { apiAxios } from "../Util/ApiAxios";

import moment from "moment";

import loader  from  '../images/loader.gif';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
];
function PatientInformation(props) {
  const classes = useStyles();
  //  modal  //
  const [open, setOpen] = React.useState(false);
  const [patientDetails, setPatientDetails] = React.useState("");

  console.log(props.history.location.state)

  useEffect(() => {
    if (props.history.location.state) {
      setPatientDetails(props.history.location.state.patientInfo);
      // getHealthcareCounts();
      getProviderProgram();
      getRecentEngagement();
      getCaregivers();

      localStorage.removeItem("engagement_timer");
      localStorage.removeItem("start_timer");
      
    }
  }, [props.history.location.state]);

  const [engageFlag, setEngageFlag] = React.useState(2);

  const handleClickOpen = (flag) => {

    setEngageFlag(flag);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);

  const [engageDetails, setEngageDetails] = React.useState([]);

  const handleClickOpen2 = (each) => {
    
    setEngageDetails(each);
    setOpen2(true);


    
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  //   ******   //
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = () => {
    if(engageFlag == 0){
      continuePatientReview();
    }
    else{
      setOpen3(true);
    }
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  //   ******   //
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {

    setOpen4(false);
   
  };
  const [value, setValue] = React.useState("patient");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [startDate, setStartDate] = useState({
    date1: new Date(),
    date2: new Date(),
    date3: new Date(),
    date4: new Date(),
  });
  // const [startDate, setStartDate] = useState(new Date());
  // const [startDate2, setStartDate2] = useState(new Date());
  // const [startDate3, setStartDate3] = useState(new Date());
  // const [startDate4, setStartDate4] = useState(new Date());
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });


  const [selectedCaregiver, setSelectedCaregiver] = React.useState("");

  const [caregiverError, setCaregiverError] = React.useState(false);

  function continuePatientReview() {

    console.log("-------------")
    console.log(selectedCaregiver)

    let isValid = true;
    let speaking_with_id, user_speaking_id, speaking_with_types;

    let pat_details = JSON.parse(localStorage.getItem("patient"));
    let jwtData = JSON.parse(localStorage.getItem("JWT"))
    // pat_details.program_abbrev = engageDetails.program_abbrev;
    // pat_details.program_name = engageDetails.program_name;
    // pat_details.cpatp_id = engageDetails.patient_program_id;

    if(selected == "patient") {
      
      speaking_with_id = pat_details.pd_id;
      speaking_with_types = "0";

    }else{


      if(selectedCaregiver){
      
        
      speaking_with_id = selectedCaregiver ? selectedCaregiver[0].value : selectedCaregiver.value;
      speaking_with_types = "1";
      setCaregiverError(false);
      
       }
       else {
        isValid = false;
        setCaregiverError(true);
      }




    }

   
    

    if(isValid){

      let data;

      if(engageFlag == 1){
        data = {
          speaking_with_id: speaking_with_id ,
          pd_id: pat_details.pd_id,
          patient_program_id: engageDetails.patient_program_id,
          user_speaking_id: localStorage.getItem("User ID") ? localStorage.getItem("User ID"): jwtData.user_uuid,
          engagement_type: engageFlag,  //1 emgagement, 0 view
          speaking_with_types: speaking_with_types
        }
      }
      else{
        data = {
          speaking_with_id: "",
          pd_id: pat_details.pd_id,
          patient_program_id: engageDetails.patient_program_id,
          user_speaking_id: localStorage.getItem("User ID") ? localStorage.getItem("User ID"): jwtData.user_uuid,
          engagement_type: engageFlag,  //1 emgagement, 0 view
          speaking_with_types: ""
        }
      }

      let url = "/patient/create-user-speaking-with-patient";
 
      apiAxios
        .post(url, data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {

            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

            localStorage.setItem("engagement_timer", JSON.stringify(engageDetails));  
            localStorage.setItem("start_timer", new Date());
            localStorage.setItem("engagement_ID", response.data.id);  
            // localStorage.setItem("engagement_parent_ID", response.data.parent_id);  
            //localStorage.setItem("patient", JSON.stringify(pat_details));
            let parentID = response.data.parent_id
            localStorage.setItem("engagement_parent_ID", JSON.stringify(parentID)); 
          
            
            props.history.push({
              pathname: "/patient-review",
              state: {
                patientInfo: props.history.location.state.patientInfo,
              },
            });
              
          }
          else if(response.data.statusCode == 502){
              //setMessage("No Data Found");
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              
                  //setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });



    }

  }

  const [healthcareResult, setHealthcareResult] = React.useState("");
  const [recentList, setRecentList] = React.useState([]);
  const [providerProgramList, setProviderProgramList] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [caregiverList, setCaregiverList] = React.useState([]);
  const [caregiverListOption, setCaregiverListOption] = React.useState([]);

  // const [selectedCaregiver, setSelectedCaregiver] = React.useState("");

  const [currentPage1, setCurrentPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  const currentPageRef1 = useRef(1);

  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  const currentPageRef2 = useRef(1);

  // function getHealthcareCounts() {
  //   let data = {
  //     pd_id: props.history.location.state.patientInfo.pd_id,
  //   };

  //   apiAxios
  //     .post("/patient/patient-count-health-cares", data, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem("Token"),
  //       },
  //     })
  //     .then((response) => {
  //       console.log("response --> ", response);
  //       if (response.data.status == 200) {
  //         setHealthcareResult(response.data.result);
  //         console.log("HC = ", healthcareResult);
  //       } else {
  //         toast.error(response.data.message, {
  //           position: "bottom-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);

  //       toast.error(error.message, {
  //         position: "bottom-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });

  //       localStorage.removeItem("Refresh_Token");
  //       localStorage.removeItem("Token");
  //       window.location.href = "/";
  //     });
  // }

  useEffect(() => {
    
    if(caregiverList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<caregiverList.length;i++){
       let value = caregiverList[i].id;
       let label = caregiverList[i].patient_caregiver_contacts_first_name + " " + caregiverList[i].patient_caregiver_contacts_last_name;
       let ob = {
           "label":label, "value": value
       }
       console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setCaregiverListOption(ob_arr);
      //  setSelectedCaregiver(ob_arr[0]);
       console.log("Caregivers - ", caregiverListOption);
   }
   
 }, [caregiverList]);

  function getCaregivers() {
    let data = {
      pd_id: props.history.location.state.patientInfo.pd_id,
      table_config_rows_per_page: "20",
      table_config_page_no: "1"
    };

    apiAxios
      .post("/patient/list-patient-caregiver-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          var temp = response.data.data;
          setCaregiverList(temp);
        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        localStorage.removeItem("Refresh_Token");
        localStorage.removeItem("Token");
        window.location.href = "/";
      });
  }

  const [selected, setSelected] = React.useState("patient");

  const handleChangeSelected = (event) => {

    //console.log("Selected = ", selected);
    setSelected(event.target.value);
   
  };

  const getRecentEngagement = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage1(parseInt(table_config_page_no));

      setRecentList([]);
      setMessage("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        speaking_with_id: props.history.location.state.patientInfo.pd_id,
        table_config_rows_per_page: table_config_rows_per_page,
      };

      apiAxios
        .post("/patient/list-user-speaking-with-patient", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setRecentList(response.data.result);
            setMessage("");
            setTotalPages1(response.data.total_pages);
          } else if (response.data.statusCode == 502) {
            setMessage("No Data Found");
          } else {
            
            setMessage("No Data Found");
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          //localStorage.removeItem("Refresh_Token");
          //localStorage.removeItem("Token");
          //window.location.href = "/";
        });
    }
  );

  const getProviderProgram = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage2(parseInt(table_config_page_no));

      setProviderProgramList([]);
      setMessage2("Loading...");

      //let patient_details = JSON.parse(localStorage.getItem("patient"));

      let data = {
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
        patient_id: props.history.location.state.patientInfo.pd_id,
      };

      apiAxios
        .post("/finalize/timer-list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setProviderProgramList(response.data.data);
            setMessage2("");
            setTotalPages2(response.data.total_pages);
          } else if (response.data.statusCode == 502) {
            setMessage2("No Data Found");
          } else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            setMessage2(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          //localStorage.removeItem("Refresh_Token");
          //localStorage.removeItem("Token");
          //window.location.href = "/";
        });
    }
  );

  const handleChange6 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Box className={classes.Pagecontent}>
        {/* left col */}
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            <PatientLeftInfo patDetails={patientDetails} />
            {/* left Accordion*/}
            {/* <Box className={classes.CcqList}>
              <Box className={classes.CcqRow}>
                <Typography className={classes.CcqText}>
                  Active Goals
                </Typography>
                {healthcareResult.active_goals >= 0 ?
                <Button className={[classes.Ccqbtn, classes.Btntext].join(" ")}>
                  {healthcareResult.active_goals}
                </Button>
                :
                <img src={loader} alt="logo" style={{width:'40px'}} />
                }
              </Box>
              <Box className={classes.CcqRow}>
                <Typography className={classes.CcqText}>
                  Active Symptoms
                </Typography>
                {healthcareResult.active_symptoms >= 0 ?
                <Button className={[classes.Ccqbtn, classes.Btntext].join(" ")}>
                  {healthcareResult.active_symptoms}
                </Button>
                :
                <img src={loader} alt="logo" style={{width:'40px'}} />
                }
              </Box>
              <Box className={classes.CcqRow}>
                <Typography className={classes.CcqText}>
                  Active Medications
                </Typography>
                {healthcareResult.active_medications >= 0 ?
                <Button className={[classes.Ccqbtn, classes.Btntext].join(" ")}>
                  {healthcareResult.active_medications}
                </Button>
                :
                <img src={loader} alt="logo" style={{width:'40px'}} />
                }
              </Box>
              <Box className={classes.CcqRow}>
                <Typography className={classes.CcqText}>
                  Active Medications CCM Provider
                </Typography>
                {healthcareResult.active_medications_ccm_provider >= 0 ?
                <Button className={[classes.Ccqbtn, classes.Btntext].join(" ")}>
                  {healthcareResult.active_medications_ccm_provider}
                </Button>
                :
                <img src={loader} alt="logo" style={{width:'40px'}} />
                }
              </Box>
           
            </Box> */}

            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Box className={classes.PatientInfo}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "poppins",
                      fontWeight: "600",
                      margin: "0 0 20px",
                    }}
                  >
                    Recent Engagements
                  </Typography>
                </Box>

                <Box className={classes.Throw}>
                  <Box className={[classes.Thcol, classes.Thtext].join(" ")}>
                    Date
                  </Box>
                  <Box className={[classes.Thcol, classes.Thtext].join(" ")}>
                    Time
                  </Box>
                  <Box className={[classes.Thcol5, classes.Thtext].join(" ")}>
                    Program
                  </Box>
                  <Box className={[classes.Thcol3, classes.Thtext].join(" ")}>
                    Elasped Time
                  </Box>
                  <Box className={[classes.Thcol4, classes.Thtext].join(" ")}>
                    User
                  </Box>
                  <Box className={[classes.Thcol2, classes.Thtext].join(" ")}>
                    Status
                  </Box>
                  {/* <Box className={[classes.Thcol6, classes.Thtext].join(" ")}>
                    Action
                  </Box> */}
                </Box>
                  {recentList.length > 0 ? 
                  <>
                    {recentList.map((each) => (
                    <Box className={classes.Tdrow}>
                      <Box
                        className={[classes.Tdcol, classes.Tdtext].join(" ")}
                      >
                        {moment.utc(each.date).local().format("YYYY-MM-DD")}
                      </Box>
                      <Box
                        className={[classes.Tdcol, classes.Tdtext].join(" ")}
                      >
                        {moment.utc(each.date).local().format("HH:mm a")}
                      </Box>
                      <Box
                        className={[classes.Tdcol5, classes.Tdtext].join(" ")}
                      >
                        {each.program_fullname}
                      </Box>
                      <Box
                        className={[classes.Tdcol3, classes.Tdtext].join(" ")}
                      >
                        {each.elapsed_time}
                      </Box>
                      <Box
                        className={[classes.Tdcol4, classes.Tdtext].join(" ")}
                      >
                        {each.user}
                      </Box>
                      <Box
                        className={[classes.Tdcol2, classes.Tdtext].join(" ")}
                      >
                        {each.finalize_flag_status == 0 ? "UnFinalized" : "Finalized" }
                      </Box>
                      {/* <Box
                        className={[classes.Tdcol6, classes.Tdtext].join(" ")}
                      >
                        {each.finalize_flag_status == 0 ?
                        <Box>
                          NA
                        </Box>
                        :
                        <Button className={classes.Downloadbtn}>
                          Download PDF
                        </Button>
                        }
                      </Box> */}
                    </Box>
                    ))}

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "right",
                          justifyContent: "right",
                          marginTop:10,
                        }}
                      >
                        <Pagination
                          count={totalPages1}
                          color="primary"
                          page={currentPage1}
                          onChange={(event, pageNumber) =>
                            getRecentEngagement({
                              table_config_page_no: `${pageNumber}`,
                            })
                          }
                        />
                      </div>
                  </>    
                  : 
                  <Box style={{textAlign: 'center', margin: '4%'}}>{message}</Box>
                  }          
                

                <Box className={classes.ProviderCol}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "30px 0",
                    }}
                  >
                    <Typography>Patient Programs</Typography>
                    <Button
                      className={classes.Viewbtn}
                      onClick={handleClickOpen4}
                    >
                      Add Program
                    </Button>
                  </Box>
                  <Box className={classes.Throw}>
                    <Box
                      className={[classes.Thcol7, classes.Thtext].join(" ")}
                    ></Box>
                    <Box className={[classes.Thcol8, classes.Thtext].join(" ")}>
                      Program
                    </Box>
                    <Box className={[classes.Thcol9, classes.Thtext].join(" ")}>
                      Provider Name
                    </Box>
                    <Box className={[classes.Thcol9, classes.Thtext].join(" ")}>
                      Agent Name
                    </Box>
                    <Box
                      className={[classes.Thcol10, classes.Thtext].join(" ")}
                    >
                      Elasped Time
                    </Box>
                    <Box
                      className={[classes.Thcol11, classes.Thtext].join(" ")}
                    >
                      Action
                    </Box>
                  </Box>
                  {providerProgramList.length > 0 ? (
                    <>
                      {providerProgramList.map((each) => (
                        <Box className={classes.Tdrow}>
                          <Box
                            className={[classes.Tdcol7, classes.Tdtext].join(
                              " "
                            )}
                          >
                            <FiPhoneCall size="20" />
                          </Box>
                          <Box
                            className={[classes.Tdcol8, classes.Tdtext].join(
                              " "
                            )}
                          >
                            {each.program_name}
                          </Box>
                          <Box
                            className={[classes.Tdcol9, classes.Tdtext].join(
                              " "
                            )}
                          >
                            {each.provider_name}
                          </Box>
                          <Box
                            className={[classes.Tdcol9, classes.Tdtext].join(
                              " "
                            )}
                          >
                            {each.agent_id}
                          </Box>
                          <Box
                            className={[classes.Tdcol10, classes.Tdtext].join(
                              " "
                            )}
                          >
                            {each.time}
                          </Box>
                          <Box
                            className={[classes.Tdcol11, classes.Tdtext].join(
                              " "
                            )}
                          >
                            <Button
                              className={classes.Addbtn}
                              onClick={() => handleClickOpen2(each)}
                            >
                              Open
                            </Button>
                          </Box>
                        </Box>
                      ))}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "right",
                          justifyContent: "right",
                          marginTop:10,
                        }}
                      >
                        <Pagination
                          count={totalPages2}
                          color="primary"
                          page={currentPage2}
                          onChange={(event, pageNumber) =>
                            getProviderProgram({
                              table_config_page_no: `${pageNumber}`,
                            })
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <Box style={{textAlign: 'center', margin: '4%'}}>{message2}</Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose2} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                What would you like to do?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                
                <Button size="large" className={classes.loginbtn} onClick={() => handleClickOpen(0)}>
                  View Record
                </Button>
                
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={() => handleClickOpen(1)}
                >
                  Engage with patient
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} size="30" />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <BiInfoCircle size="40" color="#88b3eb" />
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 30,
                  color: "#333",
                }}
              >
                Do you want to continue
                <br /> with the status: <span style={{color: '#88b3eb'}}>{engageDetails.program_abbrev}</span>?
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button size="large" onClick={handleClose} className={classes.loginbtn}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={handleClickOpen3}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose3}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Button onClick={handleClose3} className={classes.closebtn}>
            <BiXCircle className={classes.closeicon} size="30" />
          </Button>
          <Typography
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 30,
              marginTop: 10,
            }}
          >
            Who are you speaking with ?
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                style={{ display: "flex", alignItems: "center" }}
                value={selected}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <FormControlLabel
                    value="patient"
                    control={<Radio color="primary" selected={selected} onChange={handleChangeSelected} />}
                    label="Patient"
                    style={{ width: 230, display: "flex", fontSize: 14 }}
                  />
                  <Typography style={{ width: "70%", textAlign: "left", marginLeft: "15px" }}>
                    {patientDetails.patient_first_name+" "+patientDetails.patient_last_name}
                  </Typography>
                </Box>
                <Box style={{ display:'flex', alignItems: "center" }}>
                  <FormControlLabel
                    value="caregiver"
                    control={<Radio selected={selected} onChange={handleChangeSelected} color="primary" />}
                    label="Select Caregiver"
                    style={{ fontSize: 14 }}
                  />
                  {console.log(caregiverListOption)}
                  <Select
                    options={caregiverListOption}
                    onChange={setSelectedCaregiver}
                    placeholder="Select caregiver"
                    className={classes.datepicker}
                    style={{width:'230px'}}
                   
                  />
                 

                </Box>
              
              </RadioGroup>

              {caregiverError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                   Please select a caregiver if available or go with patient
                    </p>
                  ) : (
                    <></>
                  )}

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 30,
                }}
              ></Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  className={classes.loginbtn}
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  size="large"
                  className={classes.chartbtn}
                  onClick={continuePatientReview}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* modal */}
      {props.history.location.state?
      <AddPatientProgramModal
        isOpen={open4}
        onClose={handleClose4}
        providerList={props.history.location.state.providerList}
        programList={props.history.location.state.programList}
        patientDetails={props.history.location.state.patientInfo}
      ></AddPatientProgramModal>
      :
      window.location.href = "/patient-search"
}
    </div>
  );
}

export default PatientInformation;

const useStyles = makeStyles(() => ({
  Throw: {
    display: "flex",
    borderBottom: "1px rgba(0,0,0,0.2) solid",
    padding: 6,
    alignItems: "center",
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)'
  },
  calendaricon: {
    position: "absolute",
    right: "15px",
    zIndex: "1",
    top: "12px",
    minWidth: "15px",
    height: "20px",
    width: "15px",
  },
  Enddate: {
    position: "relative",
    marginRight: "0",
    marginLeft: "0",
    width: "100%",
  },
  Ccqbtn: {
    background: "#fff",
    borderRadius: 5,
    color: "#0f3d6e",
    fontFamily: "poppins",
    fontSize: 16,
    padding: 0,
    border: "1px #5f80a9 solid",
    width: 75,
    marginLeft: 20,
    fontWeight: "600",
    "&:hover": {
      background: "#fff",
      color: "#000",
    },
  },
  CcqText: {
    fontFamily: "poppins",
    fontSize: 15,
    color: "#ffffff",
    width: "70%",
  },
  Downloadbtn: {
    fontFamily: "poppins",
    fontSize: 12,
    textTransform: "capitalize",
    color: "#0f3d6e",
    padding: 0,
    "&:hover": {
      background: "none",
      color: "#000",
    },
  },
  CcqRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    borderBottom: "1px #5f80a9 solid",
    paddingBottom: 15,
  },
  loginbtn: {
    background: "#fff",
    padding: "0 20px",
    height: "40px",
    borderRadius: "10px",
    border: "1px #88b3eb solid",
    color: "#88b3eb",
    textTransform: "capitalize",
    marginRight: 15,
    "&:hover": {
      background: "#88b3eb",
      color: "#fff",
    },
  },
  loginform: {
    textAlign: "center",
  },
  chartbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    padding: "0 20px",
    height: "40px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
  CcqList: {
    borderRadius: 10,
    textAlign: "left",
    padding: "50px 0 10px",
    marginTop: 0,
  },
  Thtext: {
    fontFamily: "poppins",
    fontSize: 14,
    color: "#fff",
  },
  Thcol: {
    width: "15%",
    paddingRight:7,
  },
  Thcol2: {
    width: "15%",
    textAlign: 'right',
  },
  Thcol3: {
    width: "20%",
    textAlign: "left",
  },
  Thcol4: {
    width: "15%",
  },
  Thcol5: {
    width: "30%",
   
  },
  Thcol6: {
    width: "20%",
    textAlign: "right",
  },
  Thcol7: {
    width: "10%",
  },
  Thcol8: {
    width: "25%",
  },
  Thcol9: {
    width: "33%",
  },
  Thcol10: {
    width: "17%",
  },
  Thcol11: {
    width: "15%",
    textAlign: "right",
  },
  Tdcol7: {
    width: "10%",
    display: "flex",
    alignItems: "center",
  },
  Tdcol8: {
    width: "28%",
    wordWrap :"break-word",
  },
  Tdcol9: {
    width: "33%",
    wordWrap: "break-word",
    
  },
  Tdcol10: {
    width: "17%",
  },
  Tdcol11: {
    width: "15%",
    textAlign: "right",
  },
  Tdtext: {
    fontFamily: "poppins",
    fontSize: 14,
    color: "rgba(0,0,0,0.9)",
  },
  Tdcol: {
    width: "15%",
  },
  Tdcol2: {
    width: "15%",
    textAlign: 'right'
  },
  Tdcol3: {
    width: "20%",
    textAlign: "left",
  },
  Tdcol4: {
    width: "15%",
  },
  Tdcol5: {
    width: "30%",
    paddingRight:7,
  },
  Tdcol6: {
    width: "20%",
    textAlign: "right",
  },
  Tdrow: {
    display: "flex",
    borderBottom: "1px rgba(0,0,0,0.2) solid",
    padding: "10px 0",
    alignItems: "center",
  },
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #0f3d6e solid",
    height: "30px",
    fontSize: 14,
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "98%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Dateinput: {
    borderRadius: "10px",
    height: "42px",
    width: "94%",
    fontFamily: "Poppins",
    fontSize: "12px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    paddingLeft: 15,
    "&:focus": {
      border: "1px solid #D5D5D5 !important",
    },
  },

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "180px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  modal: {
    "& a": {
      textDecoration: "none",
    },
    "& .react-datepicker-ignore-onclickoutside:focus": {
      border: "1px #0f3d6e solid !important",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .react-datepicker__input-container": {
      borderRadius: 10,
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "500px",
      maxWidth: "500px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: 0,
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: "0 0 0 15px",
      },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },

  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "22%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "800px",
    background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
    '@media only screen and (max-width: 1200px)':{
      width: "30%",
      padding: "20px 1%",
    },
    '@media only screen and (max-width: 820px)':{
      width: '98%',
      padding:'20px 1%',
      position:'absolute',
      background:'none',
      minHeight:25,
    }, 
    '@media only screen and (max-width: 768px)':{
      width: '98%',
      padding:'20px 1%',
      position:'absolute',
      background:'none',
      minHeight:25,
    } 
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (max-width: 1200px)':{
      left: "20px",
    right: "20px",
    }
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    '@media only screen and (max-width: 1200px)':{
      width: "78%",
    padding: "20px 1%",
    },
    '@media only screen and (max-width: 820px)':{
      width:'96%',
      padding:'0 2%',
      marginTop:60,
            },
    '@media only screen and (max-width: 768px)':{
      width:'96%',
      padding:'0 2%',
      marginTop:60,
            },
    "& .MuiAccordion-rounded": {
      borderBottom: "1px rgba(0,0,0,0.1) solid",
      background: 'none',
      '@media only screen and (max-width: 1200px)':{
        borderBottom: "0px rgba(0,0,0,0.1) solid",
              },
    },
    "& .MuiAccordion-root.Mui-expanded": {
      marginBottom: 0,
    },
  },

  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "0",
  },
  Viewbtn: {
    background: "#0f3d6e",
    fontFamily: "poppins",
    fontSize: 14,
    textTransform: "capitalize",
    color: "#fff",
    padding: "6px 15px",
    borderRadius: 10,
    "&:hover": {
      background: "rgba(0,0,0,0.7)",
    },
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "30px",
    color: "#0f3d6e",
  },
}));
