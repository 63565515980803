import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiCheckCircle, BiCloudDownload,BiArrowBack, BiCloudUpload, BiXCircle} from "react-icons/bi"
import ManageUser from '../components/ManageUser'
import ManageProvider from '../components/ManageProvider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
function AddProvider({ options }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//  modal  //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                     <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box>
                 {/* left Accordion*/}
                 <ManageProvider />
                <ManageUser />
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Box className={classes.Tabcol}>
                <h3 style={{fontWeight:'500', marginBottom:'30px'}}>Add Provider</h3>
               <AppBar position="static" className={classes.contacttab}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Provider Info" {...a11yProps(0)} />
          <Tab label="Manage Care Team" {...a11yProps(1)} />
          <Tab label="Programs" {...a11yProps(2)} />
          <Tab label="Billing" {...a11yProps(3)} />
          <Tab label="Patient Upload" {...a11yProps(4)} />
          <Tab label="Goals" {...a11yProps(5)} />
          <Tab label="Reporting" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <Box className={classes.loginform}>
       <form>
           <Box className={classes.Formcol}>
               <label>Provider Name</label>
               <TextField className={classes.input}
                        placeholder="Enter Name"
          type="text" style={{width:'50%'}}
        />
           </Box>
           <Box className={classes.Formcol}>
               <label>Provider Address</label>
               <TextField className={classes.input}
                        placeholder="Enter Address"
          type="text" style={{width:'50%'}}
        />
           </Box>
           <Box className={classes.Formcol}>
               <label>Provider Phone</label>
               <TextField className={classes.input}
                        placeholder="Enter Phone"
          type="tel" style={{width:'50%'}}
        />
           </Box>
           <Box className={classes.Formcol}>
               <label>Provider Email</label>
               <TextField className={classes.input}
                        placeholder="Enter Email Address"
          type="text" style={{width:'50%'}}
        />
           </Box>
           <Box className={classes.Formcol}>
               <label>Provider NPI #</label>
               <TextField className={classes.input}
                        placeholder="Enter NPI"
          type="text" style={{width:'50%'}}
        />
           </Box>
           <Box className={classes.btncol}>
               <Button className={classes.cancelbtn}>Cancel</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
       </form>
       </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Box className={classes.loginform}>
          <form>
          <Box className={classes.Formcol}>
               <label>Provider Name</label>
               <Typography style={{margin:'0px', color:'#AEAEAE',textAlign:'left',fontSize:'18px', fontWeight:'500'}}>Dr. Caldwell</Typography>
               
           </Box>
           <Box className={classes.Formcol}>
               <label>Select Program</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select A program" className={classes.select} style={{width:300,}} />
           </Box>
           <Box className={classes.Formcol}>
               <label>Select Care Team</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select Care Team" className={classes.select} style={{width:300,}} />
           </Box>
           <Box className={classes.Formcol}>
               <label>Patient Assignment</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select Patient" className={classes.select} style={{width:300,}} />
           </Box>
           <Box className={classes.btncol}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Box className={classes.loginform}>
          <form>
           <Box className={classes.Formcol}>
               <label style={{width:'230px'}}>Select Program Template</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select a template" className={classes.select} style={{width:300,}} />
           </Box>
           <Box className={classes.Formcol}>
                <label style={{width:'230px'}}>Add Program Template</label>
                <Button className={classes.Addbtn} onClick={handleClickOpen}>Add Template</Button>
           </Box>
           
           <Box className={classes.btncol}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Box className={classes.loginform}>
          <form>
          <Box className={classes.Formcol}>
               <label>Select Billing Type</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select a type" className={classes.select} style={{ width:'400px'}} />
           </Box>
           <Box className={classes.btncol} style={{marginTop:'300px'}}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Box className={classes.loginform}>
          <form>
          <Box className={classes.Formcol}>
               <label style={{width:'335px'}}>Download Patient Upload Template</label>
               <Box style={{position:'relative'}}>
                   <BiCloudDownload className={classes.downloadicon} />
               <TextField className={classes.input}
                        placeholder="Template 1"
          type="text" style={{width:'100%'}}
        />
               </Box>
           </Box>
           <Box className={classes.Formcol}>
               <label style={{width:'335px'}}>Upload Patient Details</label>
               <Box>
               <input
        accept="image/*"
        className={classes.inputfile}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" className={classes.Uploadbtn}>
         Upload File <BiCloudUpload className={classes.icon} />
        </Button>
      </label>
               </Box>
           </Box>
           <Box className={classes.btncol} style={{marginTop:'300px'}}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Box className={classes.loginform}>
          <form>
          <Box className={classes.Formcol}>
               <label>Select Goals Template</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select Goals Template" className={classes.select} style={{ width:'400px'}} />
           </Box>
           <Box className={classes.btncol} style={{marginTop:'300px'}}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Next</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <Box className={classes.loginform}>
          <form>
          <Box className={classes.Formcol}>
               <label>Select Goals Template</label>
               <Box>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select Goals Template" className={classes.select} style={{ width:'400px'}} />
                
               </Box>
           </Box>
           <Box className={classes.Formcol}>
           <label></label>
           <Box className={classes.Templatecontent}>
                    <h3 className={classes.Textheading}>Template 1</h3>
                    <ul>
                        <li><span>Billing</span> <BiCheckCircle className={classes.checkicon} /></li>
                        <li><span>CCQ</span> <BiCheckCircle className={classes.checkicon} /></li>
                        <li><span>Finalize</span> <BiCheckCircle className={classes.checkicon} /></li>
                        <li><span>Login - Logout</span> <BiCheckCircle className={classes.checkicon} /></li>
                        <li><span>Patient Engagement</span> <BiCheckCircle className={classes.checkicon} /></li>
                    </ul>
                </Box>
                </Box>
           <Box className={classes.btncol} style={{marginTop:'300px'}}>
               <Button className={classes.cancelbtn}>Back</Button>
               <Button className={classes.nextbtn}>Submit</Button>
           </Box>
          </form>
      </Box>
      </TabPanel>
      </Box>
               </Box>
                {/* modal */}
                <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <Button onClick={handleClose} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          <Box className={classes.loginform}>
              <h3 className={classes.Toptext}>Add New Program</h3>
              <form>
              <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                          <label>Program Name</label>
                          <TextField className={classes.input} placeholder="Enter Program Name"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Description</label>
                          <TextField className={classes.input} placeholder="Enter Description" type="text" />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <h4 style={{fontSize:'16px', color:'#AEAEAE', fontWeight:'500'}}>Primary</h4>
                      <Box className={classes.Formcol}>
                          <label>Code</label>
                          <TextField className={classes.input} placeholder="Enter Code" type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Timer Maximum</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="30:00" className={classes.select} style={{width:'200px'}} />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Max Use</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="1" className={classes.select} style={{width:'200px'}} />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <h4 style={{fontSize:'16px', color:'#AEAEAE', fontWeight:'500'}}>Add-On</h4>
                      <Box className={classes.Formcol}>
                          <label>Code</label>
                          <TextField className={classes.input} placeholder="Enter Code" type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Timer Maximum</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="30:00" className={classes.select} style={{width:'200px'}} />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Max Use</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="1" className={classes.select} style={{width:'200px'}} />
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Max Billing Frequency</label>
                          <TextField className={classes.input} placeholder="Calendar Month"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Min User Level</label>
                          <TextField className={classes.input} placeholder="5"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Chronic Condition Minimum</label>
                          <TextField className={classes.input} placeholder="0"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Initial Visit Required</label>
                          <TextField className={classes.input} placeholder="NO"
                        type="text" />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{width:'380px'}}>Careplan Template</label>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="CCM" className={classes.select} style={{width:'456px'}} />
                      </Box>
                  </Grid>
              </Grid>
            </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
        <FormGroup aria-label="position" row>
        </FormGroup>
        <Button size="large" className={classes.loginbtn}>
        Save
        </Button>
        </DialogActions>
      </Dialog>
            </Box>
        </div>
    )
}

export default AddProvider
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    Templatecontent:{
        '& ul':{
            listStyle:'none',
            margin:'0px',
            padding:'0px',
            '& li':{
                marginBottom:'15px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                '& span':{
                    width:'250px'
                }
            }
        }
    },
    checkicon:{
        fontSize:'25px',
        color:'#47C932'
    },
    inputfile:{
        display:'none'
    },
    select:{
        width:'200px',
        border:'none !important',
        borderRadius:'10px !important',
        background:'#F1F1F1',
        height:'50px',
        fontSize:'14px !important',
        paddingLeft:'15px !important',
        paddingRight:'15px !important',
        '& .react-dropdown-select-input':{
            display:'none'
          }
    },
    Toptext:{
        fontSize:'18px',
        color:'#0f3d6e',
        fontWeight:'600',
        marginTop:0,
        marginBottom:30,
    },
    Textheading:{
        fontSize:'16px',
        marginTop:'0px',
        fontWeight:'500'
    },
    Addbtn:{
        width:'180px',
        height:'45px',
        background:'#E13F66',
        borderRadius:'10px',
        color:'#fff',
        boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textTransform:'capitalize',
        fontSize:'16px',
        '&:hover':{
            background:'#000'
        }
    },
    cancelbtn:{
        background:'#DADADA',
        borderRadius:'10px',
        textTransform:'capitalize',
        height:'45px',
        width:'120px',
        color:'#fff',
        fontWeight:'600',
        '&:hover':{
            background:'#000'
        }
    },
    nextbtn:{
        background:'#0f3d6e',
        borderRadius:'10px',
        textTransform:'capitalize',
        height:'45px',
        width:'120px',
        color:'#fff',
        fontWeight:'600',
        marginLeft:'15px',
        '&:hover':{
            background:'#000'
        }
    },
    Formcol:{
        display:'flex',
        alignItems:'center',
        marginBottom:'30px',
        '& p':{
            fontSize:'18px',
            margin:'0px'
        },
        '& label':{
            width:'200px'
        },
        '& .react-dropdown-select-input':{
            width:'100%'
        }
    },
    addprovider:{
        fontSize:'16px',
        color:'#0f3d6e',
        textTransform:'capitalize'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'100px'
    },
    topheading:{
        marginBottom:'50px',
        fontWeight:'600',
        color:'#0f3d6e'
    },
    Tabcol:{
        '& .MuiTabPanel-root':{
            padding:'0px'
        }
    },
    contacttab:{
        background:'#fff',
        boxShadow:'none',
        color:'#000',
        textTransform:'capitalize',
        '& .MuiTabs-indicator':{
          display:'none !important'
        },
        '& .MuiTabs-flexContainer':{
          borderRadius:'10px',
          background:'#F3F3F3',
          width:'100%',
          textTransform:'capitalize',
          overflow:'hidden',
          '& .MuiTab-textColorInherit.Mui-selected':{
            background:'#0f3d6e',
            color:'#ffffff'
          },
          '& .MuiTab-textColorInherit':{
            textTransform:'capitalize',
            fontSize:'16px',
            padding:'0 22px'
          }
        },
      },
    toprow:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#bfc4cb',
        paddingBottom:'10px',
    },
    pageTop:{
        textAlign:'left',
        marginBottom:'40px',
        display:'flex',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        }
    },
    profile:{
        width:'80px',
        height:'80px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol:{
        width:'15%',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px'
    },
    Rightcol:{
        width:'73%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minHeight:'40px',
          minWidth:'125px'
        },
        '& .MuiBox-root':{
            paddingLeft:'0px',
            paddingRight:'0px'
        }
    },
    
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Uploadbtn:{
    width:'230px',
    height:'50px',
    background:'#F9F9F9',
    color:'#AEAEAE',
    fontSize:'14px',
    display:'flex',
    justifyContent:'space-between',
    borderRadius:'10px',
    boxShadow:'none',
    textTransform:'capitalize'
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},

icon:{
  color:'#0f3d6e',
  fontSize:'30px',
  marginRight:'10px'
},
downloadicon:{
    position:'absolute',
    right:'20px',
    zIndex:'99',
    color:'#0f3d6e',
  fontSize:'30px',
  top:'10px',
  cursor:'pointer'
},
providerrow:{
    width:'100%',
    borderBottom:'1px #E3E5E5 solid',
    padding:'15px 0',
    display:'flex',
    '& p':{
        textAlign:'left'
    }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},

pageTop:{
    textAlign:'left',
    '& button':{
        padding:'0px',
        background:'none',
        color:'#bfc4cb',
        fontSize:'15px',
        textTransform:'capitalize',
        fontWeight:'500'
    }
},

input:{ 
    border:'none',
    borderRadius:'10px',
    height:'50px',
    width:'100%',
},
loginform:{
    width:'100%',
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        background:'#F9F9F9',
        padding:'0 15px'
    }
},
loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'142px',
    height:'45px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    '&:hover':{
        background:'#333'
    }
},
modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'776px',
        maxWidth:'776px'
    }
},
ccmmodal:{
    borderRadius:'10px',
},
modalbtn:{
    display:'flex',
    justifyContent:'space-between',
    marginRight:'30px',
    marginLeft:'15px',
    alignItems:'center'
},
btncol:{
    display:'flex',
    justifyContent:'flex-end',
},
closebtn:{
    width:'40px',
    position:'absolute',
    right:'10px',
    height:'40px',
    background:'#fff',
    top:'10px',
    minWidth:'40px',
    '&:hover':{
        background:'#fff'
    }
},
closeicon:{
    fontSize:'25px',
    color:'#0f3d6e'
},
}));