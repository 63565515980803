import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link, Paper } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import PatientDetails from '../components/PatientDetails'
import CcqHeader from '../components/CcqHeader'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import Carousel from 'react-material-ui-carousel'


function ProgramLanding() {
    const classes = useStyles();
      // tab //
  const [value, setValue] = React.useState('1');

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
                <PatientDetails />
               
                 {/* left Accordion*/}
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <CcqHeader />
               <Box className={classes.btncol} style={{ marginTop:'30px'}}></Box>
               <Box className={classes.Tabcol}>
                   <Button className={classes.viewbtn}>View / Start Engagement</Button>
               <TabContext value={value}>
               <AppBar position="static" className={classes.contacttab}>
          <TabList onChange={handleChange2} aria-label="simple tabs example">
            <Tab label="Recent Engagements" value="1" />
            <Tab label="Provider Programs" value="2" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
        <Box className={classes.Careplan}>
               <Box className={classes.throw}> 
                         <Box className={classes.Row1}> Date</Box>
                         <Box className={classes.Row2}>Program</Box>
                         <Box className={classes.Row3}>Elapsed Time</Box>
                         <Box className={classes.Row4}>User</Box>
                         <Box className={classes.Row5}>Status</Box>
                         <Box className={classes.Row6}>Care Plan</Box>
                     </Box> 
                     <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}> 20-05-2021</Box>
                         <Box className={classes.Row2}>CCM</Box>
                         <Box className={classes.Row3}>09:32:16</Box>
                         <Box className={classes.Row4}>Candace Kelsey</Box>
                         <Box className={classes.Row5}>Finalized</Box>
                         <Box className={classes.Row6}><Button className={classes.downloadbtn}>Download PDF</Button></Box>
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}> 20-05-2021</Box>
                         <Box className={classes.Row2}>CCM</Box>
                         <Box className={classes.Row3}>09:32:16</Box>
                         <Box className={classes.Row4}>Candace Kelsey</Box>
                         <Box className={classes.Row5}>Finalized</Box>
                         <Box className={classes.Row6}><Button className={classes.downloadbtn}>Download PDF</Button></Box>
                         </Box>
                         <Box className={classes.btnrow}>
                             <Button className={classes.loadbtn}>Load More</Button>
                         </Box>
      </Box>
        </TabPanel>
        {/* tab panel 2 */}
        <TabPanel value="2">
       
        </TabPanel>
      </TabContext>
               </Box>
               
                   {/* tab with accordion */}
                    
               </Box>
            </Box>
        </div>
    )
}

export default ProgramLanding
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    Leftcol:{
        width: '22%',
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    viewbtn:{
        width:'240px',
        height:'40px',
        background:'#0D45D6',
        borderRadius:'10px',
        fontSize:'16px',
        color:'#fff',
        display:'flex',
        justifyContent:'center',
        textTransform:'capitalize',
        alignItems:'center',
        marginBottom:'30px',
        boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
        '&:hover':{
            background:'#000'
        }
    },
    loadbtn:{
        width:'100px',
        height:'30px',
        background:'#0D45D6',
        borderRadius:'10px',
        fontSize:'12px',
        color:'#fff',
        display:'flex',
        justifyContent:'center',
        textTransform:'capitalize',
        alignItems:'center',
        marginBottom:'30px',
        boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
        '&:hover':{
            background:'#000'
        } 
    },
    btnrow:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'20px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px'
    },
    Rightcol:{
        width:'78%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minHeight:'40px'
        },
        '& .MuiTabPanel-root':{
            paddingLeft:'0px',
            paddingRight:'0px'
        }
    },
    modaltop:{
      margin:'-20px 0 0'
    },    
Careplan:{
    width:'100%',
    '& .MuiAccordionSummary-root':{
        borderBottom:'1px #5f80a9 solid',
        height:'40px',
        color:'#bfc4cb',
        padding:'0px',
        fontFamily:'Poppins',
    },
    '& .MuiAccordion-root.Mui-expanded':{
        margin:'0px'
    },
    '& .MuiAccordionSummary-content':{
        margin:'0',
        order:'2',
        width:'100%'
    },
    '& .MuiIconButton-root':{
        padding:'5px',
        fontSize:'16px',
        marginRight:'5px',
        color:'#88b3eb',
        position:'absolute',
        left:'-25px'
    },
    '& .MuiAccordion-root:before':{
        display:'none'
    }
    },
    
topheading:{
  fontSize:'16px',
  fontWeight:'normal',
  color:'#000'
},
Filter:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'flex-end'
},
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Filtertext:{
    display:'flex',
    alignItems:'center',
    margin:'0 0 7px',
    '& span':{
        color:'#bfc4cb',
        marginRight:'5px'
    }
},
contacttab:{
  background:'#fff',
  boxShadow:'none',
  color:'#000',
  textTransform:'capitalize',
  '& .MuiTabs-indicator':{
    display:'none !important'
  },
  '& .MuiTabs-flexContainer':{
    borderRadius:'10px',
    background:'#F3F3F3',
    width:'390px',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTab-textColorInherit.Mui-selected':{
        background:'#0f3d6e',
        color:'#ffffff'
    },
    '& .MuiTab-textColorInherit':{
      textTransform:'capitalize',
      fontSize:'16px',
      padding:'0 22px'
    }
  },
  
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},
downloadbtn:{
    background:'#0D45D6',
    border:'0px #AEAEAE solid',
    width:'120px',
    height:'30px',
    fontSize:'12px',
    color:'#fff',
    textTransform:'capitalize',
    borderRadius:'10px',
    fontWeight:'600',
    '&:hover':{
      background:'#000',
      color:'#fff',
    }
  },
icon:{
  color:'#0f3d6e',
  fontSize:'20px',
  marginRight:'10px'
},
throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'2px #E3E5E5 solid',
    alignItems:'center',
    fontSize:'14px',
    color:'#bfc4cb',
    paddingBottom:'5px'
},
tdrow:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px #E3E5E5 solid',
    fontSize:'14px',
    padding:'10px 0'
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},
Row1:{
    width:'10%'
},
Row2:{
    width:'15%'
},
Row3:{
    width:'15%'
},
Row4:{
    width:'25%'
},
Row5:{
    width:'15%'
},
Row6:{
    width:'15%'
},
Row7:{
    width:'10%',
    display:'flex',
    justifyContent:'center',
    textAlign:'right'
},
Row8:{
    width:'15%',
    display:'flex',
    justifyContent:'flex-end',
    textAlign:'right'
},
Row9:{
    width:'10%',
    textAlign:'right'
},
pageTop:{
    textAlign:'left',
    '& button':{
        padding:'0px',
        background:'none',
        color:'#bfc4cb',
        fontSize:'15px',
        textTransform:'capitalize',
        fontWeight:'500'
    }
},  
   }));