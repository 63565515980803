import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { BiArrowBack, BiClipboard, BiSearch, BiCheckSquare } from "react-icons/bi"
// import { Link as NavLink }   from "react-router-dom"
import { NavLink } from "react-router-dom"

import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'

function PatientStatus() {
  const classes = useStyles();
  const [userType2, setUserType] = React.useState("");
  const [capermissionFlag, setCAPermissionFlag] = React.useState(false);
  const [ctpermissionFlag, setCTPermissionFlag] = React.useState(false);

  const [selectTab, setSelectTab] = React.useState(1);
  const [selectOption, setSelectOption] = React.useState(1);


  let userType = (JSON.parse(localStorage.getItem("JWT")));
  const handleSelect = (item) => {
    setSelectTab(item);
    localStorage.setItem("tab", item);
  };
  useEffect(() => {
    if (localStorage.getItem("tab")) {

      setSelectTab(localStorage.getItem("tab"));
      console.log("Local storage - ", localStorage.getItem("tab"));
    }
  }, []);

  useEffect(() => {
    let permissions = userType.permission;

    console.log("Permissions -", permissions);

    if (permissions.includes(17) && permissions.includes(13)) {
      setCAPermissionFlag(true);
    }

    if (permissions.includes(35)) {
      setCTPermissionFlag(true);
    }


  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className={classes.patientstatus}>
        <Typography variant='h3' style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: '600', textAlign: 'left', marginBottom: 30, color: '#88b3eb' }}>{(userType.login_type == "care_team") ? "Care Team" : ""}</Typography>
        <ul className={classes.navList}>
          <li><NavLink to="/patient-search" activeClassName={classes.navActive}>PatientList</NavLink></li>
          <li><NavLink to="/patient-assignment" activeClassName={classes.navActive}> Patient Assignment</NavLink></li>
          <li><NavLink to="/reports" activeClassName={classes.navActive}>Reports</NavLink></li>
          <li><NavLink to="/ccq" activeClassName={classes.navActive}>CCQ List</NavLink> </li>
          <li><NavLink to="/recent-finalized" activeClassName={classes.navActive}>Recent Finalized</NavLink></li>
          {38 in JSON.parse(localStorage.getItem("JWT")).permission && (
            <li><NavLink to="/manage-provider" activeClassName={classes.navActive}>Manage Users</NavLink></li>
          )}
          <li><NavLink to="/program-template" activeClassName={classes.navActive}>Manage Programs</NavLink></li>
          <li><NavLink to="/manage-patient" activeClassName={classes.navActive}>Manage Patient</NavLink></li>
          <li><NavLink to="/manage-billing"

            activeClassName={classes.navActive}>Manage Billing</NavLink></li>
        </ul>
      </Box>
    </div>
  );

  return (
    <div>
      <Box className='ResponsiveNav'>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)} style={{ color: '#fff', fontFamily: 'poppins', textTransform: 'capitalize' }}>Menu</Button>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </Box>
      <Box className={classes.DsktopNav}>
        <Box className={classes.patientstatus}>
          <Typography variant='h3' style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: '600', textAlign: 'left', marginBottom: 30, color: '#88b3eb' }}>{(userType.login_type == "care_team") ? "Care Team" : ""}</Typography>
          <ul className={classes.navList}>
            <li><NavLink to="/patient-search" activeClassName={classes.navActive}>Patient List</NavLink></li>
            <li><NavLink to="/patient-assignment" activeClassName={classes.navActive}> Patient Assignment</NavLink></li>
            <li><NavLink to="/reports" activeClassName={classes.navActive}>Reports</NavLink></li>
            <li><NavLink to="/ccq" activeClassName={classes.navActive}>CCQ List</NavLink></li>
            <li><NavLink to="/recent-finalized" activeClassName={classes.navActive}>Recent Finalized</NavLink></li>
            {JSON.parse(localStorage.getItem("JWT")).permission.includes(38) && (
              <li><NavLink to="/manage-provider" activeClassName={classes.navActive}>Manage Users</NavLink></li>
            )}
            {JSON.parse(localStorage.getItem("JWT")).permission.includes(10) && (
              <li><NavLink to="/program-template" activeClassName={classes.navActive}>Manage Programs</NavLink></li>
            )}
            <li><NavLink to="/manage-patient" activeClassName={classes.navActive}>Manage Patient</NavLink></li>
            {JSON.parse(localStorage.getItem("JWT")).permission.includes(10) && (
              <li><NavLink to="/manage-billing" activeClassName={classes.navActive}>Manage Billing</NavLink></li>
            )}
          </ul>
        </Box>
      </Box>
    </div>
  )
}

export default PatientStatus
const useStyles = makeStyles(() => ({
  list: {
    width: 250,

    padding: '30px 20px',

  },
  fullList: {
    width: 'auto',

  },

  DsktopNav: {
    '@media only screen and (max-width: 820px)': {
      display: 'none'
    },
    '@media only screen and (max-width: 768px)': {
      display: 'none'
    },
  },
  patientstatus: {
    position: 'relative',
    marginBottom: '70px',
    '& p': {
      textAlign: 'left'
    },
    '& ul': {
      listStyle: 'none',
      display: "flex",
      flexDirection: "column",


      margin: '0px',
      padding: '0px',
      '& li': {
        color: '#1C295D',
        fontSize: '12px',
        fontWeight: '300',

        '& a': {
          textDecoration: "none"
        }
      },
      '& li button': {
        background: '#0f3d6e',
        width: '150px',
        height: '55px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: '10px',
        fontSize: '18px',
        display: 'flex',
        '&:hover': {
          background: '#0f3d6e'
        }
      }
    }
  },

  navList: {

    '& li': {
      color: '#1C295D',
      fontSize: '12px',
      fontWeight: '300',
      paddingBottom: "30px",
      textAlign: "left",
      '& a': {
        textDecoration: "none",
        color: "rgba(255,255,255,0.8)",
        fontSize: "14px",
        '&:hover': {
          color: "#fff"


        }


      },


    },


  },
  navActive: {
    fontWeight: '1000',
    margin: '0px',
    textAlign: 'left',
    fontSize: '16px',
    margin: '25% 0',
    cursor: 'pointer',


  },
  unselected: {
    fontWeight: '200',
    margin: '0px',
    textAlign: 'left',
    fontSize: '12px',
    margin: '25% 0',
    cursor: 'pointer',
    color: "green"
  },
}));