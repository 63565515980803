import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTime } from 'react-timer-hook'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Select from "react-dropdown-select"
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Setting from '../components/Setting'
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import { FaPlusCircle } from "react-icons/fa"
import { BiXCircle,BiDownArrowAlt } from "react-icons/bi"
import DatePicker from "react-date-picker";
import Slide from '@material-ui/core/Slide'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";

import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import { selectClasses } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function CareplanCondition({ options }) {
    const classes = useStyles();
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});
    const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const [open, setOpen] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const submitForm = (flag) => {

    let isValid = true;

    if(conditionDescription.trim().length > 0){
      setDescriptionError(false);
    }
    else{
      setDescriptionError(true);
      isValid = false;
    }

    if(icd10){
      setIcdError(false);
    }
    else{
      setIcdError(true);
      isValid = false;
    }

    if(diagnosisDate){
      setDiagDateError(false);
    }
    else{
      setDiagDateError(true);
      isValid = false;
    }

    if(isValid) {

      setShowLoader(true);
      let data, url;

      let pat_details = JSON.parse(localStorage.getItem("patient"));

        if(flag == 1){

          data = {
            pd_id: pat_details.pd_id,
            icd10_codes: icd10? icd10[0].label : "",
            condition_description: conditionDescription,
            finalize_category: finalizeCat,
            diagnosis_date: diagnosisDate? moment(diagnosisDate).format("YYYY-MM-DD") : "",
          }

          url = "/patient/create-patient-condition";

        }
        else {

          data = {
            pd_id: pat_details.pd_id,
            patient_condition_id: editData.id,
            icd10_codes: icd10.label ? icd10.label : icd10[0].label,
            condition_description: conditionDescription,
            finalize_category: finalizeCat,
            diagnosis_date: diagnosisDate? moment(diagnosisDate).format("YYYY-MM-DD") : "",
          }

          url = "/patient/update-patient-condition";

        }

        apiAxios
          .post(url, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
              setShowLoader(false);
              setOpen(false);
              getConditions();  
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    }); 
                    setShowLoader(false);
                    setOpen(false);       
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                localStorage.removeItem("Refresh_Token");
                localStorage.removeItem("Token");
                window.location.href = "/";        
  
    
          });


    }


  }

  const handleModal = (data) => {
    if(data == ""){
      setConditionDescription("");
      setFinalizeCat("");
      setIcd10codes("");
      setIcd10("");
      setDiagnosisDate("");
      setEditData("");
    }
    else{
      setEditData(data);
      setConditionDescription(data.condition_description);
      setFinalizeCat(data.finalize_category);
      setDiagnosisDate(new Date(data.diagnosis_date));
      if(icdList){
        for(var i=0;i< icdList.length; i++){
          if(data.icd10_codes == icdList[i].label){
            setIcd10(icdList[i]);
            
          }
        }
      }
    }
    console.log("icd - ", icd10);
    setOpen(true);
  }

  const handleClose = () => {
    setEditData("");
    setOpen(false);
  }

  // tab //
  const [value, setValue] = React.useState('1');
  const [editData, setEditData] = React.useState("");
  const [conditionDescription, setConditionDescription] = React.useState("");
  const [finalizeCat, setFinalizeCat] = React.useState("");
  const [icd10, setIcd10] = React.useState("");
  const [icd10codes, setIcd10codes] = React.useState("");
  const [diagnosisDate, setDiagnosisDate] = React.useState("");

  const [descriptionError, setDescriptionError] = React.useState(false);
  const [icdError, setIcdError] = React.useState(false);
  const [diagDateError, setDiagDateError] = React.useState(false);

  const [conditionList, setConditionList] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [icdList, setIcdList] = React.useState("");

  useEffect(() => {

    getConditions();
    getIcdCodes();

  }, []);  

  useEffect(() => {
    if(icd10codes){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<icd10codes.length;i++){
       let value = icd10codes[i].id;
       let label = icd10codes[i].icd10_code_id;
       let ob = {
           "label":label, "value": value
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setIcdList(ob_arr);
   }
   
 }, [icd10codes]);  

 function handleClickDelete(item) {
  
  //console.log("Delete = ", item);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  let data = {
    patient_condition_id: item.id,
    pd_id: pat_details.pd_id
  }

  apiAxios
  .post("/patient/delete-patient-condition", data, {
    headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("Token")
    },
  })
  .then((response) => {
    console.log("response --> ",response);
    if(response.data.status == 200) {
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      
      getConditions();  
    }
    else {
        toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }); 
            setShowLoader(false);       
    }
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        


  });
}

 const deleteModal = (item) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h1>Are you sure?</h1>
          <p>You want to delete this record?</p>
          <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
          <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
            onClick={() => {
              handleClickDelete(item);
              onClose();
            }}
          >
            Yes, Delete
          </button>
        </div>
      );
    }
  });
};


  const getConditions = useCallback(
    async ({
      table_config_rows_per_page = '50',
      table_config_page_no = '1',
    } = {}) => {

    //setCurrentPage(parseInt(table_config_page_no));

    let pd = JSON.parse(localStorage.getItem("patient"));
        
    setConditionList([]);
    setMessage("Loading...");

    let data ={
        table_config_page_no: table_config_page_no,
        pd_id: pd.pd_id,
        table_config_rows_per_page: table_config_rows_per_page,
      }

    apiAxios
        .post("/patient/list-patient-condition", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setConditionList(response.data.data); 
              setMessage("");
              //setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const getIcdCodes = useCallback(
    async ({
      table_config_rows_per_page = '200',
      table_config_page_no = '1',
    } = {}) => {

    //setCurrentPage(parseInt(table_config_page_no));

    let pd = JSON.parse(localStorage.getItem("patient"));
        
    setIcd10codes([]);
    //setMessage("Loading...");

    let data ={
        table_config_page_no: table_config_page_no,
        search_value: "",
        table_config_rows_per_page: table_config_rows_per_page,
      }

    apiAxios
        .post("/patient/icd10-codes-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setIcd10codes(response.data.data); 
              //setMessage("");
              //setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              //setMessage("No Data Found");
              // toast.error("ICD10 Code List is blank.", {
              //   position: "bottom-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   });
            
                //setMessage(response.data.message);  
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  //setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <div>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
                <Patientinfo />
                 {/* left Accordion*/}
                 <Box className={classes.TabPatientinfo}>
                <LeftNav />
                </Box>
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Timer />
               
                  <Box className={classes.Careplan}>
                        <Box className={classes.plantop}>
                            <h3 className={classes.topheading}>Conditions</h3>
                            <Button className={classes.recordbtn} onClick={() => handleModal(0)} ><FaPlusCircle className={classes.addicon} /> Add Condition</Button>
                        </Box>
                        <Box className={classes.CareplanTable}>
                        <Box className={classes.throw}> 
                                  <Box className={classes.Row1}>Condition Description</Box>
                                  <Box className={classes.Row2}>Finalize Category</Box>
                                  <Box className={classes.Row3}>ICD10 Codes</Box>
                                  <Box className={classes.Row4}>Diagnosis Date</Box>
                                  <Box className={classes.Row5}>Created <br/>Date & Time</Box>
                                  <Box className={classes.Row6}>Updated <br/>Date & Time</Box>
                                  <Box className={classes.Row7}>Action</Box>
                        </Box> 
                        {(conditionList.length > 0) ? 
                        <>
                        {conditionList.map((each) => (
                        <Box className={classes.tdrow}> 
                                  <Box className={classes.Row1}>{each.condition_description}</Box>
                                  <Box className={classes.Row2}>{each.finalize_category}</Box>
                                  <Box className={classes.Row3}>{each.icd10_codes}</Box>
                                  <Box className={classes.Row4}>{each.diagnosis_date ? moment.utc(each.diagnosis_date).local().format("YYYY-MM-DD") : "--"}</Box>
                                  <Box className={classes.Row5}>{each.created_at ? moment.utc(each.created_at).local().format("YYYY-MM-DD HH:mm a") : "--"}</Box>
                                  <Box className={classes.Row6}>{each.updated_at ? moment.utc(each.updated_at).local().format("YYYY-MM-DD HH:mm a") : "--"}</Box>
                                  <Box className={classes.Row7}><span className={classes.editbtn} style={{marginRight: '6px'}} onClick={() => handleModal(each)}>Edit</span> <span className={classes.deletebtn} onClick={() => deleteModal(each)}>Delete</span></Box>
                        </Box>
                        ))}
                        </>
                        :
                        <Box style={{padding: '5%', textAlign: 'center', width:'100%'}}> 
                          {message}
                        </Box>
                        }
                   </Box>
                  </Box>
                    
               </Box>
               
            </Box>


                <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        disableEnforceFocus={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <h3 style={{ margin: "0", textAlign: "left",fontFamily:'Poppins'}}>Add/Update Condition</h3>
            <Button onClick={handleClose} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          
              <form>
            <ul className={classes.Editplan}>
                <li><span>Condition Description<span style={{color:'red', fontSize: '15px'}}>*</span></span><TextareaAutosize className={classes.textarea} placeholder="Write some description..." onChange={(e) => setConditionDescription(e.target.value)}
                        value={conditionDescription ? conditionDescription : ""}
          type="text"
        /></li>
        {descriptionError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Condition description is mandatory.
                </p>
              ) : (
                <></>
              )}
                <li><span>Finalize Category</span> <TextareaAutosize onChange={(e) => setFinalizeCat(e.target.value)} className={classes.textarea} placeholder="Mention Finalize Category" aria-label="minimum height" minRows={4} value={finalizeCat ? finalizeCat : ""} /></li>
                        
                <li><span>ICD10 Codes<span style={{color:'red', fontSize: '15px'}}>*</span></span><FormControl style={{width:'100%'}}> 
                          <Select
                          
                          options={icdList}
                          value={icd10.label ? icd10.label : ""}
                          onChange={setIcd10}
                          placeholder={icd10? icd10.label : "Select ICD10 Codes"}
                          className={classes.datepicker}
                          searchable={false}
                         
                          
                        /></FormControl></li>
                {icdError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  ICD10 code is mandatory.
                </p>
              ) : (
                <></>
              )}        
                                
                <li><span>Diagnosis Date<span style={{color:'red', fontSize: '15px'}}>*</span></span> 
                <DatePicker className={classes.input} placeholder="Start Date" clearIcon={null} value={diagnosisDate} onChange={(date) => setDiagnosisDate(date)} />
                        </li>
                {diagDateError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Diagnosis date is mandatory.
                </p>
              ) : (
                <></>
              )}        
            </ul>
            
            </form>
          </DialogContentText>
        </DialogContent>
        {editData?
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={() => submitForm(2)}>
                        Update
                    </Button>
                )
        }
        </DialogActions>
        :
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={() => submitForm(1)}>
                        Submit
                    </Button>
                )
        }
        </DialogActions>
        }
                </Dialog>
            
        </div>
    )
}

export default CareplanCondition
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    TabPatientinfo:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
              },
              '@media only screen and (max-width: 768px)':{
                display:'none'
                      },
    },
    addicon:{
      marginRight:5,
    },
    CareplanTable:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
       overflowX:'auto'
              }
    },
    Leftcol:{
        width: '22%',
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
                '@media only screen and (max-width: 768px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
    },
    topheading:{
      fontSize:'18px',
      fontWeight:'normal',
      color:'#000',
      fontWeight:'500'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
    },
    Rightcol:{
        width:'78%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
          width:'72%',
          padding:'0 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
                '@media only screen and (max-width: 768px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
        '& .MuiAccordionDetails-root':{ 
            paddingLeft:'0px',
            paddingRight:'0px'
        },
        '& .MuiTabPanel-root':{
          padding:'20px 0'
        },
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #5f80a9 solid',
            height:'auto',
            color:'#bfc4cb',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minHeight:'40px'
        }
    },
    
    btncol:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:'0',
        marginBottom:20,
   },  

   datepicker:{
    borderRadius:'10px !important',
      height:'45px',
      width:'96%',
      fontFamily:'Poppins',
      fontSize:'14px',
      background:'#F9F9F9',
      border:'1px solid #D5D5D5 !important',
      paddingLeft:'15 !important',
      '&:focus':{
        border:'1px #0f3d6e solid !important',
        zIndex: '999'
      }
  },

   modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'776px',
        maxWidth:'776px'
    }
},
ccmmodal:{
    borderRadius:'10px',
    paddingTop:'0px !important',
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
},
modalbtn:{
    display:'flex',
    justifyContent:'flex-end',
    marginRight:'30px'
},
Optionsbtn:{
    width:'150px',
    border:'1px #AEAEAE solid',
    borderRadius:'10px',
    display:'flex',
    justifyContent:'space-between',
    textTransform:'capitalize',
    float:'right'
},
input:{ 
      
    border:'none',
    borderRadius:'10px',
    height:'42px',
    width:'100%',
    fontFamily:'Poppins',
    fontSize:'12px'
},
textarea:{
    width:'100%',
    borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'10px 15px',
        fontFamily:'Poppins',
        fontSize:'12px'
},
Editplan:{
  listStyle:'none',
  margin:'0px',
  padding:'0px',
  '& li':{
      display:'flex',
      fontSize:'14px',
      alignItems:'center',
      marginBottom:'15px',
      '& span':{
          flex:'0 0 120px',
          color:'#121212',
          maxHeight: '50px'
      }
  },
  '& .MuiInput-underline:before':{
      display:'none'
  },
  '& .MuiInput-underline:after':{
      display:'none'
  },
  '& .MuiInput-formControl':{ 
      height:'42px',
      
  },
  '& .MuiInputBase-input':{
      height:'42px',
      borderRadius:'10px',
      background:'#F9F9F9',
      border:'1px solid #D5D5D5',
      padding:'0 15px',
      fontFamily:'Poppins',
      fontSize:'12px',
      color:'#000'
  },
  '& .MuiInput-input:focus':{
    border:'1px #0f3d6e solid',
    boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
}
},
loginbtn:{
  background:'#0f3d6e',
  padding:'0 40px',
  width:'120px',
  height:'40px',
  borderRadius:'10px',
  color:'#fff',
  marginTop:'20px',
  textTransform:'capitalize',
  '&:hover':{
      background:'#333'
  }
},
closebtn:{
  width:'40px',
  height:'40px',
  background:'#fff',
  minWidth:'40px',
  '&:hover':{
      background:'#fff'
  }
},
closeicon:{
  fontSize:'25px',
  color:'#0f3d6e'
},
contacttab:{
  background:'#fff',
  boxShadow:'none',
  color:'#000',
  textTransform:'capitalize',
  '& .MuiTabs-indicator':{
    display:'none !important'
  },
  '& .MuiTabs-flexContainer':{
    borderRadius:'10px',
    background:'#F3F3F3',
    width:'360px',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTab-textColorInherit.Mui-selected':{
      background:'#0f3d6e',
            color:'#ffffff' 
    },
    '& .MuiTab-textColorInherit':{
      textTransform:'capitalize',
      fontSize:'16px',
      padding:'0 22px'
    }
  },
  
},
editbtn: {
  background: '#88b3eb',
  padding: '6px 8px',
  borderRadius: '10px',
  color: '#fff',
  cursor: 'pointer',
},
deletebtn: {
  background: '#0f3d6e',
  padding: '6px 8px',
  borderRadius: '10px',
  color: '#fff',
  cursor: 'pointer'
},

Careplan:{
  width:'100%',
  '& .MuiAccordionSummary-root':{
      borderBottom:'1px #e3e5e5 solid',
      height:'40px',
      color:'#bfc4cb',
      padding:'0px',
      fontFamily:'Poppins',
  },
  '& .MuiAccordion-root.Mui-expanded':{
      margin:'0px'
  },
  '& .MuiAccordionSummary-content':{
      margin:'0',
      order:'2',
      width:'100%'
  },
  '& .MuiIconButton-root':{
      padding:'5px',
      fontSize:'16px',
      marginRight:'5px',
      color:'#88b3eb',
      position:'absolute',
      left:'-25px'
  },
  '& .MuiAccordion-root:before':{
      display:'none'
  }
},
plantop:{
  display:'flex',
  justifyContent:'space-between',
  marginBottom:'30px',
  marginTop:'20px',
  '@media only screen and (max-width: 820px)':{
    marginBottom:'0px',
    marginTop:'10px',
          },
  '@media only screen and (max-width: 768px)':{
    marginBottom:'0px',
    marginTop:'10px',
          },
},
recordbtn:{
  background:'#fff',
  fontSize:'16px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  '&:hover':{
      background:'#fff'
  }
},
icon:{
  color:'#0f3d6e',
  fontSize:'20px',
  marginRight:'10px'
},
throw:{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'2px #E3E5E5 solid',
  alignItems:'center',
  fontSize:'13px',
  lineHeight:'16px',
  color:'#fff',
  background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
  padding: 6,
  width:'100%',
  alignItems:'center',
  '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
    width:1200,
           }
},
tdrow:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px #E3E5E5 solid',
    fontSize:'14px',
    padding:'20px 0',
    width:'100%',
    alignItems:'center',
    lineHeight:'15px',
    color:'#000',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
             }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    alignItems:'center',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},
Row1:{
  width:'24.5%',
  paddingRight:'0.5%',
  wordWrap:"break-word",
  //  overflow: "auto"

},
Row2:{
  width:'14.5%',
  paddingRight:'0.5%',
  wordWrap:"break-word",
  // overflow: "auto"
},
Row3:{
  width:'9.5%',
  paddingRight:'0.5%'
},
Row4:{
  width:'14.5%',
  paddingRight:'0.5%'
},
Row5:{
  width:'9.5%',
  paddingRight:'0.5%'
},
Row6:{
  width:'9.5%',
  paddingRight:'0.5%'
},
Row7:{
  width:'14.5%',
  paddingRight:'0.5%',
  display:'flex',
  justifyContent:'flex-end',
  textAlign:'right'
},


   }));