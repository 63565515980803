export const clientAdmin = () => {
    var clientAdminLevel = [
      1, 2, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
      63, 64, 65, 66, 67, 68,
    ];
    return clientAdminLevel;
  };
  
  // export const careteam = () => {
  //   var careteamLevel = [
  //     1, 2, 7, 8, 9,
  
  //     11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
  //     30, 31, 32, 33, 34, 35, 36, 37,
  
  //     39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
  
  //     58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
  //   ];
  //   return careteamLevel;
  // };
  
  export const careteam = () => {
    var careteamLevel = [];
    return careteamLevel;
  };
  export const noAdminAccess = () => {
    var noAdminLevel = [
      1, 2, 7, 8,
  
      9,
  
      11, 12,
  
      14, 15, 16,
  
      18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
  
      36, 37,
  
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
  
      58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
    ];
    return noAdminLevel;
  };
  