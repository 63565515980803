import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import { FiPlusSquare } from "react-icons/fi";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTime } from "react-timer-hook";
import Setting from "../components/Setting";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import TextField from "@material-ui/core/TextField";
import { FaMedkit } from "react-icons/fa";
import LeftNav from "../components/LeftNav";
import Patientinfo from "../components/Patientinfo";
import Timer from "../components/Timer";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import NativeSelect from "@material-ui/core/NativeSelect";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PatientContactInfo from "./ContactInformation/PatientContactInfo";
import EmergencyContact from "./ContactInformation/EmergencyContact";
import CaregiverInfo from "./ContactInformation/CaregiverInfo";
import ResidenceInfo from "./ContactInformation/ResidenceInfo";
import PatientContactInsurance from "./PatientContactInsurance";
import PrimaryProviderInfo from "./PrimaryProviderInfo";
import OtherProviderInfo from "./OtherProviderInfo";
import OtherPrescribingInfo from './OtherPrescribingInfo'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientProvider({ options }) {
  const classes = useStyles();
  const { seconds, minutes, hours, ampm } = useTime({ format: "12-hour" });
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState("panel6");
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };
  // Accordion3 //
  const [expanded4, setExpanded4] = React.useState("panel9");
  const handleChange4 = (panel) => (event, isExpanded4) => {
    setExpanded4(isExpanded4 ? panel : false);
  };
  // tab //
  const [value, setValue] = React.useState("1");

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  // select //
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange5 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // modal //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
          
            <Patientinfo />
            
            {/* left Accordion*/}
            <Box className={classes.TabPatientinfo}>
            <LeftNav />
            </Box>
            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Timer />
          {/* <Box className={classes.btncol} style={{ marginTop: "30px" }}></Box> */}

          {/* tab with accordion */}
          <Box className={classes.Tabcol}>
            <TabContext value={value}>
              <TabPanel value="1">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      Primary Provider Information
                    </Typography>
                  </AccordionSummary>
                  {/* <AccordionDetails> */}
                  <PrimaryProviderInfo />
                  {/* </AccordionDetails> */}
                </Accordion>

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className={classes.heading}>
                      Other Prescribing Provider
                    </Typography>
                  </AccordionSummary>
                  {/* <AccordionDetails> */}
                  <OtherPrescribingInfo />
                  {/* </AccordionDetails> */}
                </Accordion>


                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className={classes.heading}>
                      Other Provider Information
                    </Typography>
                  </AccordionSummary>
                  {/* <AccordionDetails> */}
                  <OtherProviderInfo />
                  {/* </AccordionDetails> */}

                </Accordion>
                
                

              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default PatientProvider;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  TabPatientinfo:{
    '@media only screen and (max-width: 820px)':{
      display:'none'
            },
    '@media only screen and (max-width: 768px)':{
      display:'none'
            },
  },
  Leftcol: {
    width: "22%",
    background: "linear-gradient(45deg, #114ebd, #0f3d6e)",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1000px",
    '@media only screen and (max-width: 1200px)':{
      width: '24%',
      padding:'20px 1%',
            },
            '@media only screen and (max-width: 820px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
            '@media only screen and (max-width: 768px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    '@media only screen and (max-width: 1200px)':{
      width:'72%',
      padding:'0 1%',
            },
            '@media only screen and (max-width: 820px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
            '@media only screen and (max-width: 768px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
    "& .MuiAccordion-rounded": {
      //borderBottom:'1px rgba(0,0,0,0.1) solid',
      background: "none",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      marginBottom: 0,
    },
    "& .MuiAccordionSummary-root": {
      background: "#f3f3f3",
      borderRadius: "10px",
      marginTop: "10px",
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "none",
    },
    '& .MuiAccordionSummary-content.Mui-expanded':{
      margin:'10px 0'
    },
    '& .MuiAccordionSummary-root.Mui-expanded':{
      minHeight:48,
    },
    '& .MuiAccordion-root:before':{
      height:0,
    }
  },

  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "0",
  },

  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  heading: {
    marginBottom: 0,
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  contacttab: {
    background: "#fff",
    boxShadow: "none",
    color: "#000",
    textTransform: "capitalize",
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
    "& .MuiTabs-flexContainer": {
      borderRadius: "10px",
      background: "#F3F3F3",
      width: "360px",
      textTransform: "capitalize",
      overflow: "hidden",
      "& .MuiTab-textColorInherit.Mui-selected": {
        background: "#0f3d6e",
        color: "#ffffff",
      },
      "& .MuiTab-textColorInherit": {
        textTransform: "capitalize",
        fontSize: "16px",
        padding: "0 22px",
      },
      "& .MuiTabPanel-root": {
        padding: "24px 0",
      },
    },
  },
  icon: {
    color: "#0f3d6e",
    fontSize: "20px",
    marginRight: "10px",
  },
}));
