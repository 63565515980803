import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiDownArrowAlt } from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTime } from "react-timer-hook";
import Select from "react-dropdown-select";
import Setting from "../components/Setting";
// import Patientinfo from "../components/Patientinfo";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { BiChevronUp, BiChevronDown, BiErrorCircle } from "react-icons/bi";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Timer from "../components/Timer";
import SymptomsCCQ from "./SymptomsCCQ";
import MedicationCCQ from "./MedicationCCQ";
import HospitalizationCCQ from "./HospitalizationCCQ";
import GoalsCCQ from "./GoalsCCQ";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";

import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateTimer from "../components/UpdateTimer";
import PatientInfoCCQAction from "../components/PatientInfoCCQAction";

function CCQAction({ props, options }) {
  const classes = useStyles();
  const { seconds, minutes, hours, ampm } = useTime({ format: "12-hour" });
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [patientDetails, setPatientDetails] = React.useState("");

  // useEffect(() => {
  //   if (props.history.location.state) {
  //     setPatientDetails(props.history.location.state.patientInfo);
  //   }
  // }, [props.history.location.state]);   

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // const [ccqHist, setCCQHist] = React.useState("");

  // useEffect(() => {

  //   getCCQHist();

   
  // }, []);

  // const getCCQHist = useCallback(
  //   async ({
  //     table_config_rows_per_page = "15",
  //     table_config_page_no = "1",
  //   } = {}) => {
  //     //setCurrentPage(parseInt(table_config_page_no));

  //     setCCQHist([]);
  //     //setMessage("Loading...");
  //     let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

  //     let data = {
  //       table_config_page_no: table_config_page_no,
  //       table_config_rows_per_page: table_config_rows_per_page,
  //       patient_program_id: engage_details.patient_program_id
  //     };

  //     apiAxios
  //       .post("/client/list-ccq-history", data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("Token"),
  //         },
  //       })
  //       .then((response) => {
  //         console.log("response --> ", response);
  //         if (response.data.statusCode == 200) {
  //           setCCQHist(response.data.data);
  //           //setMessage("");
  //           //setTotalPages(response.data.total_pages);

  //           //setCcqCount(response.data.count_ccq);
  //           //setCcqPatCount(response.data.count_patient);
  //         } else if (response.data.statusCode == 502) {
  //           //setMessage("No Data Found");
  //         } else {
  //           toast.error(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });

  //           //setMessage(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         toast.error(error.message, {
  //           position: "bottom-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });

  //         // localStorage.removeItem("Refresh_Token");
  //         // localStorage.removeItem("Token");
  //         // window.location.href = "/";
  //       });
  //   }
  // );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div>
      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            <PatientInfoCCQAction />
            {/* <Box className={classes.ccqhistory}>
              <h5 className={classes.ccqtext} style={{ color: "#88b3eb" }}>
                CCQ History
              </h5>
              { ccqHist ?
              <ul>
                { ccqHist.map( (each) => (
                <li>{ (each.ccq_category == "hospitalizations") ? "Hospitalization" : (each.ccq_category == "symptoms") ? "Symptoms" : (each.ccq_category == "goals") ? "Goals" : "Medications" } <FaArrowAltCircleDown style={{ color: '#1fdf1f', margin: '0 4px'}} />{each.ccq_add_date} <FaArrowAltCircleUp style={{ color: 'red', margin: '0 4px'}} />{each.ccq_remove_date ? each.ccq_remove_date : "--"}</li>
                ))}
              </ul>
              :
              <ul>
                <li>No Data Found</li>
              </ul>
              }
              <Link href="/patient-review">
                <Button
                  style={{
                    color: "#fff",
                    backgroundColor: "#88b3eb",
                    padding: "5px 8px",
                    borderRadius: "10px",
                    textDecoration: "none",
                  }}
                >
                  Return to Care Plan
                </Button>
              </Link>
            </Box> */}
            <Box className={classes.bottomnav}></Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Timer />
         
          {/* <UpdateTimer /> */}
         
          {/* <Box className={classes.Timercol}>
               <Box className={classes.Timer}>
               <Box className={classes.Playpusebtn}><Button className={classes.Playbtn}><Box className={classes.pause}></Box></Button></Box> 
               <Box className={classes.Timerinfo}>    
                <p className={classes.Toptext}>Timer</p>  
                <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                <h6 className={classes.Timertext}>00:23:42/00:20:00</h6>
                </Box>
               </Box>
               <Box className={classes.Addcode}>
                <p className={classes.Toptext}>Add-On Code</p> 
                <h5 className={classes.Bottomtext}>00:20:00 x 2</h5>  
                </Box> 
                <Box className={classes.Finalbtn}>
                    <Button className={classes.Finalizebtn}>Finalize</Button>
                </Box>
                <Box className={classes.Program}>
                    <p className={classes.Toptext}>Program</p>
                    <h5 className={classes.Bottomtext}>CCM IVROS</h5>
                </Box>
                <Box className={classes.Action}><Select options={options} onChange={(values) => this.setValues(values)} placeholder="Action" className={classes.select} /></Box>
               </Box> */}
          {/* <Box className={classes.btncol} style={{ marginTop: "15px" }}>
            <p className={classes.removetext}>Remove from queue</p>
            <Button className={classes.chartbtn} style={{ width: "142px" }}>
              Remedied
            </Button>
          </Box> */}
          <h3 className={classes.topheading}>Clinical Change Queue</h3>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ boxShadow: "none", marginTop: "15px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Symptoms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <SymptomsCCQ />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>
                Hospitalization & Surgeries
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <HospitalizationCCQ />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Medications</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <MedicationCCQ />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className={classes.accSummar}
            >
              <Typography className={classes.heading}>Goals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <GoalsCCQ />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </div>
  );
}

export default CCQAction;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "22%",
    background: "linear-gradient(45deg, #114ebd, #0f3d6e)",
    padding: "20px 3%",
    position: "relative",
    height: "1050px",
    '@media only screen and (max-width: 1200px)':{
      width: '24%',
      padding:'20px 1%',
            },
            '@media only screen and (max-width: 820px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
            '@media only screen and (max-width: 768px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "30px",
    width: "80%",
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
      width: "96%",
            },
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    '@media only screen and (max-width: 1200px)':{
      width:'72%',
      padding:'0 1%',
            },
            '@media only screen and (max-width: 820px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
            '@media only screen and (max-width: 768px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
    "& .MuiAccordionSummary-root": {
      height: "45px",
      color: "#104696",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    '& .MuiAccordionSummary-root.Mui-expanded':{
      minHeight:45,
    }
  },
  accSummar: {
    background: "rgb(243, 243, 243)",
    color: "rgb(15, 61, 110)",
    borderRadius: "10px",
    padding: "4px 10px",
    border: "none",
    margin: "5px 0",
  },
  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#bfc4cb",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  backarrow: {
    color: "#b4bcc9",
    fontSize: "20px",
    marginRight: "8px",
  },
  Downarrow: {
    fontSize: "15px",
  },
  patientinfo: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px #E3E5E5 solid",
    paddingBottom: "10px",
    marginBottom: "13px",
  },
  info: {
    color: "#bfc4cb",
    fontSize: "12px",
    "& span": {
      color: "#0f3d6e",
      fontSize: "16px",
      letterSpacing: "0.496657px",
    },
  },
  Optionsbtn: {
    width: "150px",
    border: "1px #AEAEAE solid",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    float: "right",
  },
  ccqhistory: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0 0 0 25px",
      position: "relative",
      "& li": {
        marginBottom: "15px",
        fontSize: "11px",
        color: "#bfc4cb",
        "&:first-child::before": {
          background: "#bfc4cb",
          content: '""',
        },
        "&::before": {
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          background: "#bfc4cb",
          content: '""',
          position: "absolute",
          left: "1.5px",
          display: "block",
          marginTop: "8px",
        },
      },
      "&::before": {
        position: "absolute",
        width: "1px",
        background: "#AEAEAE",
        content: '""',
        height: "88%",
        left: "5px",
        display: "block",
        top: "10px",
      },
    },
  },
  ccqtext: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#bfc4cb",
  },
  nametext: {
    fontSize: "12px",
    color: "#bfc4cb",
    margin: "40px 0 0",
  },
  name: {
    fontSize: "20px",
    color: "#ffffff",
    margin: "0 0 30px",
  },
  Timercol: {
    width: "96%",
    padding: "10px 2%",
    background: "linear-gradient(to left, #114ebd, #0f3d6e)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  Timer: {
    display: "flex",
    alignItems: "center",
  },
  Toptext: {
    fontSize: "12px",
    color: "#bfc4cb",
    margin: "0px",
  },
  Bottomtext: {
    fontSize: "16px",
    color: "#ffffff",
    margin: "0px",
    fontWeight: "normal",
  },
  Timertext: {
    fontSize: "14px",
    color: "#bfc4cb",
    margin: "0px",
    fontWeight: "normal",
  },
  Playbtn: {
    width: "38px",
    height: "38px",
    background: "#fff",
    borderRadius: "50%",
    display: "flex",
    minWidth: "38px",
  },
  pause: {
    width: "12px",
    height: "12px",
    background: "#000",
  },
  Playpusebtn: {
    marginRight: "10px",
  },
  Finalizebtn: {
    width: "142px",
    background: "#88b3eb",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0f3d6e",
    },
  },
  select: {
    width: "148px !important",
    height: "40px !important",
    border: "1px solid #AEAEAE !important",
    borderRadius: "10px !important",
    background: "#fff !important",
    "& .css-16cm63-DropDown": {
      width: "100% !important",
      top: "30px !important",
    },
  },
  Timerinfo: {
    "& span": {
      fontSize: "14px",
      color: "#fff",
    },
  },
  removetext: {
    fontSize: "13.5px",
    color: "#bfc4cb",
    margin: "0 0 5px",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "0",
  },
  chartbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    padding: "10px 30px",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      background: "#333",
    },
  },
  symptomsdetails: {
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      
      "& li": {
        lineHeight: "40px",
        
        display: "flex",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
         
        },
      },
    },
    width: "100%",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid",
      height: "auto",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
      
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
  Filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  Filtertext: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 7px",
    "& span": {
      color: "#bfc4cb",
      marginRight: "5px",
    },
  },
  topheading: {
    fontSize: "18px",
    fontWeight: "normal",
    color: "#000",
    fontWeight: "500",
  },
}));
