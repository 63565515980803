import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,FormControl,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import Select from "react-dropdown-select"
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import { FaPlusCircle } from "react-icons/fa"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { BiXCircle,BiDownArrowAlt } from "react-icons/bi"
import { AiFillAlert } from "react-icons/ai"
import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css"

import {durationOfPain, levelOfPain, frequencyOfPain, activeStatus} from "../Util/OtherList";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";

import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import { Pagination } from '@material-ui/lab';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function CarePlan() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('false');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      setEditData(panel);
    };
     // Accordion2 //
  const [expanded2, setExpanded2] = React.useState('panel6');
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const [pdId, setPdId] = React.useState("");  

  const [editData, setEditData] = React.useState("");  
  const [showLoader, setShowLoader] = React.useState(false);

    const [symptomsList, setSymptomsList] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [remediedPages, setRemediedPages] = React.useState(1);
    const [remediedTotalPages, setRemediedTotalPages] = React.useState(1);
    const currentPageRef = useRef(1);
    const [message, setMessage] = React.useState("");

//   dropdown menu //
const [open, setOpen] = React.useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);

const [symptom, setSymptom] = React.useState("");
const [description, setDescription] = React.useState("");
const [location, setLocation] = React.useState("");
const [levelofpain, setLevelofpain] = React.useState("");
const [frequency, setFrequency] = React.useState("");
const [duration, setDuration] = React.useState("");
const [worsenedby, setWorsenedby] = React.useState("");
const [relievededby, setRelievedby] = React.useState("");
const [dateStarted, setDateStarted] = React.useState(new Date());
const [dateRemedied, setDateRemedied] = React.useState("");
const [status, setStatus] = React.useState("");
const [notes, setNotes] = React.useState("");

// for remedied symptom start and remedied date compare

const [symptomStartDate, setSymptomStartDate] = React.useState("")
const [symptomEndDate, setSymptomEndDate] = React.useState("")


const [symptomError, setSymptomError] = React.useState(false);
const [descError, setDescError] = React.useState(false);
const [locationError, setLocationError] = React.useState(false);
const [levelError, setLevelError] = React.useState(false);
const [freqError, setFreqError] = React.useState(false);
const [durationError, setDurationError] = React.useState(false);
const [worsenedError, setWorsenedError] = React.useState(false);
const [relievedError, setRelievedError] = React.useState(false);
const [startError, setStartError] = React.useState(false);
const [remediedError, setRemediedError] = React.useState(false);
const [statusError, setStatusError] = React.useState(false);
const [notesError, setNotesError] = React.useState(false);
const [CCQFlag, setCCQFlag] = React.useState("")

//const [addCCQ, setAddCCQ] = React.useState("");


  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    //setEditData(data);
  };
  
  const canBeOpen = open && Boolean(anchorEl);

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setShowLoader(false);
    setEditData("");
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setSymptom("");
    setLocation("");
    setDescription("");
    setWorsenedby("");
    setRelievedby("");
    setNotes("");
    setDateStarted("");
    setDateRemedied("");
    //setEditData("");
    setDuration("");
    setFrequency("");
    setLevelofpain("");
    setStatus("")
  };
  // modal2 //
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen2 = (item) => {
    console.log("inside")
    let freqList = frequencyOfPain();
    for(var i=0;i<freqList.length;i++){
      if(freqList[i].value == item.symptom_frequency){
        setFrequency(freqList[i]);
        console.log("frequency")
        console.log(frequency)
      }
    }
    let levelList = levelOfPain();
    for(var i=0;i<levelList.length;i++){
      if(levelList[i].value == item.symptom_level){
        setLevelofpain(levelList[i]);
      }
    }
    let durationList = durationOfPain();
    for(var i=0;i<durationList.length;i++){
      if(durationList[i].value == item.symptom_duration){
        setDuration(durationList[i]);
      }
    }
    let statusList = activeStatus();
    for(var i=0;i<statusList.length;i++){
      if(statusList[i].value == item.status){
        setStatus(statusList[i]);
      }
    }

    setSymptom(item.symptom_name);
    setLocation(item.symptom_location);
    setDescription(item.symptom_description);
    setWorsenedby(item.symptom_worsened_by);
    setRelievedby(item.symptom_relieved_by);
    setNotes(item.symptom_notes);
    if(item.symptom_start_date){
      var a_date = new Date(item.symptom_start_date);
      setDateStarted(a_date);
    }
    if(item.symptom_stop_date){
      var stop_date = new Date(item.symptom_stop_date);
      setDateRemedied(stop_date);
    }


    setCCQFlag(item.ccq_flag === "1" ? true : "")

    setOpen2(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  useEffect(() => {

    let pat_details = JSON.parse(localStorage.getItem("patient"));
    if(pat_details){
      setPdId(pat_details.pd_id);
      getSymptoms();
      getRemediedSymptoms();
    }
  }, []); 
 

  function handleClickDelete(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));
    let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

    let data = {
      symptoms_id : item.id,
      pd_id: pat_details.pd_id,
      patient_program_id: engage_details.patient_program_id
    }

    apiAxios
    .post("/patient/patient-symptoms-delete", data, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
      },
    })
    .then((response) => {
      console.log("response --> ",response);
      if(response.data.status == 200) {
        toast.success(response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        
        getSymptoms();  
      }
      else {
          toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              }); 
              setShowLoader(false);       
      }
    })
    .catch((error) => {
      console.log(error);

      toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


    });
  }

  function handleClickReactivate(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      symptoms_id : item.id,
      pd_id: pat_details.pd_id
    }

    apiAxios
    .post("/patient/reactive-remedied-symptoms", data, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
      },
    })
    .then((response) => {
      console.log("response --> ",response);
      if(response.data.status == 200) {
        toast.success(response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        
        getSymptoms();  
        getRemediedSymptoms();
      }
      else {
          toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              }); 
              setShowLoader(false);       
      }
    })
    .catch((error) => {
      console.log(error);

      toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


    });
  }

  const deleteModal = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
                handleClickDelete(item);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      }
    });
  };

  const addToCCQ = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to add this record to CCQ?</p>
            <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
                localStorage.setItem("CCQItem", JSON.stringify(item));
                submitForm(3);
                onClose();
              }}
            >
              Yes, Add
            </button>
          </div>
        );
      }
    });
  };

  const reactivateModal = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to reactivate this record?</p>
            <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
                handleClickReactivate(item);
                onClose();
              }}
            >
              Yes, Reactivate
            </button>
          </div>
        );
      }
    });
  };

  const getSymptoms = ()=> {
    

    // setCurrentPage(parseInt(table_config_page_no));

    let pd = JSON.parse(localStorage.getItem("patient"));
         
    setSymptomsList([]);
    setMessage("Loading...");

    let data ={
        table_config_page_no: '1',
        pd_id: pd.pd_id,
        table_config_rows_per_page: '15',
      }

    apiAxios
        .post("/patient/patient-symptoms-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setSymptomsList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  

  const [remediedList, setRemediedList] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [remediedDate, setRemediedDate] = React.useState(new Date());
  const [remediedRelievedby, setRemediedRelievedBy] = React.useState("");
  const [remediedComments, setRemediedComments] = React.useState("");

  const [remediedDateError, setRemediedDateError] = React.useState(false);
  const [remediedRelievedbyError, setRemediedRelievedbyError] = React.useState(false);
  const [remediedCommentsError, setRemediedCommentsError] = React.useState(false);





  const getRemediedSymptoms = useCallback(
    async ({
      table_config_rows_per_page="15",
      table_config_page_no = '1',
    } = {}) => {

    setRemediedPages(parseInt(table_config_page_no));

    let pd = JSON.parse(localStorage.getItem("patient"));
         
    setRemediedList([]);
    setMessage2("Loading...");

    let data ={
        table_config_page_no: table_config_page_no,
        pd_id: pd.pd_id,
        table_config_rows_per_page: table_config_rows_per_page,
      }

    apiAxios
        .post("/patient/list-remedied-symptoms", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setRemediedList(response.data.data); 
              setMessage2("");
              setRemediedTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage2("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage2(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const getMonthDiff = (start, end) => {

      const st = new moment(start); 
      const en = new  moment(end); 

     

      console.log(st)
      console.log(en)

    var diff;

      if(start && end){

      //  diff = moment.duration(en.diff(st)).months();
      diff = en.diff(start, 'months');
       console.log(diff)
      

      }
      if(diff == 0){
        return "< 1";
      }
      else{
        return diff;
      }
  }



  const submitRelieved = () => {
    let isValid = true;

    console.log(symptomStartDate)
    console.log(remediedDate)

    if(remediedDate){

      if(moment(remediedDate).local().format("YYYY-MM-DD")  >= moment(symptomStartDate).local().format("YYYY-MM-DD")){
        setRemediedDateError(false);
      }else {
        setRemediedDateError(true);
        isValid= false;
      }
      
    }
    else{
      setRemediedDateError(false);
    }





    if(remediedRelievedby.trim().length > 0){
      setRemediedRelievedbyError(false);
    }
    else{
      setRemediedRelievedbyError(true);
      isValid= false;
    }

    if(remediedComments.trim().length > 0){
      setRemediedCommentsError(false);
    }
    else{
      setRemediedCommentsError(true);
      isValid= false;
    }
    

    if(isValid){

      setShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let data;
      let url;

        data ={
          pd_id: pat_details.pd_id,
          symptoms_id: needRemedied.id,
          symptom_remedied_date: remediedDate ? moment(remediedDate).format("YYYY-MM-DD") : "",
          remedied_relieved_by: remediedRelievedby,
          additional_comments_remedied: remediedComments
        }
        
        url = "/patient/create-remedied-symptoms";

      console.log("DATA =", data );

    apiAxios
        .post(url, data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            setShowLoader(false);
            setOpenRemedied(false);
            getSymptoms();  
            getRemediedSymptoms();
            window.location.href = "/care-plan-symtoms";  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  setShowLoader(false);       
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });

    }


  }


  const [needRemedied, setNeedRemedied] = React.useState("");
  const [openRemedied, setOpenRemedied] = React.useState(false);

  const remediedModal = (item) => {

    console.log(item.symptom_start_date)
    console.log(item.symptom_stop_date)

    if(item.symptom_start_date){
      var a_date = new Date(item.symptom_start_date);
      
      setSymptomStartDate(a_date)
    }

    if(item.symptom_stop_date){
      var a_date = new Date(item.symptom_stop_date);
     
      setSymptomEndDate(a_date)
    }
    
    


     setNeedRemedied(item); 
     setOpenRemedied(true);      
  }

  const closeRemedied = () => {
    setNeedRemedied("");
    setOpenRemedied(false);
  }


  const submitForm = (flag) => {

    let isValid = true;
    
 
   

    if(flag <3) {

      if(notes){
        if(notes.trim().length > 0 && notes.trim().length <=200){
          setNotesError(false)
        }else {
          setNotesError(true)
          isValid = false;
        }
      }else {
        setNotesError(false)
      }


      if(symptom.trim().length >0 && symptom.trim().length <= 100){
        setSymptomError(false);
      }else{
        setSymptomError(true);
        isValid = false;
      }
      if(description.trim().length > 0 && description.trim().length <= 200){
        setDescError(false);
      }
      else {
        setDescError(true);
        isValid = false;
      }
      if(location.trim().length > 0 && location.trim().length <= 100){
        setLocationError(false);
      }
      else{
        setLocationError(true);
        isValid = false;
      }
      if(levelofpain){
        setLevelError(false);
      }
      else{
        setLevelError(true);
        isValid = false;
      }
      if(frequency){
        setFreqError(false);
      }
      else{
        setFreqError(true);
        isValid = false;
      }
      if(duration){
        setDurationError(false);
      }
      else{
        setDurationError(true);
      }
      if(worsenedby.trim().length > 0 && worsenedby.trim().length <= 200){
        setWorsenedError(false);
      }
      else{
        setWorsenedError(true);
        isValid = false;
      }
      if(relievededby.trim().length > 0 && relievededby.trim().length <= 200){
        setRelievedError(false);
      }
      else{
        setRelievedError(true);
        isValid= false;
      }
      if(dateStarted){
        setStartError(false);
      }

      else{
        setStartError(true);
        isValid = false;
      }

      if(dateRemedied){

     

        if(moment(dateStarted).format("YYYY-MM-DD") <= moment(dateRemedied).format("YYYY-MM-DD")){
          setRemediedError(false)
          
        }else {
          isValid = false;
          setRemediedError(true)
        }
        
      }else {
        setRemediedError(false)
        
      }
      
     console.log("?????????????????????")
     console.log(dateStarted)
     console.log(dateRemedied)


     
      if(status) {
        setStatusError(false);
      }
      else{
        setStatusError(true);
        isValid = false;
      }

    }

    if(isValid){

      setShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));
      let data;
      let url;
      if(flag == 1){
        data ={
          pd_id: pat_details.pd_id,
          patient_program_id: engage_details.patient_program_id,
          symptom_name: symptom,
          symptom_start_date: moment(dateStarted).format("YYYY-MM-DD"),
          symptom_stop_date: dateRemedied? moment(dateRemedied).format("YYYY-MM-DD") : "",
          symptom_description: description,
          symptom_frequency: frequency ? frequency[0].value : "",
          symptom_duration: duration? duration[0].value : "",
          symptom_level: levelofpain ? levelofpain[0].value : "",
          symptom_location: location,
          symptom_seen_anyone: "No",
          symptom_worsened_by: worsenedby,
          symptom_relieved_by: relievededby,
          immediate_action_req: "",
          symptom_notes: notes,
          status: status? status[0].value : "",
          ccq_flag: "0",
          ros_cat_id: "",
          ros_id: "",
          ros_symptoms_id: ""
        }
        
        url = "/patient/patient-symptoms-create";
      }
      else if(flag == 3){

        let addCCQ  = JSON.parse(localStorage.getItem("CCQItem"));

        
        

        data ={
          symptoms_id: addCCQ.id,
          pd_id: pat_details.pd_id,
          patient_program_id: engage_details.patient_program_id,
          symptom_name: addCCQ.symptom_name,
          symptom_start_date: addCCQ.symptom_start_date,
          symptom_stop_date: addCCQ.symptom_stop_date,
          symptom_description: addCCQ.symptom_description,
          symptom_frequency: addCCQ.symptom_frequency,
          symptom_duration: addCCQ.symptom_duration,
          symptom_level: addCCQ.symptom_level,
          symptom_location: addCCQ.symptom_location,
          symptom_seen_anyone: addCCQ.symptom_seen_anyone,
          symptom_worsened_by: addCCQ.symptom_worsened_by,
          symptom_relieved_by: addCCQ.symptom_relieved_by,
          immediate_action_req: "",
          symptom_notes: addCCQ.symptom_notes,
          status: addCCQ.status,
          ccq_flag: "1",
          ros_cat_id: "",
          ros_id: "",
          ros_symptoms_id: ""          
      }
 
      url = "/patient/patient-symptoms-update";

      localStorage.removeItem("CCQItem");
        
      }
      else{
        data ={
            symptoms_id: editData,
            pd_id: pat_details.pd_id,
            patient_program_id: engage_details.patient_program_id,
            symptom_name: symptom,
            symptom_start_date: moment(dateStarted).format("YYYY-MM-DD"),
            symptom_stop_date: dateRemedied ? moment(dateRemedied).format("YYYY-MM-DD") : "",
            symptom_description: description,
            symptom_frequency: frequency.value ? frequency.value : frequency[0].value,
            symptom_duration: duration.value ? duration.value : duration[0].value,
            symptom_level: levelofpain.value ? levelofpain.value : levelofpain[0].value,
            symptom_location: location,
            symptom_seen_anyone: "No",
            symptom_worsened_by: worsenedby,
            symptom_relieved_by: relievededby,
            immediate_action_req: "",
            symptom_notes: notes,
            status: status.value ? status.value : status[0].value,
            ccq_flag: CCQFlag ? "1" : "0",
            ros_cat_id: "",
            ros_id: "",
            ros_symptoms_id: ""          
        }

        url = "/patient/patient-symptoms-update";
      }

      console.log("DATA =", data );

    apiAxios
        .post(url, data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            setShowLoader(false);
            setOpen2(false);
            getSymptoms();  
            window.location.href = "/care-plan-symtoms";  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  setShowLoader(false);       
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });

    }


  }



    return (
        <div>
          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
                <Patientinfo />
                 {/* left Accordion*/}
                 <Box className={classes.TabPatientinfo}>
                <LeftNav />
                </Box>
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Timer />
               {/* <Box className={classes.btncol} style={{ marginTop:'30px'}}></Box> */}
               <Box className={classes.Careplan}>
               <Box className={classes.plantop}>
                   <h3 className={classes.topheading}>Symptoms</h3>
                   
                   <Button className={classes.recordbtn} onClick={handleClickOpen}><FaPlusCircle className={classes.addicon} />Add New Record</Button>
               </Box>
               <Box className={classes.SystomTable}>
               <Box className={classes.throw}> 
                         <Box className={classes.Row1}> Symptom</Box>
                         <Box className={classes.Row2}>Location</Box>
                         <Box className={classes.Row3}>Level of pain</Box>
                         <Box className={classes.Row4}>Frequency</Box>
                         <Box className={classes.Row5}>Duration</Box>
                         <Box className={classes.Row6}>Update Date</Box>
                         <Box className={classes.Row7}>Updated by</Box>
                         <Box className={classes.Row8}>Months Active<br /> Symptom</Box>
                         <Box className={classes.Row9}>Symptom <br />Status</Box>
               </Box> 

               { (symptomsList.length >0) ?
                <>
                {symptomsList.map((each) => (       
               <Accordion expanded={(expanded == each.id)? true : false} onChange={handleChange(each.id)} style={{boxShadow:'none'}}>
                  <AccordionSummary
                    expandIcon={<FaPlusCircle />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                  <Box className={classes.tdrow}> 
                  <Box className={classes.RowCol1}>{each.symptom_name} {(each.ccq_flag == 1)? <AiFillAlert style={{color: 'red', marginLeft: '3px'}} /> : ""}</Box>
                                  <Box className={classes.RowCol2}>{each.symptom_location}</Box>
                                  <Box className={classes.Row3}>{each.symptom_level}</Box>
                                  <Box className={classes.Row4}>{each.symptom_frequency}</Box>
                                  <Box className={classes.Row5}>{each.symptom_duration}</Box>
                                  <Box className={classes.Row6}>{each.updated_at ? moment.utc(each.updated_at).local().format('MM/DD/YYYY, h:mm a') : "--"}</Box>
                                  <Box className={classes.Row7}>{each.updated_by? each.updated_by : "--"}</Box>
                                  <Box className={classes.Row8}>{ each.symptom_stop_date ? getMonthDiff(each.symptom_start_date, each.symptom_stop_date) : getMonthDiff(each.symptom_start_date, moment(new Date()).format("YYYY/MM/DD")) }</Box>
                                  <Box className={classes.Row9}><Link to="">{(each.status == 1)? "Active" : "Inactive"}</Link></Box>
                                  </Box>
                  </AccordionSummary>
                  <AccordionDetails style={{marginTop: '12px'}}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} sm={9}>
                      <ul className={classes.Plandetails}>
                          <li><span>Description</span> {each.symptom_description}</li>
                          <li><span>Triggered or<br /> worsened by</span> {each.symptom_worsened_by}</li>
                          <li><span>Relieved by</span> {each.symptom_relieved_by}</li>
                          <li><span>Date Started</span>{each.symptom_start_date}</li>
                          <li><span>Date<br /> Remedied</span>{each.symptom_stop_date}</li>
                          <li><span>Notes</span>{each.symptom_notes}</li>
                      </ul>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <Button className={classes.Optionsbtn}
                    aria-describedby={canBeOpen? each.id : undefined}
                    onClick={handleClick}
                  >
                    Options <BiChevronDown className={classes.Downarrow} />
                  </Button>
                  {(each.id == editData)?
                    <Popper id={canBeOpen? each.id : editData} open={open} anchorEl={anchorEl} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={150}>
                          <Paper style={{ boxShadow:'none',width:'150px', border:'1px #AEAEAE solid', borderRadius:'10px'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow">
                                { (each.ccq_flag == 1) ?
                                <></>
                                :
                                <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => addToCCQ(each)}>Add To CCQ</MenuItem>
                                }
                                <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => handleClickOpen2(each)}>Edit</MenuItem>
                                <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => deleteModal(each)}>Delete</MenuItem>
                                <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => remediedModal(each)}>Remedied</MenuItem>
                              </MenuList> 
                            </ClickAwayListener>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                    :
                    <></>
                    }
                  </Grid>
                  </Grid>
                  </AccordionDetails>
                </Accordion>
                ))}
                </>
                :
                <Box style={{textAlign:'center', margin:'5%'}}>{message}</Box>
                }
                </Box>
      </Box>
      <br /> <br />

      <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getSymptoms({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 

      <Accordion expanded={expanded2 === 'panel9'} onChange={handleChange3('panel9')} style={{boxShadow:'none', marginTop: '5%'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{background: '#f3f3f3', color: '#0f3d6e', borderRadius: '10px', padding:'4px 10px', border:'none'}}
        >
          
          <Typography className={classes.heading}>Remedied Symptoms</Typography>
          
        </AccordionSummary>
        
        <AccordionDetails>
        <Grid item xs={12} sm={12}>
        <Box className={classes.SystomTable}>
           
        <Box className={classes.throw}> 
                         <Box className={classes.Row1}>Symptom</Box>
                         <Box className={classes.Row2}>Relieved by</Box>
                         <Box className={classes.Row3}>Start Date</Box>
                         <Box className={classes.Row4}>Remedied Date</Box>
                         <Box className={classes.Row5} style={{width:'25%'}}>Comments</Box>
                         <Box className={classes.Row8}>Actions</Box>
               </Box> 

               { (remediedList.length >0) ?
                <>
                {remediedList.map((each) => (       
                  <Box className={classes.tdrow}> 
                                  <Box className={classes.Row1}>{each.symptom_name}</Box>
                                  <Box className={classes.Row2}>{each.remedied_relieved_by}</Box>
                                  <Box className={classes.Row3}>{each.symptom_start_date}</Box>
                                  <Box className={classes.Row4}>{each.symptom_remedied_date}</Box>
                                  <Box className={classes.Row5} style={{width:'25%'}}>{each.additional_comments_remedied}</Box>
                                  <Box className={classes.Row8} style={{color: '#88b3eb', cursor: "pointer"}}><span onClick={() => reactivateModal(each)}><FaPlusCircle style={{marginRight: '4px'}} />Reactivate</span></Box>
                  </Box>
                ))}
                </>
                :
                <Box style={{textAlign:'center', margin:'5%'}}>{message2}</Box>
                }
          
          
        </Box>
        {/* <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {remediedTotalPages}
                        color="primary"
                        page={remediedPages}
                        onChange={(event, pageNumber) =>
                          getSymptoms({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div>  */}
        </Grid>
        
        
        </AccordionDetails>
      </Accordion>               
                     
                   {/* tab with accordion */}
                    
               </Box>
               {/* modal */}
                <Dialog  className={classes.modal}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        disableEnforceFocus={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <h3 style={{ margin: "0 0 40px", textAlign: "left",}}>Add/Update Symptoms</h3>
            <Button onClick={handleClose2} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          
              <form>
            <ul className={classes.Editplan}>
                <li><span>Symptom<span style={{color:'#ff0000'}}>*</span></span><TextField placeholder='Add Symptoms here' className={classes.input} onChange={(e) => setSymptom(e.target.value)}
                        value={symptom ? symptom : ""}
          type="text"
        /></li>
        {symptomError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Symptom is mandatory(max character limit is 100)
                </p>
              ) : (
                <></>
              )}
                <li><span>Description<span style={{color:'#ff0000'}}>*</span></span> <TextareaAutosize placeholder='Write some description....' onChange={(e) => setDescription(e.target.value)} className={classes.textarea} aria-label="minimum height" minRows={4} value={description?description : ""} /></li>
                {descError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Add Description(max character length is 200)
                </p>
              ) : (
                <></>
              )}
                <li><span>Location<span style={{color:'#ff0000'}}>*</span></span> <TextField placeholder='Mention symptoms location here' className={classes.input} onChange={(e) => setLocation(e.target.value)}
                        value={location ? location : ""}  type="text" /></li>
                {locationError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Location is mandatory(max character limit is 100)
                </p>
              ) : (
                <></>
              )}        
                <li><span>Level of pain<span style={{color:'#ff0000'}}>*</span></span><FormControl style={{width:'100%'}}> <Select
                          options={levelOfPain()}
                          value={levelofpain? levelofpain : ""}
                          onChange={setLevelofpain}
                          placeholder={levelofpain? levelofpain.label : "Select level of pain"}
                          className={classes.datepicker}
                        /></FormControl></li>
                {levelError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Level of pain is mandatory.
                </p>
              ) : (
                <></>
              )}        
                <li><span>Frequency of Symptom<span style={{color:'#ff0000'}}>*</span></span><FormControl style={{width:'100%'}}> <Select
                          options={frequencyOfPain()}
                          value={frequency? frequency : ""}
                          onChange={setFrequency}
                          placeholder={frequency? frequency.label : "Select frequency of Symptom"}
                          className={classes.selectMax}
                        /></FormControl></li>
                {freqError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Frequency is mandatory.
                </p>
              ) : (
                <></>
              )}                
                <li><span>Durations<span style={{color:'#ff0000'}}>*</span></span> <FormControl style={{width:'100%'}}> <Select
                          options={durationOfPain()}
                          value={duration? duration : ""}
                          onChange={setDuration}
                          placeholder={duration? duration.label : "Select duration of pain"}
                          className={classes.datepicker}
                        /></FormControl></li>
                {durationError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Duration is mandatory.
                </p>
              ) : (
                <></>
              )}

                <li><span>Triggered or<br /> worsened by<span style={{color:'#ff0000'}}>*</span></span><TextareaAutosize placeholder='Mention how it triggers or worsens' className={classes.textarea}  minRows={4} onChange={(e) => setWorsenedby(e.target.value)}  value={worsenedby ? worsenedby : ""} /></li>
                {worsenedError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Triggered or worsened by is mandatory(max character limit is 200)
                </p>
              ) : (
                <></>
              )}
                <li><span>Relieved by<span style={{color:'#ff0000'}}>*</span></span><TextareaAutosize placeholder='Mention how it relieves' className={classes.textarea} minRows={4} onChange={(e) => setRelievedby(e.target.value)}  value={relievededby ? relievededby : ""} /></li>
                {relievedError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Relieved by is mandatory(max character limit is 200)
                </p>
              ) : (
                <></>
              )}
                <li><span>Date Started<span style={{color:'#ff0000'}}>*</span></span> 
                <DatePicker className={classes.input} maxDate={moment().toDate()} clearIcon={null} value={dateStarted} onChange={(date) => setDateStarted(date)} />
                        </li>
                {startError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Start date is mandatory.
                </p>
              ) : (
                <></>
              )}        
                <li><span>Date Remedied</span> 
                <DatePicker className={classes.input}  maxDate={moment().toDate()} clearIcon={null} value={dateRemedied} onChange={(date) => setDateRemedied(date)} />
                        </li>

                        {remediedError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Please enter valid stop date
                </p>
              ) : (
                <></>
              )} 


                <li><span>Status<span style={{color:'#ff0000'}}>*</span></span><FormControl style={{width:'100%'}}> <Select
                          options={activeStatus()}
                          value={status? status : ""}
                          onChange={setStatus}
                          placeholder={status? status.label : "Select status"}
                          className={classes.datepicker}
                        /></FormControl></li>
                {statusError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Status is mandatory.
                </p>
              ) : (
                <></>
              )}        
                <li><span>Notes</span><TextareaAutosize className={classes.textarea} onChange={(e) => setNotes(e.target.value)} aria-label="minimum height" minRows={4} value={notes?notes:""} placeholder="Notes(if any)" /></li>
                {notesError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Max character length is 200
                </p>
              ) : (
                <></>
              )} 
            </ul>
            
            </form>
          </DialogContentText>
        </DialogContent>
        {editData?
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={() => submitForm(2)}>
                        Update
                    </Button>
                )
        }
        </DialogActions>
        :
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={() => submitForm(1)}>
                        Submit
                    </Button>
                )
        }
        </DialogActions>
        }
                </Dialog>

        {/* modal */}
        <Dialog  className={classes.modal}
        open={openRemedied}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeRemedied}
        disableEnforceFocus={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      ><form>
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <Button onClick={closeRemedied} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Box className={classes.Filter}>
          <h3 className={classes.Toptext}>Remedied Symptom</h3>
        </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
        
        <Box className={classes.symptomsdetails}>
            <ul className={classes.Editplan}>
            <li><label style={{width:'40%'}}>Approximate date remedied</label> 
                <Box className={classes.startDate} style={{marginLeft: '10px'}}>
                  <DatePicker className={classes.input}  clearIcon={null} value={remediedDate} maxDate={moment().toDate()} onChange={(date) => setRemediedDate(date)} />
               </Box>
               
                        </li>
                        {remediedDateError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          textAlign: 'right'
                        }}
                      >
                        Remedied Date can't be less than Symptom Start Date
                      </p>
                    ) : (
                      <></>
                    )}
              <li><span>Relieved by<span style={{color:'#ff0000'}}>*</span></span><TextareaAutosize placeholder='Mention how it get relieved' className={classes.textarea} onChange={(e) => setRemediedRelievedBy(e.target.value)}
                        value={remediedRelievedby}
                    type="text"
                  />
              </li>
              {remediedRelievedbyError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          textAlign: 'right'
                        }}
                      >
                        Relieved by is mandatory.
                      </p>
                    ) : (
                      <></>
                    )}

                  <li><span>Additional Comments<span style={{color:'#ff0000'}}>*</span></span><TextareaAutosize placeholder='Write some additional comments..'  className={classes.textarea} onChange={(e) => setRemediedComments(e.target.value)}
                        value={remediedComments}
                    type="text"
                  />
              </li>
              {remediedCommentsError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          textAlign: 'right'
                        }}
                      >
                        Additional comment is mandatory.
                      </p>
                    ) : (
                      <></>
                    )}  
            </ul>
        </Box>
        </Grid>
        
        </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={submitRelieved}>
                        Confirm
                    </Button>
                )
        }
        </DialogActions>
        
        </form>
      </Dialog>
            </Box>

           

        </div>
    )
}

export default CarePlan
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    TabPatientinfo:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
              },
      '@media only screen and (max-width: 768px)':{
        display:'none'
              },
    },
    Leftcol:{
        width: '22%',
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
                '@media only screen and (max-width: 768px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    plantop:{
      display:'flex',
      justifyContent:'space-between',
      marginBottom:'30px',
      marginTop:'20px',
      '@media only screen and (max-width: 820px)':{
        marginBottom:'0px',
        marginTop:'10px',
              },
      '@media only screen and (max-width: 768px)':{
        marginBottom:'0px',
        marginTop:'10px',
              },
  },
  recordbtn:{
      background:'#fff',
      fontSize:'16px',
      color:'#0f3d6e',
      textTransform:'capitalize',
      '&:hover':{
          background:'#fff'
      }
  },
  addicon:{
      fontSize:'20px',
      color:'#0f3d6e',
      marginRight:'10px'
  },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
    },
    Rightcol:{
        width:'78%',
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
          width:'72%',
          padding:'0 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
                '@media only screen and (max-width: 768px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #e3e5e5 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minHeight:'40px'
        }
    },
    Downarrow:{
      fontSize:'15px'
    },   
Careplan:{
    width:'100%',
    '& .MuiAccordionSummary-root':{
        borderBottom:'1px #e3e5e5 solid',
        height:'40px',
        color:'#bfc4cb',
        padding:'0px',
        fontFamily:'Poppins',
    },
    '& .MuiAccordion-root.Mui-expanded':{
        margin:'0px'
    },
    '& .MuiAccordionSummary-content':{
        margin:'0',
        order:'2',
        width:'100%'
    },
    '& .MuiIconButton-root':{
        padding:'5px',
        fontSize:'16px',
        marginRight:'5px',
        color:'#88b3eb',
        position:'absolute',
        left:'-25px'
    },
    '& .MuiAccordion-root:before':{
        display:'none'
    }
    },   
topheading:{
  fontSize:'16px',
  fontWeight:'normal',
  color:'#000'
},
Filter:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:'25px'
},
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Filtertext:{
    display:'flex',
    alignItems:'center',
    margin:'0 0 7px',
    '& span':{
        color:'#bfc4cb',
        marginRight:'5px'
    }
},
Dateinput:{
  borderRadius:'10px',
  height:'42px',
  width:'97%',
  fontFamily:'Poppins',
  fontSize:'12px',
  background:'#F9F9F9',
  border:'1px solid #D5D5D5',
  paddingLeft:15,
  '&:focus':{
    border:'1px solid #D5D5D5 !important',
  }
},
input: {
  border: "none",
  borderRadius: "10px",
  height: "50px",
  width: "100%",
},
Toptext:{
  margin:'0px',
  fontSize:'16px'
},
contacttab:{
  background:'#fff',
  boxShadow:'none',
  color:'#000',
  textTransform:'capitalize',
  '& .MuiTabs-indicator':{
    display:'none !important'
  },
  '& .MuiTabs-flexContainer':{
    borderRadius:'10px',
    background:'#F3F3F3',
    width:'360px',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTab-textColorInherit.Mui-selected':{
      background:'#0f3d6e',
            color:'#ffffff'
    },
    '& .MuiTab-textColorInherit':{
      textTransform:'capitalize',
      fontSize:'16px',
      padding:'0 22px'
    }
  },
  
},
datepicker:{
  borderRadius:'10px !important',
    height:'45px',
    width:'96%',
    fontFamily:'Poppins',
    fontSize:'14px',
    background:'#F9F9F9',
    border:'1px solid #D5D5D5 !important',
    paddingLeft:'15px !important',
    '&:focus':{
      border:'1px #0f3d6e solid !important',
    }
},
selectMax: {
  width: "100%",
  border: "none !important",
  borderRadius: "10px !important",
  border: "1px #D5D5D5 solid",
  backgroundColor: "#F9F9F9",
  height: "50px",
  fontSize: "18px !important",
  paddingLeft: "15px !important",
  paddingRight: "15px !important",
  '& .react-dropdown-select-content':{
    '& span':{
        fontSize:11
    }
           }
 
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},
icon:{
  color:'#0f3d6e',
  fontSize:'20px',
  marginRight:'10px'
},
throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'2px #E3E5E5 solid',
    alignItems:'center',
    fontSize:'13px',
    lineHeight:'16px',
    color:'#ffffff',
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
    padding: 6,
    width:'98%',
    alignItems:'center',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
            }
},
tdrow:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'0px #E3E5E5 solid',
    fontSize:'12px',
    padding:'10px 5px',
    width:'98%',
    alignItems:'center',
    lineHeight:'15px',
    color:'#000',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
            }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},
Row1:{
    width:'14.5%',
    paddingRight:'0.5%',
    wordWrap:"break-word",
},
RowCol1:{
  width:'14.5%',
  paddingRight:'0.5%',
  wordWrap:"break-word",
},
Row2:{
    width:'14.5%',
    paddingRight:'0.5%',
    wordWrap:"break-word",
},
RowCol2:{
  width:'14.5%',
  paddingRight:'0.5%',
  wordWrap:"break-word",
},
Row3:{
    width:'11.5%',
    paddingRight:'0.5%'
},
Row4:{
    width:'9.5%',
    paddingRight:'0.5%'
},
Row5:{
    width:'9.5%',
    paddingRight:'0.5%',
    wordWrap:"break-word",
},
Row6:{
    width:'11.5%',
    paddingRight:'0.5%'
},
Row7:{
    width:'9.5%',
    paddingRight:'0.5%',
    display:'flex',
    justifyContent:'center',
    textAlign:'right',
    wordWrap:"break-word",
},
Row8:{
    width:'11.5%',
    paddingRight:'0.5%',
    display:'flex',
    justifyContent:'flex-end',
    textAlign:'right'
},
Row9:{
    width:'9.5%',
    paddingRight:'0.5%',
    textAlign:'right'
},
Plandetails:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        fontSize:'12px',
        marginBottom:'25px',
        display:'flex',
        '& span':{
            flex:'0 0 110px',
            color:'#114ebd'
        }
    }
},
modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'776px',
        maxWidth:'776px'
    }
},
ccmmodal:{
    borderRadius:'10px',
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
},
modalbtn:{
    display:'flex',
    justifyContent:'flex-end',
    marginRight:'30px'
},
Optionsbtn:{
    width:'150px',
    border:'1px #AEAEAE solid',
    borderRadius:'10px',
    display:'flex',
    justifyContent:'space-between',
    textTransform:'capitalize',
    float:'right'
},
input:{ 
      
    border:'none',
    borderRadius:'10px',
    height:'42px',
    width:'100%',
    fontFamily:'Poppins',
    fontSize:'12px'
},
textarea:{
    width:'100%',
    borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'10px 15px',
        fontFamily:'Poppins',
        fontSize:'12px'
},
Editplan:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        display:'flex',
        fontSize:'14px',
        alignItems:'center',
        marginBottom:'15px',
        '& span':{
            flex:'0 0 120px',
            color:'#121212',
            maxHeight: '50px'
        }
    },
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'42px',
        
    },
    '& .MuiInputBase-input':{
        height:'42px',
        borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'0 15px',
        fontFamily:'Poppins',
        fontSize:'12px',
        color:'#000'
    },
    '& .MuiInput-input:focus':{
      border:'1px #0f3d6e solid',
      boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
  }
},
loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
},
btncol:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
},
closebtn:{
    width:'40px',
    position:'absolute',
    right:'10px',
    height:'40px',
    background:'#fff',
    top:'10px',
    minWidth:'40px',
    '&:hover':{
        background:'#fff'
    }
},
closeicon:{
    fontSize:'25px',
    color:'#0f3d6e'
},
topheading:{
  fontSize:'16px',
  fontWeight:'normal',
  color:'#000'
},
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px'
},
Filtertext:{
    display:'flex',
    alignItems:'center',
    margin:'0 0 7px',
    '& span':{
        color:'#bfc4cb',
        marginRight:'5px'
    }
},
symptomsdetails:{
  '& .MuiInput-underline:before':{
    display:'none'
  },
  '& .MuiInput-underline:after':{
    borderBottom:'1px #ccc solid'
  },
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        '& li':{
            lineHeight:'24px',
            display:'flex',
            fontSize:'15px',
            '& p':{
                borderLeft:'4px #0f3d6e solid',
                margin:'0px',
                paddingLeft:'10px',
                color:'#bfc4cb'
            },
            '& span':{
                color:'#000',
                fontSize:'16px',
                flex:'0 0 100px'
            }
        }
    },
    width: "100%",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid",
      height: "auto",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
},
bottomtext:{
  fontSize:'14px',
  color:'#AEAEAE',
  textAlign:'right',
  fontWeight:'400'
},
loginform:{
  width:'100%',
  '& .MuiInput-underline:before':{
      display:'none'
  },
  '& .MuiInput-underline:after':{
      display:'none'
  },
  '& .MuiInput-formControl':{ 
      height:'45px', 
  },
  '& .react-dropdown-select-input':{
    fontSize:14,
  },
  '& .react-datepicker-ignore-onclickoutside:focus':{
    border:'1px #0f3d6e solid !important',
  },
  '& .MuiInputBase-input':{
      height:'45px',
      borderRadius:'10px',
      background:'#F9F9F9',
      padding:'0 15px'
  },
  '& .MuiInput-input:focus':{
    border:'1px #0f3d6e solid',
    boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
}
},
Careplan: {
  width: "100%",
  "& .MuiAccordionSummary-root": {
    borderBottom: "1px #e3e5e5 solid",
    height: "auto",
    color: "#bfc4cb",
    padding: "0px",
    fontFamily: "Poppins",
  },
  "& .MuiAccordion-root.Mui-expanded": {
    margin: "0px",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0",
    order: "2",
    width: "100%",
  },
  "& .MuiIconButton-root": {
    padding: "5px",
    fontSize: "16px",
    marginRight: "5px",
    color: "#88b3eb",
    position: "absolute",
    left: "-25px",
  },
  "& .MuiAccordion-root:before": {
    display: "none",
  },
},
SystomTable:{
  width:'100%',
  '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
   overflowX:'auto'
          }
}
   }));