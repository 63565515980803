import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTime } from 'react-timer-hook'
import Setting from '../components/Setting'
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import { Pagination } from '@material-ui/lab';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";


function CareplanNote({ options }) {
    const classes = useStyles();
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});
    const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState('panel6');
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };
  // Accordion3 //
  const [expanded4, setExpanded4] = React.useState('panel9');
  const handleChange4 = (panel) => (event, isExpanded4) => {
    setExpanded4(isExpanded4 ? panel : false);
  };
  // tab //
  const [value, setValue] = React.useState('1');

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

    const [noteList, setNoteList] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
    const [message, setMessage] = React.useState("");

    const [newNote, setNewNote] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const [pdId, setPdId] = React.useState("");
    const [cpatid, setCpatid] = React.useState("");

    const [orderby, setOrderby] = React.useState(0);

  useEffect(() => {
      let pat_details = JSON.parse(localStorage.getItem("patient"));
      if(pat_details){
        setPdId(pat_details.pd_id);
        setCpatid(pat_details.cpatp_id);
        getNotes();
      }
  }, []); 

  const changeOrderBy = () => {
    if(orderby == 1){
      setOrderby(0);
      getNotes();
    }
    else{
      setOrderby(1);
      getNotes();
    }
  }

  const getNotes = useCallback(
    async ({
      table_config_rows_per_page = '10',
      table_config_page_no = '1',
    } = {}) => {

    setCurrentPage(parseInt(table_config_page_no));

    let pat_details = JSON.parse(localStorage.getItem("patient"));
        
    setNoteList([]);
    setMessage("Loading...");

    let data ={
        table_config_page_no: table_config_page_no,
        status: '1',
        table_config_rows_per_page: table_config_rows_per_page,
        pd_id: pat_details.pd_id,
        order_by_date: ""+orderby
      }

    apiAxios
        .post("/patient/list-patient-notes", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setNoteList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const [notesError, setNotesError] = React.useState("");

  const submitNotes = () => {
    console.log(newNote.length)

    let isValid = true;
    
    if(newNote.trim().length > 0 && newNote.trim().length <=500) {
      setNotesError(false);
    }
    else{
      isValid = false;
      setNotesError(true);
    }


    if(isValid){

      setLoading(true);

      let data ={
        pd_id: pdId,
        client_patient_program_id: cpatid,
        content: newNote,
        status: "1",
        is_global_note: "1"
        
      }

        apiAxios
        .post("/patient/create-patient-notes", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

            setNewNote("");  
            setLoading(false);
            getNotes();  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              
                  setMessage(response.data.message);   
                  setLoading(false); 
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        


        });
    }



  }

    return (
        <div>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
                <Patientinfo />
                 {/* left Accordion*/}
                 <Box className={classes.TabPatientinfo}>
                <LeftNav />
                </Box>
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               <Timer />
               <Box className={classes.btncol} style={{ marginTop:'10px'}}>
                   
                   </Box>
                   <h3 className={classes.topheading}>Careplan Notes</h3>
                   {/* tab with accordion */}
                   <Box className={classes.Tabcol}>
                   <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.mainAccordion}
        >
          <Typography className={classes.heading}>Patient Instructions</Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
        <Box className={classes.Filter}>
        <p className={classes.Filtertext}><span>Sort by :</span> Date {(orderby==1) ? <BiUpArrowAlt onClick={changeOrderBy} className={classes.Downarrow} /> : <BiDownArrowAlt onClick={changeOrderBy} className={classes.Downarrow} />}</p>
        </Box>
        </Grid>
        <Grid item xs={12} sm={11}>
        
        <Box className={classes.symptomsdetails}>
          {(noteList.length>0) ?   
           <ul>
              {noteList.map((each) => ( 
                <>
               <p className={classes.Date}>{moment.utc(each.created_at).local().format('MMMM Do YYYY')}</p>
               <li>
                   <span>{moment.utc(each.created_at).local().format('HH:MM a')}</span> <p style={{wordWrap:"break-word", overflow:"auto"}}>{each.content}</p>
                   
                   </li>

                   <h6 className={classes.bottomtext}>Added by : {each.added_by}</h6>
                </>
              ))}
              <Pagination
                count= {totalPages}
                color="primary"
                page={currentPage}
                onChange={(event, pageNumber) =>
                  getNotes({
                    table_config_page_no: `${pageNumber}`,
                  })
                }
                style={{float: 'right', margin: '10px 0'}}
              />
           </ul>
           :
           <Box style={{textAlign: 'center', padding:'5%'}}>
             <p>{message}</p>
           </Box>
          }
           <TextareaAutosize className={classes.textarea} aria-label="minimum height" maxLength={500} minRows={4} value={newNote? newNote : ""} onChange={(e) => {setNewNote(e.target.value); setNotesError(false); }} placeholder="Add your notes here" />
           <p style={{textAlign: 'left', fontSize: '0.8rem', margin: '0'}}>{newNote.length} / 500</p>
           {notesError ? (
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    marginTop: "0.25rem",
                    textAlign: 'right'
                  }}
                >
                  Note is mandatory(max character limit is 500)
                </p>
              ) : (
                <></>
              )}
           <Box className={classes.modalbtn}>
           
           {loading ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : ( 
                  <Button className={classes.loginbtn} style={{color:'#fff', marginTop: '0'}} onClick={submitNotes}>
                      Update
                  </Button>
                )
          }
        </Box>
        </Box>
        </Grid>
        
        </Grid>
        
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={classes.mainAccordion}
        >
          <Typography className={classes.heading}>CCQ Summary</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No Data Available
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className={classes.mainAccordion}
        >
          <Typography className={classes.heading}>Additional Patient Notes</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No Data Available
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      
                  </Box> 
               </Box>
            </Box>
        </div>
    )
}

export default CareplanNote
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    TabPatientinfo:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
              },
      '@media only screen and (max-width: 768px)':{
        display:'none'
              },
    },
    Leftcol:{
        width: '22%',
background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
                '@media only screen and (max-width: 768px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
    },
    modalbtn:{
        display:'flex',
        justifyContent:'flex-end',
        width:'100%'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    mainAccordion: {
      background: '#f3f3f3', 
      color: '#0f3d6e !important', 
      borderRadius: '10px', 
      padding:'4px 10px !important', 
      border:'none !important',
      marginTop: '10px !important'
    },
    loginbtn:{
        background:'#0f3d6e',
        padding:'0 40px',
        width:'120px',
        height:'40px',
        borderRadius:'10px',
        color:'#fff !important',
        marginTop:'20px',
        textTransform:'capitalize',
        '&:hover':{
            background:'#333'
        }
    },
    Date:{
        fontSize:'14px',
        color:'#bfc4cb',
        marginBottom:'30px',
        marginTop:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
    },
    Rightcol:{
        width:'78%',
        overflow: "auto",
        padding:'20px 3%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 1200px)':{
          width:'72%',
          padding:'0 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
                '@media only screen and (max-width: 768px)':{
                  width:'96%',
                  padding:'0 2%',
                  marginTop:60,
                        },
        '& .MuiTabPanel-root':{
          padding:'20px 0'
        },
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minHeight:'40px'
        },
        '& .MuiAccordionSummary-root.Mui-expanded':{
          minHeight:45,
        }
    },
    
    ccqhistory:{
        '& ul':{
            listStyle:'none',
            margin:'0px',
            padding:'0 0 0 25px',
            position:'relative',
            '& li':{
                marginBottom:'15px',
                color:'#0f3d6e',
                '&:first-child::before':{
                    background:'#0f3d6e',
                    content:'""',
                },
                '&::before':{
                width:'10px',
                height:'10px',
                borderRadius:'50%',
                background:'#0f3d6e',
                content:'""',
                position:'absolute',
                left:'0px',
                display:'block',
                marginTop:'8px'
                }
            },
            '&::before':{
                position:'absolute',
                width:'1px',
                background:'#AEAEAE',
                content:'""',
                height:'88%',
                left:'5px',
                display:'block',
                top:'10px'
            }
        }
    },
    ccqtext:{
        fontSize:'12px',
        fontWeight:'normal',
        color:'#bfc4cb'
    },
   
    removetext:{
       fontSize:'13.5px',
       color:'#bfc4cb',
       margin:'0 0 5px', 
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        flexDirection:'column',
        alignItems:'flex-end',
        marginTop:'0'
   },  
   chartbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 30px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'14px',
    cursor:'pointer',
    '&:hover':{
        background:'#333'
    }
},
symptomsdetails:{
  '& .MuiInput-underline:before':{
    display:'none'
  },
  '& .MuiInput-underline:after':{
    borderBottom:'1px #ccc solid'
  },
    '& ul':{
        listStyle:'none',
        margin:'0px',
        padding:'0px',
        '& li':{
            lineHeight:'24px',
            display:'flex',
            fontSize:'15px',
            '& p':{
                borderLeft:'4px #0f3d6e solid',
                margin:'0px',
                paddingLeft:'10px',
                color:'#0f3d6e'
            },
            '& span':{
                color:'#000',
                fontSize:'16px',
                flex:'0 0 100px'
            }
        }
    }
},
bottomtext:{
    fontSize:'14px',
    color:'#88b3eb',
    textAlign:'right',
    fontWeight:'400'
},
topheading:{
  fontSize:'16px',
  fontWeight:'normal',
  color:'#000'
},
Filter:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    alignItems:'flex-end'
},
Downarrow:{
    fontSize:'20px',
    color:'#0f3d6e',
    marginLeft:'5px',
    cursor: 'pointer'
},
Filtertext:{
    display:'flex',
    alignItems:'center',
    margin:'0 0 7px',
    '& span':{
        color:'#bfc4cb',
        marginRight:'5px'
    }
},
contacttab:{
  background:'#fff',
  boxShadow:'none',
  color:'#000',
  textTransform:'capitalize',
  '& .MuiTabs-indicator':{
    display:'none !important'
  },
  '& .MuiTabs-flexContainer':{
    borderRadius:'10px',
    background:'#F3F3F3',
    width:'360px',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTab-textColorInherit.Mui-selected':{
      background:'#0f3d6e',
            color:'#ffffff'
    },
    '& .MuiTab-textColorInherit':{
      textTransform:'capitalize',
      fontSize:'16px',
      padding:'0 22px'
    }
  },
  
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#0f3d6e',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#0f3d6e',
    color:'#fff',
  }
},

icon:{
  color:'#0f3d6e',
  fontSize:'20px',
  marginRight:'10px'
},
textarea:{
    width:'100%',
    borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'10px 15px',
        fontFamily:'Poppins',
        fontSize:'14px'
},  
   }));