import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import Drawer from '@material-ui/core/Drawer'
  import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiArrowBack,BiClipboard, BiSearch,BiCheckSquare} from "react-icons/bi"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiAxios } from "../Util/ApiAxios"
import LeftNav from './LeftNav'

function Patientinfo(props) {
    const classes = useStyles();

    const [patDetails, setPatDetails] = useState("");
    const [engageDetails, setEngageDetails] = useState("");
    const [engageActive, setEngageActive] = useState(false);

    console.log(props)

    useEffect(() => {

     
        
        if(props.patDetails){
            setPatDetails(props.patDetails);
            localStorage.setItem("patient", JSON.stringify(props.patDetails));
        }
        else if(!props.patDetails && localStorage.getItem("patient")){
            setPatDetails( JSON.parse(localStorage.getItem("patient")) );
            setEngageDetails( JSON.parse(localStorage.getItem("engagement_timer")) );
        }
        else {
            
        }

        let engagement = JSON.parse(localStorage.getItem("engagement_timer"));

        if(engagement){
            setEngageActive(true);
        }

        // console.log("PAT DET - ", patDetails);
        
      }, [props]);  

      function backButton() {
        localStorage.removeItem("patient");  
        window.location.href = "/patient-search";
    }
    
    const stopModal = () => {

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>Are you sure?</h1>
                <p>You want to stop the engagement session.</p>
                <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={deleteTimer}>Yes, but Don't Save</button>
                <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                  onClick={() => {
                    handleStopTimer();
                    onClose();
                  }}
                >
                  Yes, Save & Stop
                </button>
              </div>
            );
          }
        });
      };

      // don't save delete the timer
      function deleteTimer () {

      
        let engagement_id = localStorage.getItem("engagement_ID");
      
        let data = {
          patient_engagement_current_id: engagement_id
         
        }
      
        apiAxios
        .post("/patient/delete-user-speaking-with-patient", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            
              
             
              window.location.href = "/patient-search";
             
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                        
          }
        })
        .catch((error) => {
          console.log(error);
      
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
      
              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        
      
      
        });

      }



      // stop and save engagement
      function handleStopTimer() {
  
        let engagement = JSON.parse(localStorage.getItem("engagement_timer"));
        let parentID = JSON.parse(localStorage.getItem("engagement_parent_ID"));
        let data = {
          patient_engagement_current_id: engagement,
          //pd_id: pat_details.pd_id
          parent_id: parentID,
        }
      
        apiAxios
        .post("/patient/end-call-user-speaking-with-patient", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            
              
              localStorage.removeItem("engagement_timer");
              // localStorage.removeItem("updateTimer");
              //props.history.push("/patient-information");
              window.location.href = "/patient-search";
             
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                        
          }
        })
        .catch((error) => {
          console.log(error);
      
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
      
              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        
      
      
        });
      }
// ******** //
const [state, setState] = React.useState({
  top: false,
  left: false,
  bottom: false,
  right: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setState({ ...state, [anchor]: open });
};

const list = (anchor) => (
  <div
    className={clsx(classes.list, {
      [classes.fullList]: anchor === 'top' || anchor === 'bottom',
    })}
    role="presentation"
    onClick={toggleDrawer(anchor, true)}
    onKeyDown={toggleDrawer(anchor, false)}
  >
    <Box style={{padding:'20px 20px'}}>
    <Box className={classes.pageTop}>
                     {engageActive ?
                     <Button onClick={stopModal}><BiArrowBack className={classes.backarrow} /> Back to patient search</Button>
                     :
                     <Button onClick={backButton}><BiArrowBack className={classes.backarrow} /> Back to patient search</Button>
                     
                    } 
                </Box>
                 <Box className={classes.Patientname}>
                     <p className={classes.nametext}>Patient Name</p>
                     <h4 className={classes.name}>{patDetails.patient_first_name? patDetails.patient_first_name : props.firstName} {patDetails.patient_last_name ? patDetails.patient_last_name : props.lastName }</h4>
                 </Box>
                {patDetails?
                ( <Box className={classes.patientinfo}>
                     <Box className={classes.info}>
                     EMR ID<br /><span>{patDetails.emr_id}</span>
                     </Box>
                     <Box className={classes.info}>
                     HRPM ID<br /><span>{patDetails.hrpm_id}</span>
                     </Box>
                     <Box className={classes.info}>
                     DOB<br /><span>{patDetails.patient_dob}</span>
                     </Box>
                    </Box>) : 
                    ( <Box className={classes.patientinfo}>
                      <Box className={classes.info}>
                      EMR ID<br /><span>{props.emr}</span>
                      </Box>
                      <Box className={classes.info}>
                      HRPM ID<br /><span>{props.hrpm}</span>
                      </Box>
                      <Box className={classes.info}>
                      DOB<br /><span>{props.dob}</span>
                      </Box>
                     </Box>)
                    }   
               
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                 {engageDetails ? engageDetails.program_abbrev : patDetails ?patDetails.program_abbrev : props.programName} 
                  {(patDetails || props.programName)? " Provider" : "" } 
                 {/* <br /><span>{patDetails.provider_first_name} {patDetails.provider_last_name}</span> */}

                 <br /><span>{engageDetails? engageDetails.provider_name :patDetails? patDetails.provider_first_name + " " + patDetails.provider_last_name: props.providerFname + " " + props.providerLname}</span>

                 </Box> 
                  
                 
            </Box>
      </Box>
      <LeftNav />
  </div>
);     

    return (
        <div>
          <Box className='ResponsiveNav2'>
            {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} style={{color:'#fff',fontFamily:'poppins', textTransform:'capitalize'}}>Patient Info</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
            </Box>
            <Box className={classes.DesktopDropdown}>  
            <Box className={classes.pageTop}>
                     {engageActive ?
                     <Button onClick={stopModal}><BiArrowBack className={classes.backarrow} /> Back to patient search</Button>
                     :
                     <Button onClick={backButton}><BiArrowBack className={classes.backarrow} /> Back to patient search</Button>
                     
                    } 
                </Box>
                 <Box className={classes.Patientname}>
                     <p className={classes.nametext}>Patient Name</p>
                     <h4 className={classes.name}>{patDetails.patient_first_name? patDetails.patient_first_name : props.firstName} {patDetails.patient_last_name ? patDetails.patient_last_name : props.lastName }</h4>
                 </Box>
                {patDetails?
                ( <Box className={classes.patientinfo}>
                     <Box className={classes.info}>
                     EMR ID<br /><span>{patDetails.emr_id}</span>
                     </Box>
                     <Box className={classes.info}>
                     HRPM ID<br /><span>{patDetails.hrpm_id}</span>
                     </Box>
                     <Box className={classes.info}>
                     DOB<br /><span>{patDetails.patient_dob}</span>
                     </Box>
                    </Box>) : 
                    ( <Box className={classes.patientinfo}>
                      <Box className={classes.info}>
                      EMR ID<br /><span>{props.emr}</span>
                      </Box>
                      <Box className={classes.info}>
                      HRPM ID<br /><span>{props.hrpm}</span>
                      </Box>
                      <Box className={classes.info}>
                      DOB<br /><span>{props.dob}</span>
                      </Box>
                     </Box>)
                    }   
               
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                 {engageDetails ? engageDetails.program_abbrev : patDetails ?patDetails.program_abbrev : props.programName} 
                  {(patDetails || props.programName)? " Provider" : "" } 
                 {/* <br /><span>{patDetails.provider_first_name} {patDetails.provider_last_name}</span> */}

                 <br /><span>{engageDetails? engageDetails.provider_name :patDetails? patDetails.provider_first_name + " " + patDetails.provider_last_name: props.providerFname + " " + props.providerLname}</span>

                 </Box> 
                  
                 
            </Box>
            </Box>
        </div>
    )
}

export default Patientinfo
const useStyles = makeStyles(() => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    loginbtn:{
        background:'#0f3d6e',
        padding:'0 40px',
        width:'120px',
        height:'45px',
        borderRadius:'10px',
        color:'#fff',
        textTransform:'capitalize',
        '&:hover':{
            background:'#333'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    patientinfo:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #5f80a9 solid',
        paddingBottom:'10px',
        marginBottom:'13px'
    },
    info:{
        color:'#bfc4cb',
        fontSize:'12px',
        '& span':{
            color:'#ffffff',
            fontSize:'16px',
            letterSpacing:'0.496657px'
        }
    },
    nametext:{
        fontSize:'12px',
        color:'#bfc4cb',
        margin:'40px 0 0'
    },
    name:{
        fontSize:'20px',
        color:'#ffffff',
        margin:'0 0 30px'
    },
    DesktopDropdown:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
    },
    '@media only screen and (max-width: 768px)':{
      display:'none'
  },
  }
}));