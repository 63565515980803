export const maxUseOptions = () => {
    var maxUse = [{"label":"1","value":"1"},{"label":"2","value":"2"},{"label":"3","value":"3"},{"label":"4","value":"4"},{"label":"5","value":"5"}]; 
    return maxUse;
  };


  export const minTimeOptions = () => {
    var minTime = [{"label":"10:00","value":"10"},{"label":"30:00","value":"30"},{"label":"45:00","value":"45"},{"label":"60:00","value":"60"}];
    return minTime;
  };  

  export const maxBillingFreqList = () => {
    var minTime = [{"label":"1 Month","value":"1"},{"label":"3 Months","value":"3"},{"label":"6 Months","value":"6"},{"label":"12 Months","value":"12"}];
    return minTime;
  };  

  export const hospitalizationIncidentType = () => {
    var incidents = [{"label":"Hospitalization Schedule","value":"1"},{"label":"Hospitalization Emergency","value":"2"},{"label":"Outpatient Schedule","value":"3"},{"label":"Urgent Care Emergency","value":"4"},{"label":"Urgent Care Schedule","value":"5"}]; 
    return incidents;
  };

  export const levelOfPain = () => {
    var pain = [{"label":"Severe","value":"severe"},{"label":"Moderate","value":"moderate"},{"label":"Mild","value":"mild"},{"label":"No Pain","value":"no pain"}]; 
    return pain;
  };

  export const durationOfPain = () => {
    var duration = [{"label":"Seconds","value":"Seconds"},{"label":"Minutes","value":"Minutes"},{"label":"Hours","value":"Hours"},{"label":"Days","value":"Days"},{"label":"Weeks","value":"Weeks"}]; 
    return duration;
  };

  export const frequencyOfPain = () => {
    var frequency = [{"label":"Constant","value":"constant"},{"label":"Triggered by Activity","value":"triggered by activity"},{"label":"Frequent","value":"frequent"},{"label":"Intermittent","value":"intermittent"},{"label":"Rarely","value":"rarely"},{"label":"Seasonal","value":"seasonal"}]; 
    return frequency;
  };

  export const activeStatus = () => {
    var status = [{"label":"Active","value":"1"},{"label":"Inactive","value":"0"}]; 
    return status;
  };

  export const goalsCategory = () => {
    var status = [{"label":"Exercise","value":"Exercise"},{"label":"Diet","value":"Diet"},{"label":"Others","value":"Others"}]; 
    return status;
  };

  export const interventionStatus = () => {
    var status = [{"label":"Need Intervention","value":"1"},{"label":"None","value":"0"}]; 
    return status;
  };

  export const movingDirection = () => {
    var status = [{"label":"Improving","value":"Improving"},{"label":"Same","value":"Same"},{"label":"Setbck","value":"Setback"},{"label":"Haven't Started","value":"Havent Started"}]; 
    return status;
  };

  export const medType = () => {
    var status = [
      {"label":"DHCT","value":"DHCT"},
      {"label":"EXTERNAL","value":"EXTERNAL"},
      {"label":"OTC","value":"OTC"}
    ]; 
    return status;
  };

  export const medSupply = () => {
    var status = [
      {"label":"EMR","value":"EMR"},
      {"label":"OTHER","value":"OTHER"}
    ]; 
    return status;
  };

  export const CCQActions = () => {
    var status = [
      {"label":"Remedied","value": "1"},
      {"label":"Followed Up With Patient","value": "2"},
      {"label":"Schedule Office Visit","value": "3"},
      {"label":"Instructed Patient to Call 911 / Emergency Room","value": "4"},
      {"label":"Phone Consultation With Patient","value": "5"},
      {"label":"Updated EMR","value": "6"},
      {"label":"Other","value": "7"},
    ]; 
    return status;
  };

  export const goalConditions = () => {
    var conditions = [
      'Education on Blood Pressure as many patient have no symptoms',
      'Manage stress (Meditation, Yoga & Message)',
      'Familiarize yourself with family cardiac history',
      'Follow blood pressure',
      'Decrease intake of hyper-processed foods (i.e potato chips, french fries, etc)',
      'Limited alcohol consumptions',
      'Avoid excessive dietary cholesterol (egg yolks, organ meats, shellfish)'
    ];
    return conditions;
  }


  export const patientEngagementProgram = () => {
    var status = [
      {"label":"Patient is motivated to participate","value": "Patient is motivated to participate"},
      {"label":"Patient is indifferent to participate","value": "Patient is indifferent to participate"},
      {"label":"Patient seems uninterested to participate","value": "Patient seems uninterested to participate"}
    ]; 
    return status;
  };

  export const days = () => {
    var day = [
      {"label":"Monday", "value": "Monday"},
      {"label":"Tuesday", "value": "Tuesday"},
      {"label":"Wednesday", "value": "Wednesday"},
      {"label":"Thursday", "value": "Thursday"},
      {"label":"Friday", "value": "Friday"},
      {"label":"Saturday", "value": "Saturday"},
      {"label":"Sunday", "value": "Sunday"},
      {"label":"All Days", "value": "All Days"},
    ]; 
    return day;
  };

  export const dayTime = () => {
    var time = [
      {"label":"Morning", "value": "Morning"},
      {"label":"Mid-Day(11AM-2PM)", "value": "Mid-Day(11AM-2PM)"},
      {"label":"Afternoon", "value": "Afternoon"},
    ]; 
    return time;
  };

  export const carePlanGridInfoList = () => {

    var carePlanGridInfo = [
        {
          1: "patient_demographics",
        },
        {
          2: "patient_insurance",
        },
        {
          3: "patient_provider",
        },
        {
          4: "patient_dhct_medication",
        },
        {
          225: "patient_conditions",
        },
        {
          6: "ros",
        },
        {
          7: "patient_hospitilization",
        },
        {
          8: "patient_symmptom",
        },
        {
          9: "otc_medictions",
        },
      ];
      return carePlanGridInfo;
    };
    