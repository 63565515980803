import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box, Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import { FaPlusCircle } from "react-icons/fa"
import { BiEdit } from "react-icons/bi"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Select from "react-dropdown-select";
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { BiXCircle } from "react-icons/bi"
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css"
import calendericon from '../images/calendar-icon.svg'
import { AiFillAlert } from "react-icons/ai"
import { Pagination } from "@material-ui/lab";
import { activeStatus, medType, medSupply } from "../Util/OtherList";

import Fade from '@material-ui/core/Fade'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader-spinner";

import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";

import { BiChevronUp, BiChevronDown, BiErrorCircle } from "react-icons/bi"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RviewMedication() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('false');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setEditData(panel);
  };
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState('panel6');
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editData, setEditData] = React.useState("");

  const [vaccineList, setVaccineList] = React.useState("");
  const [medicationList, setMedicationList] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [vacciPage, setVacciPage] = useState(1);
  const [vacciTotPages, setVacciTotPages] = useState(1)

  const [vaccineName, setVaccineName] = React.useState("");
  const [vaccineDate, setVaccineDate] = React.useState(new Date());
  const [vaccineRoute, setVaccineRoute] = React.useState("");
  const [vaccineManufacturer, setVaccineManufacturer] = React.useState("");
  const [vaccineNameError, setVaccineNameError] = React.useState(false);
  const [vaccineDateError, setVaccineDateError] = React.useState(false);
  const [vaccineRouteError, setVaccineRouteError] = React.useState(false);

  const [medicationNotesError, setMedicationNotesError] = React.useState(false);

  const [vaccineShowLoader, setVaccineShowLoader] = React.useState(false);

  const [vaccineID, setVaccineID] = React.useState("");
  const [vaccineDBList, setVaccineDBList] = React.useState("");
  const [vaccineDBListOption, setVaccineDBListOption] = React.useState([]);
  const [searchVaccine, setSearchVaccine] = React.useState("");


  const [status, setStatus] = React.useState("");
  const [medicationName, setMedicationName] = React.useState("");
  const [medicationSupply, setMedicationSupply] = React.useState("");
  const [medicationInstruction, setMedicationInstruction] = React.useState("");
  const [medicationPrescribedby, setMedicationPrescribedby] = React.useState("");
  const [medicationPrescribedMethod, setMedicationPrescribedMethod] = React.useState("");
  const [medicationType, setMedicationType] = React.useState("");
  const [medicationDate, setMedicationDate] = React.useState(new Date());
  const [medicationNotes, setMedicationNotes] = React.useState("");
  const [nonAdherenceStatus, setNonAdherenceStatus] = React.useState(0);
  const [nonAdherenceType, setNonAdherenceType] = React.useState("");
  const [medStopReason, setMedStopReason] = React.useState("");
  const [medStopDate, setMedStopDate] = React.useState("");
  const [medStopKnow, setMedStopKnow] = React.useState("");
  const [adherenceNote, setAdherenceNote] = React.useState("");



  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  useEffect(() => {

    getMedications();
    getVaccines();

    getVaccinesDB();
    // getProviders();
    getOtherProviders();
    getProviderData();

  }, []);

  useEffect(() => {

    if (vaccineDBList) {

      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < vaccineDBList.length; i++) {
        let value = vaccineDBList[i].vaccines_by_disease_name_id;
        let label = (vaccineDBList[i].vaccines_by_disease_name).trim();
        let ob = {
          "label": label, "value": value
        }
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
      }
      setVaccineDBListOption(ob_arr);

      console.log("VDBList - ", vaccineDBListOption);

    }

  }, [vaccineDBList]);

  const getVaccines = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

      setVacciPage(parseInt(table_config_page_no));

      let pd = JSON.parse(localStorage.getItem("patient"));

      setVaccineList([]);
      setMessage("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        pd_id: pd.pd_id,
        table_config_rows_per_page: table_config_rows_per_page,
      }

      apiAxios
        .post("/patient/list-patient-new-vaccination", data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setVaccineList(response.data.data);
            setMessage("");
            setVacciTotPages(response.data.total_pages);

          }
          else if (response.data.statusCode == 502) {
            setMessage("No Data Found");
          }
          else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            setMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


        });
    }
  );

  const [providerList, setProviderList] = React.useState([]);
  const [otherProviderList, setOtherProviderList] = React.useState([]);

  // const getProviders = useCallback(
  //   async ({
  //     table_config_rows_per_page = '30',
  //     table_config_page_no = '1',
  //   } = {}) => {

  //     //setCurrentPage(parseInt(table_config_page_no));

  //     let pat_details = JSON.parse(localStorage.getItem("patient"));

  //     setProviderList([]);

  //     let data = {
  //       // pd_id: pat_details.pd_id,
  //       // client_patient_program_id: pat_details.cpatp_id,
  //       table_config_page_no: table_config_page_no,
  //       table_config_rows_per_page: table_config_rows_per_page,
  //       search_value: "",
  //       cutid: "",
  //       user_master_type_id: "",
  //       client_id : "",
  //       is_provider : true,
  //     }

  //     apiAxios
  //       .post("/client/clientuserlist", data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Authorization": localStorage.getItem("Token")
  //         },
  //       })
  //       .then((response) => {
  //         console.log("provider_response --> ", response);
  //         if (response.data.statusCode == 200) {
  //           let medicationRes = response.data.data
  //           let pList = [];
  //           medicationRes?.map((e) => {
  //             pList.push({
  //               label: e.first_name + " " + e.last_name,
  //               value: e.id,
  //             });
  //           });

  //           setProviderList(pList);



  //           //setMessage("");
  //           //setTotalPages(response.data.total_pages);

  //         }
  //         else if (response.data.statusCode == 502) {
  //           //setMessage("No Data Found");
  //         }
  //         else {
  //           toast.error(response.data.message, {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });

  //           //setMessage(response.data.message);    
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);

  //         toast.error(error.message, {
  //           position: "bottom-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });

  //         // localStorage.removeItem("Refresh_Token");
  //         // localStorage.removeItem("Token");
  //         // window.location.href = "/";        


  //       });
  //   }
  // );

  const getProviderData = () => {
    let data = {
      pd_id: pat_details.pd_id,
      client_patient_program_id: pat_details.cpatp_id,
      table_config_rows_per_page: "50",
      table_config_page_no: "1",
      is_unique : "1",
    };
    apiAxios
      .post("/provider/list-patient-provider-program", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          console.log("provider data primary - ", response.data.data);

          let medicationRes = response.data.data
            let pList = [];
            medicationRes?.map((e) => {
              pList.push({
                label: e.provider_name,
                value: e.provider_id,
              });
            });

            setProviderList(pList);
          // if (response.data.data) {
          //   response.data.data.map((val) => {
          //     if (val.is_primary === "1") {
          //       setPrimaryProviderData(val);
          //     } else {
          //       setOtherProviderData(val);
          //     }
          //   });
          // }



        }
      })
      .catch((error) => {
        console.log(error);
      });
  };





  const getOtherProviders = useCallback(
    async ({
      table_config_rows_per_page = '30',
      table_config_page_no = '1',
    } = {}) => {

      //setCurrentPage(parseInt(table_config_page_no));

      let pat_details = JSON.parse(localStorage.getItem("patient"));

      setOtherProviderList([]);

      let data = {
        pd_id: pat_details.pd_id,
        client_patient_program_id: pat_details.cpatp_id,
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
      }

      apiAxios
        .post("/provider/list-new-patient-provider", data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            let pList = [];
            response.data.data?.map((e) => {
              pList.push({
                label: e.patient_provider_first_name + " " + e.patient_provider_last_name,
                value: e.id,
              });
            });

            setOtherProviderList(pList);
            //setMessage("");
            //setTotalPages(response.data.total_pages);

          }
          else if (response.data.statusCode == 502) {
            //setMessage("No Data Found");
          }
          else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            //setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


        });
    }
  );

  const getVaccinesDB = useCallback(
    async ({
      table_config_rows_per_page = '50',
      table_config_page_no = '1',
    } = {}) => {

      //setCurrentPage(parseInt(table_config_page_no));

      let pd = JSON.parse(localStorage.getItem("patient"));

      setVaccineList([]);
      setMessage("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        table_config_rows_per_page: table_config_rows_per_page,
        search_value: ""
      }

      apiAxios
        .post("/patient/vaccines-disease-name-list", data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setVaccineDBList(response.data.data);
            setMessage("");
            //setTotalPages(response.data.total_pages);

          }
          else if (response.data.statusCode == 502) {
            setMessage("No Data Found");
          }
          else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            setMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


        });
    }
  );

  const [showLoader, setShowLoader] = React.useState(false);

  const submitForm = (item) => {

    let pd = JSON.parse(localStorage.getItem("patient"));
    let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

    let data = {
      drug_name: item.drug_name,
      drug_strength: "",
      drug_dosage: "",
      drug_formulation: "",
      drug_reactions: "",
      drug_start_date: item.drug_start_date,
      drug_end_date: "",
      drug_instructions: item.drug_instructions,
      drug_allergies: "",
      prescribing_provider: item.prescribing_provider,
      drug_source: item.drug_source,
      non_adherence_flag: item.non_adherence_flag == "1" ? 1 : 0,
      non_adherence_date: item.non_adherence_date,
      medication_notes: item.medication_notes,
      ccq_flag: 1,
      pd_id: pd.pd_id,
      patient_program_id: engage_details.patient_program_id,
      status: item.status,
      meds_prescribed_by_type: item.meds_prescribed_by_type,
      patient_provider_id: item.patient_provider_id,
      medication_type: item.medication_type,
      non_adherence_reasons: item.non_adherence_reasons,
      non_adher_stop_take_rec: item.non_adher_stop_take_rec == "True" ? true : false,
      national_drug_code: "",
      non_adher_stop_take_med: item.non_adher_stop_take_med == "True" ? true : false,
      patient_medication_id: item.id
    }

    console.log("Med Data - ", data);

    apiAxios
      .post("/medication/update-patient-new-medication", data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setOpen2(false);
          getMedications();
        }
        else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        
      });

  }

  const getMedications = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

      setCurrentPage(parseInt(table_config_page_no));

      let pd = JSON.parse(localStorage.getItem("patient"));

      setMedicationList([]);
      setMessage2("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        pd_id: pd.pd_id,
        table_config_rows_per_page: table_config_rows_per_page,
      }

      apiAxios
        .post("/medication/list-patient-new-medication", data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setMedicationList(response.data.data);
            setMessage2(response.data.message ? response.data.message : "");
            setTotalPages(response.data.total_pages);

          }
          else if (response.data.statusCode == 502) {
            setMessage2("No Data Found");
          }
          else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            setMessage2(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        


        });
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    //setEditData(data);
  };

  const [checked, setChecked] = React.useState(false);

  const [editMed, setEditMed] = React.useState("");

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen2 = (item) => {

    setEditMed(item);

    if(item.ccq_flag == 1){
      setChecked(true);
    }
    else{
      setChecked(false);
    }
    
    
    if (item.meds_prescribed_by_type == "DHCT") {
      for (var i = 0; i < providerList.length; i++) {
        if (providerList[i].value == item.patient_provider_id) {
          setMedicationPrescribedby(providerList[i].value);
        
      }
    }
   } else if(item.meds_prescribed_by_type == "EXTERNAL") {
      for (var i = 0; i < otherProviderList.length; i++) {
        if (otherProviderList[i].value == item.patient_provider_id) {
          setMedicationPrescribedby(otherProviderList[i].value);
        }
      }
    }

    setNonAdherenceStatus(item.non_adherence_flag);

    setMedicationName(item.drug_name);
    setMedicationSupply(item.drug_source);
    setMedicationInstruction(item.drug_instructions);
    setStatus(item.status);
    if (item.drug_start_date) {
      let sd = new Date(item.drug_start_date);
      setMedicationDate(sd);
    }
    setMedicationNotes(item.medication_notes);

    for (var i = 0; i < medType.length; i++) {
      if (medType[i].value == item.meds_prescribed_by_type) {
        setMedicationType(medType[i]);
      }
    }
    setMedicationType(item.meds_prescribed_by_type);
    if (item.non_adherence_flag == 1) {

      setState({
        checkedA: true,
        checkedB: false,
      });

      setNonAdherenceType((item.non_adher_stop_take_med == "True") ? "Stopped taking medication" : "Not taking as recommended");

    }
    setMedStopReason(item.non_adherence_reasons);
    if (item.non_adherence_reasons) {
      let dt = new Date(item.non_adherence_date);
      console.log("dt --", dt);
      setMedStopDate(dt);
    }

    setOpen2(true);
  };

  const handleClickOpenAdd = () => {

    setMedicationName("");
    setMedicationDate(new Date());
    setMedicationSupply("");
    setMedicationInstruction("");
    setMedicationType("");
    setNonAdherenceStatus(0);
    setNonAdherenceType("");
    setMedStopReason("");
    setMedStopDate(new Date());


    setOpen2(true);
  };

  const [medNameError, setMedNameError] = React.useState(false);
  const [medStatusError, setMedStatusError] = React.useState(false);
  const [medSupplyError, setMedSupplyError] = React.useState(false);
  const [medTypeError, setMedTypeError] = React.useState(false);
  const [medPrescribedbyError, setMedPrescribedbyError] = React.useState(false);
  const [medPrescribedMethodError, setMedPrescribedMethodError] = React.useState(false);
  const [medDateError, setMedDateError] = React.useState(false);
  const [nonAdherenceTypeError, setNonAdherenceTypeError] = React.useState(false);
  const [reasonError, setReasonError] = React.useState(false);
  const [medKnowError, setMedKnowError] = React.useState(false);
  const [medStopError, setMedStopError] = React.useState(false);
  const [medicationInstructionError, setMedicationInstructionError] = React.useState(false)


  const submitMed = () => {
    let isValid = true;

    console.log(medicationNotes.length)
    console.log(medStopReason.length)
    console.log(medicationInstruction.length)

    if(medicationNotes){
      if(medicationNotes.trim().length <= 200){
        setMedicationNotesError(false)
        
      }else{
        isValid = false
        setMedicationNotesError(true)
      }
    }else {
      
    }


    if(medicationInstruction){
      if(medicationInstruction.trim().length <= 100){
        setMedicationInstructionError(false)
        
      }else{
        isValid = false
        setMedicationInstructionError(true)
      }
    }else {
      
    }

    if(medicationDate){
      setMedDateError(false)
    }else {
      setMedDateError(true)
      isValid = false
    }



    if (medicationName.trim().length > 0 && medicationName.trim().length <=100) {
      setMedNameError(false);
    }
    else {
      setMedNameError(true);
      isValid = false;
    }

    if (status) {
      setMedStatusError(false);
    }
    else {
      setMedStatusError(true);
      isValid = false;
    }

    if (medicationSupply.length > 0) {
      setMedSupplyError(false);
    }
    else {
      setMedSupplyError(true);
      isValid = false;
    }

    if (medicationType) {
      setMedTypeError(false);
      if (medicationType == "DHCT" && medicationType == "EXTERNAL") {
        if (medicationPrescribedby) {
          setMedPrescribedbyError(false);
        }
        else {
          setMedPrescribedbyError(true);
          isValid = false;
        }
      }
    }
    else {
      setMedTypeError(true);
      isValid = false;
    }

    if (state.checkedA) {
      if (nonAdherenceType) {
        setNonAdherenceTypeError(false);
      }
      else {
        setNonAdherenceTypeError(true);
        isValid = false;
      }

      if (medStopReason.trim().length > 0 && medStopReason.trim().length <= 200) {
        setReasonError(false);
      }
      else {
        setReasonError(true);
        isValid = false;
      }




     
          if(medStopDate >= medicationDate){
            setMedStopError(false);
          }else {
            setMedStopError(true);
            isValid = false;
          }

        
     

      

      
     
      // if(medStopKnow.length > 0){
      //   setMedKnowError(false);
      // }
      // else{
      //   setMedKnowError(true);
      //   isValid = false;
      // }

    }

    let pd = JSON.parse(localStorage.getItem("patient"));
    let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

    let prescribingProvider = "";

    if (medicationType == "DHCT") {
      for (var i = 0; i < providerList.length; i++) {
        if (providerList[i].value == medicationPrescribedby) {
          prescribingProvider = providerList[i].label;
        }
      }
    } else if(medicationType == "EXTERNAL") {
      for (var i = 0; i < otherProviderList.length; i++) {
        if (otherProviderList[i].value == medicationPrescribedby) {
          prescribingProvider = otherProviderList[i].label;
        }
      }
    }

    if (isValid) {
      setShowLoader(true);

      let data;
      let url = "";


      if(editMed.id){

        data = {
          drug_name: medicationName,
          drug_strength: "",
          drug_dosage: "",
          drug_formulation: "",
          drug_reactions: "",
          drug_start_date: medicationDate ? moment(medicationDate).format("YYYY-MM-DD") : "",
          drug_end_date: "",
          drug_instructions: medicationInstruction,
          drug_allergies: "",
          prescribing_provider: prescribingProvider,
          drug_source: medicationSupply,
          non_adherence_flag: state.checkedA ? 1 : 0,
          non_adherence_date: medStopDate ? moment(medStopDate).format("YYYY-MM-DD") : "",
          medication_notes: medicationNotes,
          ccq_flag: checked ? 1 : 0,
          pd_id: pd.pd_id,
          patient_program_id: engage_details.patient_program_id,
          status: status,
          meds_prescribed_by_type: medicationType,
          patient_provider_id: medicationPrescribedby,
          medication_type: "",
          non_adherence_reasons: medStopReason,
          non_adher_stop_take_rec: (nonAdherenceType == "Stopped taking medication") ? false : true,
          national_drug_code: "",
          non_adher_stop_take_med: (nonAdherenceType == "Stopped taking medication") ? true : false,
          patient_medication_id: editMed.id
        }

        url = "/medication/update-patient-new-medication";

      }
      else{

        data = {
          drug_name: medicationName,
          drug_strength: "",
          drug_dosage: "",
          drug_formulation: "",
          drug_reactions: "",
          drug_start_date: medicationDate ? moment(medicationDate).format("YYYY-MM-DD") : "",
          drug_end_date: "",
          drug_instructions: medicationInstruction,
          drug_allergies: "",
          prescribing_provider: prescribingProvider,
          drug_source: medicationSupply,
          non_adherence_flag: state.checkedA ? 1 : 0,
          non_adherence_date: medStopDate ? moment(medStopDate).format("YYYY-MM-DD") : "",
          medication_notes: medicationNotes,
          ccq_flag: checked ? 1 : 0,
          pd_id: pd.pd_id,
          patient_program_id: engage_details.patient_program_id,
          status: status,
          meds_prescribed_by_type: medicationType,
          patient_provider_id: medicationPrescribedby,
          medication_type: "",
          non_adherence_reasons: medStopReason,
          non_adher_stop_take_rec: (nonAdherenceType == "Stopped taking medication") ? false : true,
          national_drug_code: "",
          non_adher_stop_take_med: (nonAdherenceType == "Stopped taking medication") ? true : false,
        }

        url = "/medication/create-patient-new-medication";

      }

      

      console.log("Med Data - ", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
            setOpen2(false);
            setEditMed(false);
            getMedications();
            window.location.href = "/review-medication"; 
          }
          else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";        
        });


    }



  };

  function handleClickDelete(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      vaccination_id: item.id,
      pd_id: pat_details.pd_id
    }

    apiAxios
      .post("/patient/delete-patient-new-vaccination", data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          getVaccines();
        }
        else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        


      });
  }

  function handleClickDeleteMed(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      patient_medication_id: item.id,
      //pd_id: pat_details.pd_id
    }

    apiAxios
      .post("/medication/delete-patient-new-medication", data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          getMedications();
        }
        else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        


      });
  }

  const deleteModal = (item, type) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button className={classes.loginbtn} style={{ border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width: 'auto' }} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{ marginLeft: '10px', cursor: 'pointer', width: 'auto' }}
              onClick={() => {
                handleClickDelete(item, type);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      }
    });
  };

  const deleteModalMed = (item, type) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button className={classes.loginbtn} style={{ border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width: 'auto' }} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{ marginLeft: '10px', cursor: 'pointer', width: 'auto' }}
              onClick={() => {
                handleClickDeleteMed(item, type);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      }
    });
  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const [editVaccineData, setEditVaccineData] = React.useState("");
  const [openVaccine, setOpenVaccine] = React.useState(false);

  const handleOpenVaccine = (item) => {
    if (item == 0) {
      setEditVaccineData("");

      setEditVaccineData(item);
      setVaccineName("");
      setVaccineDate(new Date());
      setVaccineRoute("");
      setVaccineManufacturer("");

    }
    else {
      setEditVaccineData(item);

      for (var i = 0; i < vaccineDBListOption.length; i++) {
        if ((vaccineDBListOption[i].label).trim() == (item.vaccines_by_disease_name).trim()) {
          setVaccineName(item.vaccines_by_disease_name);
          console.log("vname - ", vaccineName);
        }
      }
      if (item.date_last_vaccinated) {
        var dt = new Date(item.date_last_vaccinated);
        setVaccineDate(dt);
      }
      setVaccineRoute(item.vaccination_route);
      setVaccineManufacturer(item.manufacturer);
    }
    setOpenVaccine(true);
    console.log("Edit data - ", item);

  }

  const submitVaccine = (flag) => {
    let isValid = true;

    if (vaccineName) {
      setVaccineNameError(false);
    }
    else {
      setVaccineNameError(true);
      isValid = false;
    }

    if (vaccineDate) {
      setVaccineDateError(false);
    }
    else {
      setVaccineDateError(true);
      isValid = false;
    }

    if (vaccineRoute.trim().length > 0) {
      setVaccineRouteError(false);
    }
    else {
      setVaccineRouteError(true);
      isValid = false;
    }


    if (isValid) {

      setVaccineShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let data;
      let url;

      if (flag == 1) {

        data = {
          pd_id: pat_details.pd_id,
          vaccines_by_disease_name: vaccineName,
          date_last_vaccinated: moment(vaccineDate).format("YYYY-MM-DD"),
          vaccination_route: vaccineRoute,
          manufacturer: vaccineManufacturer
        }

        url = "/patient/create-patient-new-vaccination";

      }
      else {

        data = {
          pd_id: pat_details.pd_id,
          vaccination_id: editVaccineData.id,
          vaccines_by_disease_name: vaccineName,
          date_last_vaccinated: moment(vaccineDate).format("YYYY-MM-DD"),
          vaccination_route: vaccineRoute,
          manufacturer: vaccineManufacturer
        }

        url = "/patient/update-patient-new-vaccination";

      }

      console.log("DATA =", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setVaccineShowLoader(false);
            setOpenVaccine(false);
            getVaccines();
            
          }
          else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setVaccineShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          localStorage.removeItem("Refresh_Token");
          localStorage.removeItem("Token");
          window.location.href = "/";


        });

    }


  }

  const handleCloseVaccine = () => {
    setEditVaccineData("");
    setVaccineName("");
    setVaccineDate(new Date());
    setVaccineRoute("");
    setVaccineManufacturer("");
    setOpenVaccine(false);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  // modal //
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  // modal2 //
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const addToCCQ = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to add this record to CCQ?</p>
            <button className={classes.loginbtn} style={{ border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width: 'auto' }} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{ marginLeft: '10px', cursor: 'pointer', width: 'auto' }}
              onClick={() => {
                //localStorage.setItem("CCQItem", JSON.stringify(item));
                submitForm(item);
                onClose();
              }}
            >
              Yes, Add
            </button>
          </div>
        );
      }
    });
  };

  const canBeOpen = open && Boolean(anchorEl);

  const handleChangeSelected = (event) => {
    //console.log("Selected = ", selected);
    setNonAdherenceType(event.target.value);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  // tab //
  const [value, setValue] = React.useState('1');

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
  //   switch //
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });

  const handleChange4 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    console.log("state - ", state);

  };
  const [startDate, setStartDate] = useState({ date1: new Date(), date2: new Date(), });
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            <Patientinfo />
            {/* left Accordion*/}
            <Box className={classes.TabPatientinfo}>
            <LeftNav />
            </Box>
            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>

        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Timer />
          {/* <Box className={classes.btncol} style={{ marginTop: '30px' }}></Box> */}
          <Box className={classes.Tabcol}>
          <h3 className={classes.topheading}>Medications and Vaccinations</h3>
          <br />
            <TabContext value={value}>
              <AppBar position="static" className={classes.contacttab}>
                <TabList onChange={handleChange2} aria-label="simple tabs example">
                  <Tab label="Medications" value="1" />
                  <Tab label="Vaccinations" value="2" />
                </TabList>
              </AppBar>
              <TabPanel value="1">
                <Box className={classes.Careplan}>
                  <Box className={classes.plantop}>
                    <h3 className={classes.topheading}>Medications</h3>
                    <Button className={classes.recordbtn} onClick={handleClickOpenAdd}><FaPlusCircle className={classes.addicon} /> Add Medications</Button>
                  </Box>
                  <Box className={classes.MedicationTable}>
                  <Box className={classes.throw}>
                    <Box className={classes.Row1}> Medication Name</Box>
                    <Box className={classes.Row2}>Supply</Box>
                    <Box className={classes.Row3}>Instructions</Box>
                    <Box className={classes.Row4}>Status</Box>
                    <Box className={classes.Row5}>Prescribed by</Box>
                    <Box className={classes.Row6}>Update<br /> date & time</Box>
                    <Box className={classes.Row7}>Updated by</Box>
                  </Box>
                  {(medicationList && medicationList.length > 0) ?
                    <>
                      {medicationList.map((each) => (
                        <Accordion expanded={(expanded == each.id) ? true : false} onChange={handleChange(each.id)} style={{ boxShadow: 'none' }}>
                          <AccordionSummary
                            expandIcon={<FaPlusCircle />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Box className={classes.tdrow}>
                              <Box className={classes.Row1}>{each.drug_name} {(each.ccq_flag == 1) ? <AiFillAlert style={{ color: 'red', marginLeft: '3px' }} /> : ""}</Box>
                              <Box className={classes.Row2}>{each.drug_source}</Box>
                              <Box className={classes.Row3}>{each.drug_instructions}</Box>
                              <Box className={classes.Row4}>{(each.status == 1) ? 'Active' : 'Inactive'}</Box>
                              <Box className={classes.Row5}>{each.prescribing_provider}</Box>
                              <Box className={classes.Row6}>{(each.updated_at) ? moment.utc(each.updated_at).local().format('YYYY-MM-DD HH:MM a') : '--'} </Box>
                              <Box className={classes.Row7}>{each.updated_by}</Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>

                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <ul className={classes.Plandetails}>
                                  <li><span>Drug Instructions</span> {each.drug_instructions}</li>
                                  <li><span>Notes</span> {each.medication_notes}</li>
                                  <li><span>Adherent</span>
                                    {(each.non_adherence_flag == 1) ? 'N' : 'Y'}</li>
                                  <li><span></span>
                                    <>
                                      {(each.non_adherence_flag == 1) ?
                                        <div>
                                          <ul className={classes.Plandetails} style={{ minHeight: 'auto', borderLeft: '1px solid #88b3eb', paddingLeft: '20px' }}>
                                            <li><span>Non-Adherence Date</span>{each.non_adherence_date}</li>
                                            <li><span>Non-Adherence Reason</span>{each.non_adherence_reasons}</li>
                                            <li><span>Non-Adherence Type</span>{(each.non_adher_stop_take_med == true) ? 'Stopped taking medication' : 'Not taking as recommended'}</li>
                                            <li><span>Does prescribing physician <br />know you've stopped?</span>{each.prescribing_provider}</li>
                                          </ul>
                                        </div>
                                        :
                                        <></>
                                      }
                                    </>
                                  </li>
                                  <li><span>Medication Type</span> {each.meds_prescribed_by_type}</li>
                                  <li><span>Prescribed By</span> {each.prescribing_provider}</li>
                                  <li><span>Date Prescribed</span> {each.drug_start_date}</li>

                                </ul>
                              </Grid>
                              <Grid item xs={12} sm={3} style={{ justifyContent: 'flex-end' }}>
                                <Button className={classes.Optionsbtn}
                                  aria-describedby={canBeOpen ? each.id : undefined}
                                  onClick={handleClick}
                                >
                                  Options <BiChevronDown />
                                </Button>
                                {(each.id == editData) ?
                                  <Popper id={canBeOpen ? each.id : editData} open={open} anchorEl={anchorEl} transition>
                                    {({ TransitionProps }) => (
                                      <Fade {...TransitionProps} timeout={150}>
                                        <Paper style={{ boxShadow: 'none', width: '150px', border: '1px #AEAEAE solid', borderRadius: '10px' }}>
                                          <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                              {each.ccq_flag == "0" ?
                                                <MenuItem onClick={handleClose} style={{ color: '#0f3d6e', fontSize: '14px' }} onClick={() => addToCCQ(each)}>Add To CCQ</MenuItem>
                                                :
                                                <></>
                                              }
                                              <MenuItem onClick={handleClose} style={{ color: '#0f3d6e', fontSize: '14px' }} onClick={() => handleClickOpen2(each)}>Edit</MenuItem>
                                              <MenuItem onClick={handleClose} style={{ color: '#0f3d6e', fontSize: '14px' }} onClick={() => deleteModalMed(each)}>Delete</MenuItem>
                                            </MenuList>
                                          </ClickAwayListener>
                                        </Paper>
                                      </Fade>
                                    )}
                                  </Popper>
                                  :
                                  <></>
                                }
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                    :
                    <Box style={{ padding: '5%', textAlign: 'center' }}>{message2}</Box>
                  }
                </Box>
                </Box>
                  
                <br /> <br />
              <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getMedications({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 

              </TabPanel>
             
              {/* tab panel 2 */}
              <TabPanel value="2">
                <Box className={classes.Careplan}>
                  <Box className={classes.plantop}>
                  <h3 className={classes.topheading}>Vaccinations</h3>
                    <Box className={classes.Filterleft}>
                      <Box className={classes.Selectgoal}>
                        <FormControl className={classes.formControl}>
                        </FormControl>
                      </Box>
                      <Box className={classes.Sortby}></Box>
                    </Box>
                    <Button className={classes.recordbtn} onClick={() => handleOpenVaccine(0)} ><FaPlusCircle className={classes.addicon} /> Add Vaccination</Button>
                  </Box>
                  <Box className={classes.MedicationTable}>
                  <Box className={classes.throw}>
                    <Box className={classes.Row1}>Vaccine</Box>
                    <Box className={classes.Row2}>Last Vaccine<br />Date</Box>
                    <Box className={classes.Row3}>Vaccination <br />Route</Box>
                    <Box className={classes.Row4}>Created at</Box>
                    <Box className={classes.Row5}>Updated at</Box>
                    <Box className={classes.Row6}>Manufacturer</Box>
                    <Box className={classes.Row7}>Action</Box>
                  </Box>
                  {(vaccineList.length > 0) ?
                    <>
                      {vaccineList.map((each) => (
                        <Box className={classes.tdrow}>
                          <Box className={classes.Row1}>{each.vaccines_by_disease_name}</Box>
                          <Box className={classes.Row2}>{each.date_last_vaccinated ? moment(each.date_last_vaccinated).format("YYYY-MM-DD") : "--"}</Box>
                          <Box className={classes.Row3}>{each.vaccination_route}</Box>
                          <Box className={classes.Row4}>{each.created_at ? moment.utc(each.created_at).local().format("YYYY-MM-DD") : "--"}</Box>
                          <Box className={classes.Row5}>{each.updated_at ? moment.utc(each.updated_at).local().format("YYYY-MM-DD") : "--"}</Box>
                          <Box className={classes.Row6}>{each.manufacturer ? each.manufacturer : "--"}</Box>
                          <Box className={classes.Row7}><span className={classes.editbtn} style={{ marginRight: '6px' }} onClick={() => handleOpenVaccine(each)}>Edit</span> <span className={classes.deletebtn} onClick={() => deleteModal(each)}>Delete</span></Box>
                        </Box>
                      ))}
                    </>
                    :
                    <Box className={classes.tdrow}>
                      {message}
                    </Box>
                  }
                  </Box>
                </Box>
                <br /> <br />
                <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {vacciTotPages}
                        color="primary"
                        page={vacciPage}
                        onChange={(event, pageNumber) =>
                          getVaccines({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 

              </TabPanel>
            </TabContext>
          </Box>



          {/* tab with accordion */}

        </Box>
        {/* modal */}
        <Dialog className={classes.modal}
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          disableEnforceFocus={true}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Typography variant='h3' style={{ fontSize: 18, fontFamily: 'poppins', marginBottom: 30, fontWeight: '600' }}>Add/Edit Medication</Typography>
            <Box className={classes.btncol}>
              <Button onClick={handleClose2} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <form>
                <ul className={classes.Editplan}>
                  <li>
                    <Typography variant='p'>Status<span style={{ color: '#ff0000' }}>*</span></Typography>
                    <div style={{ width: '100%' }}>
                      <Select
                        options={activeStatus()}
                        onChange={(values) => setStatus(values[0] ? values[0].value : values.value)}
                        className={classes.datepicker}
                        style={{ width: "100%" }}
                        values={activeStatus().filter(
                          (option) => option.value == status
                        )}
                      />
                    </div>
                  </li>
                  {medStatusError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Status is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><Typography variant='p'>Medication Name<span style={{ color: '#ff0000' }}>*</span></Typography> <TextField  placeholder='please mention medication name' className={classes.input}
                    onChange={(e) => setMedicationName(e.target.value)} value={medicationName}
                    type="text"
                  /></li>
                  {medNameError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Medication name is mandatory(max character limit is 100)
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><Typography variant='p'>Supply<span style={{ color: '#ff0000' }}>*</span></Typography>
                    <div style={{ width: '100%' }}>
                      <Select
                        options={medSupply()}
                        onChange={(values) => setMedicationSupply(values[0] ? values[0].label : values.label)}
                        className={classes.datepicker}
                        style={{ width: "100%" }}
                        values={medSupply().filter(
                          (option) => option.label == medicationSupply
                        )}
                      />
                    </div>
                  </li>
                  {medSupplyError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Medication supply is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><Typography variant='p'>Instructions</Typography> <TextField className={classes.input}
                    onChange={(e) => setMedicationInstruction(e.target.value)} value={medicationInstruction} type="text" placeholder='please mention instruction if any' /></li>
                   {medicationInstructionError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Max character limit is 100
                    </p>
                  ) : (
                    <></>
                  )}
                  <li>
                 

                    <Typography variant='p'>Medication Type<span style={{ color: '#ff0000' }}>*</span></Typography>
                    <div style={{ width: '100%' }}>
                      <Select
                        options={medType()}
                        onChange={(values) => setMedicationType(values[0] ? values[0].label : values.label)}
                        className={classes.datepicker}
                        style={{ width: "100%" }}
                        values={medType().filter(
                          (option) => option.label == medicationType
                        )}
                      />
                    </div>
                  </li>
                  {medTypeError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Medication type is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}

                  {(medicationType == "DHCT") ?
                    <li>
                      <Typography variant='p'>Prescribed by<span style={{ color: '#ff0000' }}>*</span></Typography>
                      <div style={{ width: '100%' }}>
                        <Select
                          options={providerList}
                          onChange={(values) => setMedicationPrescribedby(values[0] ? values[0].value : values.value)}
                          className={classes.datepicker}
                          style={{ width: "100%" }}
                          values={providerList.filter(
                            (option) => option.value == medicationPrescribedby
                          )}
                        />
                      </div>
                    </li>
                    :
                    <>
                      {(medicationType == "EXTERNAL") ?
                        <li>
                          <Typography variant='p'>Prescribed by<span style={{ color: '#ff0000' }}>*</span></Typography>
                          <div style={{ width: '100%' }}>
                            <Select
                              options={otherProviderList}
                              onChange={(values) => setMedicationPrescribedby(values[0] ? values[0].value : values.value)}
                              className={classes.datepicker}
                              style={{ width: "100%" }}
                              values={otherProviderList.filter(
                                (option) => option.value == medicationPrescribedby
                              )}
                            />
                          </div>
                        </li>
                        :
                        <></>
                      }
                    </>
                  }

                  {medPrescribedbyError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Medication prescibed by is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  


                  <li>
                    <Typography variant='p'>Date Prescribed<span style={{ color: '#ff0000' }}>*</span></Typography>
                    <Box className={classes.Enddate}>
                      <DatePicker className={classes.input} placeholder="End Date" maxDate={moment().toDate()} clearIcon={null} value={medicationDate} onChange={(date) => setMedicationDate(date)} />
                    </Box>
                  </li>
                  {medDateError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Medication prescribed Date is mandatory
                    </p>
                  ) : (
                    <></>
                  )}

                  <li><Typography variant='p'>Notes</Typography><TextareaAutosize className={classes.textarea} aria-label="minimum height" minRows={4} value={medicationNotes} onChange={(e) => setMedicationNotes(e.target.value)} placeholder="notes if any" /></li>
                  {medicationNotesError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Max character limit is 200
                    </p>
                  ) : (
                    <></>
                  )}


                  <li><Typography variant='p'>Non-Adherence Status<span style={{ color: '#ff0000' }}>*</span></Typography> <Switch
                    checked={state.checkedA}
                    onChange={handleChange4}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}

                  /></li>

                  {state.checkedA ?
                    <div>
                      <li>
                        <Typography variant='p'>Non-adherence type<span style={{ color: '#ff0000' }}>*</span></Typography>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"

                          name="radio-buttons-group"
                          value={nonAdherenceType}
                        >
                          <FormControlLabel className={classes.customRadio} value="Stopped taking medication" control={<Radio selected={nonAdherenceType} onChange={handleChangeSelected} />} label="Stopped taking medication" />
                          <FormControlLabel className={classes.customRadio} value="Not taking as recommended" control={<Radio selected={nonAdherenceType} onChange={handleChangeSelected} />} label="Not taking as recommended" />
                        </RadioGroup>
                      </li>
                      {nonAdherenceTypeError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          Please select a non-adherence type.
                        </p>
                      ) : (
                        <></>
                      )}

                      <li>
                        <Box className={classes.formgroup}>
                          <label>What is the primary reason for stopping your medication ?<span style={{ color: '#ff0000' }}>*</span></label>
                          <TextareaAutosize className={classes.textarea}placeholder='please mention some reason'  type="text" value={medStopReason} onChange={(e) => setMedStopReason(e.target.value)} />
                        </Box>
                      </li>
                      {reasonError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          Please mention the reason. (max character limit is 200)
                        </p>
                      ) : (
                        <></>
                      )}


                      <li>
                        <Box className={classes.formgroup}>
                          <label>When did you stop taking this medication ?<span style={{ color: '#ff0000' }}>*</span></label>
                          <Box className={classes.Enddate}>

                            <DatePicker className={classes.input} clearIcon={null} placeholder="End Date" value={medStopDate} onChange={(date) => setMedStopDate(date)} />
                          </Box>
                        </Box>
                      </li>

                      {medStopError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'center'
                          }}
                        >
                          Medication stop date must be greater or same as Prescribed Date
                        </p>
                      ) : (
                        <></>
                      )}

                      <li>
                        {/* <Box className={classes.formgroup}>
        <label>Does the prescribing physician know you’ve stopped ?<span style={{color:'#ff0000'}}>*</span></label>
      <TextField className={classes.input} value={medStopKnow} onChange={(e) => setMedStopKnow(e.target.value)} type="text" />
      </Box> */}
                      </li>

                      {medKnowError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          This is a mandatory field.
                        </p>
                      ) : (
                        <></>
                      )}

                      <li>
                        {/* <Box className={classes.formgroup}>
        <label>Notes</label>
      <TextField className={classes.input} value={adherenceNote} onChange={(e) => setAdherenceNote(e.target.value)} type="text" />
      </Box> */}
                      </li>
                    </div>
                    :
                    <></>
                  }
                </ul>

              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
          {showLoader?
          <Loader
                  type="ThreeDots"
                  color="#000000"
                  height={50}
                  width={50}
                  timeout={0} //30 secs
                />
            :
            <Button size="large" className={classes.loginbtn} onClick={submitMed}>
              Submit
            </Button>
}
          </DialogActions>
        </Dialog>

        {/* modal */}
        <Dialog className={classes.modal}
          open={open3}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose3}
          disableEnforceFocus={true}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol} style={{ justifyContent: 'space-between' }}>
              <h3 className={classes.modaltop}>Non-Adherent To Medication</h3>
              <Button onClick={handleClose3} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <h4 className={classes.subheading}>Medication Name</h4>
              <Typography className={classes.modaltext}>Enalapril maleate 20mg tablet</Typography>
              <form>
                <ul className={classes.Editplan}>
                  <li><Typography variant='p'>Supply</Typography><TextField className={classes.input}
                    value="9 mg" type="text" /></li>
                  <li><Typography variant='p'>Instructions</Typography> <TextField className={classes.input}
                    value="1 tablet twice per day"
                    type="text"
                  /></li>
                  <li><Typography variant='p'>Medication Name</Typography> <TextField className={classes.input}
                    value="Sodium Chloride 80 g" type="text" /></li>
                  <li><Typography variant='p'>Prescribed by</Typography> <TextField className={classes.input}
                    value="Daniel Caldwell" type="text" /></li>

                  <li><Typography variant='p'>Medication Type</Typography> <TextField className={classes.input}
                    value="Med" type="text" /></li>
                  <li><Typography variant='p'>Date Prescribed</Typography> </li>

                  <li><Typography variant='p'>Notes</Typography><TextareaAutosize className={classes.textarea} aria-label="minimum height" minRows={4} value="Lorem ipsum dolor sit amet" /></li>

                </ul>

              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn} style={{ justifyContent: 'flex-end' }}>

            <Button size="large" className={classes.loginbtn}>
              Update
            </Button>
          </DialogActions>
        </Dialog>


        {/* Vaccination Modal */}
        <Dialog className={classes.modal}
          open={openVaccine}
          TransitionComponent={Transition}
          keepMounted
          disableEnforceFocus={true}
          onClose={handleCloseVaccine}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleCloseVaccine} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className={classes.Filter}>
                <h3 className={classes.Toptext}>Add/Edit Vaccination</h3>
              </Box>
              <form>
                <ul className={classes.Editplan2}>
                  <li><span>Vaccine Name<span style={{ color: '#ff0000' }}>*</span></span>
                    <div style={{ width: '100%' }}>
                      <Select
                        options={vaccineDBListOption}
                        onChange={(values) => setVaccineName(values[0] ? values[0].label : values.label)}
                        className={classes.datepicker}
                        style={{ width: "100%" }}
                        values={vaccineDBListOption.filter(
                          (option) => option.label == vaccineName
                        )}
                      />
                    </div>
                  </li>
                  {vaccineNameError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Vaccine name is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}

                  <li><span>Approx Date of Vaccination<span style={{ color: '#ff0000' }}>*</span></span>
                    <DatePicker className={classes.input} placeholder="Vaccination Date" 
                    maxDate={moment().toDate()} 
                    clearIcon={null}  value={vaccineDate} onChange={(date) => setVaccineDate(date)} />
                  </li>
                  {vaccineDateError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Enter a valid vaccination date.
                    </p>
                  ) : (
                    <></>
                  )}

                  <li><span>Vaccination Route<span style={{ color: '#ff0000' }}>*</span></span><TextField className={classes.input} onChange={(e) => setVaccineRoute(e.target.value)}
                    value={vaccineRoute}
                    type="text"
                  />
                  </li>
                  {vaccineRouteError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Vaccination route is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}

                  <li><span>Vaccine Manufacturer</span><TextField className={classes.input} onChange={(e) => setVaccineManufacturer(e.target.value)}
                    value={vaccineManufacturer}
                    type="text"
                  />
                  </li>
                </ul>
              </form>
            </DialogContentText>
          </DialogContent>
          {editVaccineData ?
            <DialogActions className={classes.modalbtn}>
              {vaccineShowLoader ? (
                <Loader
                  type="ThreeDots"
                  color="#000000"
                  height={50}
                  width={50}
                  timeout={0} //30 secs
                />
              ) : (
                <Button size="large" className={classes.loginbtn} onClick={() => submitVaccine(2)}>
                  Update
                </Button>
              )
              }
            </DialogActions>
            :
            <DialogActions className={classes.modalbtn}>
              {vaccineShowLoader ? (
                <Loader
                  type="ThreeDots"
                  color="#000000"
                  height={50}
                  width={50}
                  timeout={0} //30 secs
                />
              ) : (
                <Button size="large" className={classes.loginbtn} onClick={() => submitVaccine(1)}>
                  Submit
                </Button>
              )
              }
            </DialogActions>
          }
        </Dialog>


      </Box>
    </div>
  )
}

export default RviewMedication
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: '100%',
    display: 'flex',
    textAlign: 'left'
  },
  TabPatientinfo:{
    '@media only screen and (max-width: 820px)':{
      display:'none'
            },
    '@media only screen and (max-width: 768px)':{
      display:'none'
            },
  },
  editbtn: {
    background: '#88b3eb',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer',
  },
  deletebtn: {
    background: '#0f3d6e',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer'
  },
  Leftcol: {
    width: '22%',
    background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
    padding: '20px 3%',
    position: 'relative',
    minHeight: '1050px',
    '@media only screen and (max-width: 1200px)':{
      width: '24%',
      padding:'20px 1%',
            },
            '@media only screen and (max-width: 820px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
            '@media only screen and (max-width: 768px)':{
              position:'absolute',
              background:'none',
              width: '98%',
              padding:'20px 1%',
              minHeight:35,
                    },
  },
  Dateinput: {
    borderRadius: '10px',
    height: '42px',
    width: '98%',
    fontFamily: 'Poppins',
    fontSize: '12px',
    background: '#F9F9F9',
    border: '1px solid #D5D5D5',
    paddingLeft: 15,
    '&:focus': {
      border: '1px solid #D5D5D5 !important',
    }
  },
  datepicker: {
    borderRadius: '10px !important',
    height: '45px',
    width: '96%',
    fontFamily: 'Poppins',
    fontSize: '14px',
    background: '#F9F9F9',
    border: '1px solid #D5D5D5 !important',
    paddingLeft: 15,
    '&:focus': {
      border: '1px #0f3d6e solid !important',
    }
  },
  Dateinput2: {
    borderRadius: '10px',
    height: '42px',
    width: '98%',
    fontFamily: 'Poppins',
    fontSize: '12px',
    background: '#F9F9F9',
    border: '1px solid #D5D5D5',
    paddingLeft: 15,
    '&:focus': {
      border: '1px solid #D5D5D5 !important',
    }
  },
  calendaricon: {
    position: 'absolute',
    right: '5px',
    zIndex: '1',
    top: '12px',
    minWidth: '15px',
    height: '20px',
    width: '15px',
  },
  bottomnav: {
    position: 'absolute',
    bottom: '0px',
    left: '0px'
  },
  leftnav: {
    position: 'absolute',
    top: '15px',
    bottom: '15px',
    left: '40px',
    right: '40px',
    '@media only screen and (max-width: 1200px)':{
      left:'20px',
      right:'20px',
            },
  },
  subheading: {
    fontSize: '16px',
    color: '#000',
    marginBottom: '0px'
  },
  Rightcol: {
    width: '78%',
    padding: '20px 3%',
    borderLeft: '1px #F6F6F6 solid',
    '@media only screen and (max-width: 1200px)':{
      width:'72%',
      padding:'0 1%',
            },
            '@media only screen and (max-width: 820px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
            '@media only screen and (max-width: 768px)':{
              width:'96%',
              padding:'0 2%',
              marginTop:60,
                    },
    '& .MuiAccordionSummary-root': {
      borderBottom: '1px #5f80a9 solid',
      height: '40px',
      color: '#bfc4cb',
      padding: '0px',
      fontFamily: 'Poppins',
    },
    '& .MuiAccordion-root:before': {
      display: 'none'
    },
    '& .MuiTab-root': {
      minHeight: '40px'
    }
  },
  modaltop: {
    margin: '-20px 0 0'
  },
  Careplan: {
    width: '100%',
    '& .MuiAccordionSummary-root': {
      borderBottom: '1px #e3e5e5 solid',
      height: 'auto',
      color: '#bfc4cb',
      padding: '0px',
      fontFamily: 'Poppins',
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-content': {
      margin: '0',
      order: '2',
      width: '100%'
    },
    '& .MuiIconButton-root': {
      padding: '5px',
      fontSize: '16px',
      marginRight: '5px',
      color: '#88b3eb',
      position: 'absolute',
      left: '-25px'
    },
    '& .MuiAccordion-root:before': {
      display: 'none'
    },

    


  },
  modaltext: {
    marginTop: '5px',
    color: '#000',
    fontSize: '14px',
    marginBottom: '50px'
  },
  topheading: {
    fontSize: '18px',
    fontWeight: 'normal',
    color: '#000',
    fontWeight: '500'
  },
  Filter: {
    textAlign: 'left',
    margin: '5px 0 20px 0',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  Downarrow: {
    fontSize: '20px',
    color: '#0f3d6e',
    marginLeft: '5px'
  },
  Filtertext: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 7px',
    '& span': {
      color: '#bfc4cb',
      marginRight: '5px'
    }
  },
  contacttab: {
    background: '#fff',
    boxShadow: 'none',
    color: '#000',
    textTransform: 'capitalize',
    '& .MuiTabs-indicator': {
      display: 'none !important'
    },
    '& .MuiTabs-flexContainer': {
      borderRadius: '10px',
      background: '#F3F3F3',
      width: '320px',
      textTransform: 'capitalize',
      overflow: 'hidden',
      '& .MuiTab-textColorInherit.Mui-selected': {
        background: '#0f3d6e',
        color: '#ffffff'
      },
      '& .MuiTab-textColorInherit': {
        textTransform: 'capitalize',
        fontSize: '16px',
        padding: '0 22px'
      }
    },

  },
  Editbtn: {
    background: '#fff',
    border: '1px #AEAEAE solid',
    width: '60px',
    height: '30px',
    color: '#0f3d6e',
    textTransform: 'capitalize',
    borderRadius: '10px',
    fontWeight: '600',
    '&:hover': {
      background: '#0f3d6e',
      color: '#fff',
    }
  },

  icon: {
    color: '#0f3d6e',
    fontSize: '20px',
    marginRight: '10px'
  },
  throw: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px #E3E5E5 solid',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '16px',
    color: '#ffffff',
    padding: 6,
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
    width: '98%',
    alignItems: 'center',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
            }
  },
  tdrow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0px #E3E5E5 solid',
    fontSize: '12px',
    padding: '10px 0',
    width: '100%',
    alignItems: 'center',
    lineHeight: '15px',
    color: '#000',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
            }
  },
  providerbtn: {
    display: 'flex',
    cursor: 'pointer',
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      width: '20px',
      marginRight: '10px',
      '& button': {
        background: 'none',
        border: 'none',
        height: '10px',
        cursor: 'pointer'
      }
    }
  },
  Row1: {
    width: '22.5%',
    wordWrap:"break-word",
    paddingRight:'0.5%'
  },
  Row2: {
    width: '12.5%',
    paddingRight:'0.5%',
    textAlign : "center"
  },
  Row3: {
    width: '16.5%',
    paddingRight:'0.5%',
    wordWrap : "break-word",
    textAlign:'center'
  },
  Row4: {
    width: '12.5%',
    paddingRight:'0.5%'
  },
  Row5: {
    width: '14.5%',
    paddingRight:'0.5%',
    wordWrap: "break-word"
  },
  Row6: {
    width: '12.5%',
    paddingRight:'0.5%',
    wordWrap: "break-word"
  },
  Row7: {
    width: '9.5%',
    paddingRight:'0.5%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'right',
    wordWrap : "break-word"
  },
  Row8: {
    width: '14.5%',
    paddingRight:'0.5%',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  Row9: {
    width: '9.5%',
    paddingRight:'0.5%',
    textAlign: 'right'
  },
  Plandetails: {
    listStyle: 'none',
    margin: '10px 0 0',
    minHeight: '300px',
    padding: '0px',
    '& li': {
      fontSize: '12px',
      marginBottom: '25px',
      display: 'flex',
      position: 'relative',
      '& span': {
        flex: '0 0 200px',
        color: '#114ebd'
      }
    }
  },
  modal: {
    '& .MuiPaper-rounded': {
      borderRadius: '10px !important',
      padding: '20px',
      width: '776px',
      maxWidth: '776px',
    },
    '& .MuiFormControlLabel-label': {
      flex: '0 0 220px !important'
    },
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'poppins'
    },
    '& .MuiBox-root': {
      width: '78%'
    }
  },
  ccmmodal: {
    borderRadius: '10px',
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
  },
  modalbtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '30px',
    marginLeft: '15px',
    alignItems: 'center'
  },
  Optionsbtn: {
    width: '150px',
    border: '1px #AEAEAE solid',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    marginTop: '10px',
    float: 'right'
  },
  input: {

    border: 'none',
    borderRadius: '10px',
    height: '42px',
    width: '100%',
    fontFamily: 'Poppins',
    fontSize: '12px'
  },
  textarea: {
    width: '100%',
    borderRadius: '10px',
    background: '#F9F9F9',
    border: '1px solid #D5D5D5',
    padding: '10px 15px',
    fontFamily: 'Poppins',
    fontSize: '12px'
  },
  Toptext: {
    margin: ' 5px 0px',
    fontSize: '16px'
  },
  Editplan: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    '& li': {
      display: 'flex',
      fontSize: '14px',
      alignItems: 'center',
      marginBottom: '15px',
      position: 'relative',
      '& .MuiTypography-root': {
        flex: '0 0 150px',
        color: '#121212',
        margin: '0px'
      }
    },
    '& span': {

    },
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiInput-formControl': {
      height: '42px',

    },
    '& .MuiInputBase-input': {
      height: '42px',
      borderRadius: '10px',
      background: '#F9F9F9',
      border: '1px solid #D5D5D5',
      padding: '0 15px',
      fontFamily: 'Poppins',
      fontSize: '12px',
      color: '#000'
    },
    '& .MuiInputBase-input:focus': {
      border: '1px #0f3d6e solid',
      boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)'
    }
  },
  Editplan2:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        display:'flex',
        fontSize:'14px',
        alignItems:'flex-start',
        marginBottom:'15px',
        flexDirection:'column',
        '& span':{
            color:'#121212'
        }
    },
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'42px',
        
    },
    '& .MuiInputBase-input':{
        height:'42px',
        borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'0 15px',
        fontFamily:'Poppins',
        fontSize:'14px',
        color:'#000'
    }
},
  loginbtn: {
    background: '#0f3d6e',
    padding: '0 40px',
    width: '120px',
    height: '40px',
    borderRadius: '10px',
    color: '#fff',
    marginTop: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#333'
    }
  },
  btncol: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closebtn: {
    width: '40px',
    position: 'absolute',
    right: '10px',
    height: '40px',
    background: '#fff',
    top: '10px',
    minWidth: '40px',
    '&:hover': {
      background: '#fff'
    }
  },
  closeicon: {
    fontSize: '25px',
    color: '#0f3d6e'
  },
  plantop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    marginTop: '10px'
  },
  recordbtn: {
    background: '#fff',
    fontSize: '16px',
    color: '#0f3d6e',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#fff'
    }
  },
  addicon: {
    fontSize: '20px',
    color: '#0f3d6e',
    marginRight: '10px'
  },
  Tabcol: {
    '& .MuiTabPanel-root': {
      padding: '0px'
    }
  },
  radiobtn: {
    '& span': {
      fontSize: '13px',
      color: '#000'
    }
  },
  formgroup: {
    width: '100%',
    '& label': {
      color: '#000',
      marginBottom: '3px',
      display: 'flex'
    }
  },
  "& .MuiInputBase-root MuiInput-root MuiInput-underline MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedEnd": {
    margin: '0 0 5px 0 !important',
    width: '100%'
  },
  "& .MuiAutocomplete-input:first-child": {
    height: '100%',
    width: '100%'
  },
  "& .MuiAutocomplete-endAdornment": {
    top: 'calc(50% - 10px)',
    right: '8%',
    position: 'absolute'
  },
  MedicationTable:{
    width:'100%',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      overflowX:'auto'
            }
  }
}));