import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiArrowBack,BiClipboard, BiSearch,BiCheckSquare} from "react-icons/bi"

function PatientDetails() {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.pageTop}>
                     <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box>
                 <Box className={classes.Patientname}>
                     <p className={classes.nametext}>Patient Name</p>
                     <h4 className={classes.name}>Mike Burroughs</h4>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>EMR ID</span> 9999
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>Address</span> Flat no. 4/5D, JD Building
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>City / State</span> Chicago, Illinois
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>Zip Code</span> 600871
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>Phone</span> 123-456-7890
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>Last Office Visit</span> 30-05-2021
                 </Box>
                 </Box>
                 <Box className={classes.patientinfo} style={{marginBottom:'0px'}}>
                 <Box className={classes.info}>
                    <span>Next Office Visit</span> 24-07-2021
                 </Box>
                 </Box>
        </div>
    )
}

export default PatientDetails
const useStyles = makeStyles(() => ({
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    patientinfo:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        paddingBottom:'0',
        marginBottom:'0px'
    },
    info:{
        color:'#0f3d6e',
        fontSize:'16px',
        padding:'20px 0',
        display:'flex',
        '& span':{
            color:'#bfc4cb',
            fontSize:'12px',
            letterSpacing:'0.496657px',
            width:'120px'
        }
    },
    nametext:{
        fontSize:'12px',
        color:'#bfc4cb',
        margin:'40px 0 0'
    },
    name:{
        fontSize:'20px',
        color:'#ffffff',
        margin:'0 0 30px'
    },
}));