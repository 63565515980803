import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Select from "react-dropdown-select"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import {
  checkPhoneNumbers,
  checkEmail,
  checkPhone,
  checkZip,
  checkAddress,
  checkUSPhone
} from "../../Util/validations";
import { stateList } from "../../Util/DropDownData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientContactModal(props) {
  const classes = useStyles();

  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phoneHome, setPhoneHome] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [emailPrimary, setEmailPrimary] = useState("");
  const [emailSecondary, setEmailSecondary] = useState("");

  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [phoneHomeError, setPhoneHomeError] = useState(false);
  const [phoneMobileError, setPhoneMobileError] = useState(false);
  const [emailPrimaryError, setEmailPrimaryError] = useState(false);
  const [emailSecondaryError, setEmailSecondaryError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

 

  useEffect(() => {
    console.log("Patient Contact Props - ", props);
    if (props.isOpen && props.isEdit) {
      setStreet(props.patientInfo.patient_mailing_address);
      setCity(props.patientInfo.patient_city);
      setState(props.patientInfo.patient_state);
      console.log("state prop")
      console.log(props.patientInfo.patient_state)

      
      let stateCatList = stateList();
  
      for(var i=0; i<stateCatList.length; i++){
  
        let str = props.patientInfo.patient_state
     
  
      const words = str.split(" ");
  
     
  
  
       for (let j = 0; j < words.length; j++) {
        words[j] = words[j][0].toUpperCase() + words[j].substr(1);
        }
    
           let newWords = words.join(' ');
            console.log(newWords)
  
  
          if(stateCatList[i].label ==  newWords){
            console.log(stateCatList[i])
          
            setState(stateCatList[i]);
            console.log(state)
          }
    }
  
      setZip(props.patientInfo.patient_zip);
      setPhoneMobile(formatPhoneNumber(props.patientInfo.patient_primary_phone));
      setEmailPrimary(props.patientInfo.patient_email);
      setPhoneHome(formatPhoneNumber(props.patientInfo.patient_second_contact_mobile_phone));
      setEmailSecondary(props.patientInfo.patient_second_contact_email);
    } else {
      setPhoneHome("");
      setPhoneMobile("");
      setEmailPrimary("");
      setEmailSecondary("");
    }
    setStateError(false);
    setCityError(false);
    setStreetError(false);
    setZipError(false);
    setPhoneHomeError(false);
    setPhoneMobileError(false);
    setEmailPrimaryError(false);
    setEmailSecondaryError(false);
  }, [props.isOpen === true]);

  const handleSubmit = () => {

    console.log("state")
    console.log(state)
    console.log(emailPrimary)

    let isValid = true;

    console.log(state)


    if(phoneHome){
      if (!checkUSPhone(phoneHome)) {
        isValid = false;
        setPhoneHomeError(true);
      } else {
        setPhoneHomeError(false);
      }
    }else {
      setPhoneHomeError(false);
    }


    if (!checkAddress(street.trim())) {
      isValid = false;
      setStreetError(true);
    } else {
      setStreetError(false);
    }
    if (!checkAddress(city.trim())) {
      isValid = false;
      setCityError(true);
    } else {
      setCityError(false);
    }
    if (state) {
      setStateError(false);
      
    } else {
      isValid = false;
      setStateError(true);
    }

    if (!checkZip(zip)) {
      isValid = false;
      setZipError(true);
    } else {
      setZipError(false);
    }
    if (!checkUSPhone(phoneMobile)) {
      isValid = false;
      setPhoneMobileError(true);
    } else {
      setPhoneMobileError(false);
    }


    if (!checkEmail(emailPrimary)) {
     
      isValid = false;
      setEmailPrimaryError(true);
    } else {
      setEmailPrimaryError(false);
    
    }


    if (emailSecondary.trim().length > 0) {
      if (!checkEmail(emailSecondary)) {
        isValid = false;
        setEmailSecondaryError(true);
      } else {
        setEmailSecondaryError(false);
      }
    }

    // if(state.label || state[0].label){
    //   setStateError(false)
    // }else if(state.length === 0) {
    //   isValid = false
    //   setStateError(true)
      
    // }else {
    //   isValid = false
    //   setStateError(true)
    // }

    if(state.length === 0) {
      isValid = false
      setStateError(true)
      
    }else {
      
      setStateError(false)
    }





    if (isValid) {
      setShowLoader(true);
      if (props.isEdit) {
        let dataEdit = {
          pd_id: pat_details.pd_id,
          patient_primary_phone: phoneMobile.replace(/[^0-9]/g, ""),
          patient_email: emailPrimary,
          patient_mailing_address: street,
          patient_state: state.label? state.label : state[0].label,
          patient_city: city,
          patient_zip: zip,
          patient_second_contact_mobile_phone: phoneHome ? phoneHome.replace(/[^0-9]/g,"") : "",
          patient_second_contact_email: emailSecondary,
        };

        console.log("dataEdit", dataEdit);

        apiAxios
          .post("/patient/patient-contact-information-update", dataEdit, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.statusCode == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          });
      }
    }
  };


  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhoneMobile(formattedPhoneNumber);
  };
  

  const handlePhone = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhoneHome(formattedPhoneNumber);
  };
  

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }


  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        className={classes.modal}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
        </Box>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.onClose();
          }}
        >
          Edit Patient Contact Details
        </DialogTitle>

        <DialogContent className={classes.ccmmodal}>
          <form>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "poppins",
                fontWeight: "600",
                marginBottom: 20,
              }}
            >
              Phone Number(s)
            </Typography>
            <Box className={classes.Formcol}>
              <label>Mobile*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Mobile Number"
                type="tel"
                value={phoneMobile}
                onChange={(e) => {
                  
                 handleInput(e)
                }}
              />
            </Box>
            {phoneMobileError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid mobile number.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Home</label>
              <TextField
                className={classes.input}
                
                type="tel"
                placeholder="Enter Home Number"
                value={phoneHome}
                onChange={(e) => {
                  handlePhone(e)
                   
                }}
              />
            </Box>
            {phoneHomeError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid home number.
              </p>
            ) : (
              <></>
            )}
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "poppins",
                fontWeight: "600",
                marginBottom: 20,
              }}
            >
              Address
            </Typography>
            <Box className={classes.Formcol}>
              <label>Street Address*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Street Address"
                type="text"
                value={street}
                onChange={(e) => {
                  setStreetError(false);
                  setStreet(e.target.value);
                }}
              />
            </Box>
            {streetError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid street address.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>City*</label>
              <TextField
                className={classes.input}
                placeholder="Enter City"
                type="text"
                value={city}
                onChange={(e) => {
                  setCityError(false);
                  setCity(e.target.value);
                }}
              />
            </Box>
            {cityError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid city.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>State*</label>
              {/* <TextField
                className={classes.input}
                type="text"
                placeholder="Enter State"
                value={state}
                onChange={(e) => {
                  setStateError(false);
                  setState(e.target.value);
                }}
              /> */}
            
            <FormControl>
                        <Select
                          options={stateList()}
                          value={state}
                          // displayEmpty={true}
                          onChange={setState}
                          placeholder={state ? state.label : "Select State"}
                          className={classes.select}
                         
                        />
                        </FormControl>
                        </Box>
            {stateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid state.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Zip*</label>
              <TextField
                className={classes.input}
                type="text"
                placeholder="Enter Zip"
                value={zip}
                onChange={(e) => {
                 
                    setZipError(false);
                    setZip(e.target.value);
                  
                }}
              />
            </Box>
            {zipError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid zip.
              </p>
            ) : (
              <></>
            )}
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "poppins",
                fontWeight: "600",
                marginBottom: 20,
              }}
            >
              Email(s)
            </Typography>
            <Box className={classes.Formcol}>
              <label>Primary Email*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Primary Email"
                type="text"
                value={emailPrimary}
                onChange={(e) => {
                  setEmailPrimary(e.target.value);
                  setEmailPrimaryError(false);
                }}
              />
            </Box>
            {emailPrimaryError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid primary email.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Email</label>
              <TextField
                className={classes.input}
                placeholder="Enter Secondary Email"
                type="text"
                value={emailSecondary}
                onChange={(e) => {
                  setEmailSecondary(e.target.value);
                  setEmailSecondaryError(false);
                }}
              />
            </Box>
            {emailSecondaryError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid secondary email.
              </p>
            ) : (
              <></>
            )}
          </form>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PatientContactModal;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    '& .react-dropdown-select-input':{
        // display: 'none'
     
    
    }
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  Selectcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'20px',
    '&>div':{
        width:'100%'
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 205px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400'
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "78%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
