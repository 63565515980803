import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, FormControl, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import Select from "react-dropdown-select";
import LeftNav from "../components/LeftNav";
import Patientinfo from "../components/Patientinfo";
import Timer from "../components/Timer";
import { FaPlusCircle } from "react-icons/fa";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { BiXCircle, BiDownArrowAlt } from "react-icons/bi";
import { BiChevronUp, BiChevronDown, BiErrorCircle } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  durationOfPain,
  levelOfPain,
  frequencyOfPain,
  activeStatus,
  CCQActions,
} from "../Util/OtherList";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";

import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import { Pagination } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SymptomsCCQ(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("false");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setEditData(panel);
  };
  // Accordion2 //
  const [expanded2, setExpanded2] = React.useState("panel6");
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const [pdId, setPdId] = React.useState("");

  const [editData, setEditData] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);

  const [symptomsList, setSymptomsList] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const [message, setMessage] = React.useState("");

  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [symptom, setSymptom] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [levelofpain, setLevelofpain] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [worsenedby, setWorsenedby] = React.useState("");
  const [relievededby, setRelievedby] = React.useState("");
  const [dateStarted, setDateStarted] = React.useState("");
  const [dateRemedied, setDateRemedied] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [notes, setNotes] = React.useState("");

  const [symptomError, setSymptomError] = React.useState(false);
  const [descError, setDescError] = React.useState(false);
  const [locationError, setLocationError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [freqError, setFreqError] = React.useState(false);
  const [durationError, setDurationError] = React.useState(false);
  const [worsenedError, setWorsenedError] = React.useState(false);
  const [relievedError, setRelievedError] = React.useState(false);
  const [startError, setStartError] = React.useState(false);
  const [remediedError, setRemediedError] = React.useState(false);
  const [statusError, setStatusError] = React.useState(false);

  console.log(" sym props")
  console.log(props.PD_ID)

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    //setEditData(data);
  };

  const canBeOpen = open && Boolean(anchorEl);

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setShowLoader(false);
    setEditData("");
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setSymptom("");
    setLocation("");
    setDescription("");
    setWorsenedby("");
    setRelievedby("");
    setNotes("");
    setDateStarted("");
    setDateRemedied("");
    //setEditData("");
    setDuration("");
    setFrequency("");
    setLevelofpain("");
  };
  // modal2 //
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen2 = (item) => {
    let freqList = frequencyOfPain();
    for (var i = 0; i < freqList.length; i++) {
      if (freqList[i].value == item.symptom_frequency) {
        setFrequency(freqList[i]);
      }
    }
    let levelList = levelOfPain();
    for (var i = 0; i < levelList.length; i++) {
      if (levelList[i].value == item.symptom_level) {
        setLevelofpain(levelList[i]);
      }
    }
    let durationList = durationOfPain();
    for (var i = 0; i < durationList.length; i++) {
      if (durationList[i].value == item.symptom_duration) {
        setDuration(durationList[i]);
      }
    }
    let statusList = activeStatus();
    for (var i = 0; i < statusList.length; i++) {
      if (statusList[i].value == item.status) {
        setStatus(statusList[i]);
      }
    }

    setSymptom(item.symptom_name);
    setLocation(item.symptom_location);
    setDescription(item.symptom_description);
    setWorsenedby(item.symptom_worsened_by);
    setRelievedby(item.symptom_relieved_by);
    setNotes(item.symptom_notes);
    if (item.symptom_start_date) {
      var a_date = new Date(item.symptom_start_date);
      setDateStarted(a_date);
    }
    if (item.symptom_stop_date) {
      var stop_date = new Date(item.symptom_stop_date);
      setDateRemedied(stop_date);
    }

    setOpen2(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  useEffect(() => {
    let pat_details = JSON.parse(localStorage.getItem("patient"));
    if (pat_details) {
      setPdId(pat_details.pd_id);
      getSymptoms();
      //getRemediedSymptoms();
    }
  }, []);

  function handleClickDelete(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      symptoms_id: item.id,
      pd_id: pat_details.pd_id ? pat_details.pd_id : "",
      
    };

    apiAxios
      .post("/patient/patient-symptoms-delete", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          getSymptoms();
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";
      });
  }

  function handleClickReactivate(item) {
    console.log("Delete = ", item);

    let pat_details = JSON.parse(localStorage.getItem("patient"));

    let data = {
      symptoms_id: item.id,
      pd_id: pat_details.pd_id ? pat_details.pd_id : "",
    };

    apiAxios
      .post("/patient/reactive-remedied-symptoms", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          getSymptoms();
          //getRemediedSymptoms();
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";
      });
  }

  const deleteModal = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button
              className={classes.loginbtn}
              style={{
                border: "2px solid #0f3d6e",
                backgroundColor: "#fff",
                color: "#0f3d6e",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className={classes.loginbtn}
              style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
              onClick={() => {
                handleClickDelete(item);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      },
    });
  };

  const reactivateModal = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to reactivate this record?</p>
            <button
              className={classes.loginbtn}
              style={{
                border: "2px solid #0f3d6e",
                backgroundColor: "#fff",
                color: "#0f3d6e",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className={classes.loginbtn}
              style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
              onClick={() => {
                handleClickReactivate(item);
                onClose();
              }}
            >
              Yes, Reactivate
            </button>
          </div>
        );
      },
    });
  };

  const getSymptoms = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let pd = JSON.parse(localStorage.getItem("patient"));

      setSymptomsList([]);
      setMessage("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        pd_id: props.PD_ID ? props.PD_ID :pd.pd_id ,
        ccq_flag: "1",
        table_config_rows_per_page: table_config_rows_per_page,
      };

      apiAxios
        .post("/patient/list-ccq-symptoms", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            var temp_arr = [];
            for (var i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].ccq_flag == 1) {
                temp_arr.push(response.data.data[i]);
              }
            }
            setSymptomsList(temp_arr);
            setMessage("");
            setTotalPages(response.data.total_pages);
          } else if (response.data.statusCode == 502) {
            setMessage("No Data Found");
          } else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });

            setMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error(error.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";
        });
    }
  );

     useEffect(()=>{
      if(props.PD_ID){
      getSymptoms();
      }
     },[])

  const [remediedList, setRemediedList] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [remediedDate, setRemediedDate] = React.useState(new Date());
  const [remediedRelievedby, setRemediedRelievedBy] = React.useState("");
  const [remediedComments, setRemediedComments] = React.useState("");

  const [remediedDateError, setRemediedDateError] = React.useState(false);
  const [remediedRelievedbyError, setRemediedRelievedbyError] =
    React.useState(false);
  const [remediedCommentsError, setRemediedCommentsError] =
    React.useState(false);

  const getRemediedSymptoms = useCallback(
    async ({
      table_config_rows_per_page = "20",
      table_config_page_no = "1",
    } = {}) => {
      //setCurrentPage(parseInt(table_config_page_no));

      let pd = JSON.parse(localStorage.getItem("patient"));

      setRemediedList([]);
      setMessage2("Loading...");

      let data = {
        table_config_page_no: table_config_page_no,
        pd_id: pd.pd_id ?  pd.pd_id : "",
        table_config_rows_per_page: table_config_rows_per_page,
      };

      apiAxios
        .post("/patient/list-remedied-symptoms", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setRemediedList(response.data.data);
            setMessage2("");
            //setTotalPages(response.data.total_pages);
          } else if (response.data.statusCode == 502) {
            setMessage2("No Data Found");
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setMessage2(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";
        });
    }
  );

  const getMonthDiff = (start, end) => {
    const st = new moment(start);
    const en = new moment(end);
    var diff;

    if (start && end) {
      diff = moment.duration(en.diff(st)).months();
    }
    if (diff == 0) {
      return "< 1";
    } else {
      return diff;
    }
  };

  const submitRelieved = () => {
    let isValid = true;

    if (remediedDate) {
      setRemediedDateError(false);
    } else {
      setRemediedDateError(true);
      isValid = false;
    }

    if (remediedRelievedby.length > 0) {
      setRemediedRelievedbyError(false);
    } else {
      setRemediedRelievedbyError(true);
      isValid = false;
    }

    if (setRemediedComments.length > 0) {
      setRemediedCommentsError(false);
    } else {
      setRemediedCommentsError(true);
      isValid = false;
    }

    if (isValid) {
      setShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let data;
      let url;

      data = {
        pd_id: pat_details.pd_id ?  pat_details.pd_id : "",
        symptoms_id: needRemedied.id,
        symptom_remedied_date: remediedDate
          ? moment(remediedDate).format("YYYY-MM-DD")
          : "",
        remedied_relieved_by: remediedRelievedby,
        additional_comments_remedied: remediedComments,
      };

      url = "/patient/create-remedied-symptoms";

      console.log("DATA =", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
            setOpenRemedied(false);
            getSymptoms();
            getRemediedSymptoms();
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          localStorage.removeItem("Refresh_Token");
          localStorage.removeItem("Token");
          window.location.href = "/";
        });
    }
  };

  const [ccqOption1, setCcqOption] = React.useState(null);
  const [ccqSelect, setCCQSelect] = React.useState("")
  const [ccqOptionError, setCcqOptionError] = React.useState(false);

  const [needRemedied, setNeedRemedied] = React.useState("");
  const [openRemedied, setOpenRemedied] = React.useState(false);

  const remediedModal = (item) => {
    let selectedOption = "";
  
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>
              You want to remove this record from CCQ. Please select the CCQ
              action,
            </p>
            <div style={{ width: "100%" }}>
              <Select
                options={CCQActions()}
                onChange={(values) => {
                  selectedOption = values[0] ? values[0].value : values.value;
                  setCcqOption(selectedOption);
                  setCcqOptionError(false);
                }}
                className={classes.datepicker}
                style={{ width: "100%" }}
                values={CCQActions().filter(
                  (option) => option.value == ccqOption1
                )}
              />
            </div>
            {ccqOptionError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign: "right",
                }}
              >
                Please select a CCQ Action.
              </p>
            ) : (
              <></>
            )}
            <button
              className={classes.loginbtn}
              style={{
                border: "2px solid #0f3d6e",
                backgroundColor: "#fff",
                color: "#0f3d6e",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={onClose}
            >
              No
            </button>

            <button
              className={classes.loginbtn}
              style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
              onClick={() => {
                handleClickRemove(item, selectedOption);

                if(selectedOption){
                  
                  onClose();
                }else {
                  
                }
                

            
                
              }}
            >
              Yes, Remove
            </button>
          </div>
        );
      },
    });
  };

 

  const handleClickRemove = (item, ccqOption) => {
    //console.log("Delete = ", item);
    let isValid = true;
    let pat_details = JSON.parse(localStorage.getItem("patient"));
    let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));

    console.log("ccqOption - ", ccqOption);

    
    if (ccqOption) {
      setCcqOptionError(false);
    } else {
      setCcqOptionError(true);
      isValid = false;
    }


    if (isValid) {
      let data = {
        symptoms_ccq_id: item.id,
        pd_id: pat_details.pd_id ? pat_details.pd_id : "",
        ccq_action_id: ccqOption,
        patient_program_id: engage_details.patient_program_id
      };

      apiAxios
        .post("/patient/remedied-ccq-symptoms", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            getSymptoms();
            //getRemediedSymptoms();
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";
        });
    }
  };

  const closeRemedied = () => {
    setNeedRemedied("");
    setOpenRemedied(false);
  };

  const submitForm = (flag) => {
    let isValid = false;

    if (symptom.length > 0) {
      setSymptomError(false);
    } else {
      setSymptomError(true);
      isValid = false;
    }
    if (description.length > 0) {
      setDescError(false);
    } else {
      setDescError(true);
      isValid = false;
    }
    if (location.length > 0) {
      setLocationError(false);
    } else {
      setLocationError(true);
      isValid = false;
    }
    if (levelofpain) {
      setLevelError(false);
    } else {
      setLevelError(true);
      isValid = false;
    }
    if (frequency) {
      setFreqError(false);
    } else {
      setFreqError(true);
      isValid = false;
    }
    if (duration) {
      setDurationError(false);
    } else {
      setDurationError(true);
    }
    if (worsenedby.length > 0) {
      setWorsenedError(false);
    } else {
      setWorsenedError(true);
      isValid = false;
    }
    if (relievededby.length > 0) {
      setRelievedError(false);
    } else {
      setRelievedError(true);
      isValid = false;
    }
    if (dateStarted) {
      setStartError(false);
    } else {
      setStartError(true);
      isValid = false;
    }
    if (dateRemedied) {
      setRemediedError(false);
    } else {
      setRemediedError(false);
      isValid = false;
    }
    if (status) {
      setStatusError(false);
    } else {
      setStatusError(true);
      isValid = false;
    }

    if (isValid) {
      setShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let data;
      let url;
      if (flag == 1) {
        data = {
          pd_id: pat_details.pd_id ? pat_details.pd_id : "",
          symptom_name: symptom,
          symptom_start_date: moment(dateStarted).format("YYYY-MM-DD"),
          symptom_stop_date: moment(dateRemedied).format("YYYY-MM-DD"),
          symptom_description: description,
          symptom_frequency: frequency ? frequency[0].value : "",
          symptom_duration: duration ? duration[0].value : "",
          symptom_level: levelofpain ? levelofpain[0].value : "",
          symptom_location: location,
          symptom_seen_anyone: "No",
          symptom_worsened_by: worsenedby,
          symptom_relieved_by: relievededby,
          immediate_action_req: "",
          symptom_notes: notes,
          status: status ? status[0].value : "",
          ccq_flag: "0",
          ros_cat_id: "",
          ros_id: "",
          ros_symptoms_id: "",
        };

        url = "/patient/patient-symptoms-create";
      } else {
        data = {
          symptoms_id: editData,
          pd_id: pat_details.pd_id ? pat_details.pd_id : "",
          symptom_name: symptom,
          symptom_start_date: moment(dateStarted).format("YYYY-MM-DD"),
          symptom_stop_date: moment(dateRemedied).format("YYYY-MM-DD"),
          symptom_description: description,
          symptom_frequency: frequency.value
            ? frequency.value
            : frequency[0].value,
          symptom_duration: duration.value ? duration.value : duration[0].value,
          symptom_level: levelofpain.value
            ? levelofpain.value
            : levelofpain[0].value,
          symptom_location: location,
          symptom_seen_anyone: "No",
          symptom_worsened_by: worsenedby,
          symptom_relieved_by: relievededby,
          immediate_action_req: "",
          symptom_notes: notes,
          status: status.value ? status.value : status[0].value,
          ccq_flag: "1",
          ros_cat_id: "",
          ros_id: "",
          ros_symptoms_id: "",
        };

        url = "/patient/patient-symptoms-update";
      }

      console.log("DATA =", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
            setOpen2(false);
            getSymptoms();
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          localStorage.removeItem("Refresh_Token");
          localStorage.removeItem("Token");
          window.location.href = "/";
        });
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Careplan} style={{ marginTop: "20px" }}>
        <Box className={classes.throw}>
          <Box className={classes.Row1}> Symptom</Box>
          <Box className={classes.Row2}>Location</Box>
          <Box className={classes.Row3}>Level of pain</Box>
          <Box className={classes.Row4}>Frequency</Box>
          <Box className={classes.Row5}>Duration</Box>
          <Box className={classes.Row6}>Update Date</Box>
          <Box className={classes.Row7}>
            # Months Active
            <br /> Symptom
          </Box>
          <Box className={classes.Row8}>
            Symptom <br />
            Status
          </Box>
          <Box className={classes.Row9}>Added To CCQ</Box>
        </Box>

        {symptomsList.length > 0 ? (
          <>
            {symptomsList.map((each) => (
              <Accordion
                expanded={expanded == each.id ? true : false}
                onChange={handleChange(each.id)}
                style={{ boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<FaPlusCircle />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header" >
               
                  <Box className={classes.tdrow}>
                    <Box className={classes.Row1}>{each.symptom_name}</Box>
                    <Box className={classes.Row2}>{each.symptom_location}</Box>
                    <Box className={classes.Row3}>{each.symptom_level}</Box>
                    <Box className={classes.Row4}>{each.symptom_frequency}</Box>
                    <Box className={classes.Row5}>{each.symptom_duration}</Box>
                    <Box className={classes.Row6}>
                      {each.updated_at
                        ? moment.utc(each.updated_at).local().format("MM/DD/YYYY, h:mm a")
                        : "--"}
                    </Box>
                    <Box className={classes.Row7}>
                      {each.symptom_stop_date
                        ? getMonthDiff(
                            each.symptom_start_date,
                            each.symptom_stop_date
                          )
                        : getMonthDiff(each.symptom_start_date, new Date())}
                    </Box>
                    <Box className={classes.Row8}>
                      <Link to="">
                        {each.symptom_status == 1 ? "Active" : "Inactive"}
                      </Link>
                    </Box>
                    <Box className={classes.Row9}>
                      {each.ccq_symp_add_date ? each.ccq_symp_add_date : "--"}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails style={{ marginTop: "12px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                      <ul className={classes.Plandetails}>
                        <li>
                          <span>Description</span> {each.symptom_description}
                        </li>
                        <li>
                          <span>
                            Triggered or
                            <br /> worsened by
                          </span>{" "}
                          {each.symptom_worsened_by}
                        </li>
                        <li>
                          <span>Relieved by</span> {each.symptom_relieved_by}
                        </li>
                        <li>
                          <span>Date Started</span>
                          {each.symptom_start_date}
                        </li>
                        <li>
                          <span>
                            Date
                            <br /> Remedied
                          </span>
                          {each.symptom_stop_date}
                        </li>
                        <li>
                          <span>Notes</span>
                          {each.symptom_notes}
                        </li>
                      </ul>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      {/* action option */}
                      {props.PD_ID ? <></>
                       :
                     ( <Button
                        className={classes.Optionsbtn}
                        aria-describedby={canBeOpen ? each.id : undefined}
                        onClick={handleClick}
                      >
                        Options <BiChevronDown className={classes.Downarrow} />
                      </Button>) 
                        }
                      {each.id == editData ? (
                        <Popper
                          id={canBeOpen ? each.id : editData}
                          open={open}
                          anchorEl={anchorEl}
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={150}>
                              <Paper
                                style={{
                                  boxShadow: "none",
                                  width: "150px",
                                  border: "1px #AEAEAE solid",
                                  borderRadius: "10px",
                                }}
                              >
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                  >
                                    {/* <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => handleClickOpen2(each)}>Edit</MenuItem>
                                  <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => deleteModal(each)}>Delete</MenuItem> */}
                                    <MenuItem
                                      onClick={handleClose}
                                      style={{
                                        color: "#0f3d6e",
                                        fontSize: "14px",
                                      }}
                                      onClick={() => remediedModal(each)}
                                    >
                                      Remove
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        ) : (
          <Box style={{ textAlign: "center", margin: "5%" }}>{message}</Box>
        )}
      </Box>
      {/* tab with accordion */}

      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.btncol}>
            <Button onClick={handleClose2} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <form>
              <ul className={classes.Editplan}>
                <li>
                  <span>Symptom</span>
                  <TextField
                    className={classes.input}
                    onChange={(e) => setSymptom(e.target.value)}
                    value={symptom ? symptom : ""}
                    type="text"
                  />
                </li>
                {symptomError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Symptom is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Description</span>{" "}
                  <TextareaAutosize
                    onChange={(e) => setDescription(e.target.value)}
                    className={classes.textarea}
                    aria-label="minimum height"
                    minRows={4}
                    value={description ? description : ""}
                  />
                </li>
                {descError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Description is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Location</span>{" "}
                  <TextField
                    className={classes.input}
                    onChange={(e) => setLocation(e.target.value)}
                    value={location ? location : ""}
                    type="text"
                  />
                </li>
                {locationError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Location is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Level of pain</span>
                  <FormControl style={{ width: "100%" }}>
                    {" "}
                    <Select
                      options={levelOfPain()}
                      value={levelofpain ? levelofpain : ""}
                      onChange={setLevelofpain}
                      placeholder={
                        levelofpain ? levelofpain.label : "Select level of pain"
                      }
                      className={classes.datepicker}
                    />
                  </FormControl>
                </li>
                {levelError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Level of pain is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Frequency of pain</span>
                  <FormControl style={{ width: "100%" }}>
                    {" "}
                    <Select
                      options={frequencyOfPain()}
                      value={frequency ? frequency : ""}
                      onChange={setFrequency}
                      placeholder={
                        frequency ? frequency.label : "Select frequency of pain"
                      }
                      className={classes.datepicker}
                    />
                  </FormControl>
                </li>
                {freqError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Frequency is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Durations</span>{" "}
                  <FormControl style={{ width: "100%" }}>
                    {" "}
                    <Select
                      options={durationOfPain()}
                      value={duration ? duration : ""}
                      onChange={setDuration}
                      placeholder={
                        duration ? duration.label : "Select duration of pain"
                      }
                      className={classes.datepicker}
                    />
                  </FormControl>
                </li>
                {durationError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Duration is mandatory.
                  </p>
                ) : (
                  <></>
                )}

                <li>
                  <span>
                    Triggered or
                    <br /> worsened by
                  </span>
                  <TextareaAutosize
                    className={classes.textarea}
                    minRows={4}
                    onChange={(e) => setWorsenedby(e.target.value)}
                    value={worsenedby ? worsenedby : ""}
                  />
                </li>
                {worsenedError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Triggered or worsened by is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Relieved by</span>
                  <TextareaAutosize
                    className={classes.textarea}
                    minRows={4}
                    onChange={(e) => setRelievedby(e.target.value)}
                    value={relievededby ? relievededby : ""}
                  />
                </li>
                {relievedError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Relieved by is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Date Started</span>
                  <DatePicker
                    className={classes.Dateinput}
                    placeholder="Start Date"
                    selected={dateStarted}
                    onChange={(date) => setDateStarted(date)}
                  />
                </li>
                {startError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Start date is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Date Remedied</span>
                  <DatePicker
                    className={classes.Dateinput}
                    placeholder="Remedied Date"
                    selected={dateRemedied}
                    onChange={(date) => setDateRemedied(date)}
                  />
                </li>
                {remediedError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Remedied date is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Status</span>
                  <FormControl style={{ width: "100%" }}>
                    {" "}
                    <Select
                      options={activeStatus()}
                      value={status ? status : ""}
                      onChange={setStatus}
                      placeholder={status ? status.label : "Select status"}
                      className={classes.datepicker}
                    />
                  </FormControl>
                </li>
                {statusError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Status is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                <li>
                  <span>Notes</span>
                  <TextareaAutosize
                    className={classes.textarea}
                    onChange={(e) => setNotes(e.target.value)}
                    aria-label="minimum height"
                    minRows={4}
                    value={notes ? notes : ""}
                  />
                </li>
              </ul>
            </form>
          </DialogContentText>
        </DialogContent>
        {editData ? (
          <DialogActions className={classes.modalbtn}>
            {showLoader ? (
              <Loader
                type="ThreeDots"
                color="#000000"
                height={50}
                width={50}
                timeout={0} //30 secs
              />
            ) : (
              <Button
                size="large"
                className={classes.loginbtn}
                onClick={() => submitForm(2)}
              >
                Update
              </Button>
            )}
          </DialogActions>
        ) : (
          <DialogActions className={classes.modalbtn}>
            {showLoader ? (
              <Loader
                type="ThreeDots"
                color="#000000"
                height={50}
                width={50}
                timeout={0} //30 secs
              />
            ) : (
              <Button
                size="large"
                className={classes.loginbtn}
                onClick={() => submitForm(1)}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>

      {/* modal */}
      <Dialog
        className={classes.modal}
        open={openRemedied}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeRemedied}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <form>
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={closeRemedied} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.Filter}>
                    <h3 className={classes.Toptext}>Remedied Symptom</h3>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.symptomsdetails}>
                    <ul className={classes.Editplan}>
                      <li>
                        <label style={{ width: "40%" }}>
                          Approximate date remedied
                        </label>
                        <Box
                          className={classes.startDate}
                          style={{ marginLeft: "10px" }}
                        >
                          <DatePicker
                            className={classes.datepicker}
                            placeholder="Remedied Date"
                            selected={remediedDate}
                            onChange={(date) => setRemediedDate(date)}
                          />
                        </Box>
                      </li>
                      <li>
                        <span>
                          Relieved by<span style={{ color: "#ff0000" }}>*</span>
                        </span>
                        <TextField
                          className={classes.input}
                          onChange={(e) =>
                            setRemediedRelievedBy(e.target.value)
                          }
                          value={remediedRelievedby}
                          type="text"
                        />
                      </li>
                      {remediedRelievedbyError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: "right",
                          }}
                        >
                          Relieved by is mandatory.
                        </p>
                      ) : (
                        <></>
                      )}

                      <li>
                        <span>
                          Additional Comments
                          <span style={{ color: "#ff0000" }}>*</span>
                        </span>
                        <TextField
                          className={classes.input}
                          onChange={(e) => setRemediedComments(e.target.value)}
                          value={remediedComments}
                          type="text"
                        />
                      </li>
                      {remediedCommentsError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: "right",
                          }}
                        >
                          Additional comment is mandatory.
                        </p>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            {showLoader ? (
              <Loader
                type="ThreeDots"
                color="#000000"
                height={50}
                width={50}
                timeout={0} //30 secs
              />
            ) : (
              <Button
                size="large"
                className={classes.loginbtn}
                onClick={submitRelieved}
              >
                Confirm
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default SymptomsCCQ;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  Leftcol: {
    width: "22%",
    background: "linear-gradient(45deg, #114ebd, #0f3d6e)",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  plantop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    marginTop: "20px",
  },
  recordbtn: {
    background: "#fff",
    fontSize: "16px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  accSummar: {
    background: "rgb(243, 243, 243)",
    color: "rgb(15, 61, 110)",
    borderRadius: "10px",
    padding: "4px 10px",
    border: "none",
  },
  addicon: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginRight: "10px",
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
  },
  Rightcol: {
    width: "78%",
    padding: "20px 3%",
    borderLeft: "1px #F6F6F6 solid",
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid !important",
      height: "40px",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: "40px",
    },
  },
  Downarrow: {
    fontSize: "15px",
  },
  Careplan: {
    width: "100%",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      overflowX:'auto'
            },
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #e3e5e5 solid",
      height: "40px",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
      '@media only screen and (max-width: 1200px)':{
        width:1000,
              },
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  Filtertext: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 7px",
    "& span": {
      color: "#bfc4cb",
      marginRight: "5px",
    },
  },
  Dateinput: {
    borderRadius: "10px",
    height: "42px",
    width: "97%",
    fontFamily: "Poppins",
    fontSize: "12px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    paddingLeft: 15,
    "&:focus": {
      border: "1px solid #D5D5D5 !important",
    },
  },
  Toptext: {
    margin: "0px",
    fontSize: "16px",
  },
  contacttab: {
    background: "#fff",
    boxShadow: "none",
    color: "#000",
    textTransform: "capitalize",
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
    "& .MuiTabs-flexContainer": {
      borderRadius: "10px",
      background: "#F3F3F3",
      width: "360px",
      textTransform: "capitalize",
      overflow: "hidden",
      "& .MuiTab-textColorInherit.Mui-selected": {
        background: "#0f3d6e",
        color: "#ffffff",
      },
      "& .MuiTab-textColorInherit": {
        textTransform: "capitalize",
        fontSize: "16px",
        padding: "0 22px",
      },
    },
  },
  datepicker: {
    borderRadius: "10px !important",
    height: "45px",
    width: "96%",
    fontFamily: "Poppins",
    fontSize: "14px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5 !important",
    paddingLeft: 15,
    "&:focus": {
      border: "1px #0f3d6e solid !important",
    },
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  icon: {
    color: "#0f3d6e",
    fontSize: "20px",
    marginRight: "10px",
  },
  throw: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px #E3E5E5 solid",
    alignItems: "center",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#ffffff",
    background: "linear-gradient(to left, #114ebd, #0f3d6e)",
    padding: 6,
    width: "98%",
    alignItems: "center",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
     width:1200,
            }
  },
  tdrow: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "0px #E3E5E5 solid",
    fontSize: "12px",
    padding: "10px 5px",
    width: "98%",
    alignItems: "center",
    lineHeight: "15px",
    color: "#000",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
             }
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },
  Row1: {
    width: "9.5%",
    paddingRight:'0.5%',
    wordBreak: 'break-word'
  },
  Row2: {
    width: "13.5%",
    paddingRight:'0.5%',
    wordBreak: 'break-word'
  },
  Row3: {
    width: "11.5%",
    paddingRight:'0.5%'
  },
  Row4: {
    width: "9.5%",
    paddingRight:'0.5%',
    wordBreak: 'break-word'
  },
  Row5: {
    width: "9.5%",
    paddingRight:'0.5%'
  },
  Row6: {
    width: "12.5%",
    paddingRight:'0.5%',
    wordBreak: 'break-word'
  },
  Row7: {
    width: "13.5%",
    paddingRight:'0.5%',
    display: "flex",
    justifyContent: "center",
    textAlign: "right",
  },
  Row8: {
    width: "9.5%",
    paddingRight:'0.5%',
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  Row9: {
    width: "9.5%",
    paddingRight:'0.5%',
    textAlign: "right",
  },
  Plandetails: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
    "& li": {
      fontSize: "12px",
      marginBottom: "25px",
      display: "flex",
      "& span": {
        flex: "0 0 110px",
        color: "#114ebd",
      },
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "776px",
      maxWidth: "776px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "30px",
  },
  Optionsbtn: {
    width: "150px",
    border: "1px #AEAEAE solid",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    float: "right",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  textarea: {
    width: "100%",
    borderRadius: "10px",
    background: "#F9F9F9",
    border: "1px solid #D5D5D5",
    padding: "10px 15px",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Editplan: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
    "& li": {
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      marginBottom: "15px",
      "& span": {
        flex: "0 0 120px",
        color: "#121212",
        maxHeight: "50px",
      },
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  loginbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "120px",
    height: "40px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#0f3d6e",
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Downarrow: {
    fontSize: "20px",
    color: "#0f3d6e",
    marginLeft: "5px",
  },
  Filtertext: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 7px",
    "& span": {
      color: "#bfc4cb",
      marginRight: "5px",
    },
  },
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        display: "flex",
        fontSize: "15px",
        "& p": {
          borderLeft: "4px #0f3d6e solid",
          margin: "0px",
          paddingLeft: "10px",
          color: "#bfc4cb",
        },
        "& span": {
          color: "#000",
          fontSize: "16px",
          flex: "0 0 100px",
        },
      },
    },
  },
  bottomtext: {
    fontSize: "14px",
    color: "#AEAEAE",
    textAlign: "right",
    fontWeight: "400",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "45px",
    },
    "& .react-dropdown-select-input": {
      fontSize: 14,
    },
    "& .react-datepicker-ignore-onclickoutside:focus": {
      border: "1px #0f3d6e solid !important",
    },
    "& .MuiInputBase-input": {
      height: "45px",
      borderRadius: "10px",
      background: "#F9F9F9",
      padding: "0 15px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
}));
