import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import EmergencyContactModal from "./EmergencyContactModal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EmergencyContact() {
  const classes = useStyles();
  // modal //
  const [isOpenModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emergencyContactInfo, setEmergencyContactInfo] = useState([]);
  const [emergencyContactEditInfo, setEmergencyContactEdit] = useState([]);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  const handleModalOpen = (isEdit, data) => {
    setOpenModal(true);
    setIsEdit(isEdit);
    setEmergencyContactEdit(data);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    let data = {
      pd_id: pat_details.pd_id,
      table_config_rows_per_page: "15",
      table_config_page_no: "1",
    };

    apiAxios
      .post("/patient/list-patient-emergency-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          console.log("emergency contact - ", response.data.data);
          setEmergencyContactInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let data = {
      pd_id: pat_details.pd_id,
      table_config_rows_per_page: "15",
      table_config_page_no: "1",
    };

    apiAxios
      .post("/patient/list-patient-emergency-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          console.log("emergency contact - ", response.data.data);
          setEmergencyContactInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleClickDelete(item) {
    let data = {
      pd_id: pat_details.pd_id,
      emergency_contact_id: item.id,
    };

    apiAxios
      .post("/patient/delete-patient-emergency-contact", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleModalClose();
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  const deleteModal = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button
              className={classes.loginbtn}
              style={{
                border: "2px solid #0f3d6e",
                backgroundColor: "#fff",
                color: "#0f3d6e",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={onClose}
            >
              No
            </button>
            <button
              className={classes.loginbtn}
              style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
              onClick={() => {
                handleClickDelete(item);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      },
    });
  };

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }



  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 15,
          }}
        >
          <Button
            className={classes.Addbtn}
            onClick={() => {
              handleModalOpen(false, {});
            }}
          >
            <FiPlusSquare size="18" style={{ marginRight: 5 }} /> Add Contact
          </Button>
        </Grid>
        {emergencyContactInfo ? (
          <>
            {emergencyContactInfo.map((item) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 0,
                  }}
                >
                  <h3 className={classes.topheading}>
                    {item.patient_emergency_contacts_first_name +
                      " " +
                      item.patient_emergency_contacts_last_name}
                  </h3>
                  <Box className={classes.Filter}>
                    <Button
                      className={classes.Editbtn}
                      onClick={() => {
                        handleModalOpen(true, item);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className={classes.Deletebtn}
                      onClick={() => {
                        deleteModal(item);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box className={classes.symptomsdetails}>
                    <ul>
                      <li>
                        <span>Relationship</span>
                        <TextField
                          value={
                            item.patient_emergency_contacts_relationship
                              ? item.patient_emergency_contacts_relationship
                              : "--"
                          }
                        />
                      </li>
                      <li>
                        <span>Home Phone</span>{" "}
                        <TextField
                          type="tel"
                          value={
                            item.patient_emergency_contacts_home_phone_number
                              ? formatPhoneNumber(item.patient_emergency_contacts_home_phone_number)
                              : "--"
                          }
                        />
                      </li>
                      <li>
                        <span>Mobile</span>{" "}
                        <TextField
                          type="tel"
                          value={
                            item.patient_emergency_contacts_mobile_phone_number
                              ? formatPhoneNumber(item.patient_emergency_contacts_mobile_phone_number)
                              : "--"
                          }
                        />
                      </li>
                      <li>
                        <span>Primary Email</span>
                        <TextField
                          type="text"
                          value={
                            item.patient_emergency_contacts_primary_email
                              ? item.patient_emergency_contacts_primary_email
                              : "--"
                          }
                        />
                      </li>
                      <li>
                        <span>Secondary Email</span>
                        <TextField
                          type="text"
                          value={
                            item.patient_emergency_contacts_secondary_email
                              ? item.patient_emergency_contacts_secondary_email
                              : "--"
                          }
                        />
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </>
            ))}
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              margin: "5%",
              width: "100%",
              color: "#0f3d6e",
            }}
          >
            No Data Found
          </div>
        )}
      </Grid>
      <EmergencyContactModal
        emergencyInfo={emergencyContactEditInfo}
        isOpen={isOpenModal}
        onClose={handleModalClose}
        isEdit={isEdit}
      ></EmergencyContactModal>
    </div>
  );
}

export default EmergencyContact;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  loginbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "120px",
    height: "40px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "78%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
