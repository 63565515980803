import { Box } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import loginbg from '../images/login-bg.png'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
function LoginVerify({ options }) {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.Login}>
                <Box className={classes.LoginLeft}>
                    <Box className={classes.logo}><img src={logo} alt="logo" /></Box>
                    <h2>HealthyRPM</h2>
                    <Box className={classes.loginform}>
                        <form>
                        <Box className={classes.Formcol}>
                          <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Clients and it Users" className={classes.select} style={{width:'100%'}} />
                      </Box>
                        <h5 style={{color:'#AEAEAE', fontWeight:'normal', fontSize:'16px'}}>Verification Code</h5>    
                        <TextField className={classes.input} placeholder="Enter OTP Here" type="tel" />
        <Link to="/dashboard"><Button size="large" className={classes.loginbtn}>
        Verify
        </Button></Link>
                        </form>
                        <p>www.healthyrpm.com</p>
                    </Box>
                </Box>
                <Box className={classes.LoginRight}>
                  <Box className={classes.rightcontent}>
                      <h3>About HealthyRPM</h3>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                  </Box>
                <img src={loginbg} alt="login-bg" />
                </Box>
            </Box>
        </div>
    )
}

export default LoginVerify
const useStyles = makeStyles(() => ({
   Login:{
       display:'flex',
       width:'100%',
       '& a':{
           textDecoration:'none'
       }
   },
   LoginLeft:{
       width:'40%',
       paddingTop:'50px',
       paddingLeft:'5%',
       paddingRight:'5%',
       textAlign:'left',
       '& p':{
           fontSize:'16px',
           color:'#AEAEAE',
           marginTop:'70%'
       },
       '& h2':{
           fontSize:'34px',
           fontWeight:'bold',
           marginBottom:'50px'
       }
   },
   LoginRight:{
       width:'50%',
       position:'relative',
       '& img':{
           width:'100%'
       }
   },
   input:{ 
      
       border:'none',
       borderRadius:'10px',
       height:'50px',
       width:'100%',
       marginBottom:'30px'
   },
   loginform:{
       width:'70%',
       '& .MuiInput-underline:before':{
           display:'none'
       },
       '& .MuiInput-underline:after':{
           display:'none'
       },
       '& .MuiInput-formControl':{ 
           height:'50px',
           
       },
       '& .MuiInputBase-input':{
           height:'50px',
           borderRadius:'10px',
           background:'#F1F1F1',
           padding:'0 15px'
       }
   },
   loginbtn:{
       background:'#0f3d6e',
       padding:'0 40px',
       width:'142px',
       height:'45px',
       borderRadius:'10px',
       color:'#fff',
       marginTop:'20px',
       '&:hover':{
           background:'#333'
       }
   },
   select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    background:'#F1F1F1',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    '& .react-dropdown-select-input':{
        display:'none'
      }
},

// selectMax:{
//     width:'100%',
//     border:'none !important',
//     borderRadius:'10px !important',
//     background:'#F1F1F1',
//     height:'100px',
//     fontSize:'14px !important',
//     paddingLeft:'15px !important',
//     paddingRight:'15px !important',
//     paddingTop:'18px !important',
//     '& .react-dropdown-select-input':{
//         display:'none'
//       }
// },
   rightcontent:{
       width:'80%',
       padding:'0 10%',
       position:'absolute',
       display:'flex',
       justifyContent:'center',
       alignItems:'flex-start',
       flexDirection:'column',
       left:'0px',
       top:'0px',
       bottom:'0px',
       '& h3':{
           color:'#fff',
           margin:'0px'
       },
       '& p':{
           textAlign:'left',
           color:'#fff'
       }
   }
  }));