import { Box, Button} from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import profile from '../images/doctor2.jpg'
import profile2 from '../images/doctor1.jpg'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"

function SelectProvider() {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.pagecontent}>
                <Box className={classes.pagetop}><img src={logo} alt="logo" /></Box>
                <Box className={classes.providerlist}>
                    <h3>Providers List</h3>
                    <Box className={classes.toprow}>
                    <Grid container spacing={3}>
                    <Grid item xs={3} style={{textAlign:'left'}}>Profile</Grid>
                    <Grid item xs={7} style={{textAlign:'left'}}><Box className={classes.providerbtn} role="button"><span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> Provider</Box></Grid>
                    <Grid item xs={2}>Action</Grid>
                    </Grid>
                    </Box>
                    
                    <Box className={classes.providerrow}>
                    <Grid container spacing={3} alignItems="center">
                    <Grid item xs={3}><Box className={classes.profile}><img src={profile} alt="profile image" /></Box></Grid>
                    <Grid item xs={7}><p>Dr. Caldwell</p></Grid>
                    <Grid item xs={2}><Link to="">View</Link></Grid>
                    </Grid>
                    </Box>
                    <Box className={classes.providerrow}>
                    <Grid container spacing={3}>
                    <Grid item xs={3}><Box className={classes.profile}><img src={profile2} alt="profile image" /></Box></Grid>
                    <Grid item xs={7}><p>Dr. Carolyn Coursey</p></Grid>
                    <Grid item xs={2}><Link to="">View</Link></Grid>
                    </Grid>
                    </Box>
                    <Box className={classes.viewallbtn}><Link to="/dashboard"><Button>View All</Button></Link></Box>
                </Box>
                <Box className={classes.bottomtext}>
                <p>www.healthyrpm.com</p>
                </Box>
               
            </Box>
        </div>
    )
}

export default SelectProvider
const useStyles = makeStyles(() => ({
  pagecontent:{
      width:'90%',
      paddingLeft:'5%',
      paddingRight:'5%',
  },
  pagetop:{
      textAlign:'left',
      paddingTop:'20px'
  },
  providerlist:{
      width:'50%',
      margin:'0px auto',
      '& h3':{
          textAlign:'left',
          fontSize:'18px',
          color:'#0f3d6e',
          fontWeight:'500',
          marginBottom:'60px'
      }
  },
  bottomtext:{
    '& p':{
        color:'#AEAEAE',
        fontSize:'16px',
        textAlign:'left',
        marginTop:'100px'
    }
  },
  toprow:{
      width:'100%',
      borderBottom:'2px #E3E5E5 solid',
      display:'flex',
      color:'#bfc4cb',
      paddingBottom:'10px'
  },
  profile:{
      width:'80px',
      height:'80px',
      borderRadius:'50%',
      overflow:'hidden',
      '& img':{
          width:'100%'
      }
  },
  providerrow:{
      width:'100%',
      borderBottom:'1px #E3E5E5 solid',
      padding:'15px 0',
      display:'flex',
      '& p':{
          textAlign:'left'
      }
  },
  providerbtn:{
      display:'flex',
      cursor:'pointer',
      '& span':{
          display:'flex',
          flexDirection:'column',
          width:'20px',
          marginRight:'10px',
          '& button':{
              background:'none',
              border:'none',
              height:'10px',
              cursor:'pointer'
          }
      }
  },
  viewallbtn:{
      display:'flex',
      justifyContent:'flex-end',
      '& button':{
          color:'#0f3d6e',
          textTransform:'capitalize',
          fontSize:'16px'
      }
  }
  }));