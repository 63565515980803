import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiChevronUp,BiChevronDown, BiCloudDownload,BiArrowBack, BiCloudUpload, BiXCircle} from "react-icons/bi"
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import calendericon from '../images/calendar-icon.svg'

function LoginLogout({ options }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div>
            <Box className={classes.loginform}>
       <form>
       <Box className={classes.Formcol}>
               <label>Program</label>
               <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select a program" className={classes.select} style={{ width:'400px'}} />
           </Box>
           <Box className={classes.Formcol}>
               <label>Select Date</label>
               <Box className={classes.startDate}>
                 <Button className={classes.calendaricon}><img src={calendericon} alt="calendar icon" /></Button>
               <DatePicker className={classes.input} placeholder="Staet Date" selected={startDate} onChange={(date) => setStartDate(date)} />
               </Box>
               <Box style={{marginLeft:'20px'}}>-</Box>
               <Box className={classes.Enddate}>
               <Button className={classes.calendaricon}><img src={calendericon} alt="calendar icon" /></Button>
               <DatePicker className={classes.input} placeholder="End Date" selected={startDate} onChange={(date) => setStartDate(date)} />
               </Box>
               <Button className={classes.nextbtn}>Generate</Button>
           </Box>
           <Box className={classes.Careplan}>
           <Box className={classes.throw}> 
                         <Box className={classes.Row1}><Box className={classes.providerbtn} role="button"> User ID <span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> </Box> </Box>
                         <Box className={classes.Row2}><Box className={classes.providerbtn} role="button"> Login Date/Time <span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> </Box></Box>
                         <Box className={classes.Row3}><Box className={classes.providerbtn} role="button"> Logout Date/Time <span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> </Box></Box>
                         <Box className={classes.Row4}><Box className={classes.providerbtn} role="button"> Total Time Logged In <span><button><BiChevronUp /></button><button><BiChevronDown /></button></span> </Box></Box>
                         <Box className={classes.Row5}><Box className={classes.providerbtn} role="button"> Total Engagement Time</Box></Box>
                         <Box className={classes.Row6}><Box className={classes.providerbtn} role="button"> Logged In - Engage Time </Box></Box>
                     </Box>
                     <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Cadance-Gauntt</Box>
                         <Box className={classes.Row2}>06-24-2021 5:30pm</Box>
                         <Box className={classes.Row3}>06-24-2021 6:30pm</Box>
                         <Box className={classes.Row4}>00:37:24</Box>
                         <Box className={classes.Row5}>00:24:21</Box>
                         <Box className={classes.Row6}>00:13:03</Box>
                         
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>C-Coursey</Box>
                         <Box className={classes.Row2}>06-17-2021 11:33am</Box>
                         <Box className={classes.Row3}>06-17-2021 2:11pm</Box>
                         <Box className={classes.Row4}>00:55:14</Box>
                         <Box className={classes.Row5}>00:37:46</Box>
                         <Box className={classes.Row6}>00:22:22</Box>
                       
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>C-Coursey</Box>
                         <Box className={classes.Row2}>06-14-2021 8:10am</Box>
                         <Box className={classes.Row3}>06-14-2021 11:21pm</Box>
                         <Box className={classes.Row4}>02:11:19</Box>
                         <Box className={classes.Row5}>01:57:19</Box>
                         <Box className={classes.Row6}>00:37:24</Box>
                         
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Joe-Flower</Box>
                         <Box className={classes.Row2}>06-08-2021 3:44pm</Box>
                         <Box className={classes.Row3}>06-08-2021 4:25pm</Box>
                         <Box className={classes.Row4}>00:57:08</Box>
                         <Box className={classes.Row5}>00:26:59</Box>
                         <Box className={classes.Row6}>00:31:51</Box>
                         
                         </Box>
           </Box>
           <Box className={classes.btncol}>
             <Button className={classes.exportbtn}>Export/Print</Button>
           </Box>
       </form>
       </Box>
        </div>
    )
}

export default LoginLogout
const useStyles = makeStyles(() => ({
  input:{ 
    border:'none',
    borderRadius:'10px',
    height:'50px',
    width:'100%',
    background:'#F9F9F9',
    padding:'0 15px'
},
exportbtn:{
  fontSize:'18px',
  color:'#88b3eb',
  textTransform:'capitalize',
  '&:hover':{
    color:'#000',
    background:'transparent'
  }
},
Formcol:{
  display:'flex',
  alignItems:'center',
  marginBottom:'30px',
  '& .react-datepicker-wrapper':{
    width:'145px',
  },
  '& p':{
      fontSize:'18px',
      margin:'0px'
  },
  '& label':{
      width:'200px'
  },
  '& .react-dropdown-select-input':{
      width:'100%'
  }
},
loginform:{
    width:'100%',
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        background:'#F9F9F9',
        padding:'0 15px'
    }
},
loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'142px',
    height:'45px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    '&:hover':{
        background:'#333'
    }
},
throw:{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'2px #E3E5E5 solid',
  alignItems:'center',
  fontSize:'13px',
  lineHeight:'16px',
  color:'#bfc4cb',
  paddingBottom:'5px',
  width:'100%',
  alignItems:'center'
},
tdrow:{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'1px #E3E5E5 solid',
  fontSize:'14px',
  padding:'15px 0',
  width:'100%',
  alignItems:'center',
  lineHeight:'15px',
  color:'#000'
},
calendaricon:{
position:'absolute',
right:'-10px',
zIndex:'1',
top:'15px',
minWidth:'15px',
height:'20px',
width:'15px'
},
checkicon:{
  fontSize:'25px',
  color:'#47C932'
},
inputfile:{
  display:'none'
},
select:{
  width:'200px',
  border:'none !important',
  borderRadius:'10px !important',
  background:'#F9F9F9',
  height:'50px',
  fontSize:'14px !important',
  paddingLeft:'15px !important',
  paddingRight:'15px !important',
  '& .react-dropdown-select-input':{
    display:'none'
  }
},
startDate:{
marginRight:'30px',
position:'relative'
},
Enddate:{
position:'relative',
marginRight:'30px',
marginLeft:'20px'
},
Toptext:{
  fontSize:'18px',
  color:'#0f3d6e',
  fontWeight:'600'
},
Textheading:{
  fontSize:'16px',
  marginTop:'0px',
  fontWeight:'500'
},
Addbtn:{
  width:'180px',
  height:'45px',
  background:'#E13F66',
  borderRadius:'10px',
  color:'#fff',
  boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  textTransform:'capitalize',
  fontSize:'16px',
  '&:hover':{
      background:'#000'
  }
},
cancelbtn:{
  background:'#DADADA',
  borderRadius:'10px',
  textTransform:'capitalize',
  height:'45px',
  width:'120px',
  color:'#fff',
  fontWeight:'600',
  '&:hover':{
      background:'#000'
  }
},
nextbtn:{
  background:'#0f3d6e',
  borderRadius:'10px',
  textTransform:'capitalize',
  height:'45px',
  width:'120px',
  color:'#fff',
  fontWeight:'600',
  marginLeft:'15px',
  fontFamily:'Poppins',
  '&:hover':{
      background:'#000'
  }
},
btncol:{
  display:'flex',
  justifyContent:'flex-end',
  marginTop:'5px'
},
closebtn:{
  width:'40px',
  position:'absolute',
  right:'10px',
  height:'40px',
  background:'#fff',
  top:'10px',
  minWidth:'40px',
  '&:hover':{
      background:'#fff'
  }
},
closeicon:{
  fontSize:'25px',
  color:'#0f3d6e'
},
Row1:{
width:'15%'
},
Row2:{
width:'15%'
},
Row3:{
width:'20%'
},
Row4:{
width:'20%'
},
Row5:{
width:'15%'
},
Row6:{
width:'10%'
},
Row7:{
width:'10%',
display:'flex',
justifyContent:'center',
textAlign:'right'
},
Row8:{
width:'10%',
display:'flex',
justifyContent:'flex-end',
textAlign:'right'
},
Row9:{
width:'5%',
textAlign:'right'
},
Row10:{
width:'10%',
textAlign:'right'
},
providerbtn:{
display:'flex',
cursor:'pointer',
alignItems:'center',
'& span':{
    display:'flex',
    flexDirection:'column',
    width:'20px',
    marginRight:'10px',
    '& button':{
        background:'none',
        border:'none',
        height:'10px',
        cursor:'pointer'
    }
}
},
Careplan:{
  width:'100%',
  '& .MuiAccordionSummary-root':{
      borderBottom:'1px #5f80a9 solid',
      height:'auto',
      color:'#bfc4cb',
      padding:'0px',
      fontFamily:'Poppins',
  },
  '& .MuiAccordion-root.Mui-expanded':{
      margin:'0px'
  },
  '& .MuiAccordionSummary-content':{
      margin:'0',
      order:'2',
      width:'100%'
  },
  '& .MuiIconButton-root':{
      padding:'5px',
      fontSize:'16px',
      marginRight:'5px',
      color:'#88b3eb',
      position:'absolute',
      left:'-25px'
  },
  '& .MuiAccordion-root:before':{
      display:'none'
  }
  },    
}));