import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { makeStyles } from '@material-ui/core/styles'
  import Header from '../components/Header'
  import { Box,Grid, Link } from '@material-ui/core'
  import Button from '@material-ui/core/Button'
  import Setting from '../components/Setting'
  import LeftNav from '../components/LeftNav'
  import Patientinfo from '../components/Patientinfo'
  import Timer from '../components/Timer'
  import { FaPlusCircle } from "react-icons/fa"
  import Accordion from '@material-ui/core/Accordion'
  import AccordionDetails from '@material-ui/core/AccordionDetails'
  import AccordionSummary from '@material-ui/core/AccordionSummary'
  import FormControl from '@material-ui/core/FormControl'
  import Typography from '@material-ui/core/Typography'
  import Select from "react-dropdown-select"
  import NativeSelect from '@material-ui/core/NativeSelect'
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
  import ClickAwayListener from '@material-ui/core/ClickAwayListener'
  import Grow from '@material-ui/core/Grow'
  import Paper from '@material-ui/core/Paper'
  import Popper from '@material-ui/core/Popper'
  import Fade from '@material-ui/core/Fade'
  import MenuItem from '@material-ui/core/MenuItem'
  import MenuList from '@material-ui/core/MenuList'
  import Dialog from '@material-ui/core/Dialog'
  import DialogActions from '@material-ui/core/DialogActions'
  import DialogContent from '@material-ui/core/DialogContent'
  import DialogContentText from '@material-ui/core/DialogContentText'
  import DialogTitle from '@material-ui/core/DialogTitle'
  import Slide from '@material-ui/core/Slide'
  import TextField from '@material-ui/core/TextField'
  import TextareaAutosize from '@material-ui/core/TextareaAutosize'
  import { BiXCircle } from "react-icons/bi"
  import DatePicker from "react-datepicker"
  import "react-datepicker/dist/react-datepicker.css"
  import calendericon from '../images/calendar-icon.svg'
  import FormGroup from '@material-ui/core/FormGroup'
  import FormControlLabel from '@material-ui/core/FormControlLabel'
  import Checkbox from '@material-ui/core/Checkbox'
  import { apiAxios } from "../Util/ApiAxios"
  import moment from "moment";
  import { Pagination } from '@material-ui/lab';
  
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  
  import {hospitalizationIncidentType, CCQActions} from "../Util/OtherList";
  
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from "react-loader-spinner";
  
  import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
  import { set } from 'date-fns'
  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  
  function HospitalizationCCQ(props) {
      const classes = useStyles();
      const [expanded, setExpanded] = React.useState('');
      const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');
        console.log("expanded = ",expanded);
        setEditData(panel);
      };
       // Accordion2 //
    const [expanded2, setExpanded2] = React.useState('panel6');
    const handleChange3 = (panel) => (event, isExpanded2) => {
      setExpanded2(isExpanded2 ? panel : false);
    };
    
  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editData, setEditData] = React.useState("");
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    //setEditData(data);
  };
  
  const canBeOpen = open && Boolean(anchorEl);
  //const id = canBeOpen ? 'transition-popper' : undefined;
  
  
    const [open2, setOpen2] = React.useState(false);
  
    const [incident, setIncident] = React.useState("");
    const [incidentType, setIncidentType] = React.useState('');
    const [hospital, setHospital] = React.useState("");
    const [admitDate, setAdminDate] = React.useState(new Date());
    const [dischargeDate, setDischargeDate] = React.useState("");
    const [admitReason, setAdmitReason] = React.useState("");
    const [dischargeReason, setDischargeReason] = React.useState("");
    const [checked, setChecked] = React.useState(false);
  
    const [showLoader, setShowLoader] = React.useState(false);

    
    
    console.log(props.PD_ID)
    console.log(" hos props")



    const handleChangeChecked = (event) => {
      setChecked(event.target.checked);
    };
  
    
  
    const handleClickOpen = () => {
      setShowLoader(false);
      setEditData("");
      setOpen2(true);
    };

    

  
    const handleClickOpen2 = (item) => {
      
      let typeList = hospitalizationIncidentType();
      for(var i=0;i<typeList.length;i++){
        if(typeList[i].value == item.incident_type){
          setIncidentType(typeList[i]);
        }
      }
      console.log("item - ", item);
      console.log("incident type - ", incidentType);
      setIncident(item.incident);
      setAdmitReason(item.admission_reason);
      setDischargeReason(item.discharge_reason);
      var a_date = new Date(item.admission_date);
      setAdminDate(a_date);
      if(item.discharge_date){
        var d_date = new Date(item.discharge_date);
        setDischargeDate(d_date);
      }
      
      setHospital(item.hospital);
      setIncidentError(false);
      setHospitalError(false);
      setAdmitReasonError(false);
      setIncidentTypeError(false);
      setOpen2(true);
  
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
      //handleChange("");
      //setEditData("");
      setIncidentType([]);
      setIncident("");
      setAdmitReason("");
      setDischargeReason("");
      setAdminDate(new Date());
      setDischargeDate("");
      setHospital("");
      setIncidentError(false);
      setHospitalError(false);
      setAdmitReasonError(false);
      setIncidentTypeError(false);
    };
    const [value, setValue] = React.useState(0);
  
      const handleChange5 = (event, newValue) => {
        setValue(newValue);
      };
      const [startDate, setStartDate] = useState(new Date());
      const [state, setState] = React.useState({
        age: '',
        name: 'hai',
      });
    
      const handleChange7 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
      };
  
      const [pdId, setPdId] = React.useState("");
  
      useEffect(() => {
  
        let pat_details = JSON.parse(localStorage.getItem("patient"));
        if(props.PD_ID){
          getHospitalization();
        }
        if(pat_details){
          setPdId(pat_details.pd_id);
          getHospitalization();
        }
      }, []);  
  
      const [hospitalizationList, setHospitalizationList] = React.useState("");
      const [currentPage, setCurrentPage] = useState(1);
      const [totalPages, setTotalPages] = useState(1);
      const currentPageRef = useRef(1);
      const [message, setMessage] = React.useState("");
  
      const[incidentError, setIncidentError] = React.useState(false);
      const[incidentTypeError, setIncidentTypeError] = React.useState(false);
      const[hospitalError, setHospitalError] = React.useState(false);
      const[admitDateError, setAdmitDateError] = React.useState(false);
      const[admitReasonError, setAdmitReasonError] = React.useState(false);

      const [ccqOption1, setCcqOption] = React.useState(null);
      const [ccqOptionError, setCcqOptionError] = React.useState(false);


  
  
  
      const getHospitalization = useCallback(
        async ({
          table_config_rows_per_page = '15',
          table_config_page_no = '1',
        } = {}) => {
    
        setCurrentPage(parseInt(table_config_page_no));
  
        let pd = JSON.parse(localStorage.getItem("patient"));
            
        setHospitalizationList([]);
        setMessage("Loading...");
    
        let data ={
            table_config_page_no: table_config_page_no,
            pd_id: props.PD_ID ? props.PD_ID : pd.pd_id,
            table_config_rows_per_page: table_config_rows_per_page,
          }
    
        apiAxios
            .post("/patient/list-ccq-hospitalizations", data, {
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": localStorage.getItem("Token")
              },
            })
            .then((response) => {
              console.log("response --> ",response);
              if(response.data.statusCode == 200) {

                var temp_arr = [];
                for(var i=0;i<response.data.data.length;i++){
                    if(response.data.data[i].ccq_flag == 1){
                        temp_arr.push(response.data.data[i]);
                    }
                }

                  setHospitalizationList(temp_arr); 
                  setMessage("");
                  setTotalPages(response.data.total_pages);
                  
              }
              else if(response.data.statusCode == 502){
                  setMessage("No Data Found");
              }
              else {
                  // toast.error(response.data.message, {
                  //     position: "bottom-center",
                  //     autoClose: 5000,
                  //     hideProgressBar: false,
                  //     closeOnClick: true,
                  //     pauseOnHover: true,
                  //     draggable: true,
                  //     progress: undefined,
                  //     });
                  
                      setMessage(response.data.message);    
              }
            })
            .catch((error) => {
              console.log(error);
      
              // toast.error(error.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
    
                  // localStorage.removeItem("Refresh_Token");
                  // localStorage.removeItem("Token");
                  // window.location.href = "/";        
    
      
            });
      }
      );
  
      const submitForm = (flag) => {
        let isValid = false;
  
        if(incident.length >0){
          setIncidentError(false);
        }else{
          isValid=false;
          setIncidentError(true);
        }
        if(incidentType){
          setIncidentTypeError(false);
        }else{
          isValid = false;
          setIncidentTypeError(true);
        }
        if(hospital.length >0){
          setHospitalError(false);
        }else{
          isValid=false;
          setHospitalError(true);
        }
        if(admitReason.length >0){
          setAdmitReasonError(false);
        }else{
          isValid=false;
          setAdmitReasonError(true);
        }
        if(admitDate){
          setAdmitDateError(false);
        }else{
          isValid=false;
          setAdmitDateError(true);
        }
  
        if(isValid){
  
          setShowLoader(true);
  
          let pat_details = JSON.parse(localStorage.getItem("patient"));
          let data;
          let url;
          if(flag == 1){
            data ={
              pd_id: pat_details.pd_id,
              facility_type: "Hospital",
              facility_name: hospital,
              admission_date: moment(admitDate).format("YYYY-MM-DD"),
              admission_reason: admitReason,
              discharge_date: dischargeDate? moment(dischargeDate).format("YYYY-MM-DD") : "",
              discharge_reason: dischargeReason,
              incident_description: incident,
              incident_type: incidentType? incidentType[0].value : "",
              hospitalization_notes:"" ,
              ccq_flag: checked? "1" : "0"
            }
            
            url = "/patient/patient-hospitalizations-create";
          }
          else{
            data ={
              hospitalization_id: editData,
              pd_id: pat_details.pd_id,
              facility_type: "Hospital",
              facility_name: hospital,
              admission_date: moment(admitDate).format("YYYY-MM-DD"),
              admission_reason: admitReason,
              discharge_date: dischargeDate ? moment(dischargeDate).format("YYYY-MM-DD") : "",
              discharge_reason: dischargeReason,
              incident_description: incident,
              incident_type: incidentType.value ? incidentType.value : incidentType[0].value,
              hospitalization_notes:"" ,
              ccq_flag: checked? "1" : "0"
            }
  
            url = "/patient/patient-hospitalizations-update";
          }
  
          console.log("DATA =", data );
    
        apiAxios
            .post(url, data, {
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": localStorage.getItem("Token")
              },
            })
            .then((response) => {
              console.log("response --> ",response);
              if(response.data.status == 200) {
                toast.success(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
                setShowLoader(false);
                setOpen2(false);
                getHospitalization();  
              }
              else {
                  toast.error(response.data.message, {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      }); 
                      setShowLoader(false);
                      setOpen2(false);       
              }
            })
            .catch((error) => {
              console.log(error);
      
              toast.error(error.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
    
                  localStorage.removeItem("Refresh_Token");
                  localStorage.removeItem("Token");
                  window.location.href = "/";        
    
      
            });
  
        }
  
  
      }
  
      function handleClickDelete(item) {
        console.log("Delete = ", item);
  
        let pat_details = JSON.parse(localStorage.getItem("patient"));
  
        let data = {
          hospitalization_id: item.hospitalization_id,
          pd_id: pat_details.pd_id
        }
  
        apiAxios
        .post("/patient/patient-hospitalizations-delete", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            
            getHospitalization();  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  setShowLoader(false);       
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
  
              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        
  
  
        });
      }
  
      const deleteModal = (item) => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>Are you sure?</h1>
                <p>You want to delete this record?</p>
                <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
                <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                  onClick={() => {
                    handleClickDelete(item);
                    onClose();
                  }}
                >
                  Yes, Delete
                </button>
              </div>
            );
          }
        });
      };

      const remediedModal =React.useCallback( (item) => {
        let selectedOption = "";
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>Are you sure?</h1>
                <p>
                  You want to remove this record from CCQ. Please select the CCQ
                  action,
                </p>
                <div style={{ width: "100%" }}>
                  <Select
                    options={CCQActions()}
                    onChange={(values) => {
                      selectedOption = values[0] ? values[0].value : values.value;
                      setCcqOption(selectedOption);
                      setCcqOptionError(false);
                    }}
                    className={classes.datepicker}
                    style={{ width: "100%" }}
                    values={CCQActions().filter(
                      (option) => option.value == ccqOption1
                    )}
                  />
                </div>
                {ccqOptionError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: "right",
                    }}
                  >
                    Please select a CCQ Action.
                  </p>
                ) : (
                  <></>
                )}
                <button
                  className={classes.loginbtn}
                  style={{
                    border: "2px solid #0f3d6e",
                    backgroundColor: "#fff",
                    color: "#0f3d6e",
                    cursor: "pointer",
                    width: "auto",
                  }}
                  onClick={onClose}
                >
                  No
                </button>
    
                <button
                  className={classes.loginbtn}
                  style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
                  onClick={() => {
                    handleClickRemove(item, selectedOption, onClose);
                  
                  }}
                >
                  Yes, Remove
                </button>
              </div>
            );
          },
          closeOnClickOutside: false,
        });
      },[ccqOptionError]);
    
      const handleClickRemove = (item, ccqOption, onClose) => {
        //console.log("Delete = ", item);
        let isValid = true;
        let pat_details = JSON.parse(localStorage.getItem("patient"));
        let engage_details = JSON.parse(localStorage.getItem("engagement_timer"));
    
        console.log("ccqOption - ", ccqOption);
    
        if (ccqOption) {
          setCcqOptionError(false);
        } else {
          setCcqOptionError(true);
          isValid = false;
        }
        if (isValid) {
          let data = {
            hospitalizations_ccq_id: item.id,
            pd_id: pat_details.pd_id,
            ccq_action_id: ccqOption,
            patient_program_id: engage_details.patient_program_id
          }
    
          apiAxios
            .post("/patient/remedied-ccq-hospitalizations", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Token"),
              },
            })
            .then((response) => {
              console.log("response --> ", response);
              if (response.data.status == 200) {
                toast.success(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
    
                getHospitalization();
               
                //getRemediedSymptoms();
              } else {
                toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                //setShowLoader(false);
              }
              onClose();
            })
            .catch((error) => {
              console.log(error);
              
              toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
    
              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";
              onClose();
            });
           
        }
      };
  
  
      return (
          <div>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />               
                 
                 <Box className={classes.Careplan}>
                 <Box className={classes.throw}> 
                           <Box className={classes.Row1}> Incident</Box>
                           <Box className={classes.Row2}>Hospital</Box>
                           <Box className={classes.Row3}>Incident Type</Box>
                           <Box className={classes.Row4}>Admission Date</Box>
                           <Box className={classes.Row5}>Discharge Date</Box>
                           <Box className={classes.Row6}>Update<br /> date & time</Box>
                           <Box className={classes.Row7}>Added to CCQ</Box>
                       </Box> 
  
                       { (hospitalizationList.length >0) ?
                       <>
                       {hospitalizationList.map((each) => ( 
                       <Accordion expanded={(expanded == each.hospitalization_id)? true : false} onChange={handleChange(each.hospitalization_id)} style={{boxShadow:'none'}}>
          <AccordionSummary
            expandIcon={<FaPlusCircle />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
           <Box className={classes.tdrow}> 
           <Box className={classes.RowCol1}> {each.incident_description}</Box>
                           <Box className={classes.RowCol2}>{each.facility_name}</Box>
                           <Box className={classes.Row3}>{(each.incident_type == 1)? "Hospitalization Schedule" : (each.incident_type == 2) ? "Hospitalization Emergency" : (each.incident_type == 3) ? "Outpatient Schedule" : (each.incident_type == 4) ? "Urgent Care Emergency" : "Urgent Care Schedule"}</Box>
                           <Box className={classes.Row4}>{each.admission_date ? moment.utc(each.admission_date).local().format("YYYY-MM-DD") : "" }</Box>
                           <Box className={classes.Row5}>{each.discharge_date ? moment.utc(each.discharge_date ).local().format("YYYY-MM-DD") : "" }</Box>
                           <Box className={classes.Row6}>{each.updated_at? moment.utc(each.updated_at).local().format("YYYY-MM-DD HH:mm a") : ""}</Box>
                           <Box className={classes.Row7}>{each.ccq_hosp_add_date ? moment.utc(each.ccq_hosp_add_date).local().format("YYYY-MM-DD HH:mm a") : "" }</Box>
                           </Box>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
              <ul className={classes.Plandetails}>
                  <li><span>Notes</span> {each.hospitalization_notes ? each. hospitalization_notes : "--"}</li>
                  <li><span>Admission Reason</span> {each.admission_reason? each.admission_reason : '--'}</li>
                  <li><span>Discharge Reason</span> {each.discharge_reason? each.discharge_reason : '--'}</li>
                 
              </ul>
          </Grid>
          <Grid item xs={12} sm={3} style={{justifyContent:'flex-end'}}>
            {/* action option */}
            {props.PD_ID ? <></>
            :
          <Button className={classes.Optionsbtn}
            aria-describedby={canBeOpen? each.hospitalization_id : undefined}
            onClick={handleClick}
          >
            Options <BiChevronDown />
          </Button>
            }
          {(each.hospitalization_id == editData)?
          <Popper id={canBeOpen? each.hospitalization_id : editData} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={150}>
                <Paper style={{ boxShadow:'none',width:'150px', border:'1px #AEAEAE solid', borderRadius:'10px'}}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => remediedModal(each)}>Remove</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
          :
          <></>
          }
          </Grid>
          </Grid>
          </AccordionDetails>
        </Accordion>
        ))}
        </>
        :
        <Box style={{textAlign:'center'}}>{message}</Box>
        }
        </Box>
        <Box style={{width:'100%',display:'flex',justifyContent:'flex-end',overflow:'hidden'}}>
        <Pagination
                          count= {totalPages}
                          color="primary"
                          page={currentPage}
                          onChange={(event, pageNumber) =>
                            getHospitalization({
                              table_config_page_no: `${pageNumber}`,
                            })
                          }
                          style={{marginTop: '10px'}}
                        />
                    </Box>
                       
                     {/* tab with accordion */}
                      
                 
                 {/* modal */}
                 <Dialog  className={classes.modal}
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
              <Box className={classes.btncol}>
                <Typography style={{fontFamily:'Poppins',fontSize:18,fontWeight:'600',margin:'0 0 20px',}}>{editData? 'Edit Record' : 'Add New Record'}</Typography>
              <Button onClick={handleClose2} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
              </Box>
            <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
                <form>
              <ul className={classes.Editplan}>
                  <li><label>Describe the incident <span style={{color:'#ff0000'}}>*</span></label><TextField className={classes.input}
                           placeholder='Describe the incident' value={incident? incident : ""} onChange={(e) => setIncident(e.target.value)}
            type="text"
          /></li>
                {incidentError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Incident is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                  <li>
                    <label>Select the incident type <span style={{color:'#ff0000'}}>*</span></label> 
                    <FormControl className={classes.formControl}>
                    <Select
                            options={hospitalizationIncidentType()}
                            value={incidentType? incidentType : ""}
                            onChange={setIncidentType}
                            placeholder={incidentType? incidentType.label : "Select incident type"}
                            className={classes.datepicker}
                            searchable={incidentType? incidentType.label : ""}
                          />
                    </FormControl>
                  </li>
                  {incidentTypeError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Incident Type is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                  <li><label>Enter the hospital, urgent care or clinic visited <span style={{color:'#ff0000'}}>*</span></label> <TextField className={classes.input}
                            type="text" value={hospital?hospital : ""} onChange={(e) => setHospital(e.target.value)} /></li>
                 {hospitalError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Hosptal name is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                  <li className={classes.Formcol}><label>Approximate date admitted <span style={{color:'#ff0000'}}>*</span></label> 
                  <Box className={classes.startDate}>
                   <Button className={classes.calendaricon}><img src={calendericon} alt="calendar icon" /></Button>
                 <DatePicker className={classes.datepicker} placeholder="Start Date" selected={admitDate} onChange={(date) => setAdminDate(date)} />
                 </Box>
                          </li>
                          {admitDateError ? (
                            <p
                              style={{
                                color: "#dc3545",
                                fontSize: ".875em",
                                marginTop: "0.25rem",
                                textAlign: 'right'
                              }}
                            >
                              Admission date is mandatory.
                            </p>
                          ) : (
                            <></>
                          )}
                  <li><label>Approximate date discharged</label> 
                  <Box className={classes.startDate}>
                   <Button className={classes.calendaricon}><img src={calendericon} alt="calendar icon" /></Button>
                 <DatePicker className={classes.datepicker} placeholder="Start Date" selected={dischargeDate} onChange={(date) => setDischargeDate(date)} />
                 </Box>
                          </li>
                  
                  <li><label>Reason admitted <span style={{color:'#ff0000'}}>*</span></label><TextareaAutosize className={classes.textarea} aria-label="minimum height" minRows={4} value={admitReason?admitReason : ""} onChange={(e) => setAdmitReason(e.target.value)} /></li>
                  {admitReasonError ? (
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                      textAlign: 'right'
                    }}
                  >
                    Admit Reason is mandatory.
                  </p>
                ) : (
                  <></>
                )}
                  <li><label>Reason discharged</label><TextareaAutosize className={classes.textarea} aria-label="minimum height" minRows={4} value={dischargeReason?dischargeReason : ""} onChange={(e) => setDischargeReason(e.target.value)} /></li>
                  <li>
                  <FormGroup>
        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChangeChecked} />} label="Flag as Clinical Change" />
      </FormGroup>
                  </li>
              </ul>
              
              </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          {editData?
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn} onClick={() => submitForm(2)}>
                          Update
                      </Button>
                  )
          }
          </DialogActions>
          :
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn} onClick={() => submitForm(1)}>
                          Submit
                      </Button>
                  )
          }
          </DialogActions>
          }
        </Dialog>
          </div>
      )
  }
  
  export default HospitalizationCCQ
  const useStyles = makeStyles(() => ({
      Pagecontent:{
          width:'100%',
          display:'flex',
          textAlign:'left'
      },
      Leftcol:{
          width: '22%',
          background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
          padding:'20px 3%',
          position:'relative',
          minHeight:'1050px',
          '@media only screen and (max-width: 1024px)':{
            width: '24%',
            padding:'20px 1%',
          },
      },
      startDate:{
        marginRight:'30px',
        position:'relative',
        width:'100%'
        },
      bottomnav:{
          position:'absolute',
          bottom:'0px',
          left:'0px'
      },
      calendaricon:{
        position:'absolute',
        right:'20px',
        zIndex:'1',
        top:'15px',
        minWidth:'15px',
        height:'20px',
        width:'15px'
        },
      leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1024px)':{
        left:'20px',
        right:'20px',
              },
      },
      Rightcol:{
          width:'78%',
          padding:'20px 3%',
          borderLeft:'1px #F6F6F6 solid',
          '@media only screen and (max-width: 1024px)':{
            width:'72%',
            padding:'0 1%',
                  },
          '& .MuiAccordionSummary-root':{
              borderBottom:'1px #5f80a9 solid',
              height:'40px',
              color:'#bfc4cb',
              padding:'0px',
              fontFamily:'Poppins',
          },
          '& .MuiAccordion-root:before':{
              display:'none'
          },
          '& .MuiTab-root':{
            minHeight:'40px'
          }
      },
      
      Careplan:{
        width:'100%',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
         overflowX:'auto'
                },
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #e3e5e5 solid',
            height:'auto',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
            '@media only screen and (max-width: 1024px)':{
              width:1000,
                    },
        },
        '& .MuiAccordion-root.Mui-expanded':{
            margin:'0px'
        },
        '& .MuiAccordionSummary-content':{
            margin:'0',
            order:'2',
            width:'100%'
        },
        '& .MuiIconButton-root':{
            padding:'5px',
            fontSize:'16px',
            marginRight:'5px',
            color:'#88b3eb',
            position:'absolute',
            left:'-25px'
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
        },   
  topheading:{
    fontSize:'18px',
    fontWeight:'normal',
    color:'#000',
    fontWeight:'500'
  },
  Filter:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-end',
      alignItems:'flex-end'
  },
  Downarrow:{
      fontSize:'20px',
      color:'#0f3d6e',
      marginLeft:'5px'
  },
  Filtertext:{
      display:'flex',
      alignItems:'center',
      margin:'0 0 7px',
      '& span':{
          color:'#bfc4cb',
          marginRight:'5px'
      }
  },
  contacttab:{
    background:'#fff',
    boxShadow:'none',
    color:'#000',
    textTransform:'capitalize',
    '& .MuiTabs-indicator':{
      display:'none !important'
    },
    '& .MuiTabs-flexContainer':{
      borderRadius:'10px',
      background:'#F3F3F3',
      width:'360px',
      textTransform:'capitalize',
      overflow:'hidden',
      '& .MuiTab-textColorInherit.Mui-selected':{
        background:'#0f3d6e',
              color:'#ffffff'
      },
      '& .MuiTab-textColorInherit':{
        textTransform:'capitalize',
        fontSize:'16px',
        padding:'0 22px'
      }
    },
    
  },
  select:{
    width:'100%',
    borderRadius:'10px !important',
    background:'#F9F9F9',
    border:'1px solid #D5D5D5',
    height:'45px',
    fontSize:'14px !important',
    paddingLeft:'10px !important',
    paddingRight:'10px !important'
  },
  Editbtn:{
    background:'#fff',
    border:'1px #AEAEAE solid',
    width:'60px',
    height:'30px',
    color:'#0f3d6e',
    textTransform:'capitalize',
    borderRadius:'10px',
    fontWeight:'600',
    '&:hover':{
      background:'#0f3d6e',
      color:'#fff',
    }
  },
  
  icon:{
    color:'#0f3d6e',
    fontSize:'20px',
    marginRight:'10px'
  },
  throw:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'2px #E3E5E5 solid',
      alignItems:'center',
      fontSize:'13px',
      lineHeight:'16px',
      color:'#fff',
      background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
      padding: 6,
      width:'100%',
      alignItems:'center',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
        width:1000,
              }
  },
  tdrow:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'0px #E3E5E5 solid',
      fontSize:'13px',
      padding:'10px 0',
      width:'100%',
      alignItems:'center',
      lineHeight:'15px',
      color:'#000',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
        width:1000,
              }
  },
  providerbtn:{
      display:'flex',
      cursor:'pointer',
      '& span':{
          display:'flex',
          flexDirection:'column',
          width:'20px',
          marginRight:'10px',
          '& button':{
              background:'none',
              border:'none',
              height:'10px',
              cursor:'pointer'
          }
      }
  },
  Row1:{
      width:'14.5%',
      paddingRight:'0.5%',
      wordWrap:"break-word",
  },
  RowCol1:{
    width:'14.5%',
    paddingRight:'0.5%',
    wordWrap:"break-word",
},
  Row2:{
      width:'19.5%',
      paddingRight:'0.5%'
  },
  RowCol2:{
    width:'19.5%',
    paddingRight:'0.5%',
    wordWrap:"break-word",
    // overflow: "auto"
},
  Row3:{
      width:'17.5%',
      paddingRight:'0.5%',
      textAlign : "center"
  },
  Row4:{
      width:'14.5%',
      paddingRight:'0.5%',
      wordWrap:"break-word",
  },
  Row5:{
      width:'14.5%',
      paddingRight:'0.5%',
      wordWrap:"break-word",
  },
  Row6:{
      width:'14.5%',
      paddingRight:'0.5%',
      wordWrap:"break-word",
  },
  Row7:{
      width:'9.5%',
      paddingRight:'0.5%',
      display:'flex',
      justifyContent:'center',
      textAlign:'right',
      wordWrap:"break-word",
  },
  Row8:{
      width:'14.5%',
      paddingRight:'0.5%',
      display:'flex',
      justifyContent:'flex-end',
      textAlign:'right'
  },
  Row9:{
      width:'9.5%',
      paddingRight:'0.5%',
      textAlign:'right'
  },
  Plandetails:{
      listStyle:'none',
      margin:'10px 0 0',
      minHeight:'300px',
      padding:'0px',
      '& li':{
          fontSize:'12px',
          marginBottom:'25px',
          display:'flex',
          flexDirection:'column',
          '&> div':{
            width:'100%'
          },
          '& span':{
              flex:'1',
              color:'#114ebd',
              marginBottom: '8px'
          }
      }
  },
  modal:{
      '& .MuiPaper-rounded':{
          borderRadius:'10px !important',
          padding:'20px',
          width:'776px',
      },
      '& .MuiFormControl-root':{
        width:'100%'
      }
  },
  ccmmodal:{
      borderRadius:'10px',
  },
  modalbtn:{
      display:'flex',
      justifyContent:'flex-end',
      marginRight:'30px'
  },
  Optionsbtn:{
      width:'150px',
      border:'1px #AEAEAE solid',
      borderRadius:'10px',
      display:'flex',
      justifyContent:'space-between',
      textTransform:'capitalize',
      marginTop:'10px',
      float:'right'
  },
  Formcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'30px',
    position:'relative',
    '& .react-datepicker-wrapper':{
      width:'100%',
      background:'#F9F9F9',
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& .react-dropdown-select-input':{
        width:'100%'
    }
  },
  input:{ 
      border:'none',
      borderRadius:'10px',
      height:'45px',
      width:'100%',
      fontFamily:'Poppins',
      fontSize:'14px',
  },
  
  datepicker:{
    borderRadius:'10px !important',
      height:'45px',
      width:'96%',
      fontFamily:'Poppins',
      fontSize:'14px',
      background:'#F9F9F9',
      border:'1px solid #D5D5D5 !important',
      paddingLeft:15,
      '&:focus':{
        border:'1px #0f3d6e solid !important',
      }
  },
  textarea:{
      width:'94%',
      borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'10px 15px',
          fontFamily:'Poppins',
          fontSize:'14px'
  },
  Editplan:{
      listStyle:'none',
      margin:'0px',
      padding:'0px',
      '& li':{
          display:'flex',
          fontSize:'14px',
          alignItems:'flex-start',
          marginBottom:'15px',
          flexDirection:'column',
          '& span':{
              color:'#121212'
          }
      },
      '& .MuiInput-underline:before':{
          display:'none'
      },
      '& .MuiInput-underline:after':{
          display:'none'
      },
      '& .MuiInput-formControl':{ 
          height:'42px',
          
      },
      '& .MuiInputBase-input':{
          height:'42px',
          borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'0 15px',
          fontFamily:'Poppins',
          fontSize:'14px',
          color:'#000'
      }
  },
  loginbtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
  },
  btncol:{
      display:'flex',
      justifyContent:'space-between',
  },
  closebtn:{
      width:'40px',
      position:'absolute',
      right:'10px',
      height:'40px',
      background:'#fff',
      top:'10px',
      minWidth:'40px',
      '&:hover':{
          background:'#fff'
      }
  },
  closeicon:{
      fontSize:'25px',
      color:'#0f3d6e'
  },
  plantop:{
      display:'flex',
      justifyContent:'space-between',
      marginBottom:'30px',
      marginTop:'20px'
  },
  recordbtn:{
      background:'#fff',
      fontSize:'16px',
      color:'#0f3d6e',
      textTransform:'capitalize',
      '&:hover':{
          background:'#fff'
      }
  },
  addicon:{
      fontSize:'20px',
      color:'#0f3d6e',
      marginRight:'10px'
  },
  loginform:{
    width:'100%',
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'45px', 
    },
    '& .react-dropdown-select-input':{
      fontSize:14,
    },
    '& .react-datepicker-ignore-onclickoutside:focus':{
      border:'1px #0f3d6e solid !important',
    },
    '& .MuiInputBase-input':{
        height:'45px',
        borderRadius:'10px',
        background:'#F9F9F9',
        padding:'0 15px'
    },
    '& .MuiInput-input:focus':{
      border:'1px #0f3d6e solid',
      boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
  }
  },
     }));