import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from "react";
  import { Pagination } from "@material-ui/lab";
  import { makeStyles } from "@material-ui/core/styles";
  import { Button, Typography } from "@material-ui/core";
  import Header from "../components/Header";
  import Select from "react-dropdown-select";
  import { Box, Grid, Link } from "@material-ui/core";
  import {
    BiChevronUp,
    BiChevronDown,
    BiArrowBack,
    BiPlusCircle,
    BiXCircle,
    BiCheckCircle,
  } from "react-icons/bi";
  import Dialog from "@material-ui/core/Dialog";
  import DialogActions from "@material-ui/core/DialogActions";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogContentText from "@material-ui/core/DialogContentText";
  import Slide from "@material-ui/core/Slide";
  import TextField from "@material-ui/core/TextField";
  import FormControlLabel from "@material-ui/core/FormControlLabel";
  import FormControl from "@material-ui/core/FormControl";
  import FormGroup from "@material-ui/core/FormGroup";
  import { pink } from "@mui/material/colors";
  import Checkbox from "@mui/material/Checkbox";
  import { BiPlus, BiMinus } from "react-icons/bi";
  import DatePicker from "react-date-picker";
  import { apiAxios } from "../Util/ApiAxios";
  import { checkNumbersOnly, checkDob } from "../Util/validations";
  import moment from "moment";
  import Loader from "react-loader-spinner";
  import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  import {
    maxUseOptions,
    minTimeOptions,
    maxBillingFreqList,
    carePlanGridInfoList,
  } from "../Util/OtherList";
  import PatientStatus from '../components/PatientStatus'
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  function BillingList(options) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const [domain,setDomain]= React.useState("");
    const [excelList, setExcelList] = React.useState("")
    const [tableMessage, setTableMessage] = React.useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
  
  
   const [showLoader, setShowLoader] = React.useState(false)
    
    const [ifLoading, setIfLoading] = useState(false);
  
  
   
   
    /*Error*/
  
  
    const client_details = JSON.parse(localStorage.getItem("JWT"));
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  

  
    
    const getExcelList = useCallback(
        async ({
            table_config_rows_per_page = "15",
            table_config_page_no = "1",
        } = {}) => {
            setCurrentPage(parseInt(table_config_page_no));
      
           
      
            let data = {
                
                table_config_rows_per_page: table_config_rows_per_page,
                table_config_page_no: table_config_page_no,
            };
      
            setTableMessage("Loading...");
      
            apiAxios
                .post("/finalize/billing-codes/list-billing-files", data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("Token"),
                    },
                })
                .then((response) => {
                    console.log("patient excel data --> ", response);
                    if (response.data.statusCode == 200) {
                      console.log(response.data.data)
                   
                      setDomain(response.data.domain)
                   
                        setExcelList(response.data.data);
                        setTotalPages(response.data.total_pages);
      
                        if (response.data.data.length == 0) {
                            setTableMessage("No Data Found");
                        }
                    } else {
                      
                    }
                })
                .catch((error) => {
                    console.log(error);
      
                    toast.error("Something Went Wrong!", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
      );
      
  
  useEffect(()=>{
    getExcelList();
  },[])

  
 
  
    return (
      <div>
        <Header />
        <Box className={classes.pagecontent}>
        <Box className={classes.Leftcol}>
                 <Box className={classes.leftnav}>  
                 <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                      <a href="/manage-billing"> <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button> </a>
                   </Box>
                   <PatientStatus />
                   {/* left Accordion*/}
                   
                   </Box>
                 </Box>
  
                 <Box className={classes.Rightcol}>
  
        <Grid container spacing={3}>
       
          <Grid item xs={12}>
         
            <Box className={classes.btncol}>
            
              {/* <Link to="/dashboard">
                <Button className={classes.backBtn}>
                  <BiArrowBack
                    className={classes.backarrow}
                    onClick={backButton}
                  /> Back to previous
                </Button>
              </Link> */}
              {/* <a href="/dashboard"><Button><BiArrowBack className={classes.backarrow} style={{color:'#b4bcc9',}} /> Back to dashboard</Button></a> */}
              <h3 className={classes.topheading} style={{color :'#141621'}}>Billing List</h3>
            
             
            </Box>
           
            <Box className={classes.Throw1}>
              <Box className={classes.Thcol5}>Month</Box>
              <Box className={classes.Thcol6}>Filter</Box>
              <Box className={classes.Thcol8}>File Name</Box>
              <Box className={classes.Thcol9}>Generated By</Box>
              <Box className={classes.Thcol10}>Generated Date</Box>
              <Box className={classes.Thcol11}>Action</Box>
            </Box>
            {excelList?.length > 0 ? (
              <>
                {excelList.map((each) => (
                  <Box className={classes.Tdrow1}>
                    <Box className={classes.Tdcol5}>{each.filter_value.month}</Box>
                    <Box className={classes.Tdcol6}>{each.program_name + " " + each.provider_name}</Box>
                    <Box className={classes.Tdcol8}>
                    {each.file_uri.substring(each.file_uri.lastIndexOf('/') + 1)}
                    </Box>
                    <Box className={classes.Tdcol9}>
                    {each.generated_by ? each.generated_by : "--"}
                    </Box>
                    <Box className={classes.Tdcol10}>
                    {each.generated_at? moment(each.generated_at).format("YYYY-MM-DD HH:mm a") : "--"}
                    </Box>
                    <Box className={classes.Tdcol11}>
                      {/* <Button className={classes.EditBtn} onClick={() => handleClickOpenView(each,2)}>Edit</Button> */}
                      <Button
                      className={classes.ActionBtn}
                        onClick={()=>{
                            window.location = domain + each.file_uri
                        }}
                    >
                     Download
                    </Button>
                     
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              <Box className={classes.Tdrow1}>
                <Box
                  className={classes.Tdcol12}
                  style={{ width: "100%", textAlign: "center", color: "#dfdfdf" }}
                >
                  No Data Available
                </Box>
              </Box>
            )}
            <div style={{display:"flex",justifyContent:"flex-end", width:"100%"}}>
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, pageNumber) =>
                getExcelList({
                  table_config_page_no: `${pageNumber}`,
                })
              }
            />
            </div>
          </Grid>
        </Grid>
        </Box> 
        </Box>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
  
     
    
       
      </div>
    );
  }
  
  export default BillingList;
  const useStyles = makeStyles(() => ({
    select: {
      width: "100%",
      border: "none !important",
      borderRadius: "10px !important",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      height: "50px",
      fontSize: "18px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
      
    },
    backarrow:{
      color:'#b4bcc9',
      fontSize:'20px',
      marginRight:'8px'
  },
  pagecontent:{
    width:'100%',
    background: 'linear-gradient(to bottom, #0f3d6e05, #ffffff00)',
    // padding:'20px 3%',
    display:'flex',
    // flexDirection:'column',
    '& a':{
      textDecoration:'none'
    }
  },
    selectMax: {
      width: "100%",
      border: "none !important",
      borderRadius: "10px !important",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      height: "50px",
      fontSize: "18px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
      '& .react-dropdown-select-content':{
        '& span':{
            fontSize:11
        }
               }
     
    },
    marginTopTen: {
      marginTop: "10px !important",
      fontSize: "15px !important",
    },
    SubmitAdd: {
      background: "#1612C6",
      borderRadius: 50,
      height: 30,
      minWidth: 30,
      color: "#fff",
      fontFamily: "Poppins",
      fontWeight: "600",
      width: 30,
      fontSize: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "capitalize",
      "&:hover": {
        background: "#0A70E8",
      },
    },
    removebtn: {
      background: "#DF0909",
      borderRadius: 50,
      height: 30,
      color: "#fff",
      fontFamily: "Poppins",
      width: 30,
      minWidth: 30,
      fontWeight: "600",
      fontSize: 20,
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: "#0A70E8",
      },
    },
    Formrow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    Throw: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
    },
    Tdrow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
    },
    Throw1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: "1px rgba(0,0,0,0.1) solid",
      paddingBottom: 4,
      background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
      color:'#fff',
      padding:"10px 0px"
    },
    pageTop:{
      textAlign:'left',
      '& button':{
          padding:'0px 5px',
          background:'none',
          color:'#bfc4cb',
          fontSize:'14px',
          textTransform:'capitalize',
          fontWeight:'500',
          borderRadius:20,
      }
  },
    Tdrow1: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
      borderBottom: "1px rgba(0,0,0,0.1) solid",
      padding: "10px 0",
    },
    Thcol: {
      width: "40%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol2: {
      width: "25%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol3: {
      width: "25%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol4: {
      width: "10%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol5: {
      width: "15%",
      fontSize: 16,
      // color: "#696969",
      textAlign : "left",
      paddingLeft: "10px",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol6: {
      width: "22%",
      fontSize: 16,
      // color: "#696969",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol7: {
      width: "14%",
      fontSize: 16,
      // color: "#696969",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol8: {
      width: "20%",
      fontSize: 16,
      // color: "#696969",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol9: {
      width: "14%",
      fontSize: 16,
      // color: "#696969",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol10: {
      width: "30%",
      fontSize: 16,
      // color: "#696969",
      fontFamily:'poppins',
      fontSize:14,
    },
    Thcol11: {
      width: "15%",
      fontSize: 16,
      // color: "#696969",
      // display: "flex",
      // justifyContent: "flex-end",
      fontFamily:'poppins',
      fontSize:14,
    },
    Tdcol: {
      width: "40%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Tdcol2: {
      width: "25%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Tdcol3: {
      width: "25%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Tdcol4: {
      width: "10%",
      fontFamily:'poppins',
      fontSize:14,
    },
    Tdcol5: {
      width: "15%",
      wordWrap:"break-word",
      overflow: "auto",
      textAlign : "left",
      paddingLeft : "10px",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
  
    },
    Tdcol6: {
      width: "22%",
      wordWrap:"break-word",
      overflow: "auto",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
    },
    Tdcol7: {
      width: "14%",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
    },
    Tdcol8: {
      width: "20%",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
    },
    Tdcol9: {
      width: "14%",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
    },
    Tdcol10: {
      width: "30%",
      fontFamily:'poppins',
      fontSize:14,
      paddingRight:5,
    },
    Tdcol11: {
      width: "15%",
      // display: "flex",
      // justifyContent: "flex-end",
      fontFamily:'poppins',
      fontSize:14,
    },
    ModalTop: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 25,
    },
    checkCol: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: 40,
    },
    checkList: {
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
      "& label": {
        flex: "0 0 160px !important",
        color: "#141621",
        marginTop: "0px !important",
      },
    },
    leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1200px)':{
        left:'20px',
        right:'20px',
              },
      },
      Leftcol: {
        width: "15%",
        padding: "20px 3%",
        position: "relative",
        minHeight: "800px",
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                },   
                '@media only screen and (max-width: 768px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                }        
      },
      Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #F6F6F6 solid',
        '@media only screen and (max-width: 820px)':{
          width:'96%',
          padding:'0 2%',
          marginTop:30,
                },
        '@media only screen and (max-width: 768px)':{
          width:'96%',
          padding:'0 2%',
          marginTop:30,
                },
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minWidth:'33%',
        },
        '& .MuiTab-root:nth-child(6)':{
          minWidth:'30%',
        },
        '& .MuiTab-root:nth-child(7)':{
          minWidth:'30%',
        },
        // '& .MuiBox-root':{
        //     paddingLeft:'0px',
        //     paddingRight:'0px'
        // }
    },
    ActionBtn: {
      borderRadius: 10,
      textTransform: "capitalize",
      background: "#0A70E8",
      color: "#fff",
      "&:hover": {
        background: "rgba(0,0,0,0.8)",
      },
    },
    EditBtn: {
      borderRadius: 10,
      textTransform: "capitalize",
      background: "#E8740A",
      color: "#fff",
      marginRight: 10,
      "&:hover": {
        background: "rgba(0,0,0,0.8)",
      },
    },
    TopHeading: {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "600",
      color : "#000 !important"
    },
    addprovider: {
      fontSize: 16,
      textTransform: "capitalize",
      color: "#7087A7",
      "&:hover": {
        background: "none",
        color: "#000",
      },
    },
    btncol: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 20,
      color:'#bfc4cb',
    },
    icon: {
      color: "#7087A7",
      fontSize: "24px",
      marginRight: "10px",
    },
    // backarrow: {
    //   color: "#8088A8",
    //   fontSize: "20px",
    //   marginRight: "8px",
    // },
    Formcol: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "20px",
      "&>div": {
        width: "100%",
      },
      "& p": {
        fontSize: "18px",
        margin: "0px",
      },
      "& label": {
        flex: "0 0 205px",
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
        marginTop: 10,
      },
      "& .react-dropdown-select-input": {
        width: "100%",
        
      },
    },
    input: {
      border: "none",
      borderRadius: "10px",
      height: "50px",
      width: "100%",
    },
    Formcol2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "5px",
      "& label": {
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
      },
    },
    Formcol45: {
      alignItems: "left",
      justifyContent: "space-between",
      marginBottom: "5px",
      "& label": {
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
      },
    },
    modal: {
      "& .MuiPaper-rounded": {
        borderRadius: "10px !important",
        padding: "20px",
        width: "776px",
        maxWidth: "776px",
      },
    },
    ccmmodal: {
      borderRadius: "10px",
    },
    modalbtn: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "30px",
      marginLeft: "15px",
      alignItems: "center",
    },
    closebtn: {
      width: "40px",
      position: "absolute",
      right: "10px",
      height: "40px",
      background: "#fff",
      top: "10px",
      minWidth: "40px",
      "&:hover": {
        background: "#fff",
      },
    },
    closeicon: {
      fontSize: "25px",
      color: "#7087A7",
    },
    loginform: {
      width: "100%",
      "& .MuiInput-underline:before": {
        display: "none",
      },
  
      
  
      "& .react-date-picker__wrapper": {
        borderRadius: 10,
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        padding: "0 10px",
      },
      "& .react-date-picker__inputGroup__input:focus": {
        border: 0,
        boxShadow: "none",
      },
      "& .MuiInput-underline:after": {
        display: "none",
      },
      "& .MuiInput-formControl": {
        height: "50px",
      },
      "& .MuiInput-input:focus": {
        border: "1px #7087A7 solid",
        boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
      },
      "& .MuiInputBase-input": {
        height: "50px",
        borderRadius: "10px",
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        padding: "0 15px",
      },
      
      
    },
    loginbtn: {
      background: "#1612C6",
      padding: "0 40px",
      width: "142px",
      height: "45px",
      borderRadius: "10px",
      color: "#fff",
      marginTop: "20px",
      "&:hover": {
        background: "#333",
      },
    },
    modalNoBtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
    },
    
    modalYesBtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
    },
  }));
  