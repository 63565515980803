import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

function ManageUser() {
    const classes = useStyles();
    return '38' in JSON.parse(localStorage.getItem("JWT")).permission ? (
        <div className={classes.btncol}>
            <Link to="/manage-user"><Button className={classes.managebtn}>Manage Users</Button></Link>
        </div>
    ) : ''
}

export default ManageUser
const useStyles = makeStyles(() => ({
    btncol:{
        '& a':{
            textDecoration:'none'
        }
    },
    managebtn:{
        width:'180px',
        height:'45px',
        background:'#E8740A',
        borderRadius:'10px',
        fontSize:'16px',
        color:'#fff',
        textTransform:'capitalize',
        marginBottom:'20px',
        '&:hover':{
            background:'#000'
        }
    }
    
}));