import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from "react";
  import { Pagination } from "@material-ui/lab";
  import { makeStyles } from "@material-ui/core/styles";
  import { Button, Typography } from "@material-ui/core";
  import Header from "../components/Header";
  import Select from "react-dropdown-select";
  import { Box, Grid, Link } from "@material-ui/core";
  import { Link as NavLink } from "react-router-dom";
  import { FiEdit } from "react-icons/fi";
  import { ReactSearchAutocomplete } from "react-search-autocomplete";
  import { Autocomplete } from '@material-ui/lab';
  import {
    BiChevronUp,
    BiChevronDown,
    BiArrowBack,
    BiPlusCircle,
    BiXCircle,
    BiCheckCircle,
  } from "react-icons/bi";
  import Dialog from "@material-ui/core/Dialog";
  import DialogActions from "@material-ui/core/DialogActions";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogContentText from "@material-ui/core/DialogContentText";
  import Slide from "@material-ui/core/Slide";
  import TextField from "@material-ui/core/TextField";
  import FormControlLabel from "@material-ui/core/FormControlLabel";
  import FormControl from "@material-ui/core/FormControl";
  import FormGroup from "@material-ui/core/FormGroup";
  import { pink} from "@mui/material/colors";
  import { green } from "@material-ui/core/colors";
  import Checkbox from "@material-ui/core/Checkbox";
//   import Checkbox from "@mui/material/Checkbox";
  import { BiPlus, BiMinus } from "react-icons/bi";
  import { withStyles } from "@material-ui/core/styles";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { apiAxios } from "../Util/ApiAxios";
  import { checkNumbersOnly, checkDob } from "../Util/validations";
  import moment from "moment";
  import Loader from "react-loader-spinner";
  import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  import {
    maxUseOptions,
    minTimeOptions,
    maxBillingFreqList,
    carePlanGridInfoList,
  } from "../Util/OtherList";
  import PatientStatus from '../components/PatientStatus'
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


  function ManageBilling(options) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [clientOptions, setClientOptions] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [client, setClient] = React.useState("");
  const [programList, setProgramList] = React.useState([]);
  const [programOptions, setProgramOptions] = React.useState([]);
  const [program, setProgram] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [providerOptions, setProviderOptions] = React.useState([]);
  const [provider, setProvider] = React.useState("");
  const [providerList, setProviderList] = React.useState([]);
  const [providerListOption, setProviderListOption] = React.useState([]);
  const [programProvider, setProgramProvider] = React.useState("");
  // const [month,setMonth] = React.useState("");
  const [billingListData, setBillingListData] = React.useState([]);
  const [tableMessage, setTableMessage] = React.useState("");
  const [tableMsg, setTableMsg] = React.useState("");
  const [edit, setEdit] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [checkbox, setCheckBox] = React.useState([])

  const [codes, setCodes] = React.useState([]);
  const [onFocusIndex, setOnFocusIndex] = React.useState("");
  const [searchString, setSearchString] = React.useState([])
  const [clearFlag, setClearFlag] = React.useState(false)

  const [clientError, setClientError] = React.useState(false);
  const [programError, setProgramError] = React.useState(false);
  const [providerError, setProviderError] = React.useState(false);
  const [startDateError, setStartDateError] = React.useState(false)
  
  // const [startDate, setStartDate] = useState(
  //   moment().subtract(1, "months").toDate()
  // );
  const [startDate, setStartDate] = useState(
    moment().toDate()
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [codesOptions, setCodesOptions] = useState([]);
  const [availableCodes, setAvailableCodes] = useState([]);
    
   
 
 
  
const client_details = JSON.parse(localStorage.getItem("JWT"));

useEffect(()=>{
    getProgramList();
    getUserList();
},[])
  
  
function getProgramList() {
    setProgramOptions([]);

    setProgram("");
    setProvider("")

    let data = {
      client_id: client_details ? client_details.client_id : "" ,
      table_config_rows_per_page: "150",
      table_config_page_no: "1",
      program_id: "",
      provider_id: "",
      search_value: "",
      status: "1",
      is_unique: "1",
    };

    apiAxios
      .post("/client/client-provider-program-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setProgramList(response.data.data);
        } else if (response.data.statusCode == 502) {
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("admin_Refresh_Token");
        // localStorage.removeItem("admin_Token");
        // window.location.href = "/";
      });
  };
  
  
 // provider list of a particular client

 function getUserList()  {




    let data = {
      client_id: client_details ? client_details.client_id : "" ,
      table_config_rows_per_page: "200",
      table_config_page_no: "1",
      user_master_type_id: "",
      cutid: "",
      search_value: "",
      is_provider: 0,
    };

    apiAxios
      .post("/client/clientuserlist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setUserList(response.data.data);
        } else if (response.data.statusCode == 502 || response.data.statusCode == 501) {

        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // localStorage.removeItem("admin_Refresh_Token");
        // localStorage.removeItem("admin_Token");
        // window.location.href = "/";
      });
  };
   
  
   // related provider list of a particular program in a particular client

   const getProviderList = (id) => {
    let data = {
      client_id: client_details ? client_details.client_id : "" ,
      program_id: id,
      table_config_rows_per_page: "200",
      table_config_page_no: "1",
    };

    setProviderOptions([]);
    setUserList([]);
    setProvider("")

    apiAxios
      .post("/provider/provider-for-given-program-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("res", response.data);
        // setShowLoader(false);

        if (response.data.statusCode == 200) {
          setProviderList(response.data.data);
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        // setShowLoader(false);
        console.log(error);
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
   
  useEffect(() => {
    if (providerList) {
      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < providerList.length; i++) {
        let value = providerList[i].client_provider_id;
        
        let label = providerList[i].name;

        let ob = {
          label: label,
          value: value,
        };
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
      }

      console.log(ob_arr);
      setProviderListOption(ob_arr);
      // setSelectedProgram(ob_arr[0]);
    }
  }, [providerList]);

   
  useEffect(() => {
  

    if (programList.length > 0) {
      let ob_arr = [];
      for (var i = 0; i < programList.length; i++) {
        let label = programList[i].program_name;
        let value = programList[i].template_id;

        let obj = {
          label: label,
          value: value,
        };

        ob_arr.push(obj);
      }

      let objc = {
        label: "All Programs",
        value: "all",
      };

      ob_arr.unshift(objc);
      setProgramOptions(ob_arr);
    }

    if (userList.length > 0) {
      let ob_arr = [];
      for (var i = 0; i < userList.length; i++) {
        if (userList[i].short_name === "Provider") {
          let label =
            userList[i].provider_first_name +
            " " +
            userList[i].provider_last_name;

          let value = userList[i].id;

          let obj = {
            label: label,
            value: value,
          };

          ob_arr.push(obj);
        }
      }

      let objc = {
        label: "All Providers",
        value: "all",
      };

      ob_arr.unshift(objc);
      setProviderOptions(ob_arr);
    }
  }, [programList, userList]);

    
  const handleOnSelect = (item) => {
    // the item selected
  
   
      console.log(searchString)
      console.log(onFocusIndex)
    
     
  
   console.log(typeof(codes))
   console.log(item)
   console.log(item.name)

    
 

  if(item){
    console.log("item")
    // setCodes(prev=> prev.filter((x,i)=> i !== onFocusIndex))
  
      const codesArr = [...codes];
      codesArr.splice(onFocusIndex, 1, item.name)
      setCodes(codesArr)
      // setCodes(prev =>[...prev,item.name])
    }
   
    
   
    
   
   
   
   
   

    


  };


  const handleOnFocus = (index) => {
    console.log("Focused");
    console.log(index)
   setOnFocusIndex(index)
   
  
  };

  const handleClear = (index)=>{

    console.log("clear")
    console.log(index)
    setCodes(prev => prev.filter((x,i)=> i != index))
  }
  

  const handleChange2 =(event,k,data) => {
    // setState({ ...state, [event.target.name]: event.target.checked });

    // if (event.target.id === k) {
    //   setIsChecked(true);
    //   billingUpdate(data, k);
    // }
  
  const {id, checked} = event.target
  console.log(id)
  console.log(checked)

if(checked && id === "checkedAll"){
  setCheckBox([...checkbox,id])
  // billingBulkApprove();
  bulkApproveModal();
}else {

  // setCheckBox(prev =>prev.filter(x=> x!== "checkAll"))
    let isCheck = false;
  if(checked) {
    
    setCheckBox(prev=>[...prev,id])
    setIsChecked(!isChecked)
    isCheck = true;
    
    approveModal(k,data, isCheck);
       // billingUpdate(data, k)
     
     
    
   }else {
     setCheckBox(prev=> prev.filter(x=> x !== id))
     setIsChecked(false)
     isCheck  = false;
     
   }

}


  
  
  }

  // update billing data

  function billingUpdate (id,item,flag) {
    console.log("submit form");

    console.log(item.available_add_on_codes)
    // console.log(isChecked)
    console.log(checkbox.find(x=> x === id)? "yes" : "no")
    console.log(flag ? "true" : "false")
    

    console.log(codes);

    let data = {
      billing_token: id,
      is_approved: flag ? "1" : "0",
      billing_data: {
        first_name: item.first_name,
        last_name: item.last_name,
        middle_name: item.middle_name,
        billing_tier: item.billing_tier,
        date: item.date,
        codes: codes,
        available_add_on_codes: item.available_add_on_codes ? item.available_add_on_codes : [] ,
        overall_time: item.overall_time,
        remaining_time: item.remaining_time,
        primary_billing_code: item.primary_billing_code,
        hrpm_id: item.hrpm_id,
        agent_first_name: item.agent_first_name,
        agent_last_name : item.agent_last_name,
      },
    };

    apiAxios
      .post("/finalize/billing-codes/update", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response billing update --> ", response);
        if (response.data.statusCode == 200) {
          setEdit("");
          console.log(response.data.data);
          toast.success(response.data.messsage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          submitBillingFilter();
          setIsChecked(false)
          setOnFocusIndex("")
        } else {
          setEdit("");
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIfLoading(false);
      });
  }

  useEffect(() => {
    if (codesOptions) {
      let arr = [];
      for (var i = 0; i < codesOptions.length; i++) {
        let name = codesOptions[i].code;
        let id = i;
        let ob = {
          id: id,
          name: name,
          // label : name,
          // id: id,
        };

        arr.push(ob);
      }

      console.log(arr);

      setAvailableCodes(arr);
    }
  }, [codesOptions]);

  // getting billing list after submitting client, program, provider filter

  const submitBillingFilter = useCallback(
    async ({
      table_config_rows_per_page = "5",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let isValid = true;

      console.log(startDate)
      console.log("submit form");
      console.log(client);
      console.log(program);
      console.log(provider);
      console.log(moment(startDate).format("YYYY-MM"));

      // if (client) {
      //   setClientError(false);
      // } else {
      //   setClientError(true);
      //   isValid = false;
      // }

      if (program) {
        setProgramError(false);
      } else {
        setProgramError(true);
        isValid = false;
      }

      if (provider) {
        setProviderError(false);
      } else {
        setProviderError(true);
        isValid = false;
      }

      if(startDate !== null){
        setStartDateError(false)
       
      }else {
        setStartDateError(true)
        isValid = false
      }


      if (isValid) {
        setCheckBox([])
        setBillingListData([]);
        setTableMessage("Loading...");
        // setIfLoading(true)
        let data = {
          // client_id: client.value,
          prog_id: program.value,
          provider_id: provider.value,
          month: startDate ? moment(startDate).format("YYYY-MM") : "",
          is_paginated: "1",
          table_config_rows_per_page: table_config_rows_per_page,
          table_config_page_no: table_config_page_no,
        };

        apiAxios
          .post("/finalize/billing-codes/list", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log("response billing list --> ", response);
            if (response.data.statusCode == 200) {
              console.log(response.data.data);
              setTotalPages(response.data.total_pages);

              if (
                response.data.data.length == 0 ||
                Object.entries(response.data.data).length == 0
              ) {
                setTableMessage("No Data Found");
              } else {
                setBillingListData(response.data.data);
              }
            } else if (response.data.statusCode == 404) {
              setTableMessage("No Data Found");
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setIfLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setIfLoading(false);
          });
      }
    }
  );
 
  
  
  
  
   // modal for a particular list row checkbox approve

   const approveModal = (k,data, isCheck) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to approve this record?</p>
            <p>Note: After submit you can not undo it</p>
            <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
                
                billingUpdate(k,data, isCheck)
                onClose();
              }}
            >
              Yes, Approve
            </button>
          </div>
        );
      }
    });
  };

  const bulkApproveModal = (k,data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to approve all records?</p>
            <p>Note: After submit you can not undo it</p>
            <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
               
               
                billingBulkApprove();
                onClose();
              }}
            >
              Yes, Approve
            </button>
          </div>
        );
      }
    });
  };

  
  const billingBulkApprove = ()=>{
   

    

    let data = {
      // client_id: client_details ? client_details.client_id : "" ,
      prog_id: program.value,
      provider_id: provider.value,
      month: startDate ? moment(startDate).format("YYYY-MM") : "",
      is_paginated: 0,
     
    };

    apiAxios
      .post("/finalize/billing-codes/bulk-approve", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response bulk apprve list --> ", response);
        if (response.data.statusCode == 200) {
          // console.log(response.data.data);
          // setTotalPages(response.data.total_pages);
          submitBillingFilter();
         
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIfLoading(false);
      });
  
}

const handleOnHover = (result) => {
  // the item hovered
  console.log(result);
};

const handleOnSearch = (string, results) => {
  // onSearch will have as the first callback parameter
  // the string searched and for the second the results.
  console.log(string, results);
  console.log(string)

  setSearchString(string)
 
 
 
};

useEffect(()=>{

  if(searchString.length < 1){
   
    setCodes(prev=>prev.filter((x,i)=> i != onFocusIndex))
   
  }

},[onFocusIndex])


const downloadBilling = ()=>{
   
 
  
    let data = {
   
      prog_id: program.value,
      provider_id: provider.value,
      month: startDate ? moment(startDate).format("YYYY-MM") : "",
      is_paginated: 0,
     
    };

    apiAxios
      .post("/finalize/billing-codes/create-billing-file", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        console.log("response bulk apprve list --> ", response);
        if (response.data.statusCode == 200) {
            let domain = response.data.domain
            let file = response.data.file_uri
            console.log(domain)
            console.log(file)
            console.log(domain+file)
            window.location = domain + file

            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
      
             submitBillingFilter();
         
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIfLoading(false);
      });
  
}




  
  
    return (
      <div>
        <Header />
        <Box className={classes.pagecontent}>
        <Box className={classes.Leftcol}>
                 <Box className={classes.leftnav}>  
                 <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                      <a href="/dashboard"> <Button><BiArrowBack className={classes.backarrow} /> Back to previous</Button> </a>
                   </Box>
                   <PatientStatus />
                   {/* left Accordion*/}
                   
                   </Box>
                 </Box>
  
                 <Box className={classes.Rightcol}>
  
        <Grid container spacing={3}>
       
          <Grid item xs={12}>
         
            <Box className={classes.btncol}>
            
             
              {/* <a href="/dashboard"><Button><BiArrowBack className={classes.backarrow} style={{color:'#b4bcc9',}} /> Back to dashboard</Button></a> */}
              <h3 className={classes.topheading} style={{color :'#141621'}}>Billing</h3>

             
             
            </Box>

            <Box className={classes.loginform}>
        <Box className={classes.TopFiletr}>
          
    
          <Box
            className={classes.SelectCol2}
            style={{ marginRight: 15, marginBottom: 15 }}
          >
            <Select
              options={programOptions}
              placeholder="Select Program "
              className={classes.select}
              style={{ width: "100%" }}
              onChange={(values) => {
                setProgram(values[0]);
                if (values[0].value == "all") {
                  setProvider("");
                  getUserList();

                } else {
                  getProviderList(values[0].value);
                }
              }}
              backspaceDelete={false}
            />
            {programError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a program
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box style={{ marginRight: 15, width: "30%" }}>
            <Select
              placeholder="Select Provider"
              className={classes.select}
              style={{ width: "100%" }}
              options={
                providerOptions.length > 0
                  ? providerOptions
                  : providerListOption
              }
              onChange={(values) => setProvider(values[0])}
              backspaceDelete={false}
            />
           {providerError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a provider
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box
            className={classes.SelectCol3}
            style={{ marginRight: 20, marginBottom: 15 }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className={classes.dateInput}
              // maxDate={moment().subtract(1, "months").toDate()}
                maxDate={moment().toDate()}
              minDate={moment().subtract(1, "years").toDate()}
            />
            {startDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a date
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <Button className={classes.SubmitBtn} onClick={submitBillingFilter}>
              Create Billing Preview
            </Button>
          </Box>
        </Box>
        <Box className={classes.TopFiletr}>
          {/* <Box style={{marginRight:15,width:'33%'}}><TextField className={classes.input} placeholder="Other" type="text" /></Box> */}
        </Box>
        
      </Box>

      <Box
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={(e)=>handleChange2(e)}
                
                id="checkedAll"
                color="primary"
                // checked={Object.entries(billingListData).every(([key,data])=> data.status === 2)? "checked" : ""}

              checked = {(checkbox.find(x => x === "checkedAll") || (Object.keys(billingListData).length ===0 ? "" :Object.entries(billingListData).every(([key,data])=> data.status === 2))) ? "checked" : ""} 
              

              />
            }
            label="Approve All"
          />
        </Box>

        <Box className={classes.BillingTable}>
        <Box className={classes.Throw}>
        <Box className={classes.Thcol1}>Patient ID</Box>
          <Box className={classes.Thcol01}>First Name</Box>
          <Box className={classes.Thcol2}>Last Name</Box>
          <Box className={classes.Thcol3}>
            <Box className={classes.providerbtn} role="button">
              Dos
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>
          <Box className={classes.Thcol4}>User</Box>
          <Box className={classes.Thcol5}>Billing Tier</Box>
          <Box className={classes.Thcol10}>Primary BCode</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol7}>
            {" "}
            <Box className={classes.providerbtn} role="button">
              Time
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>
          <Box className={classes.Thcol7}>
            {" "}
            <Box className={classes.providerbtn} role="button">
              R.Time
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>

          <Box className={classes.Thcol8}>APR</Box>
          <Box className={classes.Thcol9}>Mod</Box>
        </Box>
        {Object.keys(billingListData).length > 0 ? (
          <>
            {Object.entries(billingListData).map(([key, data]) => (
              <Box className={classes.Tdrow} key={key}>
                <Box className={classes.Tdcol1}>{data.hrpm_id}</Box>
                <Box className={classes.Tdcol01}>{data.first_name}</Box>
                <Box className={classes.Tdcol2}>{data.last_name}</Box>
                <Box className={classes.Tdcol3}>
                  {moment.utc(data.date).local().format("YYYY-MM-DD")}
                </Box>
                <Box className={classes.Tdcol4}>{data.agent_first_name + " " + data.agent_last_name}</Box>
                <Box className={classes.Tdcol5}>{data.billing_tier}</Box>
                <Box className={classes.Tdcol10}>
                  {data.primary_billing_code}
                </Box>
            {data.codes.length < 5 ?
                <>
               {data.codes.map((code, index) => (
                  <>
                    {(edit === key  && data.status === 1 )  ? 
                      <Box className={classes.Tdcol6}>
                   

                        <div>
                          <ReactSearchAutocomplete
                            items={availableCodes}
                            fuseOptions={{ keys: ["name"] }}
                            inputSearchString={code}
                            onSearch={handleOnSearch}
                              
                          
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={()=>handleOnFocus(index)}
                            onClear={()=>handleClear(index)}
                            key = {index}
                            autoFocus
                            // formatResult={formatResult}
                            styling={{
                              height: "35px",
                              fontSize: "11px",
                              clearIconMargin: "1px 3px 0 0",
                              searchIconMargin: "0 0 0 2px",
                              borderRadius: "8px",
                            }}
                            showClear
                           
                          
                        
                          />
 
                        </div>
                      </Box>
                     : 
                      <Box key={index} className={classes.Tdcol6}>
                        {/* {typeof code === "object" ? "" : code} */}
                        {/* {code[index]? code[index] : "N/A"} */}
                        {code}
                      
                      </Box>
                    }
                  </>
                ))}



               
                
                {[...new Array(5-data.codes.length).keys()].map((ele,i)=>(
                  
                  // <Box key={key} className={classes.Tdcol6}>&nbsp;</Box>
                  <>
                  {(edit === key  && data.status === 1 )  ? 
                    <Box className={classes.Tdcol6}>
                 

                      <div>
                        <ReactSearchAutocomplete
                          items={availableCodes}
                          fuseOptions={{ keys: ["name"] }}
                          // inputSearchString={code}
                          onSearch={handleOnSearch}
                           
                        
                          onHover={handleOnHover}
                          onSelect={handleOnSelect}
                          onFocus={()=>handleOnFocus(data.codes.length+i)}
                          onClear={()=>handleClear(data.codes.length+i)}
                          key = {data.codes.length+i}
                          autoFocus
                          // formatResult={formatResult}
                          styling={{
                            height: "35px",
                            fontSize: "11px",
                            clearIconMargin: "1px 3px 0 0",
                            searchIconMargin: "0 0 0 2px",
                            borderRadius: "8px",
                          }}
                          showClear
                         
                        
                      
                        />

                      </div>
                    </Box>
                   : 
                    <Box key={data.codes.length+i} className={classes.Tdcol6}>
                  
                      &nbsp;
                    
                    </Box>
                  }
                </>
                  
                  
                  )
                )
} 
</>

: 
<>
 {data.codes.slice(0,5).map((code, index) => (
    <>
      {(edit === key  && data.status === 1 )  ? 
        <Box className={classes.Tdcol6}>
     

          <div>
            <ReactSearchAutocomplete
              items={availableCodes}
              inputSearchString={code}
              fuseOptions={{ keys: ["name"] }}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={()=>handleOnFocus(index)}
              onClear={()=>handleClear(index)}
              key = {index}
              autoFocus
              // formatResult={formatResult}
              styling={{
                height: "35px",
                fontSize: "11px",
                clearIconMargin: "1px 3px 0 0",
                searchIconMargin: "0 0 0 2px",
                borderRadius: "8px",
              }}
            />
          </div>
        </Box>
       : 
        <Box key={index} className={classes.Tdcol6}>
          {/* {typeof code === "object" ? "" : code} */}
          {/* {code[index]? code[index] : "N/A"} */}
          {code}
        
        </Box>
      }
    </>
  ))}

</>
}

       

            

                <Box className={classes.Tdcol7}>
                  {moment.utc(data.overall_time * 1000).format("HH:mm:ss")}
                </Box>
                <Box className={classes.Tdcol7}>
                  {moment.utc(data.remaining_time * 1000).format("HH:mm:ss")}
                </Box>
                <Box className={classes.Tdcol8}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        onChange={(e) => handleChange2(e,key,data)}
                        id={key}
                        // checked = {data.status === 2  ? "checked" : ""}

                       
                        checked={(checkbox.find((i)=> key === i) || checkbox.find((i)=> i === "checkedAll") || data.status === 2) ? "checked" : ""}

                        value={[key, data]}
                      />
                    }
                  />
                </Box>
                {(edit === key && data.status === 1) ? (
                  <Box className={classes.Tdcol9}>
                    <button
                      className={classes.SaveBtn}
                      onClick={() => billingUpdate(key,data,isChecked)}
                    >
                      Save
                    </button>
                    <button
                      className={classes.CancelBtn}
                      onClick={() => {
                        setEdit("");
                        // setCodes({  code1: "",
                        //   code2 : "",
                        //   code3 : "",
                        //   code4 : "",
                        //   code5: "",

                        // })

                        setCodes([]);

                        setCodesOptions([]);
                      }}
                    >
                      Cancel
                    </button>
                  </Box>
                ) : (
                  <Box className={classes.Tdcol9}>
                    <Button className={classes.EditIcon}
                      onClick={() => {
                        setEdit(key);
                        setCodes([])
                         
                        

                        console.log(data.available_add_on_codes);
                        setCodes([...data.codes])
                        setCodesOptions(data.available_add_on_codes);
                      }}
                    >
                      {" "}
                      <FiEdit size="20" color="#5C85EE" />
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {tableMessage}
            </Box>
          </>
        )}
                    
 </Box>
           
           
           <br />
            <div style={{display:"flex",justifyContent:"flex-end", width:"100%"}}>
            <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, pageNumber) =>
            submitBillingFilter({
              table_config_page_no: `${pageNumber}`,
            })
          }
        />
            </div>
              <Box className={classes.BtnCol}>
            

               <NavLink to="/billing-list"> <Button className={classes.SubmitBtn}>Billing List</Button></NavLink>
                
                
              
                {Object.keys(billingListData).length > 0 ?
                    <Button className={classes.SubmitBtn} onClick={downloadBilling}>Create Billing File</Button>
                    :
                    <Button className={classes.SubmitBtn} style={{pointerEvents : "none"}}>Create Billing File</Button>

                }
        
      </Box>
          </Grid>
        </Grid>
        </Box> 
        </Box>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
  
       

       
      </div>
    );
  }
  
  export default ManageBilling;
  const useStyles = makeStyles(() => ({
    select: {
      width: "100%",
      border: "none !important",
      borderRadius: "10px !important",
      background: "#F1F1F1",
      height: "50px",
      fontSize: "18px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
    
      SelectCol3: {
        width: "15%",
        '& .react-datepicker-ignore-onclickoutside':{
          fontFamily:'poppins'
        },
        "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
          {
            width: "31%",
          },
      },
      SelectCol2: {
        width: "28%",
        "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
          {
            width: "31%",
          },
      },
 
    loginform: {
        width: "100%",
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiInput-formControl": {
          height: "50px",
        },
        "& .MuiInputBase-input": {
          height: "50px",
          borderRadius: "10px",
          background: "#F1F1F1",
          padding: "0 15px",
        },
        "& .MuiInput-input:focus": {
          border: "1px #7087A7 solid",
          boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
        },
        "& .MuiTabs-flexContainer": {
          flexDirection: "row",
          justifyContent: "space-between",
        },
        "& .MuiTabScrollButton-root:last-child": {
          position: "absolute",
          right: "-15px",
          marginTop: "11px",
        },
        "& .MuiTabScrollButton-root:first-child": {
          position: "absolute",
          left: "-15px",
          zIndex: "99",
          marginTop: "12px",
        },
        "& .MuiTabs-indicator": {
          display: "none !important",
        },
        "& .MuiTabScrollButton-root": {
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          background: "#0A70E8",
          color: "#fff",
        },
        "& .MuiTab-root": {
          textTransform: "capitalize",
          fontFamily: "Poppins",
        },
        "& .MuiTabs-flexContainer": {
          borderRadius: "10px",
          background: "#F3F3F3",
          textTransform: "capitalize",
          justifyContent: "space-between",
          "& .MuiTab-textColorPrimary.Mui-selected": {
            background: "#D9E3F0",
            color: "#000",
            borderRadius: "10px",
          },
          "& .MuiTab-textColorInherit": {
            textTransform: "capitalize",
            fontSize: "16px",
            padding: "0 22px",
          },
        },
      },
   
    backarrow:{
      color:'#b4bcc9',
      fontSize:'20px',
      marginRight:'8px'
  },
  pagecontent:{
    width:'100%',
    background: 'linear-gradient(to bottom, #0f3d6e05, #0f3d6e05)',
    // padding:'20px 3%',
    display:'flex',
    // flexDirection:'column',
    '& a':{
      textDecoration:'none'
    }
  },
    selectMax: {
      width: "100%",
      border: "none !important",
      borderRadius: "10px !important",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      height: "50px",
      fontSize: "18px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
      '& .react-dropdown-select-content':{
        '& span':{
            fontSize:11
        }
               }
     
    },
    marginTopTen: {
      marginTop: "10px !important",
      fontSize: "15px !important",
    },
    SubmitAdd: {
      background: "#1612C6",
      borderRadius: 50,
      height: 30,
      minWidth: 30,
      color: "#fff",
      fontFamily: "Poppins",
      fontWeight: "600",
      width: 30,
      fontSize: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "capitalize",
      "&:hover": {
        background: "#0A70E8",
      },
    },
    removebtn: {
      background: "#DF0909",
      borderRadius: 50,
      height: 30,
      color: "#fff",
      fontFamily: "Poppins",
      width: 30,
      minWidth: 30,
      fontWeight: "600",
      fontSize: 20,
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: "#0A70E8",
      },
    },
    Formrow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    providerbtn: {
        display: "flex",
        cursor: "pointer",
        "& span": {
          display: "flex",
          flexDirection: "column",
          width: "20px",
          marginRight: "10px",
          "& button": {
            background: "none",
            border: "none",
            height: "10px",
            cursor: "pointer",
          },
        },
      },
     pageTop:{
      textAlign:'left',
      '& button':{
          padding:'0px 5px',
          background:'none',
          color:'#bfc4cb',
          fontSize:'14px',
          textTransform:'capitalize',
          fontWeight:'500',
          borderRadius:20,
      }
  },
  Throw: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 5,
    borderBottom: "1px #ccc solid",
    width: 1366,
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: 1366,
      },
  },
  Tdrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    padding: "7px 0",
    width: 1366,
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: 1366,
      },
      '& .iUnaGm input':{
        fontFamily:'poppins',
        fontSize:13,
        paddingLeft:8,
      }
  },
  Thcol1: {
    width: "13%",
    fontSize: 14,
    color: "#696969",
    position:'sticky',
    left:0,
    background:'#fafbfc',
    minHeight:40,
    display:'flex',
    alignItems:'center'
  },
  Thcol01: {
    width: "15%",
    fontSize: 14,
    color: "#696969",
    position:'sticky',
    left:'7.5%',
    background:'#fafbfc',
    minHeight:40,
    display:'flex',
    alignItems:'center',
    '@media only screen and (max-width: 1920px)':{
      left:'80px',
            },
    '@media only screen and (max-width: 1180px)':{
      left:'80px',
            },
    '@media only screen and (max-width: 1024px)':{
      left:'80px',
            },
  },
  Thcol2: {
    width: "15%",
    fontSize: 14,
    color: "#696969",
    position:'sticky',
    left:'16%',
    background:'#fafbfc',
    minHeight:40,
    display:'flex',
    alignItems:'center',
    '@media only screen and (max-width: 1920px)':{
      left:'172px',
            },
    '@media only screen and (max-width: 1180px)':{
      left:'172px',
            },
    '@media only screen and (max-width: 1024px)':{
      left:'172px',
            },
  },
  Thcol3: {
    width: "14%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol4: {
    width: "14%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol5: {
    width: "13%",
    fontSize: 14,
    color: "#696969",
    textAlign: "center",
  },
  Thcol6: {
    width: "17%",
    fontSize: 14,
    color: "#696969",
    textAlign : "center"
  },
  Thcol10: {
    width: "14%",
    fontSize: 14,
    color: "#696969",
    textAlign : "center"
  },
  Thcol7: {
    width: "10%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol8: {
    width: "7%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol9: {
    width: "10%",
    fontSize: 14,
    color: "#696969",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  Tdcol1: {
    width: "12.5%",
    fontSize: 14,
    paddingRight: "0.5%",
    wordWrap : "break-word",
    position:'sticky',
    zIndex:'99999',
    left:0,
    background:'#fafbfc',
    minHeight:40,
  },
  Tdcol01: {
    width: "15%",
    fontSize: 14,
    paddingRight: "0.5%",
    wordWrap : "break-word",
    position:'sticky',
    left:'80px',
    background:'#fafbfc',
    minHeight:40,
    zIndex:'99999',
    textAlign:'left',
    '@media only screen and (max-width: 1920px)':{
      left:'79px',
            },
    '@media only screen and (max-width: 1180px)':{
      left:'80px',
            },
    '@media only screen and (max-width: 1024px)':{
      left:'80px',
            },
  },
  Tdcol2: {
    width: "14.5%",
    fontSize: 14,
    paddingRight: "0.5%",
    wordWrap : "break-word",
    position:'sticky',
    left:'16%',
    background:'#fafbfc',
    minHeight:40,
    zIndex:'99999',
    textAlign:'left',
    '@media only screen and (max-width: 1920px)':{
      left:'172px',
            },
    '@media only screen and (max-width: 1180px)':{
      left:'172px',
            },
    '@media only screen and (max-width: 1024px)':{
      left:'172px',
            },
  },
  Tdcol3: {
    width: "14%",
    fontSize: 14,
    paddingRight: "0.5%",
    
  },
  Tdcol4: {
    width: "13.5%",
    fontSize: 14,
    paddingRight: "0.5%",
  },
  Tdcol5: {
    width: "13%",
    fontSize: 14,
    textAlign: "center",
    paddingRight: "0.5%",
  },
  Tdcol6: {
    width: "17%",
    fontSize: 14,
    paddingRight: "0.5%",
    textAlign:'center'
  },
  Tdcol10: {
    width: "14%",
    fontSize: 14,
    paddingRight: "0.5%",
    textAlign:'center'
  },
  Tdcol7: {
    width: "10%",
    fontSize: 14,
    paddingRight: "0.5%",
  },
  Tdcol8: {
    width: "7%",
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
  },
  Tdcol9: {
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 14,
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign:'right'
  },
  Throw1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 5,
    
  },

  ThCol: {
    width: "8%",
},
ThCol2: {
    width: "8%",
},
ThCol3: {
    width: "8%",
},

ThCol4: {
  width: "45%",
  fontSize: 16,
  color: "#696969",
},
ThCol5: {
    width: "35%",
    fontSize: 16,
    color: "#696969",
  },
  ThCol6: {
    width: "40%",
    fontSize: 16,
    color: "#696969",
  },

  ThCol8: {
    width: "35%",
    fontSize: 16,
    color: "#696969",
  },
  ThCol9: {
    width: "30%",
    fontSize: 16,
    color: "#696969",
  },
  ThCol11: {
    width: "35%",
    fontSize: 16,
    color: "#696969",
  },
  Tdrow1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
   
    marginBottom: 15,
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    padding: "10px 0",
  },
  TdCol4: {
    width: "45%",
    wordWrap:"break-word",
    overflow: "auto",
    paddingRight:'0.5%'
  },
  TdCol5: {
    width: "35%",
    wordWrap:"break-word",
    overflow: "auto",
    paddingRight:'0.5%'
  },
  TdCol6: {
    width: "40%",
    wordWrap:"break-word",
    overflow: "auto",
    paddingRight:'0.5%'
  },
  TdCol7: {
    width: "13.5%",
    paddingRight:'0.5%'
  },
  TdCol8: {
    width: "35%",
    paddingRight:'0.5%',
    wordWrap:"break-word",
  },
  TdCol9: {
    width: "30%",
    paddingRight:'0.5%',
    wordWrap:"break-word",
  },
  TdCol10: {
    width: "19.5%",
    paddingRight:'0.5%'
  },
  TdCol11: {
    width: "35%",
    fontSize: 16,
    color: "#696969",
  },
  SaveBtn: {
    width: 60,
    background: "#7087A7",
    borderRadius: 5,
    fontSize: 12,
    color: "#fff",
    border: "none",
    padding: "4px 0",
    marginBottom: 4,
    cursor: "pointer",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  CancelBtn: {
    width: 60,
    background: "#fff",
    borderRadius: 5,
    fontSize: 12,
    color: "#BD3535",
    border: "1px #BD3535 solid",
    padding: "2px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#BD3535",
      color: "#fff",
    },
  },
  EditIcon:{
    minWidth:'initial'
  },
    ModalTop: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 25,
    },
    checkCol: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: 40,
    },
    checkList: {
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
      "& label": {
        flex: "0 0 160px !important",
        color: "#141621",
        marginTop: "0px !important",
      },
    },
    leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1200px)':{
        left:'20px',
        right:'20px',
              },
      },
      Leftcol: {
        width: "15%",
        padding: "20px 3%",
        position: "relative",
        minHeight: "800px",
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                },   
                '@media only screen and (max-width: 768px)':{
                  width: '98%',
                  padding:'20px 1%',
                  position:'absolute',
                  background:'none',
                  minHeight:25,
                }        
      },
      Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #fff solid',
        '@media only screen and (max-width: 820px)':{
          width:'96%',
          padding:'0 2%',
          marginTop:30,
                },
        '@media only screen and (max-width: 768px)':{
          width:'96%',
          padding:'0 2%',
          marginTop:30,
                },
        '& .MuiAccordionSummary-root':{
            borderBottom:'1px #5f80a9 solid',
            height:'40px',
            color:'#bfc4cb',
            padding:'0px',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        },
        '& .MuiTab-root':{
          minWidth:'33%',
        },
        '& .MuiTab-root:nth-child(6)':{
          minWidth:'30%',
        },
        '& .MuiTab-root:nth-child(7)':{
          minWidth:'30%',
        },
        // '& .MuiBox-root':{
        //     paddingLeft:'0px',
        //     paddingRight:'0px'
        // }
    },
    ActionBtn: {
      borderRadius: 10,
      textTransform: "capitalize",
      background: "#0A70E8",
      color: "#fff",
      "&:hover": {
        background: "rgba(0,0,0,0.8)",
      },
    },
    EditBtn: {
      borderRadius: 10,
      textTransform: "capitalize",
      background: "#E8740A",
      color: "#fff",
      marginRight: 10,
      "&:hover": {
        background: "rgba(0,0,0,0.8)",
      },
    },
    TopHeading: {
      fontFamily: "Poppins",
      fontSize: 20,
      fontWeight: "600",
      color : "#000 !important"
    },
    addprovider: {
      fontSize: 16,
      textTransform: "capitalize",
      color: "#7087A7",
      "&:hover": {
        background: "none",
        color: "#000",
      },
    },
    btncol: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 20,
      color:'#bfc4cb',
    },
    icon: {
      color: "#7087A7",
      fontSize: "24px",
      marginRight: "10px",
    },
    // backarrow: {
    //   color: "#8088A8",
    //   fontSize: "20px",
    //   marginRight: "8px",
    // },
    Formcol: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "20px",
      "&>div": {
        width: "100%",
      },
      "& p": {
        fontSize: "18px",
        margin: "0px",
      },
      "& label": {
        flex: "0 0 205px",
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
        marginTop: 10,
      },
      "& .react-dropdown-select-input": {
        width: "100%",
        
      },
    },
    input: {
      border: "none",
      borderRadius: "10px",
      height: "50px",
      width: "100%",
    },
    Formcol2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "5px",
      "& label": {
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
      },
    },
    Formcol45: {
      alignItems: "left",
      justifyContent: "space-between",
      marginBottom: "5px",
      "& label": {
        color: "#000",
        fontSize: "15px",
        fontWeight: "400",
      },
    },
    modal: {
      "& .MuiPaper-rounded": {
        borderRadius: "10px !important",
        padding: "20px",
        width: "950px",
        maxWidth: "950px",
      },
    },
    ccmmodal: {
      borderRadius: "10px",
    },
    modalbtn: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "30px",
      marginLeft: "15px",
      alignItems: "center",
    },
    closebtn: {
      width: "40px",
      position: "absolute",
      right: "10px",
      height: "40px",
      background: "#fff",
      top: "10px",
      minWidth: "40px",
      "&:hover": {
        background: "#fff",
      },
    },
    closeicon: {
      fontSize: "25px",
      color: "#7087A7",
    },
  
      
    
    loginbtn: {
      background: "#1612C6",
      padding: "0 40px",
      width: "142px",
      height: "45px",
      borderRadius: "10px",
      color: "#fff",
      marginTop: "20px",
      "&:hover": {
        background: "#333",
      },
    },
    modalNoBtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
    },
    
    modalYesBtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
    },
    TopFiletr: {
        display: "flex",
        marginBottom: 10,
        flexWrap: "wrap",
        "& .dPOxbO": {
          borderRadius: 10,
          background: "#F1F1F1",
          fontSize: 14,
          color: "#8b8686",
        },
        "& .iuxhhH": {
          height: 35,
          padding: "6px 17px",
        },
        "& .cCMzRR": {
          display: "none",
        },
        "& .eeOlSf": {
          top: 50,
        },
        "& .clLvve.selected": {
          background: "#0A70E8",
        },
        "& .react-dropdown-select-no-data": {
          fontSize: 14,
        },
       
      },
      dateInput: {
        border: "none",
        borderRadius: "10px",
        height: "50px",
        width: "90%",
        paddingLeft:'10%',
        // background:'#fff !important',
        background: "#F1F1F1",
        fontFamily:'Poppins'
      },
      SubmitBtn: {
        background: "#7087A7",
        padding: "0 20px",
        height: "48px",
        borderRadius: "10px",
        textTransform: "capitalize",
        color: "#fff",
        "&:hover": {
          background: "#000",
        },
      },
      BillingTable: {
        width: "100%",
        overflowX: "auto",
        minHeight:'250px',
        "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
          {
            overflowX: "auto",
          },
      },
      BtnCol: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: 20,
      },
  }));
  