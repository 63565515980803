import { Box, Button, ListItem } from '@material-ui/core'
import React,{
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/DHCT_Logo.png'
import linechart from '../images/Line-chart.jpg'
import barchart from '../images/Bar-chart.jpg'
import Grid from '@material-ui/core/Grid'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Header from '../components/Header'
import Setting from '../components/Setting'
import PatientStatus from '../components/PatientStatus'
import { Line} from "react-chartjs-2"
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import Select from "react-dropdown-select"
import ManageUser from '../components/ManageUser'
import ManageProvider from '../components/ManageProvider'
import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import { useLocation } from 'react-router-dom'
import jwt_decode from "jwt-decode";

import loader  from  '../images/loader.gif';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
function DashboardRedirect(props,{ options }) {
    
  


const search = useLocation().search;
const encoded = new URLSearchParams(search).get('token')
const encodedRefreshTokken = new URLSearchParams(search).get('refreshToken')



let token = window.atob(encoded)
let refreshToken = window.atob(encodedRefreshTokken )


if(token && refreshToken) {

    for (var i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).substring(0,6) != 'admin_') {
            localStorage.removeItem(localStorage.key(i));
        }
    }

    localStorage.setItem("Token", token);
    localStorage.setItem("Refresh_Token", refreshToken);

    var decoded = jwt_decode(localStorage.getItem("Token"));
    localStorage.setItem("JWT", JSON.stringify(decoded) );

    props.history.push("/dashboard");



}else {
    props.history.push("/");
}


    return (

      
        <div>

         <h1>{token}</h1>
      
        </div>
    )
}

export default DashboardRedirect;
