import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Button, Link, Typography } from '@material-ui/core'
import {BiArrowBack} from "react-icons/bi"
import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Setting from '../components/Setting'
import Checkbox from '@material-ui/core/Checkbox'
import { FaFileMedicalAlt } from "react-icons/fa"
import Radio from '@mui/material/Radio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import UpdateTimer  from '../components/UpdateTimer'
import { apiAxios } from "../Util/ApiAxios"
import Loader from "react-loader-spinner";
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import loader  from  '../images/loader.gif';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function PatientReview(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [patientDetails, setPatientDetails] = React.useState("");

    useEffect(() => {
      if (props.history.location.state) {
        console.log(props.history.location.state)
        setPatientDetails(props.history.location.state.patientInfo);
      }
    }, [props.history.location.state]);   

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const [expanded, setExpanded] = React.useState('false');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    //setEditData(panel);
  };

  const [ROSList, setROSList] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [flag, setFlag] = React.useState(0);
  const [showLoader, setShowLoader] = React.useState(false);

  const [deSelect, setDeselect] = React.useState(false);

  // patient review check boxes
  // const [checked, setChecked] = useState(false)

  const [updateList, setUpdateList] = React.useState("");
  let engagement = JSON.parse(localStorage.getItem("engagement_timer"));

  useEffect(() => {

    

    let pat_details = JSON.parse(localStorage.getItem("patient"));
    if(pat_details){
      getROS();
    }
  }, []); 

  useEffect(() => {

    if(flag == 1){
      setROSList(ROSList);
      setFlag(0);
    }
    console.log("roslist")
   
  }, [flag]); 

 

  const handleSymptomChange = (event) => {
    // setChecked(checked)
    if(deSelect){
      setDeselect(!deSelect)
    }else {

    }
      

      var arr=[];
      const isChecked = event?  event.target.value  : null
      console.log(isChecked)
      const eventID = event ? event.target.name : null
      const check = isChecked ? isChecked : null
      var symp = [];
      var ros = ROSList;
      var up_symp = [];
      setUpdateList("");
      console.log("Event ID ->", eventID);
      console.log("Check ->", check);
      for(var i =0;i< ros.length;i++){
        symp = ros[i].symptoms;
        for(var j=0; j < symp.length;j++){
          //  symp[j].checked = 'N';
           if(symp[j].checked === 'N' || symp[j].checked === null){
            symp[j].checked = 'N';
            console.log("this is working first Time")
           }
          
          if(symp[j].id === eventID){
            if(isChecked === 'Y'){
              symp[j].checked = 'Y';
             
            }
            else {
              symp[j].checked = 'N';
           
              
             
            
            }
          }
         
            
            
          

          
          up_symp.push(symp[j]);
        }
        ros[i].symptom = symp;
      }
      //console.log(up_symp);
      setUpdateList(up_symp);
      setROSList(ros);
      setFlag(1);
      console.log("updateList")
      console.log(updateList)
    
  }


  const submitForm =  () => {
    let isValid = true;
    
    console.log(ROSList)
    console.log("update")

    console.log(updateList)

  
    
    if(isValid){

      setShowLoader(true);

      let pat_details = JSON.parse(localStorage.getItem("patient"));
      let data;
      let url;
        data ={
          pd_id: pat_details.pd_id,
          ros_value: updateList,
          patient_program_id: pat_details.cpatp_id,
          ccq_flag: "0",
        }
        
      url = "/patient/patient-ros-create-update";
      
      console.log("DATA =", data );

    apiAxios
        .post(url, data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            setShowLoader(false);
            getROS();  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  setShowLoader(false);       
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });

    }


  }

  // const [updateList, setUpdateList] = React.useState("");

  // const handleSymptomChange = (event) => {
  //   // setChecked(checked)
  //     var arr=[];
  //     const isChecked =  event.target.value 
  //     console.log(isChecked)
  //     const eventID = event.target.name 
  //     const check = isChecked 
  //     var symp = [];
  //     var ros = ROSList;
  //     var up_symp = [];
  //     setUpdateList("");
  //     console.log("Event ID ->", eventID);
  //     console.log("Check ->", check);
  //     for(var i =0;i< ros.length;i++){
  //       symp = ros[i].symptoms;
  //       for(var j=0; j < symp.length;j++){
  //         if(symp[j].id == eventID || null){
  //           if(isChecked == 'N'){
  //             symp[j].checked = 'N';
  //           }
  //           else{
  //             symp[j].checked = 'Y';
  //           }
  //         }
  //         up_symp.push(symp[j]);
  //       }
  //       ros[i].symptom = symp;
  //     }
  //     //console.log(up_symp);
  //     setUpdateList(up_symp);
  //     setROSList(ros);
  //     setFlag(1);
      
  // }


  const submitReset = () =>{
    // setROSList("");
    // getROS();
    window.location.href = "/patient-review";
  }  

  const getROS = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

    let pd = JSON.parse(localStorage.getItem("patient"));
         
    //setROSList([]);
    setMessage("Loading...");

    let data ={
        pd_id: pd.pd_id,
        // patient_program_id: pd.cpatp_id
        patient_program_id : engagement.patient_program_id 
      }

    apiAxios
        .post("/patient/patient-ros-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("ros_response --> ",response);
          if(response.data.statusCode == 200) {
              setROSList(response.data.data.data.data); 
              console.log("ROS - ", ROSList);
              setMessage("");   
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              // toast.error(response.data.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          // toast.error(error.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     });

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }
  );

  const handleDeselect = () => {
    setDeselect(!deSelect)
    var symp = [];
      var ros = ROSList;
      var up_symp = [];
    for(var i =0;i< ros.length;i++){
      symp = ros[i].symptoms;
      for(var j=0; j < symp.length;j++){
        symp[j].checked = '';
        up_symp.push(symp[j]);
      }
      ros[i].symptom = symp;
    }
    //console.log(up_symp);
    setROSList(ros);
    setFlag(1);
  }

    return (
        <div>
         
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
             <Header />
             <Box className={classes.Pagecontent}>
             <Box className={classes.Leftcol}>
             <Box className={classes.leftnav}>
              
                <Patientinfo patDetails= {patientDetails} />
                
                 {/* left Accordion*/}
                 <Box className={classes.TabPatientinfo}>
                <LeftNav />
                </Box>  
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
             </Box>
             <Box className={classes.Rightcol}>
                 <Box className={classes.Searchresult}>
                     <Timer />

                     <UpdateTimer />
                     {engagement.initial_visit_required === true ?
                    <>
                     <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                     <Typography variant='h4' style={{fontFamily:'Poppins',fontSize:18,fontWeight:'600',textTransform:'capitalize',width:'50%'}}>Review of Systems</Typography>
                     <Box style={{display:'flex',alignItems:'center',justifyContent:'flex-end',width:'50%'}}>
                         
                         {showLoader?
                          <Loader
                          type="ThreeDots"
                          color="#000000"
                          height={50}
                          width={50}
                          timeout={0} //30 secs
                        />
                        :
                        (
                          <>
                          <Button className={classes.chartbtn} onClick={submitReset}>Reset</Button> 

                          {/* <Button className={classes.savebtn} onClick={submitForm}>Save</Button> */}

                          {(updateList.length > 0)?
                          <Button className={classes.savebtn} onClick={submitForm}>Save</Button>
                          :
                          <Button className={classes.savebtn} style={{opacity: '0.1'}}>Save</Button>
                          }
                          </>
                        )
                        }
                         <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleDeselect}
                                checked={deSelect}
                                name="checkedAll"
                                color="primary"
                              />
                            }
                            label="Deselect All"
                          />
                     </Box>
                     <Box>
                     </Box>
                     </Box>
                  
          
                     <Grid container spacing={3} style={{marginBottom:50,}}>  
                     { (ROSList.length >0) ?
                          <>
                          {ROSList.map((each) => ( 
                        <Grid item xs={12} sm={6}>
                          <Accordion expanded={(expanded == each.heading_id)? true : false} onChange={handleChange(each.heading_id)} style={{boxShadow:'none'}}>
                    
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              style={{background: '#f3f3f3', color: '#0f3d6e', borderRadius: '10px', padding:'4px 10px', border:'none'}}
                            >
                                <Typography style={{textAlign:'left',fontSize:16,}}><FaFileMedicalAlt style={{margin: '5px 10px -2px 10px'}} /> {each.heading}</Typography>
                              </AccordionSummary>
                            <AccordionDetails style={{marginTop: '12px', display: 'block'}}>
                            {(each.symptoms).map((symptom) => (
                            <Grid item xs={12} sm={12}>  
                              <Box style={{display:'flex',alignItems:'center'}}>
                                  <label className={classes.CheckboxLabel} style={{textAlign:'left',fontSize:14,color:'rgb(150 151 155)'}}>{symptom.name}</label>
                                  <Box>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"                                   
                                      >
                                        <FormControlLabel value='N' control={<Checkbox name={symptom.id}  checked={(symptom.checked == null || symptom.checked == 'N')?  "checked" : "" }  onChange={handleSymptomChange} color="danger" />} label="No" />
                                        <FormControlLabel value='Y' control={<Checkbox name={symptom.id} checked={(symptom.checked == 'Y')? 'checked' : ""}   onChange={handleSymptomChange} color="success" />} label="Yes" />
                                      </RadioGroup>
                                    </FormControl>
                                  </Box>
                              </Box>
                             </Grid>
                            ))}
                            </AccordionDetails>
                          
                          </Accordion> 
                      </Grid>
                      ))}
                      </>
                      :
                      <Box style={{textAlign:'center', margin:'15%'}}>{(message == 'Loading...') ? <img src={loader} alt="logo" style={{width:'40px'}} /> : message}</Box>
                    }
        <Grid item xs={12} sm={6}>
         
        </Grid>
                     </Grid></>
                     :
                     <></>
                  }
                </Box>
                  {/* modal */}
                  <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <DialogContentText id="alert-dialog-slide-description">
            <BiErrorCircle className={classes.infoicon} />
            <h3>What would you like to do ?</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <Button onClick={handleClose} className={classes.viewbtn}>
          View Record
          </Button>
          <Button onClick={handleClose} className={classes.Engagebtn}>
          Engage With Patient
          </Button>
        </DialogActions>
      </Dialog>
      
             </Box>
             </Box>
             
            
    
                  

            
                  
        </div>
    )
}

export default PatientReview
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left',
        fontSize:14,
        '& .MuiFormControlLabel-label':{
          fontSize:14,
        }
    },
    savebtn:{
        background:'#0f3d6e',
        padding:'0 20px',
        height:'40px',
        borderRadius:'10px',
        fontSize:16,
        marginRight:15,
        color:'#fff',
        textTransform:'capitalize',
        '&:hover':{
            background:'#333'
        }
    },
    chartbtn:{
        background:'#95a2b3',
        padding:'0 20px',
        height:'40px',
        borderRadius:'10px',
        fontSize:16,
        marginRight:15,
        color:'#fff',
        textTransform:'capitalize',
        '&:hover':{
            background:'#333'
        }
    },
    CheckboxLabel:{
      width:200,
      '@media only screen and (max-width: 820px)':{
        width:175,
              },
    },
    TabPatientinfo:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
              },
      '@media only screen and (max-width: 768px)':{
        display:'none'
              },
    },
    Leftcol:{
        width: '22%',
        background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 1200px)':{
          width: '24%',
          padding:'20px 1%',
                },
                '@media only screen and (max-width: 820px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
                '@media only screen and (max-width: 768px)':{
                  position:'absolute',
                  background:'none',
                  width: '98%',
                  padding:'20px 1%',
                  minHeight:35,
                        },
    },
    leftnav:{
        position:'absolute',
        top:'15px',
        bottom:'15px',
        left:'40px',
        right:'40px',
        '@media only screen and (max-width: 1200px)':{
          left:'20px',
          right:'20px',
                },
                
        },
        Rightcol:{
            width:'78%',
            padding:'20px 3%',
            borderLeft:'1px #F6F6F6 solid',
            '@media only screen and (max-width: 1200px)':{
              width:'72%',
              padding:'0 1%',
                    },
                    '@media only screen and (max-width: 820px)':{
                      width:'96%',
                      padding:'0 2%',
                      marginTop:60,
                            },
                    '@media only screen and (max-width: 768px)':{
                      width:'96%',
                      padding:'0 2%',
                      marginTop:60,
                            },
            '& .MuiAccordionSummary-root':{
                borderBottom:'1px #5f80a9 solid',
                height:'40px',
                minHeight:45,
                color:'#bfc4cb',
                padding:'0px',
                fontFamily:'Poppins',
            },
            '& .MuiAccordion-root:before':{
                display:'none'
            },
            '& .MuiTab-root':{
              minHeight:'40px'
            }
        },
        bottomnav:{
            position:'absolute',
            bottom:'0px',
            left:'0px'
        },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#bfc4cb',
        paddingBottom:'5px'
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 0'
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    provider:{
        width:'14.5%',
        textAlign:'left',
        paddingRight:'0.5%'
    },
    firstname:{
        width:'9.5%',
        paddingRight:'0.5%'
    },
    lastname:{
      width:'9.5%',
        paddingRight:'0.5%'
    },
    dob:{
        width:'7.5%',
        paddingRight:'0.5%'
    },
    emrID:{
      width:'7.5%',
      paddingRight:'0.5%'
    },
    hrpmID:{
        width:'8.5%',
        paddingRight:'0.5%'
    },
    program:{
        width:'9.5%',
        paddingRight:'0.5%'
    },
    time:{
        width:'9.5%',
        paddingRight:'0.5%'
    },
    teammember:{
        width:'20%',
        display:'flex',
        justifyContent:'flex-end'
    },
   
    planbtn:{
        fontSize:'14px',
        color:'#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px'
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px'
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        },
        '& .MuiCheckbox-root': {
          color: '#88b3eb'
        }

    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end',
        marginTop:'40px'
   },

viewbtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    color:'#fff',
    textTransform:'capitalize',
    width:'220px',
    fontSize:'16px',
    cursor:'pointer',
    marginRight:'15px',
    '&:hover':{
        background:'#0f3d6e'
    }
},
Engagebtn:{
    background:'#0f3d6e',
    borderRadius:'10px',
    padding:'10px 20px',
    width:'220px',
    color:'#fff',
    textTransform:'capitalize',
    fontSize:'16px',
    cursor:'pointer',
    '&:hover':{
        background:'#0f3d6e'
    }
},
modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'15px !important',
        padding:'40px'
    }
},
ccmmodal:{
    width:'500px',
    borderRadius:'30px',
    textAlign:'center',
    '& h3':{
        fontSize:'28px',
        color:'#0f3d6e',
        marginBottom:'0px',
        marginTop:'15px'
    }
},
modalbtn:{
    display:'flex',
    justifyContent:'center'
},
infoicon:{
    fontSize:'55px',
    color:'#0f3d6e'
}
}));