import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import LeftNav from '../components/LeftNav'
import Patientinfo from '../components/Patientinfo'
import Timer from '../components/Timer'
import logo from '../images/DHCT_Logo.png'
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import { Link } from 'react-router-dom'

function JourneySelector() {
    const classes = useStyles();
    return (
        <div>
             
            <Box className={classes.Pagecontent}>
            <Box className={classes.logo}><img src={logo} alt="logo" /></Box>
            <Box>
            <h3>HealthyRPM</h3>
            </Box>
            <Box className={classes.Journeycol}>
                <Box className={classes.Journeycolor}>
                <Link to="/provider-list"><Button className={classes.Journey1}></Button></Link>
                <Typography>Journey #1</Typography>
                </Box>
                <Box className={classes.Journeycolor}>
                <Button className={classes.Journey2}></Button>
                <Typography>Journey #2</Typography>
                </Box>
                <Box className={classes.Journeycolor}>
                <Button className={classes.Journey3}></Button>
                <Typography>Journey #3</Typography>
                </Box>
                <Box className={classes.Journeycolor}>
                <Button className={classes.Journey4}></Button>
                <Typography>Journey #4</Typography>
                </Box>
            </Box>
            </Box>
        </div>
    )
}

export default JourneySelector
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'90%',
        display:'flex',
        textAlign:'left',
        padding:'60px 5%',
        flexDirection:'column'
    },
    Journeycol:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        marginTop:90,
        '& a':{
            textDecoration:'none'
        }
    },
    Journey1:{
    width:65,
    height:65,
    borderRadius:'50%',
    backgroundColor:'#FFBD13',
    marginBottom:10,
    '&:hover':{
        backgroundColor:'#FFBD13',
    }
    },
    Journey2:{
        width:65,
        height:65,
        borderRadius:'50%',
        backgroundColor:'#1363FF',
        marginBottom:10,
        '&:hover':{
            backgroundColor:'#1363FF',
        }
        },
        Journey3:{
            width:65,
            height:65,
            borderRadius:'50%',
            backgroundColor:'#FF1392',
            marginBottom:10,
            '&:hover':{
                backgroundColor:'#FF1392',
            }
            },
            Journey4:{
                width:65,
                height:65,
                borderRadius:'50%',
                backgroundColor:'#32DFA1',
                marginBottom:10,
                '&:hover':{
                    backgroundColor:'#32DFA1',
                }
                },
                Journeycolor:{
                    margin:'0 20px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                }        
   }));