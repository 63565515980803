import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {BiChevronUp,BiChevronDown, BiCloudDownload,BiArrowBack, BiCloudUpload, BiXCircle} from "react-icons/bi"
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import calendericon from '../images/calendar-icon.svg'
import { Doughnut } from 'react-chartjs-2';

const data = {
  labels: ['50-60', '61-70', '71-90', '91-120', '>120'],
  datasets: [
    {
      label: '# of Votes',
      data: [7, 2, 4, 7, 14,],
      backgroundColor: [
        '#ff4d7d',
        '#ff9d00',
        '#ffd030',
        '#00c4c3',
        '#009ef2',
      ],
      borderColor: [
        '#fff',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
      ],
      borderWidth:1,
    },
  ],
};

function PopulationPieChart({ options }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <div>
      <Box className={classes.loginform}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box style={{paddingRight:100,}}>
        <Box className={classes.Careplan}>
           <Box className={classes.throw}> 
                         <Box className={classes.Row1}><b>Billing Tier Description</b></Box>
                         <Box className={classes.Row9}><b># of Patients</b></Box>
                         <Box className={classes.Row10}><b>Value One</b></Box>
                        
                     </Box>
                     <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Tier 1- Should have no CoPay</Box>
                         <Box className={classes.Row9}>26</Box>
                         <Box className={classes.Row10}>John</Box>
                         
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Tier 2- Should have CoPay</Box>
                         <Box className={classes.Row9}>26</Box>
                         <Box className={classes.Row10}>John</Box>
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Tier 3- Need to validate insurance</Box>
                         <Box className={classes.Row9}>26</Box>
                         <Box className={classes.Row10}>John</Box>
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Tier 4- High Risks/ Opt-Outs</Box>
                         <Box className={classes.Row9}>26</Box>
                         <Box className={classes.Row10}>John</Box>
                         
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}><b>Grand Total</b></Box>
                         <Box className={classes.Row9}><b>420</b></Box>
                         <Box className={classes.Row10}><b>12 210</b></Box>
                         
                         </Box>
           </Box>
           </Box>
        </Grid>
        <Grid item xs={5}><Doughnut data={data} /></Grid>
        <Grid item xs={8}>
        <Typography variant="h5" style={{marginBottom:30,}}>Texas Lung Consolidated Forecast</Typography>
        <Box className={classes.Careplan}>
           <Box className={classes.throw}> 
                         <Box className={classes.Row1}></Box>
                         <Box className={classes.Row2}><b>FY 2021</b></Box>
                         <Box className={classes.Row3}><b>FY 2021</b></Box>
                         <Box className={classes.Row4}><b>Var</b></Box>
                         <Box className={classes.Row5}><b>% Var</b></Box>
                        
                     </Box>
                     <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Revenue</Box>
                         <Box className={classes.Row2}>$71,447</Box>
                         <Box className={classes.Row3}>$79,148</Box>
                         <Box className={classes.Row4}>$7,701</Box>
                         <Box className={classes.Row5}>10.80%</Box>
                         
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>Costs (Includes MA & DHCT)</Box>
                         <Box className={classes.Row2}>$34,060</Box>
                         <Box className={classes.Row3}>$36,824</Box>
                         <Box className={classes.Row4}>$2,764</Box>
                         <Box className={classes.Row5}>8.10%</Box>
                         </Box>
                         <Box className={classes.tdrow}> 
                         <Box className={classes.Row1}>P/L</Box>
                         <Box className={classes.Row2}>$37,387</Box>
                         <Box className={classes.Row3}>$42,324</Box>
                         <Box className={classes.Row4}>$4,937</Box>
                         <Box className={classes.Row5}>13.20%</Box>
                         </Box>
                        
           </Box>
        </Grid>
      </Grid>
       </Box>
        </div>
    )
}

export default PopulationPieChart
const useStyles = makeStyles(() => ({
  input:{ 
    border:'none',
    borderRadius:'10px',
    height:'50px',
    width:'100%',
    background:'#F9F9F9',
    padding:'0 15px'
},
exportbtn:{
  fontSize:'18px',
  color:'#88b3eb',
  textTransform:'capitalize',
  '&:hover':{
    color:'#000',
    background:'transparent'
  }
},
Formcol:{
  display:'flex',
  alignItems:'center',
  marginBottom:'30px',
  '& .react-datepicker-wrapper':{
    width:'145px',
  },
  '& p':{
      fontSize:'18px',
      margin:'0px'
  },
  '& label':{
      width:'200px'
  },
  '& .react-dropdown-select-input':{
      width:'100%'
  }
},
loginform:{
    width:'100%',
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        background:'#F9F9F9',
        padding:'0 15px'
    }
},
loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'142px',
    height:'45px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    '&:hover':{
        background:'#333'
    }
},
throw:{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'2px #E3E5E5 solid',
  alignItems:'center',
  fontSize:'13px',
  lineHeight:'16px',
  color:'#bfc4cb',
  paddingBottom:'5px',
  width:'100%',
  alignItems:'center'
},
tdrow:{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'1px #E3E5E5 solid',
  fontSize:'14px',
  padding:'15px 0',
  width:'100%',
  alignItems:'center',
  lineHeight:'15px',
  color:'#000'
},
calendaricon:{
position:'absolute',
right:'-10px',
zIndex:'1',
top:'15px',
minWidth:'15px',
height:'20px',
width:'15px'
},
checkicon:{
  fontSize:'25px',
  color:'#47C932'
},
inputfile:{
  display:'none'
},
select:{
  width:'200px',
  border:'none !important',
  borderRadius:'10px !important',
  background:'#F9F9F9',
  height:'50px',
  fontSize:'14px !important',
  paddingLeft:'15px !important',
  paddingRight:'15px !important',
  '& .react-dropdown-select-input':{
    display:'none'
  }
},
startDate:{
marginRight:'30px',
position:'relative'
},
Enddate:{
position:'relative',
marginRight:'30px',
marginLeft:'20px'
},
Toptext:{
  fontSize:'18px',
  color:'#0f3d6e',
  fontWeight:'600'
},
Textheading:{
  fontSize:'16px',
  marginTop:'0px',
  fontWeight:'500'
},
Addbtn:{
  width:'180px',
  height:'45px',
  background:'#E13F66',
  borderRadius:'10px',
  color:'#fff',
  boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  textTransform:'capitalize',
  fontSize:'16px',
  '&:hover':{
      background:'#000'
  }
},
cancelbtn:{
  background:'#DADADA',
  borderRadius:'10px',
  textTransform:'capitalize',
  height:'45px',
  width:'120px',
  color:'#fff',
  fontWeight:'600',
  '&:hover':{
      background:'#000'
  }
},
nextbtn:{
  background:'#0f3d6e',
  borderRadius:'10px',
  textTransform:'capitalize',
  height:'45px',
  width:'120px',
  color:'#fff',
  fontWeight:'600',
  marginLeft:'15px',
  fontFamily:'Poppins',
  '&:hover':{
      background:'#000'
  }
},
btncol:{
  display:'flex',
  justifyContent:'flex-end',
  marginTop:'5px'
},
closebtn:{
  width:'40px',
  position:'absolute',
  right:'10px',
  height:'40px',
  background:'#fff',
  top:'10px',
  minWidth:'40px',
  '&:hover':{
      background:'#fff'
  }
},
closeicon:{
  fontSize:'25px',
  color:'#0f3d6e'
},
Row1:{
width:'45%'
},
Row2:{
width:'14%'
},
Row3:{
width:'14%'
},
Row4:{
width:'14%'
},
Row5:{
width:'14%'
},
Row6:{
width:'10%'
},
Row7:{
width:'10%',
display:'flex',
textAlign:'left'
},
Row8:{
width:'12%',
display:'flex',
textAlign:'left'
},
Row9:{
width:'20%',
textAlign:'center'
},
Row10:{
width:'20%',
textAlign:'right'
},
providerbtn:{
display:'flex',
cursor:'pointer',
alignItems:'center',
'& span':{
    display:'flex',
    flexDirection:'column',
    width:'20px',
    marginRight:'10px',
    '& button':{
        background:'none',
        border:'none',
        height:'10px',
        cursor:'pointer'
    }
}
},
Careplan:{
  width:'100%',
  '& .MuiAccordionSummary-root':{
      borderBottom:'1px #E3E5E5 solid',
      height:'auto',
      color:'#bfc4cb',
      padding:'0px',
      fontFamily:'Poppins',
  },
  '& .MuiAccordion-root.Mui-expanded':{
      margin:'0px'
  },
  '& .MuiAccordionSummary-content':{
      margin:'0',
      order:'2',
      width:'100%'
  },
  '& .MuiIconButton-root':{
      padding:'5px',
      fontSize:'16px',
      marginRight:'5px',
      color:'#88b3eb',
      position:'absolute',
      left:'-25px'
  },
  '& .MuiAccordion-root:before':{
      display:'none'
  }
  },    
}));