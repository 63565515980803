import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import { BiArrowBack, BiXCircle, BiCloudUpload } from "react-icons/bi";
import { AiFillFileExcel } from "react-icons/ai";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import PatientStatus from '../components/PatientStatus';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import FormGroup from "@material-ui/core/FormGroup";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { apiAxios } from "../Util/ApiAxios";
import axios from 'axios';
import Loader from "react-loader-spinner";
import moment from "moment";
import {
   
    BiPlusCircle,
   
  } from "react-icons/bi";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    checkName,
    checkEmail,
    checkNumbers,
    checkPhone,
    checkPhoneNumbers,
    checkNumbersOnly,
    checkDob,
    checkZip,
    checkUSPhone
} from "../Util/validations";

import { stateList } from "../Util/DropDownData";
import { FaGlasses } from "react-icons/fa";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ManagePatient({ options }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [tablemessage, setTableMessage] = useState("");
    const [patientList, setPatientList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);

    const [search, setSearch] = useState("");
    const [provider, setProvider] = useState("");
    const [program, setProgram] = useState("");

    const location = useLocation();
    const client_id = location.state?.client_id;
    const client_name = location.state?.client_name;

    const [value2, onChange2] = useState(new Date());
    const [emrid, setEmrID] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const[file, setFile] = useState({});
    const [excelList, setExcelList] = useState("");
    const [submitBtnFlag, setSubmitBtnFlag] = useState(false);
    const [loader, setLoader] = useState(false)

    //Error States
    const [fileError, setFileError] = useState(false);
    const [emrError, setEmrError] = useState(false);
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [zipError, setZipError] = useState(false);

    console.log("test - ", location.state);
// patient excel modal


    const [openView, setOpenView] = React.useState(false);

    const handleClickOpenView = () =>{
        getExcelList();
        setOpenView(true);
    }

    
  const handleCloseView = () => {
    setOpenView(false);
  };
// patient excel list download

const getExcelList = useCallback(
    async ({
        table_config_rows_per_page = "15",
        table_config_page_no = "1",
    } = {}) => {
        setCurrentPage(parseInt(table_config_page_no));

        let data = {
            // client_id: client_id,
            // program_id: "",
            // provider_id: "",
            // search_value: search,
            table_config_rows_per_page: table_config_rows_per_page,
            table_config_page_no: table_config_page_no,
        };

        setTableMessage("Loading...");

        apiAxios
            .post("/import/list-files", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("Token"),
                },
            })
            .then((response) => {
                console.log("patient list data --> ", response);
                if (response.data.statusCode == 200) {
                    setExcelList(response.data.data);
                    setTotalPages(response.data.total_pages);

                    if (response.data.data.length == 0) {
                        setTableMessage("No Data Found");
                    }
                } else {
                  
                }
            })
            .catch((error) => {
                console.log(error);

                // toast.error("Something Went Wrong!", {
                //     position: "bottom-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
            });
    }
);





    const getPatientList = useCallback(
        async ({
            table_config_rows_per_page = "15",
            table_config_page_no = "1",
        } = {}) => {
            setCurrentPage(parseInt(table_config_page_no));

            let data = {
                client_id: client_id,
                program_id: "",
                provider_id: "",
                search_value: search,
                table_config_rows_per_page: table_config_rows_per_page,
                table_config_page_no: table_config_page_no,
            };

            setTableMessage("Loading...");

            apiAxios
                .post("/patient/patient-demographics-list-search", data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("Token"),
                    },
                })
                .then((response) => {
                    console.log("patient list data --> ", response);
                    if (response.data.statusCode == 200) {
                        setPatientList(response.data.data);
                        // setTotalPages(response.data.total_pages);

                        if (response.data.data.length == 0) {
                            setTableMessage("No Data Found");
                        }
                    } else {
                       
                    }
                })
                .catch((error) => {
                    console.log(error);

                    // toast.error("Something Went Wrong!", {
                    //     position: "bottom-center",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                });
        }
    );

    function onSearchSubmit() {
        getPatientList();
    }

    function submitForm() {
        let isValid = true;

        console.log("submit form");

        if (!checkName(firstname.trim())) {
            setFirstNameError(true);
            isValid = false;
            console.log("First Error if= ", firstnameError);
        } else {
            setFirstNameError(false);
        }
        console.log("First Error = ", firstnameError);

        if (!checkName(lastname.trim())) {
            setLastNameError(true);
            isValid = false;
        } else {
            setLastNameError(false);
        }
        if (emrid.trim().length >0 && emrid.trim().length <=255) {
            setEmrError(false);
        } else {
            setEmrError(true);
            isValid = false;
        }
        if (!checkEmail(email) && email.length > 0) {
            setEmailError(true);
            isValid = false;
        } else {
            setEmailError(false);
        }
        if (!checkUSPhone(phone)) {
            setPhoneError(true);
            isValid = false;
        } else {
            setPhoneError(false);
        }
        console.log("Age no sub = ", parseInt(checkDob(value2)));
        if (parseInt(checkDob(value2)) < 0 ||  parseInt(checkDob(value2)) >200 || isNaN(parseInt(checkDob(value2)))) {
            setDobError(true);
            isValid = false;
        } else {
            setDobError(false);
        }
        if (address.trim().length > 0) {
            setAddressError(false);
        } else {
            setAddressError(true);
            isValid = false;
        }
        if (state.length > 0) {
            setStateError(false);
        } else {
            setStateError(true);
            isValid = false;
        }
        if (city.trim().length > 0 && city.trim().length <=255) {
            setCityError(false);
        } else {
            setCityError(true);
            isValid = false;
        }
        if (checkZip(zip)) {
            setZipError(false);
          } else {
            setZipError(true);
            isValid = false;
          }
        if (isValid) {
            let data = {
                client_id: client_id,
                emr_id: emrid,
                patient_first_name: firstname,
                patient_last_name: lastname,
                patient_mailing_address: address,
                patient_city: city,
                patient_state: state[0].label,
                patient_zip: zip,
                patient_email: email,
                patient_dob: moment(value2).format("YYYY-MM-DD"),
                patient_primary_phone: phone.replace(/[^0-9]/g,""),
            };

            apiAxios
                .post("/patient/create-patient", data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("Token"),
                    },
                })
                .then((response) => {
                    console.log("response --> ", response);
                    if (response.data.statusCode == 200) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        
                        window.location.href = "/patient-assignment";


                    } else {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);

                    toast.error("Something Went Wrong!", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    }

    useEffect(() => {
        getPatientList();
        // getExcelList();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //  modal  //
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // file upload

    const OnInputChange = (e) =>{
        console.log(e.target.files[0])
        let fileInfo  = e.target.files[0]
        setFile(fileInfo);
       

    }

    const onFileSubmit = () =>{





      console.log("file")
      console.log(file)

        let isValid = true;
        if(file.name){
            setFileError(false)
        }else {
            setFileError(true)
            isValid = false;
        }

        console.log(" file submit");

       
        if (isValid) {
            setSubmitBtnFlag(!submitBtnFlag)
            setLoader(true)
            let data = {
                file_name: file.name
            };

            apiAxios
                .post("/import/generate-presigned-url", data, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("Token"),
                    },
                })
                .then((response) => {
                    console.log("presigned url response --> ", response);
                    if (response.status == 200) {
                        toast.success(response.data.message, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        

                           // uploading File to S3 after presigned url generation
                   
                            let signedUrl = response.data.url
                            let newfields = response.data.fields
                           


                            const formData = new FormData();
                            // formData.append('Content-Type', "application/vnd.ms-excel");
                            Object.entries(newfields).forEach(([k, v]) => {
                              formData.append(k, v);
                            });
                            formData.append('file', file); // The file has be the last element
                          
                             axios.post(signedUrl, formData, {
                              headers: {'Content-Type': 'multipart/form-data'},
                            }).then((response)=>{
                                if (response.status == 204) {


                                    // import patient api 
                                   
                                        let data = {
                                            file_name: file.name,
                                        };
                            
                                        apiAxios
                                            .post("/import/patient-import", data, {
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Authorization: localStorage.getItem("Token"),
                                                },
                                            })
                                            .then((response) => {
                                                console.log("response --> ", response);
                                                if (response.data.statusCode == 200) {
                                                    toast.success(response.data.message, {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                    
                                                    setLoader(false)
                                                    setTimeout(() => {
                                                        window.location.href = "/manage-patient";
                                                      }, "2000")
                                                   
                                                      
                            
                            
                                                } else {
                                                    toast.error(response.data.message, {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });

                                                    setLoader(false)
                                                    setTimeout(() => {
                                                        window.location.href = "/manage-patient";
                                                      }, "3000")
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                            
                                                toast.error("Something Went Wrong!", {
                                                    position: "bottom-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                
                                                setLoader(false)
                                                setTimeout(() => {
                                                    window.location.href = "/manage-patient";
                                                  }, "2000")
                                            });
                                    






                                }
                            } )
                              .catch((error)=>{
                                console.log(error)
                                setLoader(false)
                              }) 
    


                    } else {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoader(false)
                    }
                })
                .catch((error) => {
                    console.log(error);

                    toast.error("Something Went Wrong!", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }


        setLoader(false)
    }


    const downloadExcel = () => {


        let data ={
        
        }
    
        apiAxios
            .post("/import/patient-template-download", data, {
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": localStorage.getItem("Token")
              },
            })
            .then((response) => {
              console.log("excel response --> ",response);
              if(response.data.statusCode == 200) {
                  
                let url = response.data.url
                console.log(url)
                window.location.href = url;
                  
              }
              else {
                  toast.error(response.data.message, {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
              }
            })
            .catch((error) => {
              console.log(error);
      
              toast.error(error.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
    
                //   localStorage.removeItem("Refresh_Token");
                //   localStorage.removeItem("Token");
                //   window.location.href = "/";        
    
      
            });
      }



      const handleInput = (e) => {
   
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        
        setPhone(formattedPhoneNumber);
      };
    
    
      function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;
      
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');
      
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
      
        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
      
        if (phoneNumberLength < 4) return phoneNumber;
      
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
      
        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
    
    
      }

   

    return (
        <div>
            <Header />
            <Box className={classes.Pagecontent}>
                <Box className={classes.leftcol}>
                    <Box className={classes.leftnav}>
                        {(localStorage.getItem("JWT")) ?
                            <PatientStatus />
                            :
                            <></>
                        }
                        
                    </Box>
                </Box>
                {/* right col */}
                <Box className={classes.Rightcol}>
                    <Link to="dashboard">
                        <Button className={classes.backBtn}>
                            <BiArrowBack className={classes.backarrow} />
                        </Button>
                    </Link>
                    <br />

                    {/* filter end */}
                    <Box className={classes.PatientTable}>
                        <Box className={classes.loginform}>
                            <h3 className={classes.Toptext}>Add New Patient</h3>
                            <form>
                                <Grid container spacing={5}>
                                    <Grid item xs={8} sm={8}>
                                        <Box className={classes.Formcol}>
                                            <label>
                                                Patient EMR ID
                                                <span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Patient EMR ID"
                                                type="text"
                                                value={emrid}
                                                onChange={(e) => setEmrID(e.target.value)}
                                            />
                                        </Box>
                                        {emrError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Emr ID is required (max char length 255)
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <Box className={classes.Formcol}>
                                            <label>
                                                First Name<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter First Name"
                                                value={firstname}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                // onChange={(e) =>{
                                                //     setFirstName(e.target.value);
                       
                                                //     if (!checkName(firstname.trim())) {
                                                //      setFirstNameError(true);
                                                     
                                                //    } else {
                                                //      setFirstNameError(false);
                                                //    }
                                                  
                                                //  }}
                                                type="text"
                                            />
                                        </Box>
                                        {firstnameError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Please enter a valid first name
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        <Box className={classes.Formcol}>
                                            <label>
                                                Last Name<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Last Name"
                                                value={lastname}
                                                onChange={(e) => setLastName(e.target.value)}
                                                // onChange={(e) =>{
                                                //     setLastName(e.target.value);
                                                   
                                                //     if (!checkName(lastname.trim())) {
                                                //      setLastNameError(true);
                                                     
                                                //    } else {
                                                //      setLastNameError(false);
                                                //    }
                                                  
                                                   
                                                   
                                                //  }
                                                //    }

                                                type="text"
                                            />
                                        </Box>
                                        {lastnameError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                  Please enter a valid last name
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        <Box className={classes.Formcol}>
                                            <label>
                                                Date of Birth
                                                <span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <DatePicker
                                                // onChange={onChange2}
                                                onChange={(date) => onChange2(date)}
                                                value={value2}
                                                className={classes.input}
                                                maxDate={moment().toDate()}
                                            />
                                        </Box>
                                        {dobError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                               Age should be less than 200 yrs.
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <Box className={classes.Formcol}>
                                            <label>Email</label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Email"
                                                type="text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Box>
                                        {emailError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Provide a valid email.
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <Box className={classes.Formcol}>
                                            <label>
                                                Phone Number
                                                <span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Phone Number"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => handleInput(e)}
                                                // onChange={(e) =>{

                                                //     setPhone(e.target.value);
                                                //     if (!checkPhone(e.target.value)) {
                                                //      setPhoneError(true);
                                                  
                                                //    } else {
                                                //      setPhoneError(false);
                                                //    }
                                                //    }}
                                            />
                                        </Box>
                                        {phoneError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Provide a valid phone number.
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        <Box className={classes.Formcol}>
                                            <label>
                                                Address<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Address"
                                                type="text"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </Box>
                                        {addressError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Address is required.
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <Box className={classes.Formcol}>
                                            <label>
                                                State<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <Select
                                                options={stateList()}
                                                value={state}
                                                onChange={setState}
                                                placeholder="Select State"
                                                className={classes.select}
                                            />
                                        </Box>
                                        {stateError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Please select a state.
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <Box className={classes.Formcol}>
                                            <label>
                                                City<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter City"
                                                type="text"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                // onChange={(e) =>{

                          
                                                //     setCity(e.target.value)
                                                //     if (city.length > 0) {
                                                //      setCityError(false);
                                                //    } else {
                                                //      setCityError(true);
                                                   
                                                //    }
                                                //  }}
                                            />
                                        </Box>
                                        {cityError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                                City is required (max char length 255)
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        <Box className={classes.Formcol}>
                                            <label>
                                                Zipcode<span style={{ color: "#ff0000" }}>*</span>
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Enter Zipcode"
                                                type="tel"
                                                value={zip}
                                                onChange={(e) => setZip(e.target.value)}
                                                // onChange={(e) =>{
                                                //     setZip(e.target.value);
                                                //     if (checkZip(e.target.value)) {
                                                //      setZipError(false);
                                                //    } else {
                                                //      setZipError(true);
                                                     
                                                //    }
                                                //    }}
                                            />
                                        </Box>
                                        {zipError ? (
                                            <p
                                                style={{
                                                    color: "#dc3545",
                                                    fontSize: ".875em",
                                                    marginTop: "0.25rem",
                                                    width: "100%",
                                                    textAlign: "right",
                                                }}
                                            >
                                               (4 to 6) digit zipcode is required
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        {/* <Box className={classes.bulkUpload}>
                                           
                                           
                                        
                                           <label for="file-input">
                                            <BiCloudUpload style={{fontSize: '4rem',cursor: "pointer"}} />  </label>
                                            <input id="file-input" type="file" style={{display:"none"}} onChange={OnInputChange} accept=".xlsx,.csv" />
                                            <h5 style={{margin: '0'}}>Bulk Patient Upload</h5>
                                            {file.name && !submitBtnFlag?
                                            <Button onClick={onFileSubmit}>Submit</Button>
                                            : 
                                            
                                            <></>
                                          
                                             }
                                          
                                           
                                        </Box> */}
                                        <Box className={classes.bulkUpload} style={{marginTop: '5%', backgroundColor: '#f1d3fe'}}>
                                        <Button className={classes.planbtn} onClick={downloadExcel}>
                                        <AiFillFileExcel style={{fontSize: '4rem'}} /> 
                                        </Button>
                                            
                                            <h5 style={{margin: '0'}}>Download Excel Template</h5>
                                        </Box>

                                        <Box className={classes.bulkUpload} style={{marginTop: '5%', backgroundColor: '#f1d3fe'}}>
                                        <Button className={classes.planbtn} onClick= {handleClickOpenView}>
                                        <AiFillFileExcel style={{fontSize: '4rem'}} /> 
                                        </Button>
                                            
                                            <h5 style={{margin: '0'}}>Excel List</h5>
                                        </Box>


                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                        <Box style={{width: "90%", textAlign : "center"}}>
                        <Button
                            size="large"
                            className={classes.loginbtn}
                            onClick={() => submitForm()}
                            
                        >
                            Save
                        </Button>
                        </Box>

                    </Box>


                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Box>
                {/* modal */}
                <Dialog
                    className={classes.modal}
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent className={classes.ccmmodal}>
                        <Box className={classes.btncol}>
                            <Button onClick={handleClose} className={classes.closebtn}>
                                <BiXCircle className={classes.closeicon} />
                            </Button>
                        </Box>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box className={classes.loginform}>
                                <h3 className={classes.Toptext}>Add New Patient</h3>
                                <form>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} sm={12}>
                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Patient EMR ID
                                                    <span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Patient EMR ID"
                                                    type="text"
                                                    value={emrid}
                                                    onChange={(e) => setEmrID(e.target.value)}
                                                />
                                            </Box>
                                            {emrError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Emr ID is required.
                                                </p>
                                            ) : (
                                                <></>
                                            )}

                                            <Box className={classes.Formcol}>
                                                <label>
                                                    First Name<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter First Name"
                                                    value={firstname}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    type="text"
                                                />
                                            </Box>
                                            {firstnameError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    First name is required.
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Last Name<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Last Name"
                                                    value={lastname}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    type="text"
                                                />
                                            </Box>
                                            {lastnameError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Last name is required.
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Date of Birth
                                                    <span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <DatePicker
                                                    onChange={onChange2}
                                                    value={value2}
                                                    className={classes.input}
                                                />
                                            </Box>
                                            {dobError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Age should be more than 40yrs.
                                                </p>
                                            ) : (
                                                <></>
                                            )}

                                            <Box className={classes.Formcol}>
                                                <label>Email</label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Box>
                                            {emailError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Provide a valid email.
                                                </p>
                                            ) : (
                                                <></>
                                            )}

                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Phone Number
                                                    <span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Phone Number"
                                                    type="tel"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </Box>
                                            {phoneError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Provide a valid phone number.
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Address<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Address"
                                                    type="text"
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </Box>
                                            {addressError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Address is required.
                                                </p>
                                            ) : (
                                                <></>
                                            )}

                                            <Box className={classes.Formcol}>
                                                <label>
                                                    State<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <Select
                                                    options={stateList()}
                                                    value={state}
                                                    onChange={setState}
                                                    placeholder="Select State"
                                                    className={classes.select}
                                                />
                                            </Box>
                                            {stateError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Please select a state.
                                                </p>
                                            ) : (
                                                <></>
                                            )}

                                            <Box className={classes.Formcol}>
                                                <label>
                                                    City<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter City"
                                                    type="text"
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </Box>
                                            {cityError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    Please select a city.
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                            <Box className={classes.Formcol}>
                                                <label>
                                                    Zipcode<span style={{ color: "#ff0000" }}>*</span>
                                                </label>
                                                <TextField
                                                    className={classes.input}
                                                    placeholder="Enter Zipcode"
                                                    type="tel"
                                                    value={zip}
                                                    onChange={(e) => setZip(e.target.value)}
                                                />
                                            </Box>
                                            {zipError ? (
                                                <p
                                                    style={{
                                                        color: "#dc3545",
                                                        fontSize: ".875em",
                                                        marginTop: "0.25rem",
                                                        width: "100%",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                   Enter a valid zipcode
                                                </p>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.modalbtn}>
                        <FormGroup aria-label="position" row></FormGroup>
                        <Button
                            size="large"
                            className={classes.loginbtn}
                            onClick={() => submitForm()}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                

                {/* Excel List */}
        <Dialog
        className={classes.modal}
        open={openView}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseView}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            {/* <Typography variant="h5" className={classes.TopHeading}>
              Excel List 
            </Typography> */}
            <Button onClick={handleCloseView} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          
          <DialogContentText id="alert-dialog-slide-description">
           
             
          <h2 style={{textAlign:"center"}}>Patient Excel Upload List</h2> <br /> 
        <Grid container spacing={3}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Box className={classes.btncol}>
        
          </Box>
          <Box className={classes.Throw1}>
            <Box className={classes.Thcol5}>Input File</Box>
            <Box className={classes.Thcol6}>Output File</Box>
            <Box className={classes.Thcol8}>Created At</Box>
            <Box className={classes.Thcol11}>Action</Box>
           
          </Box>
            
          {excelList.length > 0 ? (
            <>
              {excelList.map((each) => (
                <Box className={classes.Tdrow1}>
                  <Box className={classes.Tdcol5}>{each.input_file}</Box>
                  <Box className={classes.Tdcol6}>{each.output_file}</Box>
                  <Box className={classes.Tdcol8}>
                  {each.created_at? moment(each.created_at).format("YYYY-MM-DD HH:mm a") : "--"}
                  </Box>
                  {/* <Box className={classes.Tdcol9}>
                    {each.effective_end_date}
                  </Box>
                  <Box className={classes.Tdcol10}>
                    {each.reimbursement_amt}
                  </Box> */}
                  <Box className={classes.Tdcol11}>
                    {/* <Button className={classes.EditBtn} onClick={() => handleClickOpenView(each,2)}>Edit</Button> */}
                    <Button
                      className={classes.ActionBtn}
                        onClick={()=>{
                            window.location = each.output_link
                        }}
                    >
                     Download
                    </Button>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box className={classes.Tdrow1}>
              <Box
                className={classes.Tdcol12}
                style={{ width: "100%", textAlign: "center", color: "#dfdfdf" }}
              >
                No Data Available
              </Box>
            </Box>
          )}

          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, pageNumber) =>
              getExcelList({
                table_config_page_no: `${pageNumber}`,
              })
            }
          />

         
        </Grid>
      </Grid>

                
           
          
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
        
        </DialogActions>
      </Dialog>
      </Box>
        </div>
    );
}

export default ManagePatient;
const useStyles = makeStyles(() => ({
    Pagecontent: {
        width: "100%",
        display: "flex",
        textAlign: "left",
    },
    loginform: {
        width: "100%",
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiInput-formControl": {
          height: "50px",
        },
        "& .MuiInput-input:focus": {
          border: "1px #7087A7 solid",
          boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
        },
        "& .MuiInputBase-input": {
          height: "50px",
          borderRadius: "10px",
          border: "1px #D5D5D5 solid",
          backgroundColor: "#F9F9F9",
          padding: "0 15px",
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
    
        "& .react-date-picker__wrapper": {
          borderRadius: 10,
          border: "1px #D5D5D5 solid",
          backgroundColor: "#F9F9F9",
          padding: "0 10px",
        },
        "& .react-date-picker__inputGroup__input:focus": {
          border: 0,
          boxShadow: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiInput-formControl": {
          height: "50px",
        },
      },
    BottomCol: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 20,
    },
    Toptext: {
        fontSize: 20,
        marginTop: 0,
        fontWeight: "600",
        fontFamily: "Poppins",
        color: "#121212",
    },
    ActionBtn: {
        borderRadius: 10,
        textTransform: "capitalize",
        background: "#0A70E8",
        color: "#fff",
        "&:hover": {
          background: "rgba(0,0,0,0.8)",
        },
      },
    PatientTop: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 40,
    },
    bulkUpload: {
        height: '120px',
        backgroundColor: '#f3f5f8',
        borderRadius: '20px',
        width: '74%',
        marginLeft: '10%',
        padding: '3%',
        textAlign: 'center',
        '@media only screen and (max-width: 1024px)':{
            width: '94%',
            marginLeft:0,
        }
    },
    Throw: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px #ccc solid",
        paddingBottom: 7,
        fontSize: 14,
        "& label": {
            color: "#919699",
        },
    },
    Throw1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px rgba(0,0,0,0.1) solid",
        paddingBottom: 5,
      },
    Thcol: {
        width: "8%",
    },
    Thcol2: {
        width: "8%",
    },
    Thcol3: {
        width: "8%",
    },
    Thcol4: {
        width: "8%",
    },

    Thcol5: {
        width: "24%",
        fontSize: 16,
        color: "#696969",
      },
      Thcol6: {
        width: "27%",
        fontSize: 16,
        color: "#696969",
      },

      Thcol8: {
        width: "25%",
        fontSize: 16,
        color: "#696969",
      },
      Thcol9: {
        width: "14%",
        fontSize: 16,
        color: "#696969",
      },
      Thcol10: {
        width: "20%",
        fontSize: 16,
        color: "#696969",
      },
      Thcol11: {
        width: "20%",
        fontSize: 16,
        color: "#696969",
        display: "flex",
        justifyContent: "flex-end",
      },
   
    Thcol7: {
        width: "10%",
    },
   
    Thcol12: {
        width: "5%",
    },
    Thcol13: {
        width: "10%",
    },
    Tdrow: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px #ccc solid",
        padding: "10px 0",
        fontSize: 14,
    },
    Tdrow1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        borderBottom: "1px rgba(0,0,0,0.1) solid",
        padding: "10px 0",
      },
      Tdcol5: {
        width: "23.5%",
        wordWrap:"break-word",
        overflow: "auto",
        paddingRight:'0.5%'
      },
      Tdcol6: {
        width: "26.5%",
        wordWrap:"break-word",
        overflow: "auto",
        paddingRight:'0.5%'
      },
      Tdcol7: {
        width: "13.5%",
        paddingRight:'0.5%'
      },
      Tdcol8: {
        width: "27.5%",
        paddingRight:'0.5%'
      },
      Tdcol9: {
        width: "13.5%",
        paddingRight:'0.5%'
      },
      Tdcol10: {
        width: "19.5%",
        paddingRight:'0.5%'
      },
      Tdcol11: {
        width: "19.5%",
        paddingRight:'0.5%',
        display: "flex",
        justifyContent: "flex-end",
      },
    Searchbtn: {
        background: "#7087A7",
        borderRadius: 10,
        display: "flex",
        color: "#fff",
        height: 50,
        paddingLeft: 15,
        paddingRight: 15,
        marginLeft: 10,
        textTransform: "capitalize",
        marginTop: 28,
        "&:hover": {
            background: "rgba(0,0,0,0.8)",
        },
    },
    leftcol:{
        width:'12%',
        padding:'30px 4%',
        background: 'linear-gradient(-45deg, #114ebd, #0f3d6e)',
        position:'relative',
        '@media only screen and (max-width: 1024px)':{
            width: '20%',
            padding:'20px 1%',
                  },
                  '@media only screen and (max-width: 1024px)':{
                    position:'absolute',
                    background:'none'
                          },         
        '& ul':{
            listStyle:'none',
            margin:'0px',
            padding:'0px',
            '& li':{
                color:'#1C295D',
                fontSize:'12px',
                fontWeight:'500'
            },
            '& li button':{
                background:'#f1f1f1',
                width:'150px',
                height:'55px',
                margin: '8px 0',
                textAlign:'center',
                color:'#0f3d6e',
                borderRadius:'10px',
                fontSize:'18px',
                '&:hover':{
                    background:'#0f3d6e'
                }
            }
        }
    },
    leftnav:{
        position:'absolute',
        top:'15px',
        bottom:'15px',
        left:'40px',
        '@media only screen and (max-width: 1024px)':{
            left:'20px',
            right:'20px',
                  },
    },
    Downloadbtn: {
        background: "#0A70E8",
        borderRadius: 10,
        display: "flex",
        color: "#fff",
        height: 35,
        fontSize: 14,
        paddingLeft: 15,
        paddingRight: 15,
        textTransform: "capitalize",
        "&:hover": {
            background: "rgba(0,0,0,0.8)",
        },
    },
    ViewAll: {
        background: "#7087A7",
        borderRadius: 10,
        display: "flex",
        color: "#fff",
        height: 40,
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,
        textTransform: "capitalize",
        "&:hover": {
            background: "rgba(0,0,0,0.8)",
        },
    },
    Addbtn: {
        background: "#1612C6",
        borderRadius: 10,
        display: "flex",
        color: "#fff",
        height: 40,
        fontSize: 14,
        paddingLeft: 15,
        paddingRight: 15,
        textTransform: "capitalize",
        "&:hover": {
            background: "rgba(0,0,0,0.8)",
        },
    },
    backBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: 30,
        height: 20,
        "&:hover": {
            background: "none",
        },
    },
    // ModalTop: {
    //     display: "flex",
    //     flexDirection: "row",
    //     alignItems: "center",
    //     justifyContent: "space-between",
    //     marginBottom: 25,
    // },
    input: {
        border: "none",
        borderRadius: "10px",
        height: "50px",
        width: "100%",
    },
    Topfilter: {
        width: "100%",
        display: "flex",
        marginTop: 30,
        marginBottom: 30,
        flexDirection: "row",
        "& label": { color: "#666", marginBottom: 5, display: "flex" },
        "& .MuiInput-underline:before": {
            display: "none",
        },
        "& .MuiInput-underline:after": {
            display: "none",
        },
        "& .MuiInput-formControl": {
            height: "50px",
        },
        "& .MuiInput-input:focus": {
            border: "1px #0074D9 solid",
            boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
        },
        "& .MuiInputBase-input": {
            height: "50px",
            borderRadius: "10px",
            border: "1px #D5D5D5 solid",
            backgroundColor: "#F9F9F9",
            padding: "0 15px",
        },
    },
    btncol: {
        display: "flex",
        justifyContent: "flex-end",
    },
    
    pageTop: {
        textAlign: "left",
        marginBottom: "40px",
        display: "flex",
        "& button": {
            padding: "0px",
            background: "none",
            color: "#919699",
            fontSize: "15px",
            textTransform: "capitalize",
            fontWeight: "500",
        },
    },

    backarrow: {
        color: "#7087A7",
        fontSize: "20px",
        marginRight: "8px",
    },
   
    bottomnav: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
    },
   
    Rightcol: {
        width: "80%",
        padding: "20px 2%",
        '@media only screen and (max-width: 1024px)':{
            width:'96%'
                  },
        borderLeft: "1px #F6F6F6 solid",
        "& .MuiAccordionSummary-root": {
            borderBottom: "1px #E3E5E5 solid",
            height: "40px",
            color: "#919699",
            padding: "0px",
            fontFamily: "Poppins",
        },
        "& .MuiAccordion-root:before": {
            display: "none",
        },
        "& .MuiTab-root": {
            minWidth: "18%",
        },
        "& .MuiTab-root:nth-child(6)": {
            minWidth: "30%",
        },
        "& .MuiTab-root:nth-child(7)": {
            minWidth: "30%",
        },
        "& .MuiBox-root": {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
        "& .MuiTab-root": {
            minHeight: "40px",
        },
        "& .MuiTabs-root": {
            minHeight: "40px",
        },
    },
    loginbtn: {
        background: "#1612C6",
        padding: "0 40px",
        width: "142px",
        height: "45px",
        borderRadius: "10px",
        color: "#fff",
        marginTop: "20px",
        "&:hover": {
            background: "#333",
        },
    },

    Formcol: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        "&>div": {
            width: "100%",
        },
        "& p": {
            fontSize: "18px",
            margin: "0px",
        },
        "& label": {
            flex: "0 0 205px",
            color: "#000",
            fontSize: "15px",
            fontWeight: "400",
        },
        "& .react-dropdown-select-input": {
            width: "100%",
            fontFamily:'Poppins',
            fontSize:14,
        },
    },
    select: {
        width: "100%",
        border: "none !important",
        borderRadius: "10px !important",
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        height: "50px",
        fontSize: "18px !important",
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
    },
    modal: {
        "& .MuiPaper-rounded": {
            borderRadius: "10px !important",
            padding: "20px",
            width: "950px",
            maxWidth: "950px",
        },
    },
    ccmmodal: {
        borderRadius: "10px",
        "& label": {
            color: "#666",
            marginBottom: 5,
            display: "flex",
            flex: "0 0 150px",
        },
        "& .MuiInput-underline:before": {
            display: "none",
        },
        "& .MuiInput-underline:after": {
            display: "none",
        },
        "& .MuiInput-formControl": {
            height: "50px",
        },
        "& .MuiInput-input:focus": {
            border: "1px #0074D9 solid",
            boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
        },
        "& .MuiInputBase-input": {
            height: "50px",
            borderRadius: "10px",
            border: "1px #D5D5D5 solid",
            backgroundColor: "#F9F9F9",
            padding: "0 15px",
        },
        "& .react-date-picker__wrapper": {
            borderRadius: 10,
            border: "1px #D5D5D5 solid",
            backgroundColor: "#F9F9F9",
            padding: "0 10px",
        },
    },
    modalbtn: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "30px",
        marginLeft: "15px",
        alignItems: "center",
    },
    closebtn: {
        width: "40px",
        position: "absolute",
        right: "10px",
        height: "40px",
        background: "#fff",
        top: "10px",
        minWidth: "40px",
        "&:hover": {
            background: "#fff",
        },
    },
    closeicon: {
        fontSize: "25px",
        color: "#7087A7",
    },
     

    // PatientTable: {
    //     "& label": {
    //         color: "#666",
    //         marginBottom: 5,
    //         display: "flex",
    //         flex: "0 0 150px",
    //     },
    //     "& .MuiInput-underline:before": {
    //         display: "none",
    //     },
    //     "& .MuiInput-underline:after": {
    //         display: "none",
    //     },
    //     "& .MuiInput-formControl": {
    //         height: "50px",
    //     },
    //     "& .MuiInput-input:focus": {
    //         border: "1px #0074D9 solid",
    //         boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    //     },
    //     "& .MuiInputBase-input": {
    //         height: "50px",
    //         borderRadius: "10px",
    //         border: "1px #D5D5D5 solid",
    //         backgroundColor: "#F9F9F9",
    //         padding: "0 15px",
    //         fontSize:14,
    //     },
    //     "& .react-date-picker__wrapper": {
    //         borderRadius: 10,
    //         border: "1px #D5D5D5 solid",
    //         backgroundColor: "#F9F9F9",
    //         padding: "0 10px",
    //         fontFamily:'Poppins',
    //         fontSize:14,
    //         '&:hover':{
    //             border: "1px #0074D9 solid",
    //         boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    //         }
    //     },
    // },

}));



