import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box,FormControl,Grid, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTime } from 'react-timer-hook'
import Select from "react-dropdown-select"
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { BiXCircle } from "react-icons/bi"
import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import Loader from "react-loader-spinner";

import {dayTime, days, patientEngagementProgram} from "../Util/OtherList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Timer({ options, props }) {
    const classes = useStyles();
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});
        //   dropdown menu //
const [open, setOpen] = React.useState(false);
const anchorRef = React.useRef(null);

const [engagementDetails, setEngagementDetails] = React.useState("");
const [timerMin, setTimerMin] = React.useState("");
const [elapsedTime, setElapsedTime] = React.useState("");
const [elapsedTotalTime, setElapsedTotalTime] = React.useState("");
const [timerFlag, setTimerFlag] = React.useState(true);
const [showLoader, setShowLoader] = React.useState(false);

const patDetails = JSON.parse(localStorage.getItem("patient"));

const start = new Date(localStorage.getItem("start_timer"));



useEffect(() => {

  let engagement = JSON.parse(localStorage.getItem("engagement_timer"));

  if(!engagement){
    window.location.href = "/patient-search";  
  }

  setEngagementDetails(engagement);
   
  
    
  if(engagement){
    
    setTimerMin(engagement.billing_timer);

    setInterval(function() {

        var now = new Date();
        var pastDuration = 0; 
        var duration = (now - start);
        //console.log("duration - ", duration);
        var seconds,minutes,hours;
        if(duration > 0){
          seconds = Math.floor((duration / 1000) % 60);
          minutes = Math.floor((duration / (1000 * 60)) % 60);
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

          // let updateFlag = JSON.parse(localStorage.getItem("updateTimer"))
          // if( (minutes % 2 == 0 && seconds == 0) || (hours > 0 && minutes == 0 && seconds == 0) ) {
          //   console.log("timerpolling called");


          //  timerPolling();
          //   // console.log(updateFlag)
          //   // if(bool){
          //   //   timerPolling();
          //   // }else {

          //   // }
            
            
          // }

          hours = (hours < 10) ? "0" + hours : hours;
          minutes = (minutes < 10) ? "0" + minutes : minutes;
          seconds = (seconds < 10) ? "0" + seconds : seconds;

          var engagedTime = engagement.finalized_time;
          var engagedHr = (engagedTime[1] == ':' ) ? engagedTime[0] : (engagedTime[0] + "" + engagedTime[1]);
          var engagedMin = (engagedTime[1] == ':' ) ? (engagedTime[2] + "" + engagedTime[3]) : (engagedTime[3] + "" + engagedTime[4]);
          var engagedSec = (engagedTime[1] == ':' ) ? (engagedTime[5] + "" + engagedTime[6]) : (engagedTime[6] + "" + engagedTime[7]);
          var theFutureTime = moment().hour(engagedHr).minute(engagedMin).seconds(engagedSec).add(duration,'milliseconds').format("HH:mm:ss");
          
          //console.log("future time - ", theFutureTime);
          setElapsedTime(hours+":"+minutes+":"+seconds);
          setElapsedTotalTime(theFutureTime);

          


        }

    }, 1000)

    

    

  }


  }, []);

const handleToggle = () => {
  setOpen((prevOpen) => !prevOpen);
};

const handleClose = (event) => {
  if (anchorRef.current && anchorRef.current.contains(event.target)) {
    return;
  }

  setOpen(false);
};

function handleListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setOpen(false);
  }
}


const stopModal = () => {

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h1>Are you sure?</h1>
          <p>You want to stop the engagement session.</p>
          <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
          <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
            onClick={() => {
              handleStopTimer();
              onClose();
            }}
          >
            Yes, Stop
          </button>
        </div>
      );
    }
  });
};


const stopModalDelete = () => {

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h1>Are you sure?</h1>
          <p>You want to Delete the Engagement.</p>
          <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
          <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
            onClick={() => {
              deleteTimer();
              onClose();
            }}
          >
            Yes, Delete
          </button>
        </div>
      );
    }
  });
};

// don't save  delete  and end the engagement
function deleteTimer () {

      
  let engagement_id = localStorage.getItem("engagement_ID");

  let data = {
    patient_engagement_current_id: engagement_id
   
  }

  apiAxios
  .post("/patient/delete-user-speaking-with-patient", data, {
    headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("Token")
    },
  })
  .then((response) => {
    console.log("response --> ",response);
    if(response.data.status == 200) {
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      
        
        const timer = setTimeout(() => {
          window.location.href = "/patient-search";   
        }, 3000);
      
       
       
    }
    else {
        toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }); 
                  
    }
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        localStorage.removeItem("Refresh_Token");
        localStorage.removeItem("Token");
        window.location.href = "/";        


  });

}


function handleStopTimer() {
  
  let parent_id = JSON.parse(localStorage.getItem("engagement_parent_ID"));
  let engagement_id = localStorage.getItem("engagement_ID");

  let data = {
    parent_id: parent_id,
    patient_engagement_current_id: engagement_id
    //pd_id: pat_details.pd_id
  }

  apiAxios
  .post("/patient/end-call-user-speaking-with-patient", data, {
    headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("Token")
    },
  })
  .then((response) => {
    console.log("response --> ",response);
    if(response.data.status == 200) {
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      
        setTimerFlag(false);
        localStorage.removeItem("engagement_timer");
        //props.history.push("/patient-information");
        // localStorage.removeItem("updateTimer");
        window.location.href = "/patient-search";

    }
    else {
        toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }); 
                  
    }
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        localStorage.removeItem("Refresh_Token");
        localStorage.removeItem("Token");
        window.location.href = "/";        


  });
}

// function timerPolling() {
  
//   let engagement_id = localStorage.getItem("engagement_ID");

//   let data = {
//     patient_engagement_current_id: engagement_id,
//     //pd_id: pat_details.pd_id
//   }

//   apiAxios
//   .post("/patient/update-user-speaking-with-patient", data, {
//     headers: {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem("Token")
//     },
//   })
//   .then((response) => {
//     console.log("response --> ",response);
//     if(response.data.status == 200) {
//       toast.success("Engagement auto updated successfully.", {
//         position: "bottom-center",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         });
//     }
//     else {
//         toast.error(response.data.message, {
//             position: "bottom-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             }); 
                  
//     }
//   })
//   .catch((error) => {
//     console.log(error);

//     toast.error(error.message, {
//         position: "bottom-center",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         });

//         // localStorage.removeItem("Refresh_Token");
//         // localStorage.removeItem("Token");
//         // window.location.href = "/";        


//   });
// }

const [openFinalize, setOpenFinalize] = React.useState(false);
const [opinion, setOpinion] = React.useState("");
const [outsideRecom, setOutsideRecom] = React.useState("");
const [finalizeShowLoader, setFinalizeShowLoader] = React.useState(false);
const [engagementOpinion, setEngagementOpinion] = React.useState("");
const [callday, setCallDay] = React.useState("");
const [callTime, setCallTime] = React.useState("");

const [engagementOpinionError, setEngagementOpinionError] = React.useState(false);
const [callDayError, setCallDayError] = React.useState(false);
const [callTimeError, setCallTimeError] = React.useState(false);
const [outsideRecomError, setOutsideRecomError] = React.useState(false);
 
const handleOpenFinalize = () => {
  setOpenFinalize(true);
}

const handleCloseFinalize = () => {
  setOpenFinalize(false);
}

// function saveFinalize() {
  

//   let pat_details = JSON.parse(localStorage.getItem("patient"));

//   let isValid = true;

//   if(engagementOpinion.length > 0){
//       setEngagementOpinionError(false);
//   }
//   else{
//     setEngagementOpinionError(true);
//     isValid = false;
//   }

//   if(callTime.length > 0 && callday.length > 0){
//     setCallDayError(false);
//   }
//   else{
    
//   }

//   if(outsideRecom.trim().length > 0){
//     setOutsideRecomError(false);
//   }
//   else{
//     setOutsideRecomError(true);
//     isValid = false;
//   }



//   let data = {
//     pd_id: pat_details.pd_id,
//     client_patient_program_id: engagementDetails.patient_program_id,
//     finalize_day: callday,
//     finalize_time: callTime,
//     opinion: engagementOpinion,
//     outside_recommendations: outsideRecom
//   }

//   if(isValid){
//     setFinalizeShowLoader(true)
//     apiAxios
//       .post("/finalize/create-patient-new-finalization", data, {
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": localStorage.getItem("Token")
//         },
//       })
//       .then((response) => {
//         console.log("response --> ", response);
//         if (response.data.status == 200) {
//           toast.success(response.data.message, {
//             position: "bottom-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//           setFinalizeShowLoader(false)
//           setOpenFinalize(false);
//           window.location.href = "/patient-search"; 
//         }
//         else {
//           toast.error(response.data.message, {
//             position: "bottom-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//           setFinalizeShowLoader(false)
//         }
//       })
//       .catch((error) => {
//         console.log(error);

//         toast.error(error.message, {
//           position: "bottom-center",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });
//         setFinalizeShowLoader(false)
//         // localStorage.removeItem("Refresh_Token");
//         // localStorage.removeItem("Token");
//         // window.location.href = "/";        


//       });
//   }
// }

function saveFinalize(){

  
  let pat_details = JSON.parse(localStorage.getItem("patient"));

  let isValid = true;

  if(engagementOpinion.length > 0){
      setEngagementOpinionError(false);
  }
  else{
    setEngagementOpinionError(true);
    isValid = false;
  }

  if(callTime.length > 0 && callday.length > 0){
    setCallDayError(false);
  }
  else{
    
  }

  if(outsideRecom.trim().length > 0){
    setOutsideRecomError(false);
  }
  else{
    setOutsideRecomError(true);
    isValid = false;
  }



  
  let engagement = JSON.parse(localStorage.getItem("engagement_timer"));
  let parentID = JSON.parse(localStorage.getItem("engagement_parent_ID"));
  let data = {
    patient_engagement_current_id: engagement,
    //pd_id: pat_details.pd_id
    parent_id: parentID,
  }
  if(isValid){
    setFinalizeShowLoader(true)
  apiAxios
  .post("/patient/end-call-user-speaking-with-patient", data, {
    headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("Token")
    },
  })
  .then((response) => {
    console.log("response --> ",response);
    if(response.data.status == 200) {
        
  let data = {
    pd_id: pat_details.pd_id,
    client_patient_program_id: engagementDetails.patient_program_id,
    finalize_day: callday,
    finalize_time: callTime,
    opinion: engagementOpinion,
    outside_recommendations: outsideRecom
  }

  
   
    apiAxios
      .post("/finalize/create-patient-new-finalization", data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("Token")
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFinalizeShowLoader(false)
          setOpenFinalize(false);
          window.location.href = "/patient-search"; 
        }
        else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFinalizeShowLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFinalizeShowLoader(false)
        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        


      });
  
      
        
       
       
    }
    else {
        toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }); 
            setFinalizeShowLoader(false)        
    }
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setFinalizeShowLoader(false)
        // localStorage.removeItem("Refresh_Token");
        // localStorage.removeItem("Token");
        // window.location.href = "/";        


  });

  }
}

// return focus to the button when we transitioned from !open -> open
const prevOpen = React.useRef(open);
React.useEffect(() => {
  if (prevOpen.current === true && open === false) {
    anchorRef.current.focus();
  }

  prevOpen.current = open;
}, [open]);
    return (
        <div>
            
            <Box className={classes.Timercol}>
               <Box className={classes.Timer}>
               <Box className={classes.Playpusebtn} onClick={stopModal}><Button className={classes.Playbtn}><Box className={classes.pause}></Box></Button></Box> 
               <Box className={classes.Timerinfo} style={{color:'#fff'}}>    
                <p className={classes.Toptext}>Timers</p>  
                <span>{elapsedTime}</span>
                <h6 className={classes.Timertext}>{elapsedTotalTime}/{timerMin}</h6>
                </Box>
               </Box>
               { engagementDetails ?
               <Box className={classes.Addcode}>
                {/* <p className={classes.Toptext}>Add-On Code</p>  */}
                {(engagementDetails.add_on_code.length > 0 && engagementDetails.add_on_code[0] != null) ?
                <>
                <p className={classes.Toptext}>Add-On Code</p> 
                <h5 className={classes.Bottomtext}>
                  {engagementDetails.add_on_code.map((eachAddon,index) => (
                  <>
                    <span style={{backgroundColor: '#88b3eb', borderRadius: '20px', padding: '4px 6px', fontSize: '12px', marginRight: '5px'}}>
                      {/* {engagementDetails.add_on_code[index]} x {engagementDetails.add_on_max_use[index]} */}
                      {engagementDetails.add_on_timer_min[index]}
                    </span>
                  </>
                  ))}
                </h5></>
                :
                // <h5 className={classes.Bottomtext}>
                    
                // </h5>
                <></>
                }  
                </Box>
                :
                <></>
                } 
                <Box className={classes.Finalbtn}>
                    <Button className={classes.Finalizebtn} onClick={handleOpenFinalize}>Finalize</Button>
                </Box>
                <Box className={classes.Program}>
                    <p className={classes.Toptext}>Program</p>
                    <h5 className={classes.Bottomtext}>{engagementDetails.program_abbrev}</h5>
                </Box>
                <Box className={classes.Action}>
                    {/* <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Action" className={classes.select} /> */}
                    <Button className={classes.Optionsbtn}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Actions <BiChevronDown className={classes.Downarrow} />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:'999999'}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper style={{ boxShadow:'none',width:'150px', border:'1px #AEAEAE solid', borderRadius:'10px',}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose} style={{color:'#0f3d6e', fontSize:'14px'}} onClick={stopModal}>End Session</MenuItem>
                    <MenuItem onClick={handleClose} style={{color:'#0f3d6e', fontSize:'14px'}} onClick={stopModalDelete}>Delete Engagement</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
                </Box>
               </Box>


               <Dialog  className={classes.modal}
        open={openFinalize}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFinalize}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
            <Button onClick={handleCloseFinalize} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          <Box className={classes.loginform}>
          <h3 className={classes.Toptext2}>You are about to finalize patient's monthly CCM session</h3>
          <form>
          <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
          <Box className={classes.Formcol}>
            <label>Select your opinion of the patient’s engagement with CCM Program<span style={{color:'#ff0000'}}>*</span></label>
            <Box className={classes.SelectCol}>
            {/* <Select
                    options={patientEngagementProgram()}
                    onChange={(values) => setOpinion(values[0] ? values[0].label : values.label)}
                    className={classes.datepicker}
                    style={{ width: "100%",borderRadius:5, }}
                    values={patientEngagementProgram().filter(
                      (option) => option.label == opinion
                    )}
                  /> */}
                  
                      <Select
                        options={patientEngagementProgram()}
                        onChange={(values) => setEngagementOpinion(values[0] ? values[0].label : values.label)}
                        className={classes.select}
                        style={{ width: "100%" }}
                        placeholder={engagementOpinion? engagementOpinion : "Select Opinion"}
                        searchable={false}
                       
                        values={patientEngagementProgram().filter(
                          (option) => option.label == engagementOpinion
                        )}
                        
                      />
                     
                      {engagementOpinionError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          Opinion is mandatory.
                        </p>
                      ) : (
                        <></>
                      )}


            </Box>
          </Box>
          <Box className={classes.Formcol}>
          <label>Provide any outside resource/recommendations you have given to the patient<span style={{color:'#ff0000'}}>*</span></label>
          <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          placeholder="Mention outside resource/recommendations.."
          onChange={(e) => setOutsideRecom(e.target.value)}
          variant="outlined"
          maxLength={10}
        />
          {outsideRecomError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          Outside resource/recommendations is mandatory.
                        </p>
                      ) : (
                        <></>
                      )}
          </Box>
          <Box className={classes.Formcol}>
          <label>Do not call Day/Time</label>
          <Box style={{display:'flex',justifyContent:'space-between'}}>
            <Box style={{width:'48%'}}>
                      <Select
                        options={days()}
                        onChange={(values) => setCallDay(values[0] ? values[0].label : values.label)}
                        className={classes.select}
                        searchable={false}
                        placeholder={callday ? callday.label : "Day"}
                        style={{ width: "100%" }}
                        values={days().filter(
                          (option) => option.label == callday
                        )}
                      />
            </Box>
            <Box style={{width:'48%'}}>
                      <Select
                        options={dayTime()}
                        onChange={(values) => setCallTime(values[0] ? values[0].label : values.label)}
                        className={classes.select}
                        searchable={false}
                        placeholder={callTime? callTime.label : "Time"}
                        style={{ width: "100%" }}
                        values={dayTime().filter(
                          (option) => option.label == callTime
                        )}
                      />
            </Box>
            <br/>
            {callDayError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            textAlign: 'right'
                          }}
                        >
                          Do no call Day/Time is mandatory.
                        </p>
                      ) : (
                        <></>
                      )}
          </Box>
          </Box>
          </Grid>
          </Grid>
          </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        
        <DialogActions className={classes.modalbtn}>
        {finalizeShowLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <Button size="large" className={classes.loginbtn} onClick={saveFinalize}>
                        Confirm
                    </Button>
                )
        }
      
                  
                    <Button size="large" className={classes.cancelbtn} onClick={handleCloseFinalize}>
                        Cancel
                    </Button>
                
        
        </DialogActions>
      </Dialog> 

        </div>
    )
}

export default Timer
const useStyles = makeStyles(() => ({
    Timercol:{
        width:'96%',
        padding:'10px 2%',
        background:'linear-gradient(to left, #114ebd, #0f3d6e)',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    Optionsbtn:{
        width:'150px',
        border:'1px #AEAEAE solid',
        borderRadius:'10px',
        display:'flex',
        justifyContent:'space-between',
        textTransform:'capitalize',
        float:'right',
        backgroundColor:'#fff',
        '&:hover':{
            backgroundColor:'#fff'
        }
    },
    
    SelectCol:{
      width:'100%'
    },
    Formcol:{
      display:'flex',
      alignItems:'flex-start',
      flexDirection:'column',
      marginBottom:'30px',
      position:'relative',
      '& p':{
          fontSize:'18px',
          margin:'0px'
      },
      '& label':{
          color:'#000',
          fontSize:'15px',
          fontWeight:'400',
          marginBottom:5,
      },
      '& .react-dropdown-select-input':{
          width:'100%'
      },
      '& .MuiFormControl':{
        width:'100%'
      },
      '& .MuiFormControl-root':{
        width:'100%'
      }
  },
    btncol:{
      display:'flex',
      justifyContent:'flex-end',
  },
  Toptext:{
    fontSize:'12px',
    color:'#bfc4cb',
    margin:'0px',
},
Toptext2:{
  fontSize:'18px',
  color:'#000',
  textTransform:'capitalize',
  marginBottom:'30px',
  marginTop:-15,
  fontWeight:'600',
  fontFamily:'Poppins'
},
    Timer:{
        display:'flex',
        alignItems:'center'
    },
    Timertext:{
        fontSize:'14px',
        color:'#bfc4cb',
        margin:'0px',
        fontWeight:'normal'
    },
    Playbtn:{
        width:'38px',
        height:'38px',
        background:'#fff',
        borderRadius:'50%',
        display:'flex',
        minWidth:'38px',
    },
    pause:{
        width:'12px',
        height:'12px',
        background:'#000'
    },
    Playpusebtn:{
        marginRight:'10px'
    },
    Finalizebtn:{
        width:'142px',
        background:'#88b3eb',
        borderRadius:'10px',
        color:'#fff',
        boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
        textTransform:'capitalize',
        '&:hover':{
            background:'#0f3d6e'
        }
    },
    select:{
      width:'100%',
      border:'none !important',
      borderRadius:'10px !important',
      background:'#F1F1F1',
      height:'50px',
      fontSize:'14px !important',
      paddingLeft:'15px !important',
      paddingRight:'15px !important',
      // paddingTop:'17px !important'
      // '& .react-dropdown-select-input':{
      //   display:'none'
      // }
  },
  // datepicker:{
  //   borderRadius:'10px !important',
  //     height:'45px',
  //     width:'96%',
  //     fontFamily:'Poppins',
  //     fontSize:'14px',
  //     background:'#F9F9F9',
  //     border:'1px solid #D5D5D5 !important',
  //     paddingLeft:15,
  //     '&:focus':{
  //       border:'1px #0f3d6e solid !important',
  //       zIndex: '999'
  //     }
  // },

    loginbtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'45px',
      borderRadius:'10px',
      color:'#fff',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
  },
  cancelbtn:{
    background:'#DADADA',
    borderRadius:'10px',
    textTransform:'capitalize',
    height:'45px',
    width:'120px',
    color:'#999',
    fontWeight:'600',
    '&:hover':{
        background:'#000'
    }
},
  modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'776px',
        maxWidth:'776px',
    },
    '& .MuiFormControlLabel-label':{
      flex:'0 0 220px !important'
    },
    '& .MuiTypography-root':{
      fontSize:14,
      fontFamily:'poppins'
    },
    '& .MuiBox-root':{
      width:'100%'
    }
},
ccmmodal:{
    borderRadius:'10px',
},
Filter:{
  textAlign: 'left',
  margin: '5px 0 20px 0',
  flexDirection:'column',
  justifyContent:'flex-end',
  alignItems:'flex-end'
},
Downarrow:{
  fontSize:'20px',
  color:'#0f3d6e',
  marginLeft:'5px'
},
Filtertext:{
  display:'flex',
  alignItems:'center',
  margin:'0 0 7px',
  '& span':{
      color:'#bfc4cb',
      marginRight:'5px'
  }
},
Editplan:{
  listStyle:'none',
  margin:'0px',
  padding:'0px',
  '& li':{
      display:'flex',
      fontSize:'14px',
      alignItems:'center',
      marginBottom:'15px',
      position:'relative',
      '& .MuiTypography-root':{
          flex:'0 0 150px',
          color:'#121212',
          margin:'0px'
      }
  },
  '& span':{
    
  },
  '& .MuiInput-underline:before':{
      display:'none'
  },
  '& .MuiInput-underline:after':{
      display:'none'
  },
  '& .MuiInput-formControl':{ 
      height:'42px',
      
  },
  '& .MuiInputBase-input':{
      height:'42px',
      borderRadius:'10px',
      background:'#F9F9F9',
      border:'1px solid #D5D5D5',
      padding:'0 15px',
      fontFamily:'Poppins',
      fontSize:'12px',
      color:'#000'
  },
  '& .MuiInputBase-input:focus':{
    border:'1px #0f3d6e solid',
    boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
}
},
modalbtn:{
    display:'flex',
    justifyContent:'flex-end',
    marginRight:'0px',
    marginLeft:'15px',
    alignItems:'center'
},
    Timerinfo:{
        '& span':{
            fontSize:'14px',
            color: '#ffffff'
        }
    },
    Bottomtext:{
        fontSize:'16px',
        color:'#ffffff',
        margin:'0px',
        fontWeight:'normal'
    } ,
    closebtn:{
      width:'40px',
      position:'absolute',
      right:'10px',
      height:'40px',
      background:'#fff',
      top:'10px',
      minWidth:'40px',
      '&:hover':{
          background:'#fff'
      }
  },
  closeicon:{
      fontSize:'25px',
      color:'#7087A7'
  }, 
}));