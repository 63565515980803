import moment from "moment";

export const checkName = (data) => {
  var regex = /^[a-zA-Z' ]{2,30}$/;
  return regex.test(data);
};

export const checkAddress = (data) => {
  var regex = /^[a-zA-Z0-9.,' ]{1,30}$/;
  return regex.test(data);
};

// export const checkEmail = (data) => {
//   var regex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+\.?[A-Za-z]{1,}$/;
//   return regex.test(data);
// };

export const checkEmail = (data) => {
  var regex =  /^((?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9._]{4,30})[@][a-z0-9]{2,}([.][a-z]{2,})+$/;
  
  return regex.test(data);
};




export const checkPhone = (data) => {
  var regex = /^\d{10}$/;
  return regex.test(data);
};

export const checkZip = (data) => {
  var regex = /^\d{4,6}$/;
  return regex.test(data);
};

export const checkPhoneNumbers = (data) => {
  var regex = /^\d{0,10}$/;
  return regex.test(data);
};

export const checkUSPhone = (data) => {

  var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
  return regex.test(data);
};

export const checkNumbersOnly = (data) => {
  var regex = /^\d{1,}$/;
  return regex.test(data);
};

export const checkOtp = (data) => {
  var regex = /^\d{0,6}$/;
  return regex.test(data);
};

export const checkPassword = (data) => {
  var regex = /^[a-zA-Z0-9@_!.]{2,30}$/;
  return regex.test(data);
};

export const checkDob = (data) => {
  const age = moment(data).fromNow();
  return age;
};

export const alphaNumeric = (data) => {
  var regex = /^[a-zA-Z0-9/-]{1,30}$/;
  return regex.test(data);
};

export const checkOnlyZero = (data) => {
  var regex = /^0*$/;
  return regex.test(data);
};

export const checkUserName = (data) => {
  var regex = /^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  return regex.test(data);
};

export const checkFaxNumber = (data) => {
  var regex = /^\+?[0-9]{6,12}$/;
  return regex.test(data)
}
