import React, {useEffect} from 'react';
import { useTime } from 'react-timer-hook';
import { apiAxios } from "../Util/ApiAxios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function UpdateTimer () {
    const {
        seconds,
        minutes,
        hours,
        ampm,
      } = useTime({ format: '12-hour'});

      const start = new Date(localStorage.getItem("start_timer"));


      useEffect(() => {

        let engagement = JSON.parse(localStorage.getItem("engagement_timer"));
       
         
          
        if(engagement){
          
        //   setTimerMin(engagement.billing_timer);
      
          setInterval(function() {
      
              var now = new Date();
              var pastDuration = 0; 
              var duration = (now - start);
              //console.log("duration - ", duration);
              var seconds,minutes,hours;
              if(duration > 0){
                seconds = Math.floor((duration / 1000) % 60);
                minutes = Math.floor((duration / (1000 * 60)) % 60);
                hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
                // let updateFlag = JSON.parse(localStorage.getItem("updateTimer"))
                if( (minutes % 2 == 0 && seconds == 0) || (hours > 0 && minutes == 0 && seconds == 0) ) {
                  console.log("timerpolling called");
      
      
                 timerPolling();
                  // console.log(updateFlag)
                  // if(bool){
                  //   timerPolling();
                  // }else {
      
                  // }
                  
                  
                }
      
                // hours = (hours < 10) ? "0" + hours : hours;
                // minutes = (minutes < 10) ? "0" + minutes : minutes;
                // seconds = (seconds < 10) ? "0" + seconds : seconds;
      
                // var engagedTime = engagement.time;
                // var engagedHr = (engagedTime[1] == ':' ) ? engagedTime[0] : (engagedTime[0] + "" + engagedTime[1]);
                // var engagedMin = (engagedTime[1] == ':' ) ? (engagedTime[2] + "" + engagedTime[3]) : (engagedTime[3] + "" + engagedTime[4]);
                // var engagedSec = (engagedTime[1] == ':' ) ? (engagedTime[5] + "" + engagedTime[6]) : (engagedTime[6] + "" + engagedTime[7]);
                // var theFutureTime = moment().hour(engagedHr).minute(engagedMin).seconds(engagedSec).add(duration,'milliseconds').format("HH:mm:ss");
                
                // //console.log("future time - ", theFutureTime);
                // setElapsedTime(hours+":"+minutes+":"+seconds);
                // setElapsedTotalTime(theFutureTime);
      
                
      
      
              }
      
          }, 1000)
      
          
      
          
      
        }
      
      
        }, []);

        function timerPolling() {
  
            let engagement_id = localStorage.getItem("engagement_ID");
          
            let data = {
              patient_engagement_current_id: engagement_id,
              //pd_id: pat_details.pd_id
            }
          
            apiAxios
            .post("/patient/update-user-speaking-with-patient", data, {
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": localStorage.getItem("Token")
              },
            })
            .then((response) => {
              console.log("response --> ",response);
              if(response.data.status == 200) {
                // toast.success("Engagement auto updated successfully.", {
                //   position: "bottom-center",
                //   autoClose: 5000,
                //   hideProgressBar: false,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                //   });
              }
              else {
                  // toast.error(response.data.message, {
                  //     position: "bottom-center",
                  //     autoClose: 5000,
                  //     hideProgressBar: false,
                  //     closeOnClick: true,
                  //     pauseOnHover: true,
                  //     draggable: true,
                  //     progress: undefined,
                  //     }); 
                            
              }
            })
            .catch((error) => {
              console.log(error);
          
              // toast.error(error.message, {
              //     position: "bottom-center",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //     });
          
                  // localStorage.removeItem("Refresh_Token");
                  // localStorage.removeItem("Token");
                  // window.location.href = "/";        
          
          
            });
          }

return (

    





  <>
  
  </>






)





}

export default UpdateTimer;