import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { makeStyles } from '@material-ui/core/styles'
  import Header from '../components/Header'
  import { Box,Grid, Link } from '@material-ui/core'
  import Button from '@material-ui/core/Button'
  import Setting from '../components/Setting'
  import LeftNav from '../components/LeftNav'
  import Patientinfo from '../components/Patientinfo'
  import Timer from '../components/Timer'
  import { FaPlusCircle, FaCheckCircle, FaTable } from "react-icons/fa"
  import Accordion from '@material-ui/core/Accordion'
  import AccordionDetails from '@material-ui/core/AccordionDetails'
  import AccordionSummary from '@material-ui/core/AccordionSummary'
  import Typography from '@material-ui/core/Typography'
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
  import ClickAwayListener from '@material-ui/core/ClickAwayListener'
  import Grow from '@material-ui/core/Grow'
  import Paper from '@material-ui/core/Paper'
  import Popper from '@material-ui/core/Popper'
  import MenuItem from '@material-ui/core/MenuItem'
  import MenuList from '@material-ui/core/MenuList'
  import Dialog from '@material-ui/core/Dialog'
  import DialogActions from '@material-ui/core/DialogActions'
  import DialogContent from '@material-ui/core/DialogContent'
  import DialogContentText from '@material-ui/core/DialogContentText'
  import DialogTitle from '@material-ui/core/DialogTitle'
  import Slide from '@material-ui/core/Slide'
  import TextField from '@material-ui/core/TextField'
  import TextareaAutosize from '@material-ui/core/TextareaAutosize'
  import { BiXCircle } from "react-icons/bi"
  import AppBar from '@material-ui/core/AppBar'
  import Tab from '@material-ui/core/Tab'
  import TabContext from '@material-ui/lab/TabContext'
  import TabList from '@material-ui/lab/TabList'
  import Switch from '@material-ui/core/Switch'
  import Radio from '@material-ui/core/Radio'
  import RadioGroup from '@material-ui/core/RadioGroup'
  import FormControlLabel from '@material-ui/core/FormControlLabel'
  import FormControl from '@material-ui/core/FormControl'
  import FormLabel from '@material-ui/core/FormLabel'
  import Checkbox from '@material-ui/core/Checkbox'
  import FormGroup from '@material-ui/core/FormGroup'
  import PropTypes from 'prop-types'
  import Tabs from '@material-ui/core/Tabs'
  import Select from "react-dropdown-select"
  import InputLabel from '@material-ui/core/InputLabel'
  import { activeStatus, interventionStatus, goalsCategory, movingDirection, goalConditions, CCQActions } from '../Util/OtherList'
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from "react-loader-spinner";
  
  import { apiAxios } from "../Util/ApiAxios"
  import moment from "moment";
  import { Pagination } from '@material-ui/lab';
  
  import {BiChevronUp, BiChevronDown, BiErrorCircle} from "react-icons/bi"
  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  // tab scroll //
  function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }
  
    // end //
  
  function GoalsCCQ(props) {
      const classes = useStyles();
      const [expanded, setExpanded] = React.useState('false');
      const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setEditData(panel);
      };
       // Accordion2 //
    const [expanded2, setExpanded2] = React.useState('panel6');
    const handleChange3 = (panel) => (event, isExpanded2) => {
      setExpanded2(isExpanded2 ? panel : false);
    };
    // Accordion3 //
    const [expanded4, setExpanded4] = React.useState('panel9');
    const handleChange5 = (panel) => (event, isExpanded4) => {
      setExpanded4(isExpanded4 ? panel : false);
    };
  //   dropdown menu //
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [openPG, setOpenPG] = React.useState(false);
  
  const [heightFt, setHeightFt] = React.useState("");
  const [heightInch, setHeightInch] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [bp_sys, setBP_Sys] = React.useState("");
  const [bp_dia, setBP_Dia] = React.useState("");
  const [bloodSugar, setBloodSugar] = React.useState("");
  const [bmi, setBMI] = React.useState("");
  
  
  const [heightError, setHeightError] = React.useState("");
  const [weightError, setWeightError] = React.useState("");
  const [bpError, setBpError] = React.useState("");
  const [bsError, setBsError] = React.useState("");
  
    const handleOpenPG = () => {
      setOpenPG(true);
    };
  
    const handleClosePG = () => {
      setOpenPG(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
  
    const [pdId, setPdId] = React.useState("");  
  
    const [editData, setEditData] = React.useState("");  
    const [showLoader, setShowLoader] = React.useState(false);
  
    const [goalList, setGoalList] = React.useState("");
    const [providerGoalList, setProviderGoalList] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
    const [message, setMessage] = React.useState("");
    const [providerList, setProviderList] = React.useState("");
    const [caregiverList, setCaregiverList] = React.useState("");
  
    const [goalName, setGoalName] = React.useState("");
    const [goalDesc, setGoalDesc] = React.useState("");
    const [goalCat, setGoalCat] = React.useState("");
    const [interStatus, setInterStatus] = React.useState("");
    const [interResource, setInterResource] = React.useState("");
    const [moving, setMoving] = React.useState("");
    const [patientFeeling, setPatientFeeling] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [createdBy, setCreatedBy] = React.useState("");

    console.log("goals")
    console.log(props.PD_ID)
  
  
    useEffect(() => {

      if(props.PD_ID){
        getGoals();
      }
  
      let pat_details = JSON.parse(localStorage.getItem("patient"));
      if(pat_details){
        setPdId(pat_details.pd_id);
        getGoals();
        //getProviderGoals();
        //getProviders();
        //getCaregivers();
      }
    }, []); 
  
    const calcBMI = () => {
  
      if(heightFt>0 && heightInch>0 && weight>0){
        let height = 0;
        height = (heightFt*12) + heightInch;
        height = height * 0.0254;
  
        setBMI((weight*0.453592)/(height*height));
      }
  
    }
  
    const getGoals = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
           
      setGoalList([]);
      setMessage("Loading...");
  
      let data ={
          table_config_page_no: table_config_page_no,
          pd_id: props.PD_ID ? props.PD_ID : pd.pd_id,
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/patient/list-ccq-goals", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log(" goal list response --> ",response);
            if(response.data.statusCode == 200) {
                // let goalListRes = response.data.data
                // console.log(goalListRes)
                // var temp_arr = [];
                // for(var i=0;i<response.data.data.length;i++){
                //     if(response.data.data[i].ccq_flag == 1){
                //         temp_arr.push(response.data.data[i]);
                //     }
                // }

                setGoalList(response.data.data); 
                if(goalList > 0){
                    setMessage("");
                }
                else{
                    setMessage("No Data In CCQ");
                }
                
                setTotalPages(response.data.total_pages);
                
            }
            else if(response.data.statusCode == 502){
                setMessage("No Data Found");
            }
            else {
                // toast.error(response.data.message, {
                //     position: "bottom-center",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     });
                
                    setMessage(response.data.message);    
            }
          })
          .catch((error) => {
            console.log(error);
    
            // toast.error(error.message, {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );
  
    const getProviderGoals = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      // setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
           
      setProviderGoalList([]);
  
      let data ={
          table_config_page_no: table_config_page_no,
          pd_id: pd.pd_id ? pd.pd_id : props.PD_ID,
          ccq_flag: "1",
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/patient/list-provider-goals", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.statusCode == 200) {
                setProviderGoalList(response.data.data); 
            }
            else if(response.data.statusCode == 502){
              setProviderGoalList(""); 
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );
  
    const getProviders = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      //setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
           
      setProviderList([]);
      //setMessage("Loading...");
  
      let data ={
          table_config_page_no: table_config_page_no,
          cutid: "",
          search_value: "",
          user_master_type_id: "",
          is_provider: 1,
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/client/clientuserlist", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.statusCode == 200) {
              var ob_arr = [];
              for(var i=0;i< response.data.data.length;i++){
                let value = response.data.data[i].id;
                let label = response.data.data[i].first_name + " " + response.data.data[i].last_name;
                let ob = {
                    "label":label, "value": value
                }
                //console.log("ob types = ", ob);
                ob_arr.push(ob);
  
              }
              setProviderList(ob_arr); 
                
            }
            else if(response.data.statusCode == 502){
                //setMessage("No Data Found");
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
                    //setMessage(response.data.message);    
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );
  
    const [checked, setChecked] = React.useState("");
    const [checkedList, setCheckedList] = React.useState([]);
  
    const handleChangeChecked = (event) => {
      
        var arr=[];
        const isChecked = event.target.checked;
        if(isChecked){
          arr =  checkedList ? checkedList : [];
          arr.push(event.target.value);
          setCheckedList(arr);
  
        }
        else{
          const index = checkedList.indexOf(event.target.value);
          arr = checkedList ? checkedList : [];
          arr.splice(index,1);
          setCheckedList(arr);
  
          //console.log("--->", index);
        }
        console.log("--->", checkedList);
      
  
    };
  
    const [diet, setDiet] = React.useState("");
    const [exercise, setExercise] = React.useState("");
    const [smoking, setSmoking] = React.useState("");
    const [otherC, setOtherC] = React.useState("");
  
  
    const handleCheckDiet = (event) => {
        setDiet(event.target.checked);
        console.log("Diet - ",diet);
    }
    const handleCheckExercise = (event) => {
      setExercise(event.target.checked);
    }
    const handleCheckSmoking = (event) => {
      setSmoking(event.target.checked);
    }
    const handleCheckOther= (event) => {
      setOtherC(event.target.checked);
    }
  
    const getCaregivers = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      //setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
           
      setCaregiverList([]);
      //setMessage("Loading...");
  
      let data ={
          table_config_page_no: table_config_page_no,
          pd_id: pd.pd_id ? pd.pd_id : "",
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/patient/list-patient-caregiver-contact", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.statusCode == 200) {
                var ob_arr = [];
                for(var i=0;i< response.data.data.length;i++){
                  let value = response.data.data[i].id;
                  let label = response.data.data[i].patient_caregiver_contacts_first_name + " " + response.data.data[i].patient_caregiver_contacts_last_name;
                  let ob = {
                      "label":label, "value": value
                  }
                  //console.log("ob types = ", ob);
                  ob_arr.push(ob);
  
                }
                setCaregiverList(ob_arr); 
                //setMessage("");
                //setTotalPages(response.data.total_pages);
                
            }
            else if(response.data.statusCode == 502){
                //setMessage("No Data Found");
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
                    //setMessage(response.data.message);    
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
      //setEditData(data);
    };
  
    const canBeOpen = open && Boolean(anchorEl);
  
  // modal //
    const [open2, setOpen2] = React.useState(false);
  
    const handleClickOpen = () => {
      setGoalCat("");
      setGoalName("");
      setGoalDesc("");
      setInterStatus("");
      setInterResource("");
      setNotes("");
      setMoving("");
      setCheckedList("");
      setEditData("");
      setStatus("");
      setOpen2(true);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
    };
  // modal //
    const [open3, setOpen3] = React.useState(false);
  
    const handleClickOpenEdit = (item) => {
      let goalCatList = goalsCategory();
      for(var i=0;i<goalCatList.length;i++){
        if(goalCatList[i].value == item.goal_category){
          setGoalCat(goalCatList[i]);
        }
      }
      
      for(var i=0;i<providerList.length;i++){
        if(providerList[i].value == item.goal_created_by_id){
          setCreatedBy(providerList[i]);
        }
      }
  
      for(var i=0;i<caregiverList.length;i++){
        if(caregiverList[i].value == item.intervention_resource){
          setInterResource(caregiverList[i]);
        }
      }
  
      let is = interventionStatus();
      for(var i=0;i<is.length;i++){
        if(is[i].value == item.intervention_status){
          setInterStatus(is[i]);
        }
      }
      let md = movingDirection();
      for(var i=0;i<md.length;i++){
        if(md[i].value == item.moving_in_the_right_direction){
          setMoving(md[i]);
        }
      }
      let as = activeStatus();
      for(var i=0;i<as.length;i++){
        if(as[i].value == item.goal_status){
          setStatus(as[i]);
        }
      }
  
      setGoalName(item.goal_name);
      setGoalDesc(item.goal_description);
      setPatientFeeling(item.patient_feels_about);
      setNotes(item.goal_notes);
      let condition_details = item.associated_condition;
      
      console.log("condition_details = ", JSON.parse(condition_details));
      //setCheckedList(condition_details);
  
      setOpen2(true);
    }
  
    const handleClickOpen2 = () => {
  
      // let freqList = frequencyOfPain();
      // for(var i=0;i<freqList.length;i++){
      //   if(freqList[i].value == item.symptom_frequency){
      //     setFrequency(freqList[i]);
      //   }
      // }
      // let levelList = levelOfPain();
      // for(var i=0;i<levelList.length;i++){
      //   if(levelList[i].value == item.symptom_level){
      //     setLevelofpain(levelList[i]);
      //   }
      // }
      // let durationList = durationOfPain();
      // for(var i=0;i<durationList.length;i++){
      //   if(durationList[i].value == item.symptom_duration){
      //     setDuration(durationList[i]);
      //   }
      // }
      // let statusList = activeStatus();
      // for(var i=0;i<statusList.length;i++){
      //   if(statusList[i].value == item.status){
      //     setStatus(statusList[i]);
      //   }
      // }
  
      // setSymptom(item.symptom_name);
      // setLocation(item.symptom_location);
      // setDescription(item.symptom_description);
      // setWorsenedby(item.symptom_worsened_by);
      // setRelievedby(item.symptom_relieved_by);
      // setNotes(item.symptom_notes);
      // var a_date = new Date(item.symptom_start_date);
      // setDateStarted(a_date);
      // var stop_date = new Date(item.symptom_stop_date);
      // setDateRemedied(stop_date);
  
      setOpen3(true);
    };
  
  
    const [goalNameError, setGoalNameError] = React.useState(false);
    const [goalDescError, setGoalDescError] = React.useState(false);
    const [createdbyError, setCreatedbyError] = React.useState(false);
    const [goalCatError, setGoalCatError] = React.useState(false);
    const [interStatusError, setInterStatusError] = React.useState(false);
    const [activeStatusError, setActiveStatusError] = React.useState(false);
  
  
    const submitForm = (flag) => {
      let isValid = true;
  
      if(goalName.length >0){
        setGoalNameError(false);
      }else{
        setGoalNameError(true);
        isValid = false;
      }
      if(goalDesc.length >0){
        setGoalDescError(false);
      }else{
        setGoalDescError(true);
        isValid = false;
      }
      if(createdBy){
        setCreatedbyError(false);
      }else{
        setCreatedbyError(true);
        isValid = false;
      }
      if(goalCat){
        setGoalCatError(false);
      }else{
        setGoalCatError(true);
        isValid = false;
      }
      if(interStatus){
        setInterStatusError(false);
      }else{
        setInterStatusError(true);
        isValid = false;
      }
      if(activeStatus){
        setActiveStatusError(false);
      }else{
        setActiveStatusError(true);
        isValid = false;
      }
  
      if(isValid){
  
        setShowLoader(true);
  
        let pat_details = JSON.parse(localStorage.getItem("patient"));
        let data;
        let url;
        if(flag == 1){
          data ={
            pd_id: pat_details.pd_id ? pat_details.pd_id : '',
            goal_description: goalDesc,
            moving_in_the_right_direction: moving[0].value ? moving[0].value : "",
            created_by_name: createdBy[0].label ? createdBy[0].label : "",
            goal_created_by_id: createdBy[0].value ? createdBy[0].value : "",
            goal_name: goalName,
            goal_category: goalCat[0].value ? goalCat[0].value : "",
            associated_condition: checkedList,
            goal_status: status[0].value ? status[0].value : "",
            goal_start_date: moment(new Date()).format("YYYY-MM-DD"),
            associated_provider_goal: goalCat[0].value ? goalCat[0].value : "",
            intervention_status: interStatus[0].value ? interStatus[0].value : "",
            intervention_resource: interResource[0].value ? interResource[0].value : "",
            patient_progress: "",
            patient_feels_about: patientFeeling,
            PatientWeight: "",
            ccq_flag: "",
            goal_notes: notes
          }
          
          url = "/patient/create-patient-additional-goals-care-plan";
        }
        else{
          console.log("Moving = ", moving);
          data ={
            pd_id: pat_details.pd_id ? pat_details.pd_id : "",
            goal_description: goalDesc,
            goal_id: editData,
            moving_in_the_right_direction: moving.value ? moving.value : moving[0].value,
            created_by_name: createdBy.label ? createdBy.label : createdBy[0].label,
            goal_created_by_id: createdBy.value ? createdBy.value : createdBy[0].value,
            goal_name: goalName,
            goal_category: goalCat.value ? goalCat.value : goalCat[0].value,
            associated_condition: checkedList,
            goal_status: status.value ? status.value : status[0].value,
            goal_start_date: moment(new Date()).format("YYYY-MM-DD"),
            associated_provider_goal: goalCat.value ? goalCat.value : goalCat[0].value,
            intervention_status: interStatus.value ? interStatus.value : interStatus[0].value,
            intervention_resource: interResource? (interResource.value ? interResource.value : interResource[0].value) : "",
            patient_progress: "",
            patient_feels_about: patientFeeling,
            PatientWeight: "",
            ccq_flag: "",
            goal_notes: notes         
          }
  
          url = "/patient/update-patient-additional-goals-care-plan";
        }
  
        console.log("DATA =", data );
  
      apiAxios
          .post(url, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
              setShowLoader(false);
              setOpen2(false);
              setOpen(false);
              setOpen3(false);
              getGoals();  
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    }); 
                    setShowLoader(false);       
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                localStorage.removeItem("Refresh_Token");
                localStorage.removeItem("Token");
                window.location.href = "/";        
  
    
          });
  
      }
  
  
    }
  
    const submitVitals = () => {
      let isValid = true;
  
      if(heightFt > 0 && heightInch > 0){
        setHeightError(false);
      }
      else{
        setHeightError(true);
        isValid = false;
      }
      if(weight && weight > 0){
        setWeightError(false);
      }
      else{
        setWeightError(true);
        isValid = false;
      }
      if(bp_sys > 0 && bp_dia > 0){
        setBpError(false);
      }
      else{
        setBpError(true);
        isValid = false;
      }
      if(bloodSugar > 0){
        setBsError(false);
      }
      else{
        setBsError(true);
        isValid = false;
      }
      calcBMI();
      if(bmi){
  
      }else{
        isValid = false;
      }
  
      if(isValid){
  
        setShowLoader(true);
        
  
        let pat_details = JSON.parse(localStorage.getItem("patient"));
        let data;
        let url;
  
          data ={
            pd_id: pat_details.pd_id ? pat_details.pd_id : "",
            current_bmi: bmi,
            weight: weight,
            height: heightFt+"'"+heightInch,
            current_bp: bp_sys+"/"+bp_dia,
            current_bs: bloodSugar,
            diet: diet? "1" : "0",
            exercise: exercise? "1" : "0",
            smoking_cessation: smoking? "1" : "0",
            other: otherC? "1" : "0"
          }
          
          url = "/patient/create-provider-goals";
        
  
        //console.log("DATA =", data );
  
      apiAxios
          .post(url, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
              setShowLoader(false);
              setOpenPG(false);
              getProviderGoals();  
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    }); 
                    setShowLoader(false);       
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                localStorage.removeItem("Refresh_Token");
                localStorage.removeItem("Token");
                window.location.href = "/";        
  
    
          });
  
      }
  
  
    }
  
    const deleteModal = (item) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to delete this record?</p>
              <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
              <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                onClick={() => {
                  //handleClickDelete(item);
                  onClose();
                }}
              >
                Yes, Delete
              </button>
            </div>
          );
        }
      });
    };
  
    
  
    const handleClose3 = () => {
      setOpen3(false);
    };
    // tab //
    const [value, setValue] = React.useState(0);
  
    const handleChange2 = (event, newValue) => {
      setValue(newValue);
    };
  //   switch //
  const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
    });
  
    const handleChange4 = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };
  //   select  //

  const [ccqOption1, setCcqOption] = React.useState(null);
  const [ccqOptionError, setCcqOptionError] = React.useState(false);

  const remediedModal = (item) => {
    let selectedOption = "";
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>
              You want to remove this record from CCQ. Please select the CCQ
              action,
            </p>
            <div style={{ width: "100%" }}>
              <Select
                options={CCQActions()}
                onChange={(values) => {
                  selectedOption = values[0] ? values[0].value : values.value;
                  setCcqOption(selectedOption);

                  // selectedOption = values[0] ? values[0].value : values.value;
                 
                    setCcqOptionError(false);
                
      
                }}
                className={classes.datepicker}
                style={{ width: "100%" }}
                values={CCQActions().filter(
                  (option) => option.value == ccqOption1
                )}
              />
            </div>
            {ccqOptionError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign: "right",
                }}
              >
                Please select a CCQ Action.
              </p>
            ) : (
              <></>
            )}
            <button
              className={classes.loginbtn}
              style={{
                border: "2px solid #0f3d6e",
                backgroundColor: "#fff",
                color: "#0f3d6e",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={onClose}
            >
              No
            </button>
 
            <button
              className={classes.loginbtn}
              style={{ marginLeft: "10px", cursor: "pointer", width: "auto" }}
              onClick={() => {
                handleClickRemove(item, selectedOption);
                onClose();
              
              }}
            >
              Yes, Remove
            </button>
          </div>
        );
      },
    });
  };

  const handleClickRemove = (item, ccqOption) => {
    console.log("Delete = ", item);
    let isValid = true;
    let pat_details = JSON.parse(localStorage.getItem("patient"));

    console.log("ccqOption - ", ccqOption);
   

    if (ccqOption) {
      setCcqOptionError(false);
      console.log("inside")
    } else {
      setCcqOptionError(true);
      console.log("outside")
      isValid = false;
    }
    if (isValid) {
      let data = {
        goals_ccq_id: item.id,
        pd_id: pat_details.pd_id ? pat_details.pd_id : "",
        ccq_action_id: ccqOption,
        patient_program_id: pat_details.cpatp_id ? pat_details.cpatp_id : "",
      };

      apiAxios
        .post("/patient/remedied-ccq-goals", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
              
            getGoals();
            //getRemediedSymptoms();
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            //setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          localStorage.removeItem("Refresh_Token");
          localStorage.removeItem("Token");
          window.location.href = "/";
        });
    }
  };



    
      return (
          <div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
                   
                   <Box className={classes.Careplan}>
                 <Box className={classes.throw}> 
                           <Box className={classes.Row1}>Goal Name</Box>
                           <Box className={classes.Row2}>Goal Category</Box>
                           <Box className={classes.Row3}>Intervention<br /> Status</Box>
                           <Box className={classes.Row4}>Intervention<br/> Resource</Box>
                           <Box className={classes.Row5}>Patient Feelings<br/> on Goals</Box>
                           <Box className={classes.Row6}>Update<br/> date & time</Box>
                           <Box className={classes.Row7}>Added to CCQ</Box>
                       </Box> 
  
          { (goalList.length >0) ?
          <>
          {goalList.map((each) => (               
          <Accordion expanded={(expanded == each.goals_id)? true : false} onChange={handleChange(each.goals_id)} style={{boxShadow:'none'}}>
          <AccordionSummary
            expandIcon={<FaPlusCircle />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
           <Box className={classes.tdrow}> 
           <Box className={classes.Row1}>{each.goal_name}</Box>
                           <Box className={classes.Row2}>{each.goal_category}</Box>
                           <Box className={classes.Row3}>{(each.intervention_status == 1) ? "Need Intervention" : "None" }</Box>
                           <Box className={classes.Row4}>{each.intervention_resource ? each.intervention_resource : "--"}</Box>
                           <Box className={classes.Row5}>{each.patient_feels_about}</Box>
                           <Box className={classes.Row6}>{each.updated_at ? moment.utc(each.updated_at).local().format('MM/DD/YYYY, h:mm a') : "--"}</Box>
                           <Box className={classes.Row7}>{each.ccq_goals_add_date ? moment.utc(each.ccq_goals_add_date).local().format('MM/DD/YYYY, h:mm a') : "--"}</Box>
                           </Box>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
              <ul className={classes.Plandetails}>
                  <li><span>Created at</span> {each.created_at? moment.utc(each.created_at).local().format('MM/DD/YYYY, h:mm a') : "--"}</li>
                  <li><span>Created by</span> {each.goal_created_by}</li>
                  <li><span>Patient Feels about</span> {each.patient_feels_about}</li>
                  <li><span>Description</span> {each.goal_description}</li>
                  <li><span>Moving in the right direction?</span> {each.moving_in_the_right_direction}</li>
                  <li><span>Notes</span>{each.goal_notes}</li>
              </ul>
          </Grid>
          <Grid item xs={12} sm={3} style={{justifyContent:'flex-end'}}>
            {/* action option */}
            {props.PD_ID ? <></>
            :
          <Button className={classes.Optionsbtn}
            aria-describedby={canBeOpen? each.goals_id : undefined}
            onClick={handleClick}
          >
            Options <BiChevronDown />
          </Button>
          }
          {(each.goals_id == editData)?
          <Popper id={canBeOpen? each.goals_id : editData} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper style={{ boxShadow:'none',width:'150px', border:'1px #AEAEAE solid', borderRadius:'10px'}}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      {/* <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}}>Add To CCQ</MenuItem> */}
                      <MenuItem
                        style={{color:'#0f3d6e',fontSize:'14px'}}
                        onClick={() => {
                          handleClose();
                          remediedModal(each);
                        }}>
                          Remove
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}} onClick={() => deleteModal(each)}>Delete</MenuItem>
                      <MenuItem onClick={handleClose} style={{color:'#0f3d6e',fontSize:'14px'}}>Change Adherence</MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          :
          <></>
          }
          </Grid>
          </Grid>
          </AccordionDetails>
        </Accordion>
        ))}
        </>
        :
        <Box style={{textAlign:'center', margin:'5%'}}>{message}</Box>
        }
        
        </Box>
        <Box style={{width:'100%',display:'flex',justifyContent:'flex-end',overflow:'hidden'}}>
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getGoals({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </Box>   
                       
                     {/* tab with accordion */}
                      
                 
                 {/* modal */}
                 <Dialog  className={classes.modal}
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
              <Box className={classes.btncol}>
                <h3 style={{margin:'-20px 0 0'}}>Add Goals</h3>
              <Button onClick={handleClose2} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
              </Box>
            <DialogContentText id="alert-dialog-slide-description">
              {/* <h4 style={{marginBottom:'0px', color:'#000',fontWeight:'600'}}>Chronic Care Management Recommendations</h4> */}
              <p style={{color:'#000'}}>Select Conditions</p>
                
              <ul className={classes.Editplan2}>
              {goalConditions().map((each) => ( 
                <li>
                  <FormGroup>
                    <FormControlLabel control={<input type="checkbox" value={each} onChange={handleChangeChecked} />} label={each} />
                  </FormGroup>
                </li>
              ))}
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
          <Button size="large" className={classes.loginbtn} onClick={handleClickOpen2}>
              Next
          </Button>
          </DialogActions>
        </Dialog>
  
        {/* modal */}
        <Dialog  className={classes.modal}
          open={open3}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose3}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
              <Box className={classes.btncol}>
                <h3 style={{margin:'-20px 0 0'}}>Add Goals</h3>
              <Button onClick={handleClose3} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
              </Box>
            <DialogContentText id="alert-dialog-slide-description">
                <form>
              <ul className={classes.Editplan}>
                    <li><span>Goal Name</span><FormControl style={{width:'100%'}}>
                    <TextField className={classes.input}
                                onChange={(e) => setGoalName(e.target.value)}
                                value={goalName ? goalName : ""}
                      type="text"
                    />
                    </FormControl>
                    </li>
                    {goalNameError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          textAlign: 'right'
                        }}
                      >
                        Goal name is mandatory.
                      </p>
                    ) : (
                      <></>
                    )}
                  <li><span>Goal Description</span><FormControl style={{width:'100%'}}>
                    <TextField className={classes.input}
                          onChange={(e) => setGoalDesc(e.target.value)}
                          value={goalDesc ? goalDesc : ""}
                      type="text"
                    />
                    </FormControl>
                  </li>
                  {goalDescError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Goal description is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><span>Created by</span><FormControl style={{width:'100%'}}>
                  <Select
                            options={providerList}
                            value={createdBy? createdBy : ""}
                            onChange={setCreatedBy}
                            placeholder={createdBy? createdBy.label : "Select Created By"}
                            className={classes.datepicker}
                          />
                  </FormControl>        
                  </li>
                  {createdbyError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Created by is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><span>Goal Category</span><FormControl style={{width:'100%'}}>
                  <Select
                            options={goalsCategory()}
                            value={goalCat? goalCat : ""}
                            onChange={setGoalCat}
                            placeholder={goalCat? goalCat.label : "Select Goal Category"}
                            className={classes.datepicker}
                          />
                  </FormControl>        
                  </li>
                  {goalCatError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Goal category is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><span>Intervention Status</span> <FormControl style={{width:'100%'}}>
                  <Select
                            options={interventionStatus()}
                            value={interStatus? interStatus : ""}
                            onChange={setInterStatus}
                            placeholder={interStatus? interStatus.label : "Select Intervention Status"}
                            className={classes.datepicker}
                          />
                  </FormControl>        
                  </li>
                  {interStatusError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Intervention status is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><span>Intervention Resource</span> <FormControl style={{width:'100%'}}>
                  <Select
                            options={caregiverList}
                            value={interResource? interResource : ""}
                            onChange={setInterResource}
                            placeholder={interResource? interResource.label : "Select Intervention Resource"}
                            className={classes.datepicker}
                          />
                  </FormControl>        
                  </li>
                  <li><span>Moving In Right Direction ?</span><FormControl style={{width:'100%'}}>
                  <Select
                            options={movingDirection()}
                            value={moving? moving : ""}
                            onChange={setMoving}
                            placeholder={moving? moving.label : "Select An Option"}
                            className={classes.datepicker}
                          />
                          </FormControl>
                  </li>
                  
                  <li><span>Patient Feelings On Goal</span><FormControl style={{width:'100%'}}>
                    <TextField className={classes.input}
                            type="text" onChange={(e) => setPatientFeeling(e.target.value)}
                            value={patientFeeling ? patientFeeling : ""} />
                    </FormControl>          
                  </li>
                  <li><span>Goal Status</span><FormControl style={{width:'100%'}}>
                  <Select
                            options={activeStatus()}
                            value={status? status : ""}
                            onChange={setStatus}
                            placeholder={status? status.label : "Select Active Status"}
                            className={classes.datepicker}
                          />
                          </FormControl>
                  </li>
                  {activeStatusError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Goal status is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li><span>Notes</span><FormControl style={{width:'100%'}}>
                     <TextareaAutosize style={{width: '100%'}} className={classes.textarea} aria-label="minimum height" minRows={4} onChange={(e) => setNotes(e.target.value)}
                                value={notes ? notes : ""} />
                      </FormControl>          
                  </li>
                  
              </ul>
              
              </form>
            </DialogContentText>
          </DialogContent>
          {editData?
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn} onClick={() => submitForm(2)}>
                          Update
                      </Button>
                  )
          }
          </DialogActions>
          :
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn} onClick={() => submitForm(1)}>
                          Submit
                      </Button>
                  )
          }
          </DialogActions>
          }
        </Dialog>
  
  
        {/* Provider Goals modal */}
        <Dialog  className={classes.modal}
          open={openPG}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClosePG}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          
        >
          <DialogContent className={classes.ccmmodal}>
              <Box className={classes.btncol}>
                <h3 style={{margin:'-20px 0 0'}}>Add Provider Goals</h3>
              <Button onClick={handleClosePG} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
              </Box>
            <DialogContentText id="alert-dialog-slide-description">
                <form>
              <ul className={classes.Editplan}>
                    <li><span>Enter Height</span><FormControl style={{width:'100%'}}>
                      <TextField className={classes.input}
                                  onChange={(e) => setHeightFt(e.target.value)}
                                  value={heightFt ? heightFt : ""}
                        type="number"
                        placeholder="Ft"
                        style={{width:'30%'}}
                      /><span style={{width:'20%'}}>Ft.</span>
                      <TextField className={classes.input}
                                  onChange={(e) => setHeightInch(e.target.value)}
                                  value={heightInch ? heightInch : ""}
                        type="number"
                        placeholder="Inch"
                        style={{width:'80%'}}
                      /><span style={{width:'20%'}}>Inch.</span>
                    </FormControl>
                    </li>
                    {heightError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          textAlign: 'right'
                        }}
                      >
                        Height is mandatory.
                      </p>
                    ) : (
                      <></>
                    )}
                  <li><span>Enter Weight</span><FormControl style={{width:'100%'}}>
                    <TextField className={classes.input}
                          onChange={(e) => setWeight(e.target.value)}
                          value={weight ? weight : ""}
                          style={{width:'80%'}}
                      type="number"
                    /><span>lbs</span>
                    </FormControl>
                  </li>
                  {weightError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Weight is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  
                  
                  <li><span>Blood Pressure</span><FormControl style={{width:'100%'}}>
                      <TextField className={classes.input}
                                  onChange={(e) => setBP_Sys(e.target.value)}
                                  value={bp_sys ? bp_sys : ""}
                        type="number"
                        placeholder="SYS"
                        style={{width:'80%'}}
                      /><span style={{width:'20%'}}>mmHg</span>
                      <TextField className={classes.input}
                                  onChange={(e) => setBP_Dia(e.target.value)}
                                  value={bp_dia ? bp_dia : ""}
                        type="number"
                        placeholder="DIA"
                        style={{width:'80%'}}
                      /><span style={{width:'20%'}}>mmHg</span>
                  </FormControl>        
                  </li>
                  {bpError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Blood Pressure is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  
                  <li><span>Fasting Blood Sugar</span><FormControl style={{width:'100%'}}>
                    <TextField className={classes.input}
                            type="number" onChange={(e) => setBloodSugar(e.target.value)}
                            style={{width:'80%'}}
                            value={bloodSugar ? bloodSugar : ""} />
                    </FormControl>          
                  </li>
                  {bsError ? (
                    <p
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                        textAlign: 'right'
                      }}
                    >
                      Blood sugar is mandatory.
                    </p>
                  ) : (
                    <></>
                  )}
                  <li>
                  <span>Select the options</span>
                    <span>
                      <FormControl style={{width:'25%'}}>
                        <FormGroup>
                          <FormControlLabel control={<input type="checkbox" checked={diet} onChange={handleCheckDiet} />} label="Diet" />
                        </FormGroup> 
                      </FormControl>    
                    </span>
                    <span>
                      <FormControl style={{width:'25%'}}>
                        <FormGroup>
                          <FormControlLabel control={<input type="checkbox" checked={exercise} onChange={handleCheckExercise} />} label="Exercise" />
                        </FormGroup> 
                      </FormControl>    
                    </span>
                    <span>
                      <FormControl style={{width:'25%'}}>
                        <FormGroup>
                          <FormControlLabel control={<input type="checkbox" checked={smoking} onChange={handleCheckSmoking} />} label="Smoking cessetion" />
                        </FormGroup> 
                      </FormControl>    
                    </span>
                    <span>
                      <FormControl style={{width:'25%'}}>
                        <FormGroup>
                          <FormControlLabel control={<input type="checkbox" checked={otherC} onChange={handleCheckOther} />} label="Other" />
                        </FormGroup> 
                      </FormControl>    
                    </span>      
                  </li>
                  
              </ul>
              
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
          {showLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#000000"
                      height={50}
                      width={50}
                      timeout={0} //30 secs
                    />
                  ) : (    
                      <Button size="large" className={classes.loginbtn} onClick={() => submitVitals()}>
                          Submit
                      </Button>
                  )
          }
          </DialogActions>
        </Dialog>  
  
  
             
          </div>
      )
  }
  
  export default GoalsCCQ
  const useStyles = makeStyles(() => ({
      Pagecontent:{
          width:'100%',
          display:'flex',
          textAlign:'left',
         fontFamily:'Poppins !important'
      },
      Leftcol:{
          width: '22%',
          background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
          padding:'20px 3%',
          position:'relative',
          minHeight:'1050px'
      },
      bottomnav:{
          position:'absolute',
          bottom:'0px',
          left:'0px'
      },
      leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px'
      },
      Rightcol:{
          width:'78%',
          padding:'20px 3%',
          borderLeft:'1px #F6F6F6 solid',
          '& .MuiAccordionSummary-root':{
              borderBottom:'1px #5f80a9 solid',
              height:'40px',
              color:'#bfc4cb',
              padding:'0px',
              fontFamily:'Poppins',
          },
          '& .MuiAccordion-root:before':{
              display:'none'
          },
          '& .MuiTab-root':{
            minHeight:'40px'
          },
          '& .MuiTabs-root':{
            minHeight:'40px'
          }
      },   
  Careplan:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
        overflowX:'auto'
               },
      '& .MuiAccordionSummary-root':{
          borderBottom:'1px #e3e5e5 solid',
          height:'auto',
          color:'#bfc4cb',
          padding:'0px',
          fontFamily:'Poppins',
          '@media only screen and (max-width: 1024px)':{
            width:1000,
                  },
      },
      '& .MuiAccordion-root.Mui-expanded':{
          margin:'0px'
      },
      '& .MuiAccordionSummary-content':{
          margin:'0',
          order:'2',
          width:'100%'
      },
      '& .MuiIconButton-root':{
          padding:'5px',
          fontSize:'16px',
          marginRight:'5px',
          color:'#88b3eb',
          position:'absolute',
          left:'-25px'
      },
      '& .MuiAccordion-root:before':{
          display:'none'
      }
      },   
  topheading:{
    fontSize:'18px',
    fontWeight:'normal',
    color:'#000',
    fontWeight:'500'
  },
  Filter:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-end',
      alignItems:'flex-end'
  },
  Downarrow:{
      fontSize:'20px',
      color:'#0f3d6e',
      marginLeft:'5px'
  },
  Filtertext:{
      display:'flex',
      alignItems:'center',
      margin:'0 0 7px',
      '& span':{
          color:'#bfc4cb',
          marginRight:'5px'
      }
  },
  mainAccordion: {
    background: '#f3f3f3', 
    color: '#0f3d6e !important', 
    borderRadius: '10px', 
    padding:'4px 10px !important', 
    border:'none !important',
    marginBottom: '15px'
  },
  contacttab:{
    background:'#F3F3F3',
    borderRadius:'10px',
    boxShadow:'none',
    color:'#000',
    textTransform:'capitalize',
    overflow:'hidden',
    '& .MuiTabScrollButton-root:last-child':{
        position:'absolute',
        right:'-15px',
        marginTop:'5px'
    },
    '& .MuiTabScrollButton-root:first-child':{
      position:'absolute',
      left:'-15px',
      zIndex:'99',
      marginTop:'5px'
  },
    '& .MuiTabs-indicator':{
      display:'none !important'
    },
    '& .MuiTabScrollButton-root':{
        width:'25px',
        height:'25px',
        borderRadius:'50%',
        background:'#88b3eb',
        color:'#fff',
    },
    '& .MuiTab-root':{
      textTransform:'capitalize',
      fontFamily:'Poppins'
    },
    '& .MuiTabs-flexContainer':{
      borderRadius:'10px',
      justifyContent:'space-between',
      background:'#F3F3F3',
      textTransform:'capitalize',
      '& .MuiTab-textColorPrimary.Mui-selected':{
        background:'#88b3eb',
        color:'#fff',
        borderRadius:'10px'
      },
      '& .MuiTab-textColorInherit':{
        textTransform:'capitalize',
        fontSize:'16px',
        padding:'0 22px'
      },
      '& .MuiTab-textColorPrimary': {
        color: '#0f3d6e'
      }
    },
    
  },
  datepicker:{
    borderRadius:'10px !important',
      height:'45px',
      width:'96%',
      fontFamily:'Poppins',
      fontSize:'14px',
      background:'#F9F9F9',
      border:'1px solid #D5D5D5 !important',
      paddingLeft:15,
      '&:focus':{
        border:'1px #0f3d6e solid !important',
      }
  },
  Editbtn:{
    background:'#fff',
    border:'1px #AEAEAE solid',
    width:'60px',
    height:'30px',
    color:'#0f3d6e',
    textTransform:'capitalize',
    borderRadius:'10px',
    fontWeight:'600',
    '&:hover':{
      background:'#0f3d6e',
      color:'#fff',
    }
  },
  icon:{
    color:'#0f3d6e',
    fontSize:'20px',
    marginRight:'10px'
  },
  throw:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'2px #E3E5E5 solid',
      alignItems:'center',
      fontSize:'13px',
      lineHeight:'16px',
      color:'#ffffff',
      background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
      padding: 6,
      width:'98%',
      alignItems:'center',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
        width:1000,
                },
  },
  tdrow:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'0px #E3E5E5 solid',
      fontSize:'12px',
      padding:'10px 0',
      width:'100%',
      alignItems:'center',
      lineHeight:'15px',
      color:'#000',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)':{
        width:1000,
                },
  },
  providerbtn:{
      display:'flex',
      cursor:'pointer',
      '& span':{
          display:'flex',
          flexDirection:'column',
          width:'20px',
          marginRight:'10px',
          '& button':{
              background:'none',
              border:'none',
              height:'10px',
              cursor:'pointer'
          }
      }
  },
  Row1:{
      width:'19.5%',
      paddingRight:'0.5%'
  },
  Row2:{
      width:'14.5%',
      paddingRight:'0.5%',
      textAlign:'left'
      
  },
  Row3:{
      width:'11.5%',
      paddingRight:'0.5%',
      textAlign:'right'
  },
  Row4:{
      width:'13.5%',
      paddingRight:'0.5%',
      textAlign:'right'
  },
  Row5:{
      width:'14.5%',
      paddingRight:'0.5%',
      textAlign:'right'
  },
  Row6:{
      width:'11.5%',
      paddingRight:'0.5%',
      textAlign:'right',
    
  },
  Row7:{
      width:'11.5%',
      paddingRight:'0.5%',
      display:'flex',
      justifyContent:'center',
      textAlign:'right'
  },
  Row8:{
      width:'14.5%',
      paddingRight:'0.5%',
      display:'flex',
      justifyContent:'flex-end',
      textAlign:'right'
  },
  Row9:{
      width:'9.5%',
      paddingRight:'0.5%',
      textAlign:'right'
  },
  Plandetails:{
      listStyle:'none',
      margin:'12px 0 0',
      minHeight:'300px',
      padding:'0px',
      '& li':{
          fontSize:'12px',
          marginBottom:'25px',
          display:'flex',
          '& span':{
              flex:'0 0 200px',
              color:'#114ebd'
          }
      }
  },
  modal:{
      '& .MuiPaper-rounded':{
          borderRadius:'10px !important',
          padding:'20px',
          width:'800px',
          maxWidth:'776px'
      }
  },
  ccmmodal:{
      borderRadius:'10px',
  },
  formcol:{
    marginBottom:'15px !important'
  },
  modalbtn:{
      display:'flex',
      justifyContent:'flex-end',
      marginRight:'30px',
      marginLeft:'15px',
      alignItems:'center'
  },
  Optionsbtn:{
      width:'150px',
      border:'1px #AEAEAE solid',
      borderRadius:'10px',
      display:'flex',
      justifyContent:'space-between',
      textTransform:'capitalize',
      marginTop:'10px',
      float:'right'
  },
  input:{ 
        
      border:'none',
      borderRadius:'10px',
      height:'42px',
      width:'100%',
      fontFamily:'Poppins',
      fontSize:'12px'
  },
  textarea:{
      width:'100%',
      borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'10px 15px',
          fontFamily:'Poppins',
          fontSize:'12px'
  },
  Editplan:{
      listStyle:'none',
      margin:'0px',
      padding:'0px',
      '& li':{
          display:'flex',
          fontSize:'14px',
          alignItems:'center',
          margin:'10px 0',
          '& span':{
            fontSize:'14px',
            color:'#121212',
            width: '50%'
          },
          '& p':{
              flex:'0 0 200px',
              color:'#bfc4cb',
              margin:'0px'
          }
      },
      '& .MuiInput-underline:before':{
          display:'none'
      },
      '& .MuiInput-underline:after':{
          display:'none'
      },
      '& .MuiInput-formControl':{ 
          height:'42px',
      },
      '& .MuiInputBase-input':{
          height:'42px',
          borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'0 15px',
          fontFamily:'Poppins',
          fontSize:'12px',
          color:'#000'
      },
      '& .react-dropdown-select-input':{
        fontSize:14,
        width: '100%'
      },
  },
  Editplan2:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        display:'flex',
        fontSize:'14px',
        alignItems:'center',
        margin:'10px 0',
        '& span':{
          fontSize:'14px',
          color:'#121212',
          width: '100%'
        },
        '& p':{
            flex:'0 0 200px',
            color:'#bfc4cb',
            margin:'0px'
        }
    },
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'42px',
    },
    '& .MuiInputBase-input':{
        height:'42px',
        borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'0 15px',
        fontFamily:'Poppins',
        fontSize:'12px',
        color:'#000'
    },
    '& .react-dropdown-select-input':{
      fontSize:14,
      width: '100%'
    },
  },
  loginbtn:{
      background:'#0f3d6e',
      padding:'0 40px',
      width:'120px',
      height:'40px',
      borderRadius:'10px',
      color:'#fff',
      marginTop:'20px',
      textTransform:'capitalize',
      '&:hover':{
          background:'#333'
      }
  },
  btncol:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
  },
  closebtn:{
      width:'40px',
      position:'absolute',
      right:'10px',
      height:'40px',
      background:'#fff',
      top:'10px',
      minWidth:'40px',
      '&:hover':{
          background:'#fff'
      }
  },
  closeicon:{
      fontSize:'25px',
      color:'#0f3d6e'
  },
  plantop:{
      display:'flex',
      justifyContent:'space-between',
      marginBottom:'20px',
      marginTop:'10px',
      width: '100%'
  },
  recordbtn:{
      background:'#fff',
      fontSize:'16px',
      color:'#0f3d6e',
      textTransform:'capitalize',
      '&:hover':{
          background:'#fff'
      }
  },
  addicon:{
      fontSize:'20px',
      color:'#0f3d6e',
      marginRight:'10px'
  },
  Tabcol:{
      '& .MuiTabPanel-root':{
          padding:'0px'
      }
  },
  radiobtn:{
    '& span':{
      fontSize:'13px',
      color:'#000'
    }
  },
  formgroup:{
    width:'100%',
    '& label':{
      color:'#000',
      marginBottom:'3px',
      display:'flex'
    }
  },
  Goalbtn:{
      width:'230px',
      height:'40px',
      borderRadius:'10px',
      border:'1px solid #AEAEAE',
      fontSize:'16px',
      color:'#0f3d6e',
      textTransform:'capitalize',
      marginTop:'30px'
  }
     }));