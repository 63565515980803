import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { makeStyles } from '@material-ui/core/styles'
  import Header from '../components/Header'
  import { Box,Grid, Link } from '@material-ui/core'
  import Button from '@material-ui/core/Button'
  import Accordion from '@material-ui/core/Accordion'
  import AccordionDetails from '@material-ui/core/AccordionDetails'
  import AccordionSummary from '@material-ui/core/AccordionSummary'
  import Typography from '@material-ui/core/Typography'
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
  import { useTime } from 'react-timer-hook'
  import TextField from '@material-ui/core/TextField'
  import Dialog from '@material-ui/core/Dialog'
  import DialogActions from '@material-ui/core/DialogActions'
  import Select from "react-dropdown-select"
  import DialogContent from '@material-ui/core/DialogContent'
  import DialogContentText from '@material-ui/core/DialogContentText'
  import DialogTitle from '@material-ui/core/DialogTitle'
  import Setting from '../components/Setting'
  import LeftNav from '../components/LeftNav'
  import Patientinfo from '../components/Patientinfo'
  import Timer from '../components/Timer'
  import TextareaAutosize from '@material-ui/core/TextareaAutosize'
  import FormControlLabel from '@material-ui/core/FormControlLabel'
  import FormControl from '@material-ui/core/FormControl'
  import FormLabel from '@material-ui/core/FormLabel'
  import Checkbox from '@material-ui/core/Checkbox'
  import FormGroup from '@material-ui/core/FormGroup'
  import { FaPlusCircle } from "react-icons/fa"
  import { BiXCircle,BiDownArrowAlt } from "react-icons/bi"
  import DatePicker from "react-datepicker"
  import Slide from '@material-ui/core/Slide'
  import { Pagination } from '@material-ui/lab';
  import { confirmAlert } from 'react-confirm-alert'; // Import
  import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
  
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from "react-loader-spinner";
  import { stateList } from '../Util/DropDownData'
  import { checkPhoneNumbers, checkPhone, checkEmail, checkFaxNumber, checkName, checkUSPhone } from '../Util/validations'
  import { apiAxios } from "../Util/ApiAxios"
  import moment from "moment";
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  
  function PatientPharmacy({ options }) {
      const classes = useStyles();
      const {
          seconds,
          minutes,
          hours,
          ampm,
        } = useTime({ format: '12-hour'});
      const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
   
    const [showLoader, setShowLoader] = React.useState(false);
    let pat_details = JSON.parse(localStorage.getItem("patient"));
    
  
    // const handleModal = (data) => {
    //   if(data == ""){
    //     setConditionDescription("");
    //     setFinalizeCat("");
    //     setIcd10codes("");
    //     setIcd10("");
    //     setDiagnosisDate("");
    //     setEditData("");
    //   }
    //   else{
    //     setEditData(data);
    //     setConditionDescription(data.condition_description);
    //     setFinalizeCat(data.finalize_category);
    //     setDiagnosisDate(new Date(data.diagnosis_date));
    //     if(icdList){
    //       for(var i=0;i< icdList.length; i++){
    //         if(data.icd10_codes == icdList[i].label){
    //           setIcd10(icdList[i]);
              
    //         }
    //       }
    //     }
    //   }
    //   console.log("icd - ", icd10);
    //   setOpen(true);
    // }
  
   
    // tab //
    const [value, setValue] = React.useState('1');
    const [editData, setEditData] = React.useState("");
   
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
   
    const [pharmacyList, setPharmacyList] = React.useState("");
    const [message, setMessage] = React.useState("");
  
    const [pharmacyName, setPharmacyName] = useState("");
    const [city, setCity]= useState("");
    const [state, setState]= useState("")
    const [phone, setPhone]= useState("")
    const [email,setEmail]= useState("");
    const [fax,setFax] = useState("")
    const [pharmacyID, setPharmacyID] = useState("")
  
    // error

    const [pharmacyNameError, setPharmacyNameError]= useState(false)
    const [cityError, setCityError]= useState(false)
    const [stateError, setStateError]= useState(false)
    const [phoneError, setPhoneError]= useState(false)
    const [faxError, setFaxError]= useState(false)
    const [emailError, setEmailError] = useState(false)




    useEffect(() => {
  
        getPharmacy();
  
    }, []);  
  
  
  
  
  
    const getPharmacy = useCallback(
      async ({
        table_config_rows_per_page = '15',
        table_config_page_no = '1',
      } = {}) => {
  
      //setCurrentPage(parseInt(table_config_page_no));
  
      let pd = JSON.parse(localStorage.getItem("patient"));
          
      setPharmacyList([]);
      setMessage("Loading...");
  
      let data ={
          table_config_page_no: table_config_page_no,
          pd_id: pat_details.pd_id,
       
          table_config_rows_per_page: table_config_rows_per_page,
        }
  
      apiAxios
          .post("/provider/list-pharmacy", data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("Token")
            },
          })
          .then((response) => {
            console.log("response --> ",response);
            if(response.data.statusCode == 200) {
                setPharmacyList(response.data.data); 
                setMessage("");
                //setTotalPages(response.data.total_pages);
                
            }
            else if(response.data.statusCode == 502){
                setMessage("No Data Found");
            }
            else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                
                    setMessage(response.data.message);    
            }
          })
          .catch((error) => {
            console.log(error);
    
            toast.error(error.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
  
                // localStorage.removeItem("Refresh_Token");
                // localStorage.removeItem("Token");
                // window.location.href = "/";        
  
    
          });
    }
    );

    // submit
    const handleSubmit = (flag) => {
        let isValid = true;


        console.log(state)

    
        if (!checkName(pharmacyName) || pharmacyName.trim().length == 0) {
          isValid = false;
          setPharmacyNameError(true);
        } else {
            setPharmacyNameError(false);
        }
        if (!checkName(city) || city.trim().length == 0) {
          isValid = false;
          setCityError(true);
        } else {
          setCityError(false);
        }
        if (!checkUSPhone(phone)) {
          isValid = false;
          setPhoneError(true);
        } else {
          setPhoneError(false);
        }
        if (state) {
          
          setStateError(false);
        } else {
          setStateError(true);
          isValid = false;
        }

        if(!checkEmail(email)){
            setEmailError(true)
            isValid = false
        }else {
            setEmailError(false)
        }

        if(fax){
            if(!checkFaxNumber(fax)){
                setFaxError(true)
                isValid = false
            }else {
                setFaxError(false)
            }
        }else {
            setFaxError(false)
        }
    
        if (isValid) {
          setShowLoader(true);
          if (flag == "1") {
            let dataEdit = {
              pd_id: pat_details.pd_id,
	          pharmacy_name:pharmacyName,
	          city: city,
	          state:  state[0]? state[0].label : "",
	          phone_no: phone.replace(/[^0-9]/g,""),  
	          email: email,
	          fax: fax,
             
            };
    
           
    
            apiAxios
              .post(
                "/provider/create-pharmacy",
                dataEdit,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("Token"),
                  },
                }
              )
              .then((response) => {
                console.log(response.data);
                if (response.data.status == 200) {
                  toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  getPharmacy();
                  setShowLoader(false);
                   handleClose();
                } else {
                  toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setShowLoader(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setShowLoader(false);
                toast.error(error.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          } else {
            let dataAdd = {
              pd_id: pat_details.pd_id,
	          pharmacy_name:pharmacyName,
	          city: city,
	          state: state.label ? state.label : state[0].label,
	          phone_no: phone.replace(/[^0-9]/g,""),  
	          email: email,
	          fax: fax,
              pharmacy_id: pharmacyID
             
            };
    
            console.log("dataAdd", dataAdd);
    
            apiAxios
              .post(
                "/provider/edit-pharmacy",
                dataAdd,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("Token"),
                  },
                }
              )
              .then((response) => {
                console.log(response.data);
                if (response.data.status == 200) {
                  toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  getPharmacy();
                  setShowLoader(false);
                  handleClose();
                } else {
                  toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setShowLoader(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setShowLoader(false);
                toast.error(error.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }
        }
      };


    //modal

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (data) => {


        console.log(data)

        if(!data){
            setEditData("")
            setPharmacyName("")
            setCity("")
            setState("")
            setPhone("")
            setEmail("")
            setFax("")
           

        }else {
            setEditData(data)
            setPharmacyName(data.pharmacy_name)
            setCity(data.city)
            if(data.state){
            let stateCatList = stateList();
  
            for(var i=0; i<stateCatList.length; i++){
        
              let str = data.state
            // let stateName = str.charAt(0).toUpperCase() + str.slice(1);
        
            const words = str.split(" ");
        
            // words.map((word) => { 
            //     return word[0].toUpperCase() + word.substring(1); 
            //  }).join(" ");
        
             for (let j = 0; j < words.length; j++) {
              words[j] = words[j][0]?.toUpperCase() + words[j].substr(1);
              }
          
                 let newWords = words.join(' ');
                  console.log(newWords)
            
                if(stateCatList[i].label ==  newWords){
                  console.log(stateCatList[i])
                  // let st = stateCatList[i]
                  // console.log(st)
                  setState(stateCatList[i]);
                  console.log(state)
                }
          }



        }else {
            setState("")
        }



              
            setPhone(formatPhoneNumber(data.phone))
            setEmail(data.email)
            setFax(data.fax)
           
        }



        setPharmacyID(data.id)


      setOpen(true);
    };
  
    const handleClose = () => {
       
        setEditData("")
        setPharmacyName("")
        setCity("")
        setState("")
        setPhone("")
        setEmail("")
        setFax("")
        
      setOpen(false);
    };
  
    // delete pharmacy

    function handleClickDelete(item) {
  
        //console.log("Delete = ", item);
      
        let pat_details = JSON.parse(localStorage.getItem("patient"));
      
        let data = {
          pharmacy_id: item.id,
          pd_id: pat_details.pd_id
        }
      
        apiAxios
        .post("/provider/delete-pharmacy", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.status == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
            
            getPharmacy();  
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  setShowLoader(false);       
          }
        })
        .catch((error) => {
          console.log(error);
      
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
      
              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        
      
      
        });
      }
      
       const deleteModal = (item) => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>Are you sure?</h1>
                <p>You want to delete this record?</p>
                <button className={classes.loginbtn} style={{border: '2px solid #0f3d6e', backgroundColor: '#fff', color: '#0f3d6e', cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
                <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                  onClick={() => {
                    handleClickDelete(item);
                    onClose();
                  }}
                >
                  Yes, Delete
                </button>
              </div>
            );
          }
        });
      };
      
      
    
  
    const handleChange2 = (event, newValue) => {
      setValue(newValue);
    };

    const handleInput = (e) => {
   
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      
      setPhone(formattedPhoneNumber);
    };
    
  

    function formatPhoneNumber(value) {
      // if input value is falsy eg if the user deletes the input, then just return
      if (!value) return value;
    
      // clean the input for any non-digit values.
      const phoneNumber = value.replace(/[^\d]/g, '');
    
      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;
    
      // we need to return the value with no formatting if its less then four digits
      // this is to avoid weird behavior that occurs if you  format the area code to early
    
      if (phoneNumberLength < 4) return phoneNumber;
    
      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
    
      // finally, if the phoneNumberLength is greater then seven, we add the last
      // bit of formatting and return it.
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    
    
    }
      return (
          <div>
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Header />
              <Box className={classes.Pagecontent}>
                 <Box className={classes.Leftcol}>
                 <Box className={classes.leftnav}>
                 
                  <Patientinfo />
                  
                   {/* left Accordion*/}
                   <Box className={classes.TabPatientinfo}>  
                  <LeftNav />
                  </Box>
                   <Box className={classes.bottomnav}>
                   <Setting />
                 </Box>
                 </Box>
                 
                 </Box>
                 {/* right col */}
                 <Box className={classes.Rightcol}>
                 <Timer />
                 
                    <Box className={classes.Careplan}>
                          <Box className={classes.plantop}>
                              
                              <h3 className={classes.topheading}>Pharmacy</h3>
                              <Button className={classes.recordbtn} onClick={()=>handleClickOpen(0)}><FaPlusCircle className={classes.addicon}  />Add Pharmacy</Button>

                          </Box>
                          <Box className={classes.pharmacyTable}>
                          <Box className={classes.throw}> 
                                    <Box className={classes.Row3}>Pharmacy Name</Box>
                                    <Box className={classes.Row2}>City</Box>
                                    <Box className={classes.Row1}>State</Box>
                                    <Box className={classes.Row4}>Phone No</Box>
                                    <Box className={classes.Row5}>Email</Box>
                                    <Box className={classes.Row6}>Action</Box>
                                    
                          </Box> 
                          {(pharmacyList.length > 0) ? 
                          <>
                          {pharmacyList.map((each) => (
                          <Box className={classes.tdrow}> 
                                    <Box className={classes.Row3}>{each.pharmacy_name}</Box>
                                    <Box className={classes.Row2}>{each.city}</Box>
                                    <Box className={classes.Row1}>{each.state}</Box>
                                    <Box className={classes.Row4}>{formatPhoneNumber(each.phone)}</Box>
                                    <Box className={classes.Row5}>{each.email}</Box>
                                    <Box className={classes.Row6}><span className={classes.editbtn} style={{marginRight: '6px'}} onClick={() => handleClickOpen(each)}>Edit</span> <span className={classes.deletebtn} onClick={()=>deleteModal(each)}>Delete</span></Box>
                                    
                          </Box>
                          ))}
                          </>
                          :
                          <Box style={{padding: '5%', textAlign: 'center', width:'90%'}}> 
                            {message}
                          </Box>
                          }
                          </Box>                  
                    </Box>
                    <br />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getPharmacy({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 
                      
                 </Box>
                 
                 
              </Box>
              
  
  
              <Dialog
        className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
          }}
        >
          {/* {props.isEdit ? "Edit" : "Add"} Pharmacy */}
         {!editData ? "Add" : "Update"}{" "}Pharmacy
        </DialogTitle>
          <Button
            onClick={() => {
                handleClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
          
        </Box>
        
        <DialogContent className={classes.ccmmodal}>
          <form>
            <Box className={classes.Formcol}>
              <label>Pharmacy Name*</label>
              <TextField
                className={classes.input}
                placeholder="Name of Pharmacy"
                type="text"
                value={pharmacyName}
                onChange={(e) => {
                    setPharmacyNameError(false)
                    setPharmacyName(e.target.value);
                }}
              />
            </Box>
            {pharmacyNameError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please provide a valid pharmacy name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>City*</label>
              <TextField
                className={classes.input}
                placeholder="City"
                type="text"
                value={city}
                onChange={(e) => {
                  setCityError(false);
                  setCity(e.target.value);
                }}
              />
            </Box>
            {cityError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please provide a valid city.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>State*</label>
              <FormControl className={classes.formControl}>
                <Select
                  options={stateList()}
                  value={state}
                //   onChange={(e) => {
                //     setState(e[0].label);
                //   }}
                  onChange={setState}
                  placeholder={state? state.label : "Select State"}
                  className={classes.select}
                />
              </FormControl>
            </Box>
            {stateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please select a valid state.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Phone*</label>
              <TextField
                className={classes.input}
                placeholder="Phone Number"
                type="tel"
                value={phone}
                onChange={(e) => {
                 
                    handleInput(e)
                }}
              />
            </Box>
            {phoneError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please provide a valid phone number.
              </p>
            ) : (
              <></>
            )}
             <Box className={classes.Formcol}>
              <label>Email*</label>
              <TextField
                className={classes.input}
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                }}
              />
            </Box>
            {emailError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please provide a valid email.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Fax</label>
              <TextField
                className={classes.input}
                placeholder="Fax Number"
                type="tel"
                value={fax}
                onChange={(e) => {
                  setFaxError(false);
                
                    setFax(e.target.value);
                }}
              />
            </Box>
            {faxError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  textAlign : "right"
                }}
              >
                Please provide a valid fax number.
              </p>
            ) : (
              <></>
            )}
          </form>
        </DialogContent>
        {/* <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
                onClick = {()=>{
                    handleSubmit(1)
                }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
                handleClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions> */}
    
        {!editData?
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (  
                    <>
                    <Button size="large" className={classes.Engagebtn} onClick={() => handleSubmit(1)}>
                        Submit
                    </Button>
                    <Button
                    onClick={() => {
                        handleClose();
                    }}
                    className={classes.Closebtn}
                  >
                    Close
                  </Button>
                  </>  
                )
        }
        
        </DialogActions>
        :
        <DialogActions className={classes.modalbtn}>
        {showLoader ? (
                  <Loader
                    type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (    
                    <>
                    <Button size="large" className={classes.Engagebtn} onClick={() => handleSubmit(2)}>
                        Update
                    </Button>
                     <Button
                     onClick={() => {
                         handleClose();
                     }}
                     className={classes.Closebtn}
                   >
                     Close
                   </Button>
                   </>
                )
        }
       
          
        </DialogActions>
        }


      </Dialog>
              
          </div>
      )
  }
  
  export default PatientPharmacy
  const useStyles = makeStyles(() => ({
      Pagecontent:{
          width:'100%',
          display:'flex',
          textAlign:'left'
      },
      TabPatientinfo:{
        '@media only screen and (max-width: 820px)':{
          display:'none'
                },
        '@media only screen and (max-width: 768px)':{
          display:'none'
                },
      },
      addicon:{
        marginRight:5,
      },
      
      Leftcol:{
          width: '22%',
          background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
          padding:'20px 3%',
          position:'relative',
          minHeight:'1050px',
          '@media only screen and (max-width: 1200px)':{
            width: '24%',
            padding:'20px 1%',
                  },
                  '@media only screen and (max-width: 820px)':{
                    position:'absolute',
                    background:'none',
                    width: '98%',
                    padding:'20px 1%',
                    minHeight:35,
                          },
                  '@media only screen and (max-width: 768px)':{
                    position:'absolute',
                    background:'none',
                    width: '98%',
                    padding:'20px 1%',
                    minHeight:35,
                          },
      },
      topheading:{
        fontSize:'18px',
        fontWeight:'normal',
        color:'#000',
        fontWeight:'500'
      },
      bottomnav:{
          position:'absolute',
          bottom:'0px',
          left:'0px'
      },
      leftnav:{
      position:'absolute',
      top:'15px',
      bottom:'15px',
      left:'40px',
      right:'40px',
      '@media only screen and (max-width: 1200px)':{
        left:'20px',
        right:'20px',
              },
      },
      Rightcol:{
          width:'78%',
          padding:'20px 3%',
          borderLeft:'1px #F6F6F6 solid',
          '@media only screen and (max-width: 1200px)':{
            width:'72%',
            padding:'0 1%',
                  },
                  '@media only screen and (max-width: 820px)':{
                    width:'96%',
                    padding:'0 2%',
                    marginTop:60,
                          },
                  '@media only screen and (max-width: 768px)':{
                    width:'96%',
                    padding:'0 2%',
                    marginTop:60,
                          },
          '& .MuiAccordionDetails-root':{ 
              paddingLeft:'0px',
              paddingRight:'0px'
          },
          '& .MuiTabPanel-root':{
            padding:'20px 0'
          },
          '& .MuiAccordionSummary-root':{
              borderBottom:'2px #5f80a9 solid',
              height:'auto',
              color:'#bfc4cb',
              fontFamily:'Poppins',
          },
          '& .MuiAccordion-root:before':{
              display:'none'
          },
          '& .MuiTab-root':{
            minHeight:'40px'
          }
      },
      pharmacyTable:{
        width:'100%',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          overflowX:'auto'
        }
      },  
      btncol:{
          display:'flex',
          justifyContent:'space-between',
          alignItems:'center',
          marginTop:'0',
          marginBottom:20,
     }, 
     select: {
        width: "100%",
        border: "none !important",
        borderRadius: "10px !important",
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        height: "50px",
        fontSize: "18px !important",
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
      }, 
      input: {
        border: "none",
        borderRadius: "10px",
        height: "42px",
        width: "100%",
        fontFamily: "Poppins",
        fontSize: "12px",
      },
  
     datepicker:{
      borderRadius:'10px !important',
        height:'45px',
        width:'96%',
        fontFamily:'Poppins',
        fontSize:'14px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5 !important',
        paddingLeft:15,
        '&:focus':{
          border:'1px #0f3d6e solid !important',
          zIndex: '999'
        }
    },
  
    modal: {
        "& .MuiPaper-rounded": {
          borderRadius: "10px !important",
          padding: "20px",
          width: "550px",
          maxWidth: "550px",
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiInput-formControl": {
          height: "42px",
        },
        "& .MuiInputBase-input": {
          height: "42px",
          borderRadius: "10px",
          background: "#F9F9F9",
          border: "1px solid #D5D5D5",
          padding: "0 15px",
          fontFamily: "Poppins",
          fontSize: "12px",
          color: "#000",
        },
        "& .MuiInput-input:focus": {
          border: "1px #0f3d6e solid",
          boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
        },
        "& .MuiFormControl-root": {
          width: "78%",
        },
        "& .MuiDialogTitle-root": {
          paddingTop: 0,
        },
      },
      ccmmodal: {
        textAlign: "left",
        "& h3": {
          fontSize: "28px",
          color: "#0f3d6e",
          marginBottom: "0px",
          marginTop: "15px",
        },
      },

      formControl: {
        minWidth: 120,
      },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  Optionsbtn:{
      width:'150px',
      border:'1px #AEAEAE solid',
      borderRadius:'10px',
      display:'flex',
      justifyContent:'space-between',
      textTransform:'capitalize',
      float:'right'
  },
  input:{ 
        
      border:'none',
      borderRadius:'10px',
      height:'42px',
      width:'100%',
      fontFamily:'Poppins',
      fontSize:'12px'
  },
  textarea:{
      width:'100%',
      borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'10px 15px',
          fontFamily:'Poppins',
          fontSize:'12px'
  },
  Editplan:{
    listStyle:'none',
    margin:'0px',
    padding:'0px',
    '& li':{
        display:'flex',
        fontSize:'14px',
        alignItems:'center',
        marginBottom:'15px',
        '& span':{
            flex:'0 0 120px',
            color:'#121212',
            maxHeight: '50px'
        }
    },
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'42px',
        
    },
    '& .MuiInputBase-input':{
        height:'42px',
        borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'0 15px',
        fontFamily:'Poppins',
        fontSize:'12px',
        color:'#000'
    },
    '& .MuiInput-input:focus':{
      border:'1px #0f3d6e solid',
      boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
  }
  },
  loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  closeicon:{
    fontSize:'25px',
    color:'#0f3d6e'
  },
  contacttab:{
    background:'#fff',
    boxShadow:'none',
    color:'#000',
    textTransform:'capitalize',
    '& .MuiTabs-indicator':{
      display:'none !important'
    },
    '& .MuiTabs-flexContainer':{
      borderRadius:'10px',
      background:'#F3F3F3',
      width:'360px',
      textTransform:'capitalize',
      overflow:'hidden',
      '& .MuiTab-textColorInherit.Mui-selected':{
        background:'#0f3d6e',
              color:'#ffffff' 
      },
      '& .MuiTab-textColorInherit':{
        textTransform:'capitalize',
        fontSize:'16px',
        padding:'0 22px'
      }
    },
    
  },
  editbtn: {
    background: '#88b3eb',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer',
  },
  deletebtn: {
    background: '#0f3d6e',
    padding: '6px 8px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer'
  },
  
  Careplan:{
    width:'100%',
    '& .MuiAccordionSummary-root':{
        borderBottom:'1px #e3e5e5 solid',
        height:'40px',
        color:'#bfc4cb',
        padding:'0px',
        fontFamily:'Poppins',
    },
    '& .MuiAccordion-root.Mui-expanded':{
        margin:'0px'
    },
    '& .MuiAccordionSummary-content':{
        margin:'0',
        order:'2',
        width:'100%'
    },
    '& .MuiIconButton-root':{
        padding:'5px',
        fontSize:'16px',
        marginRight:'5px',
        color:'#88b3eb',
        position:'absolute',
        left:'-25px'
    },
    '& .MuiAccordion-root:before':{
        display:'none'
    }
  },
  plantop:{
    display:'flex',
    justifyContent:'space-between',
    marginBottom:'30px',
    marginTop:'20px',
    '@media only screen and (max-width: 820px)':{
      marginBottom:'0px',
      marginTop:'10px',
            },
    '@media only screen and (max-width: 768px)':{
      marginBottom:'0px',
      marginTop:'10px',
            },
  },
  recordbtn:{
    background:'#fff',
    fontSize:'16px',
    color:'#0f3d6e',
    textTransform:'capitalize',
    '&:hover':{
        background:'#fff'
    }
  },
  icon:{
    color:'#0f3d6e',
    fontSize:'20px',
    marginRight:'10px'
  },
  throw:{
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'2px #E3E5E5 solid',
    alignItems:'center',
    fontSize:'13px',
    lineHeight:'16px',
    color:'#fff',
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
    padding:'1%',
    width:'98%',
    alignItems:'center',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:900,
    }
  },
  tdrow:{
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'1px #E3E5E5 solid',
      fontSize:'14px',
      padding:'20px 0',
      width:'100%',
      alignItems:'center',
      lineHeight:'15px',
      color:'#000',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
        width:900,
      }
  },
  providerbtn:{
      display:'flex',
      cursor:'pointer',
      alignItems:'center',
      '& span':{
          display:'flex',
          flexDirection:'column',
          width:'20px',
          marginRight:'10px',
          '& button':{
              background:'none',
              border:'none',
              height:'10px',
              cursor:'pointer'
          }
      }
  },
  Row1:{
    width:'14.5%',
    paddingRight:'0.5%',
    textAlign: "left"
  },
  Row2:{
    width:'11.5%',
    paddingRight:'0.5%',
    textAlign: "center",
    wordWrap: "break-word"
  },
  Row3:{
    width:'12.5%',
    paddingRight:'0.5%',
    textAlign: "center",
    wordWrap: "break-word"
  },
  Row4:{
    width:'19.5%',
    paddingRight:'0.5%',
    textAlign: "left"
  },
  Row5:{
    width:'19.5%',
    paddingRight:'0.5%',
    wordWrap: "break-word",
    textAlign: "left"
  },
  Row6:{
    width:'14.5%',
    paddingRight:'0.5%',
    display:'flex',
    justifyContent:'flex-end',
    textAlign:'right'
  },
  Row7:{
    width:'14.5%',
    paddingRight:'0.5%',
    display:'flex',
    justifyContent:'center',
    textAlign:'right'
  },
  
  
     }));