import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiArrowBack, BiClipboard, BiSearch, BiXCircle } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import { FiPlusSquare } from "react-icons/fi";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { apiAxios } from "../../Util/ApiAxios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import {
  checkPhoneNumbers,
  checkEmail,
  checkName,
  checkPhone,
  checkUSPhone
} from "../../Util/validations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CareGiverModal(props) {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [relation, setRelation] = useState("");
  const [phoneHome, setPhoneHome] = useState("");
  const [phoneMobile, setPhoneMobile] = useState("");
  const [emailPrimary, setEmailPrimary] = useState("");
  const [emailSecondary, setEmailSecondary] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneHomeError, setPhoneHomeError] = useState(false);
  const [phoneMobileError, setPhoneMobileError] = useState(false);
  const [emailPrimaryError, setEmailPrimaryError] = useState(false);
  const [emailSecondaryError, setEmailSecondaryError] = useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  let pat_details = JSON.parse(localStorage.getItem("patient"));

  useEffect(() => {
    console.log("Care Giver Props - ", props);
    if (props.isOpen && props.isEdit) {
      setFirstName(props.caregiverInfo.patient_caregiver_contacts_first_name);
      setLastName(props.caregiverInfo.patient_caregiver_contacts_last_name);
      setRelation(props.caregiverInfo.patient_caregiver_contacts_relationship);
      setPhoneHome(
        formatPhoneNumber(props.caregiverInfo.patient_caregiver_contacts_home_phone_number)
      );
      setPhoneMobile(
        formatPhoneNumber(props.caregiverInfo.patient_caregiver_contacts_mobile_phone_number)
      );
      setEmailPrimary(
        props.caregiverInfo.patient_caregiver_contacts_primary_email
      );
      setEmailSecondary(
        props.caregiverInfo.patient_caregiver_contacts_secondary_email
      );
    } else {
      setFirstName("");
      setLastName("");
      setRelation("");
      setPhoneHome("");
      setPhoneMobile("");
      setEmailPrimary("");
      setEmailSecondary("");
    }
    setFirstNameError(false);
    setLastNameError(false);
    setPhoneHomeError(false);
    setPhoneMobileError(false);
    setEmailPrimaryError(false);
    setEmailSecondaryError(false);
  }, [props.isOpen === true]);

  const handleSubmit = () => {
    let isValid = true;

    if (!checkName(firstName.trim())) {
      isValid = false;
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (!checkName(lastName.trim())) {
      isValid = false;
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (phoneHome) {
      if (!checkUSPhone(phoneHome)) {
        isValid = false;
        setPhoneHomeError(true);
      } else {
        setPhoneHomeError(false);
      }
    }

    if (!checkUSPhone(phoneMobile)) {
      isValid = false;
      setPhoneMobileError(true);
    } else {
      setPhoneMobileError(false);
    }

    if (!checkEmail(emailPrimary)) {
      isValid = false;
      setEmailPrimaryError(true);
    } else {
      setEmailPrimaryError(false);
    }
    if (emailSecondary.trim().length > 0) {
      if (!checkEmail(emailSecondary)) {
        isValid = false;
        setEmailSecondaryError(true);
      } else {
        setEmailSecondaryError(false);
      }
    }

    if (isValid) {
      setShowLoader(true);
      if (props.isEdit) {
        let dataEdit = {
          pd_id: pat_details.pd_id,
          caregiver_contact_id: props.caregiverInfo.id,
          emr_id: "1",
          table_config_rows_per_page: "15",
          table_config_page_no: "1",
          patient_caregiver_contacts_first_name: firstName,
          patient_caregiver_contacts_last_name: lastName,
          patient_caregiver_contacts_relationship: relation,
          patient_caregiver_contacts_home_phone_number: phoneHome ? phoneHome.replace(/[^0-9]/g,"") : "",
          patient_caregiver_contacts_work_phone_number: "",
          patient_caregiver_contacts_mobile_phone_number: phoneMobile.replace(/[^0-9]/g,""),
          patient_caregiver_contacts_primary_email: emailPrimary,
          patient_caregiver_contacts_secondary_email: emailSecondary,
          patient_plan_id: "",
          patient_plan_name: "",
          provider_npi_ccm_number: "",
          patient_primary_contact_phone: "",
          patient_primary_emergency_contact: "",
          patient_caregiver_contact_id: "",
          caregivers_updated_user_id: "",
          "authorizer-principal-id": {
            user_id: "1240a21e-18b3-4fc2-880c-8744577eb609",
            client_id: "95058708-20a9-4feb-8e0a-5119585db988",
            login_type: "care_team",
            user_login_panel_type: 1,
          },
          action: "update_caregiver_contact_information",
        };

        console.log("dataEdit", dataEdit);

        apiAxios
          .post("/patient/update-patient-caregiver-contact", dataEdit, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        let dataAdd = {
          pd_id: pat_details.pd_id,
          caregiver_contact_id: props.caregiverInfo.id,
          emr_id: "1",
          patient_caregiver_contacts_first_name: firstName,
          patient_caregiver_contacts_last_name: lastName,
          patient_caregiver_contacts_relationship: relation,
          patient_caregiver_contacts_home_phone_number: phoneHome ? phoneHome.replace(/[^0-9]/g,"") : "",
          patient_caregiver_contacts_work_phone_number: "",
          patient_caregiver_contacts_mobile_phone_number: phoneMobile.replace(/[^0-9]/g,""),
          patient_caregiver_contacts_primary_email: emailPrimary,
          patient_caregiver_contacts_secondary_email: emailSecondary,
          patient_plan_id: "",
          patient_plan_name: "",
          provider_npi_ccm_number: "",
          patient_primary_contact_phone: "",
          patient_primary_emergency_contact: "",
          patient_caregiver_contact_id: "",
          caregivers_updated_user_id: "",
        };

        console.log("dataAdd", dataAdd);

        apiAxios
          .post("/patient/create-patient-caregiver-contact", dataAdd, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("Token"),
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
              props.onClose();
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowLoader(false);
            toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  };


  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhoneMobile(formattedPhoneNumber);
  };
  

  const handlePhone = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhoneHome(formattedPhoneNumber);
  };
  

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }


  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        className={classes.modal}
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.btncol}>
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Modalclose}
          >
            <BiXCircle
              size="30"
              color="#0f3d6e"
              className={classes.closeicon}
            />
          </Button>
        </Box>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            props.onClose();
          }}
        >
          {props.isEdit ? "Edit" : "Add"} Caregiver Information Details
        </DialogTitle>

        <DialogContent className={classes.ccmmodal}>
          <form>
            <Box className={classes.Formcol}>
              <label>First Name*</label>
              <TextField
                className={classes.input}
                placeholder="First Name"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError(false);
                }}
              />
            </Box>
            {firstNameError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid first name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Last Name*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Last Name"
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError(false);
                }}
              />
            </Box>
            {lastNameError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid last name.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Relationship</label>
              <TextField
                className={classes.input}
                placeholder="Enter Relationship"
                type="text"
                value={relation}
                onChange={(e) => {
                  setRelation(e.target.value);
                }}
              />
            </Box>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "poppins",
                fontWeight: "600",
                marginBottom: 20,
              }}
            >
              Phone Number(s)
            </Typography>
            <Box className={classes.Formcol}>
              <label>Mobile*</label>
              <TextField
                className={classes.input}
                placeholder="Enter Mobile Number"
                type="tel"
                value={phoneMobile}
                onChange={(e) => {
                 
                    handleInput(e)
                }}
              />
            </Box>
            {phoneMobileError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid mobile number.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Home</label>
              <TextField
                className={classes.input}
               
                type="tel"
                placeholder="Enter Home Number"
                value={phoneHome}
                onChange={(e) => {
                
                    handlePhone(e)
                }}
              />
            </Box>
            {phoneHomeError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid home number.
              </p>
            ) : (
              <></>
            )}
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "poppins",
                fontWeight: "600",
                marginBottom: 20,
              }}
            >
              Email(s)
            </Typography>
            <Box className={classes.Formcol}>
              <label>Primary Email*</label>
              <TextField
                className={classes.input}
                placeholder="Primary Email"
                type="text"
                value={emailPrimary}
                onChange={(e) => {
                  setEmailPrimary(e.target.value);
                  setEmailPrimaryError(false);
                }}
              />
            </Box>
            {emailPrimaryError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid primary email.
              </p>
            ) : (
              <></>
            )}
            <Box className={classes.Formcol}>
              <label>Secondary Email</label>
              <TextField
                className={classes.input}
                placeholder="Secondary Email"
                type="text"
                value={emailSecondary}
                onChange={(e) => {
                  setEmailSecondary(e.target.value);
                  setEmailSecondaryError(false);
                }}
              />
            </Box>
            {emailSecondaryError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              >
                Please provide a valid secondary email.
              </p>
            ) : (
              <></>
            )}
          </form>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          {showLoader ? (
            <Loader
              type="ThreeDots"
              color="#000000"
              height={50}
              width={50}
              timeout={0} //30 secs
            />
          ) : (
            <Button
              className={classes.Engagebtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Change
            </Button>
          )}
          <Button
            onClick={() => {
              props.onClose();
            }}
            className={classes.Closebtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CareGiverModal;
const useStyles = makeStyles(() => ({
  symptomsdetails: {
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px #ccc solid",
    },
    "& ul": {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      "& li": {
        lineHeight: "40px",
        display: "flex",
        fontSize: "15px",
        "& span": {
          color: "#bfc4cb",
          width: "250px",
        },
      },
    },
  },
  topheading: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000",
  },
  Filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    marginRight: 10,
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  Deletebtn: {
    background: "#fff",
    border: "1px #f12626 solid",
    width: "60px",
    height: "30px",
    color: "#f12626",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#f12626",
      color: "#fff",
    },
  },
  Addbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    height: "35px",
    textTransform: "capitalize",
    color: "#0f3d6e",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#0f3d6e",
      color: "#fff",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "42px",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      width: "150px",
      textAlign: "left",
      fontSize: 14,
      color: "rgba(0,0,0,0.6)",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "42px",
    },
    "& .MuiInputBase-input": {
      height: "42px",
      borderRadius: "10px",
      background: "#F9F9F9",
      border: "1px solid #D5D5D5",
      padding: "0 15px",
      fontFamily: "Poppins",
      fontSize: "12px",
      color: "#000",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0f3d6e solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiFormControl-root": {
      width: "78%",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  ccmmodal: {
    textAlign: "left",
    "& h3": {
      fontSize: "28px",
      color: "#0f3d6e",
      marginBottom: "0px",
      marginTop: "15px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    minWidth: 120,
  },
  Modalclose: {
    width: 45,
    height: 45,
    minWidth: 45,
    borderRadius: 50,
    position: "absolute",
    right: 12,
    top: 10,
  },
  Engagebtn: {
    background: "#0f3d6e",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
  Closebtn: {
    background: "#e92437",
    color: "#fff",
    borderRadius: 10,
    padding: "7px 20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.9)",
    },
  },
}));
